<template>
  <SimilarRidesChart :activities="similarActivities" :activity="activity" />
</template>

<script>
import SimilarRidesChart from 'components/charts/SimilarRidesChart'

export default {
  components: {
    SimilarRidesChart
  },

  props: {
    activity: {
      type: Object,
      required: true
    },
    similarActivities: {
      type: Array,
      required: true
    }
  },

  metaInfo() {
    return {
      title: this.activity?.name
    }
  }
}
</script>

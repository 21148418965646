<template>
  <div v-if="chartData" class="FitnessChart">
    <chart ref="chart" :options="chartData" :callback="setChart"></chart>
  </div>
</template>

<script>
import * as chartConfig from 'constants/chartConfig'

import dayjs from 'utils/dayjs'
import { isSameOrAfter } from 'utils/date'
import formatLabels from 'filters/formatLabels'

export default {
  props: {
    fitness: {
      type: Array,
      required: true
    },
    dateRange: {
      type: Object,
      required: true
    },
    height: {
      type: Number,
      required: false,
      default: 500
    }
  },

  data() {
    return {
      chart: undefined,
      drag: false
    }
  },

  computed: {
    fitnessData() {
      const data = this.fitness.filter(({ date }) => isSameOrAfter(date, this.dateRange.from))
      if (this.dateRange.id === 'all') {
        const firstRecord = data.findIndex(({ ctl }) => ctl > 0)
        // select only first day of week
        return data.slice(firstRecord).filter(({ date }) => dayjs(date).weekday() === 0)
      } else {
        return data
      }
    },

    chartData() {
      const series = []

      series.push({
        // color: '#004efb',
        color: '#50BEF1',
        borderColor: '#50BEF1',
        name: 'Fitness',
        type: 'areaspline',
        yAxis: 0,
        data: this.fitnessData.map(({ date, ctl }) => {
          return {
            x: dayjs(date).valueOf(),
            y: ctl
          }
        })
      })

      series.push({
        name: 'Form',
        color: '#ff5e3b',
        borderColor: '#ff5e3b',
        yAxis: 1,
        opacity: 0.85,
        data: this.fitnessData.map(({ date, tsb }) => {
          return {
            x: dayjs(date).valueOf(),
            y: tsb
          }
        })
      })

      series.push({
        name: 'Fatigue',
        color: '#90568a',
        borderColor: '#90568a',
        opacity: 0.8,
        yAxis: 2,
        data: this.fitnessData.map(({ date, atl }) => {
          return {
            x: dayjs(date).valueOf(),
            y: atl
          }
        })
      })

      const dates = this.fitnessData.map(({ date }) => date)
      const minAtl = Math.min(...this.fitnessData.map(({ atl }) => atl))
      const maxAtl = Math.max(...this.fitnessData.map(({ atl }) => atl))
      const maxCtl = Math.max(...this.fitnessData.map(({ ctl }) => ctl))
      const minTsb = Math.min(...this.fitnessData.map(({ tsb }) => tsb))

      const { formatter } = formatLabels(dates.length, 'week', this.$isMobile())

      const options = {
        ...chartConfig.defaultOptions,
        ...chartConfig.withLegend,
        chart: {
          ...chartConfig.chart,
          height: this.$isMobile() ? this.height / 2 : this.height,
          type: 'areaspline',
          zoomType: 'x'
        },
        exporting: {
          ...chartConfig.exporting,
          enabled: !this.$isMobile()
        },
        tooltip: {
          ...chartConfig.withTooltip,
          positioner: function (labelWidth, labelHeight, point) {
            var leftThird = point.plotX < this.chart.plotWidth / 2
            return {
              x: leftThird ? point.plotX + this.chart.plotLeft + 80 : this.chart.plotLeft + point.plotX - (labelWidth + 80),
              y: 20
            }
          },
          formatter: function () {
            const formattedDate = dayjs(this.x).format('dddd, MMM DD YYYY')
            return this.points.reduce(function (s, point) {
              return `${s}<div class="flex items-center text-sm font-medium"><svg class="mr-1" height="16" width="16"><circle cx="8" cy="8" r="4" fill="${point.series.userOptions.borderColor}" /></svg><span class="font-bold">${point.series.name}:</span>&nbsp; ${Math.round(point.y)}</div>`
            }, `<div class="w-48 mb-1 text-base font-bold">${formattedDate}</div>`)
          }
        },

        xAxis: [
          {
            crosshair: {
              width: 1,
              color: '#000',
              enabled: true,
              zIndex: 99,
              snap: true
            },
            plotBands: [
              {
                from: dayjs().startOf('day').valueOf(),
                to: dayjs().add(7, 'days').valueOf(),
                color: 'rgba(255, 255, 255, .6)',
                zIndex: 5
              }
            ],
            plotLines: [
              {
                color: 'black',
                dashStyle: 'Dash',
                value: dayjs().startOf('day').valueOf(),
                width: 1,
                label: {
                  text: 'Today'
                }
              }
            ],
            type: 'datetime',
            startOfWeek: Number(dayjs().weekday(0).format('d')),
            lineWidth: 1,
            lineColor: '#ccc',
            gridLineWidth: dates.length <= 50 ? 1 : 0,
            gridLineDashStyle: 'Dot',
            gridLineColor: '#999',
            margin: 0,
            padding: 0,
            labels: {
              formatter: formatter,
              autoRotation: false,
              padding: 0
            }
          }
        ],
        yAxis: [
          {
            gridLineDashStyle: 'Dot',
            min: minTsb,
            max: maxCtl,
            startOnTick: false,
            endOnTick: false,
            title: {
              enabled: false
            }
          },
          {
            visible: false,
            min: minTsb,
            max: maxCtl,
            startOnTick: false,
            endOnTick: false
          },
          {
            visible: false,
            min: minAtl,
            max: maxAtl * 4,
            startOnTick: false,
            endOnTick: false
          }
        ],
        plotOptions: {
          series: {
            shadow: false,
            marker: {
              enabled: false,
              symbol: 'circle'
            },
            lineWidth: 0,
            states: {
              inactive: {
                opacity: 1,
                enabled: false,
                animation: {
                  enabled: false
                },
                lineWidth: 1,
                halo: {
                  size: 0
                }
              },
              hover: {
                opacity: 1,
                enabled: false,
                animation: {
                  enabled: false
                },
                lineWidth: 1,
                halo: {
                  size: 0
                }
              }
            }
          },
          area: {
            marker: {
              enabled: false,
              symbol: 'circle'
            }
          }
        },
        series: series
      }

      return options
    }
  },

  mounted() {
    this.$el.addEventListener('mousemove', () => (this.drag = true))
    this.$el.addEventListener('mousedown', () => (this.drag = false))
    this.$el.addEventListener('mouseup', () => !this.drag && this.resetZoom())
  },

  methods: {
    setChart(chart) {
      this.chart = chart
    },
    resetZoom() {
      this.chart.xAxis[0].setExtremes && this.chart.xAxis[0].setExtremes(null, null, false)
      this.chart.redraw()
    }
  }
}
</script>

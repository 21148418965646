import Vue from 'vue'
import Vuex from 'vuex'

const { env } = require('process')

import profile from 'store/modules/profile'
import preferences from 'store/modules/preferences'

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: env.NODE_ENV !== 'production',

  actions: {
    init({ dispatch }) {
      dispatch('profile/init', { root: true })
    }
  },

  modules: {
    profile,
    preferences
  }
})

export default store

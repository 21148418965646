<template>
  <div class="TextInput">
    <input class="input" :class="[inputClass, { 'isInvalid': error }]" :type="type" v-bind="$attrs" v-on="listeners" />
    <InputErrorMessage class="mt-px" :error="error" />
  </div>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    type: {
      type: String,
      default: 'text',
      validator: (type) => ['text', 'number', 'password', 'email', 'tel'].includes(type)
    },

    error: {
      type: [String, Array]
    },

    inputClass: [String, Array, Object]
  },

  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: (e) => this.$emit('input', e.target.value)
      }
    }
  }
}
</script>

<template>
  <div>
    <div>
      <div class="flex flex-wrap mb-8 -mx-2">
        <div class="md:w-1/4 md:mb-0 w-1/2 px-2 mb-4">
          <StatsCard title="Average Heart Rate" :score="activity.computed_average_heartrate" />
        </div>

        <div class="md:w-1/4 md:mb-0 w-1/2 px-2 mb-4">
          <StatsCard title="Max Heart Rate" :score="activity.computed_max_heartrate" />
        </div>

        <div class="md:w-1/4 w-1/2 px-2">
          <StatsCard title="HR Stress Score" term="hrss" :score="activity.hrss" />
        </div>

        <div v-if="activity.aerobic_decoupling" key="hr_advanced_stats" class="md:w-1/4 w-1/2 px-2">
          <StatsCard title="Aerobic Decoupling" term="aerobic-decoupling" :score="activity.aerobic_decoupling" format="percent" />
        </div>
        <div v-else key="hr_advanced_stats" class="md:w-1/4 w-1/2 px-2">
          <StatsCard title="Heart Rate Reserve" :score="activity.heartrate_reserve" />
        </div>
      </div>

      <StatsSection title="Time in Zone – HR" size="small">
        <TimeInZoneChart :activity="activity" attribute="time_in_hr_zone" :zones="user.heartrate_zones" units="%" />
      </StatsSection>
    </div>
  </div>
</template>

<script>
import StatsCard from 'components/StatsCard'
import StatsSection from 'components/StatsSection'
import TimeInZoneChart from 'components/charts/TimeInZoneChart'

export default {
  components: {
    StatsCard,
    StatsSection,
    TimeInZoneChart
  },

  props: {
    activity: {
      type: Object,
      required: true
    },
    activities: {
      type: Array,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    units: {
      type: String,
      required: true
    }
  },

  mounted() {
    if (!this.activity.has_heartrate) {
      this.$router.push({ name: 'dashboard.activity', params: { id: this.activity.id } })
    }
  },

  metaInfo() {
    return {
      title: this.activity?.name
    }
  }
}
</script>

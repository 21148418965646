<template>
  <div class="w-full">
    <div v-if="user.has_powermeter">
      <PeaksChart title="Peak Power" :data="currentSummary" :segment-by="segmentBy" attribute="peak_power" format="power" />

      <HeartRateZonesChart
        title="Time in Power Zones "
        :data="currentSummary"
        :segment-by="segmentBy"
        :attributes="[
          'time_in_power_zone_1',
          'time_in_power_zone_2',
          'time_in_power_zone_3',
          'time_in_power_zone_4',
          'time_in_power_zone_5',
          'time_in_power_zone_6',
          'time_in_power_zone_7'
        ]"
      />

      <StatsSection title="Normalized Power">
        <Chart title="NP" :data="currentSummary" :segment-by="segmentBy" attribute="computed_weighted_power" format="power" />
        <template #header>
          <InfoIcon term="normalized-power" />
        </template>
      </StatsSection>

      <StatsSection title="Intensity Factor">
        <Chart title="Intensity" :data="currentSummary" :segment-by="segmentBy" attribute="average_intensity" format="percent" />
        <template #header>
          <InfoIcon term="intensity" />
        </template>
      </StatsSection>

      <StatsSection title="Variability Index">
        <Chart title="Variability Index" :data="currentSummary" :segment-by="segmentBy" attribute="variability_index" format="percent" />
        <template #header>
          <InfoIcon term="variability-index" />
        </template>
      </StatsSection>

      <StatsSection title="Efficiency factor">
        <Chart title="Efficiency factor" :data="currentSummary" :segment-by="segmentBy" attribute="efficiency_factor" :decimal-places="2" format="numeric" />
        <template #header>
          <InfoIcon term="efficiency-factor" />
        </template>
      </StatsSection>
    </div>
    <div v-else>
      <div class="text-muted p-12 text-center bg-white rounded shadow">
        Add activities with power data and
        <router-link class="text-link" :to="{ name: 'settings.power' }">set your FTP</router-link>
        to see you power profile and trends.
      </div>
    </div>
  </div>
</template>

<script>
import Chart from 'components/charts/Chart'
import PeaksChart from 'components/charts/PeaksChart'
import HeartRateZonesChart from 'components/charts/HeartRateZonesChart'
import StatsSection from 'components/StatsSection'

export default {
  components: {
    Chart,
    HeartRateZonesChart,
    PeaksChart,
    StatsSection
  },

  props: {
    summary: {
      type: Object,
      required: true
    },
    currentSummary: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    activities: {
      type: Array,
      required: true
    },
    segmentBy: {
      type: String,
      required: true
    },
    dateRange: {
      type: Object,
      required: true
    }
  },

  metaInfo() {
    return {
      title: 'Power Analysis'
    }
  }
}
</script>

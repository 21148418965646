<template>
  <div class="StatsCard">
    <span class="StatsCard__title">
      <InfoIcon v-if="term" :term="term" :text="title" />
      <span v-else>
        {{ title }}
      </span>
    </span>
    <h2 class="StatsCard__score">
      {{ score | format(format) }}
    </h2>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    score: {
      type: [String, Number],
      required: true
    },
    format: {
      type: String,
      required: false
    },
    term: {
      type: String,
      required: false
    }
  }
}
</script>

<style lang="postcss">
.StatsCard {
  @apply relative p-4 bg-white rounded shadow cursor-default;

  transition: box-shadow 0.3s ease-out;

  &__title,
  &__score {
    @apply relative;
    z-index: 2;
  }

  &__title {
    @apply flex items-center justify-between text-base;
  }

  &__score {
    @apply text-3xl font-medium;
  }

  &:hover {
    @apply shadow-lg;
  }
}
</style>

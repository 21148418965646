export function getPowerCurve(activities, weight = null) {
  const powerCurveData = {}

  activities.forEach((activity) => {
    activity.power_curve.forEach((datapoint) => {
      if (!powerCurveData[datapoint.t] || datapoint.v > powerCurveData[datapoint.t].value) {
        powerCurveData[datapoint.t] = {
          value: datapoint.v,
          name: activity.name,
          date: activity.start_date_local
        }
      }
    })
  })

  const dates = Object.entries(powerCurveData)
    .map(([, value]) => value.date)
    .sort()
  const lastDate = dates[dates.length - 1]

  return Object.entries(powerCurveData).map(([key, value]) => {
    const dayDifference = Math.ceil((new Date(lastDate).getTime() - new Date(value.date).getTime()) / (1000 * 3600 * 24))
    const wattKilo = weight && Number(weight) > 0 ? Number((value.value / weight).toFixed(2)) : null
    return {
      name: key,
      y: value.value,
      s: value.name,
      d: value.date,
      w: value.value,
      wkg: wattKilo,
      colorValue: dayDifference
    }
  })
}

export function getTickPositions(powerCurve) {
  const tickPositions = []

  powerCurve.forEach(({ name }, index) => {
    const whitelist = [1, 5, 30, 60, 5 * 60, 10 * 60, 20 * 60, 30 * 60, 60 * 60, 2 * 60 * 60, 3 * 60 * 60, 4 * 60 * 60, 5 * 60 * 60, 10 * 60 * 60]

    if (whitelist.includes(Number(name))) {
      tickPositions.push(Number(index))
    }
  })

  return tickPositions
}

export function getElitePowerProfiles(sex) {
  if (sex === 'M') {
    return [
      [
        24.04,
        23.77,
        23.5,
        23.22,
        22.95,
        22.68,
        22.41,
        22.14,
        21.86,
        21.59,
        21.32,
        21.05,
        20.78,
        20.51,
        20.23,
        19.96,
        19.69,
        19.42,
        19.15,
        18.87,
        18.6,
        18.33,
        18.06,
        17.79,
        17.51,
        17.24,
        16.97,
        16.7,
        16.43,
        16.15,
        15.88,
        15.61,
        15.34,
        15.07,
        14.79,
        14.52,
        14.25,
        13.98,
        13.71,
        13.44,
        13.16,
        12.89,
        12.62,
        12.35,
        12.08,
        11.8,
        11.53,
        11.26,
        10.99,
        10.72,
        10.44,
        10.17
      ],
      [
        11.5,
        11.39,
        11.27,
        11.16,
        11.04,
        10.93,
        10.81,
        10.7,
        10.58,
        10.47,
        10.35,
        10.24,
        10.12,
        10.01,
        9.89,
        9.78,
        9.66,
        9.55,
        9.43,
        9.32,
        9.2,
        9.09,
        8.97,
        8.86,
        8.74,
        8.63,
        8.51,
        8.4,
        8.28,
        8.17,
        8.05,
        7.94,
        7.82,
        7.71,
        7.59,
        7.48,
        7.36,
        7.25,
        7.13,
        7.02,
        6.9,
        6.79,
        6.67,
        6.56,
        6.44,
        6.33,
        6.21,
        6.1,
        5.99,
        5.87,
        5.76,
        5.64
      ],
      [
        7.6,
        7.5,
        7.39,
        7.29,
        7.19,
        7.08,
        6.98,
        6.88,
        6.77,
        6.67,
        6.57,
        6.46,
        6.36,
        6.26,
        6.15,
        6.05,
        5.95,
        5.84,
        5.74,
        5.64,
        5.53,
        5.43,
        5.33,
        5.22,
        5.12,
        5.01,
        4.91,
        4.81,
        4.7,
        4.6,
        4.5,
        4.39,
        4.29,
        4.19,
        4.08,
        3.98,
        3.88,
        3.77,
        3.67,
        3.57,
        3.46,
        3.36,
        3.26,
        3.15,
        3.05,
        2.95,
        2.84,
        2.74,
        2.64,
        2.53,
        2.43,
        2.33
      ],
      [
        6.4,
        6.31,
        6.22,
        6.13,
        6.04,
        5.96,
        5.87,
        5.78,
        5.69,
        5.6,
        5.51,
        5.42,
        5.33,
        5.24,
        5.15,
        5.07,
        4.98,
        4.89,
        4.8,
        4.71,
        4.62,
        4.53,
        4.44,
        4.35,
        4.27,
        4.18,
        4.09,
        4.0,
        3.91,
        3.82,
        3.73,
        3.64,
        3.55,
        3.47,
        3.38,
        3.29,
        3.2,
        3.11,
        3.02,
        2.93,
        2.84,
        2.75,
        2.66,
        2.58,
        2.49,
        2.4,
        2.31,
        2.22,
        2.13,
        2.04,
        1.95,
        1.86
      ]
    ]
  } else {
    return [
      [
        19.42,
        19.2,
        18.99,
        18.77,
        18.56,
        18.34,
        18.13,
        17.91,
        17.7,
        17.48,
        17.26,
        17.05,
        16.83,
        16.62,
        16.4,
        16.19,
        15.97,
        15.76,
        15.54,
        15.32,
        15.11,
        14.89,
        14.68,
        14.46,
        14.25,
        14.03,
        13.82,
        13.6,
        13.39,
        13.17,
        12.95,
        12.74,
        12.52,
        12.31,
        12.09,
        11.88,
        11.66,
        11.45,
        11.23,
        11.01,
        10.8,
        10.58,
        10.37,
        10.15,
        9.94,
        9.72,
        9.51,
        9.29,
        9.07,
        8.86,
        8.64,
        8.43
      ],
      [
        9.29,
        9.2,
        9.11,
        9.02,
        8.93,
        8.84,
        8.75,
        8.66,
        8.56,
        8.47,
        8.38,
        8.29,
        8.2,
        8.11,
        8.02,
        7.93,
        7.84,
        7.75,
        7.66,
        7.57,
        7.48,
        7.39,
        7.3,
        7.21,
        7.11,
        7.02,
        6.93,
        6.84,
        6.75,
        6.66,
        6.57,
        6.48,
        6.39,
        6.3,
        6.21,
        6.12,
        6.03,
        5.94,
        5.85,
        5.76,
        5.66,
        5.57,
        5.48,
        5.39,
        5.3,
        5.21,
        5.12,
        5.03,
        4.94,
        4.85,
        4.76,
        4.67
      ],
      [
        6.61,
        6.52,
        6.42,
        6.33,
        6.24,
        6.15,
        6.05,
        5.96,
        5.87,
        5.78,
        5.68,
        5.59,
        5.5,
        5.41,
        5.31,
        5.22,
        5.13,
        5.04,
        4.94,
        4.85,
        4.76,
        4.67,
        4.57,
        4.48,
        4.39,
        4.3,
        4.2,
        4.11,
        4.02,
        3.93,
        3.83,
        3.74,
        3.65,
        3.56,
        3.46,
        3.37,
        3.28,
        3.19,
        3.09,
        3.0,
        2.91,
        2.82,
        2.72,
        2.63,
        2.54,
        2.45,
        2.35,
        2.26,
        2.17,
        2.07,
        1.98,
        1.89
      ],
      [
        5.69,
        5.61,
        5.53,
        5.44,
        5.36,
        5.28,
        5.2,
        5.12,
        5.03,
        4.95,
        4.87,
        4.79,
        4.7,
        4.62,
        4.54,
        4.46,
        4.38,
        4.29,
        4.21,
        4.13,
        4.05,
        3.97,
        3.88,
        3.8,
        3.72,
        3.64,
        3.55,
        3.47,
        3.39,
        3.31,
        3.23,
        3.14,
        3.06,
        2.98,
        2.9,
        2.82,
        2.73,
        2.65,
        2.57,
        2.49,
        2.4,
        2.32,
        2.24,
        2.16,
        2.08,
        1.99,
        1.91,
        1.83,
        1.75,
        1.67,
        1.58,
        1.5
      ]
    ]
  }
}

<template>
  <modal-layout class="TooltipModal" width="800">
    <div class="flex">
      <div class="md:block flex-shrink-0 hidden p-8 bg-gray-100" style="min-width: 280px">
        <div class="label">Training Glossary</div>
        <nav>
          <a
            v-for="(item, index) in glossary"
            :key="index"
            href
            class="link block py-1 text-sm text-current border-b"
            :class="{ 'text-primary font-bold': index == selectedTerm }"
            @click.prevent="setTerm(index)"
          >
            {{ item.title }}
          </a>
        </nav>
      </div>
      <div class="p-8">
        <h3 class="mb-6">{{ currentTerm.title }}</h3>
        <div class="TooltipMarkdown" v-html="description"></div>
      </div>
    </div>
  </modal-layout>
</template>

<script>
import marked from 'marked'
import infoTooltips from 'constants/infoTooltips'

export default {
  props: {
    term: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      glossary: infoTooltips,
      selectedTerm: undefined
    }
  },

  computed: {
    currentTerm() {
      return this.glossary[this.selectedTerm] || Object.values(this.glossary)[0]
    },

    description() {
      return `<div>${marked(this.currentTerm.description)}</div>`
    }
  },

  mounted() {
    this.selectedTerm = this.term
  },

  methods: {
    setTerm(key) {
      this.selectedTerm = key
    }
  }
}
</script>

<style lang="postcss">
.TooltipModal .ModalLayout__body {
  @apply p-0 overflow-hidden rounded;
}

.TooltipMarkdown {
  @apply text-sm;
  strong {
    @apply font-bold;
  }
  ul {
    @apply mt-3;
  }
  p + p {
    @apply mt-3;
  }
  h3 {
    @apply mt-2 mb-0 text-lg;
    font-size: theme('fontSize.base') !important;
  }
}
</style>

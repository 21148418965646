<template>
  <div class="xl:w-192 w-full">
    <div class="p-8 mb-6 bg-white border rounded">
      <h1 class="mb-2 text-3xl">Variability Index Calculator</h1>
      <p class="text-muted mb-4 text-sm">
        Variability Index (VI) measures how smooth (evenly paced) or variable (with frequent changes) was your power output during your ride. Enter Average and
        Normalized Power to get a Variability Index of your cycling workout.
      </p>

      <div class="FormGroup">
        <div class="FormGroup__label">
          Average Power
          <InputErrorMessage v-if="errors.average_power" :error="errors.average_power" />
        </div>
        <div class="FormGroup__content md:w-1/4">
          <TextInput v-model="form.average_power" class="w-full" placeholder="e.g. 120" type="number" aria-label="Enter average power" />
        </div>
      </div>

      <div class="FormGroup">
        <div class="FormGroup__label">
          Normalized Power
          <InputErrorMessage v-if="errors.normalized_power" :error="errors.normalized_power" />
        </div>
        <div class="FormGroup__content md:w-1/4">
          <TextInput v-model="form.normalized_power" class="w-full" placeholder="e.g. 120" type="number" aria-label="Enter normalized power" />
        </div>
      </div>

      <AppButton class="mt-4" primary small @click="calculate()">Calculate Variability Index</AppButton>
    </div>

    <div v-if="result" class="p-6 mt-6 text-center bg-white border rounded">
      <div class="label">Variability Index (VI)</div>
      <div class="-mt-4 text-5xl font-bold">
        {{ result }}
      </div>
    </div>

    <div class="md:flex-row flex flex-col p-8 my-4 space-x-8 space-y-4 overflow-hidden bg-white border">
      <div class="max-w-md">
        <h2 class="mb-2 text-3xl font-bold leading-tight">Training with a power meter?</h2>
        Start tracking your cycling power and get actionable insights from your training. Our AI-powered tools are designed to help you become a stronger and
        faster cyclist in less than 8 weeks.
        <AppButton class="block mt-8" primary small>
          <router-link :to="{ name: 'join' }" title="Create a free account">Sign up for FREE</router-link>
        </AppButton>
      </div>
      <div class="max-w-md" style="margin-right: -6rem">
        <img :src="require('images/features/training-load-sm.jpg') | imageUrl('w_640')" alt="Cycling analytics dashboard" />
      </div>
    </div>
  </div>
</template>

<script>
import ValidationService, { isRequired, isMin, isMax } from 'services/ValidationService'

export default {
  data() {
    return {
      form: {},
      errors: {},
      result: undefined
    }
  },

  methods: {
    async calculate() {
      this.errors = {}

      const rules = {
        average_power: [isRequired(), isMin(1), isMax(1000)],
        normalized_power: [isRequired(), isMin(1), isMax(1000)]
      }

      const result = await ValidationService.validate(this.form, rules)

      if (!result.isValid) {
        this.result = undefined
        this.errors = result.errors
      } else {
        this.result = (this.form.normalized_power / this.form.average_power).toFixed(2)
      }
    }
  },

  metaInfo() {
    return {
      title: `Variability Index Calculator`,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Online Variability Index (VI) calculator to easily get Variability Index of any ride'
        }
      ]
    }
  }
}
</script>

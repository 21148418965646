<template>
  <div>
    <SectionTitle title="Calendar">
      <div class="flex items-center space-x-8">
        <div class="md:flex text-muted items center hidden space-x-4 text-xs font-bold uppercase" title="Size of the bubble shows overal training stress score">
          <img src="~images/icons/training-load.svg" alt="Training stress score" width="82" height="12" />
          <div>{{ selectedAttribute.name }}</div>
        </div>
        <div class="md:flex text-muted items center hidden space-x-4 text-xs font-bold uppercase" title="Color shows how intensive was your ride">
          <img src="~images/icons/intensity.svg" alt="Training intensity" width="60" height="12" />
          <div>Intensity</div>
        </div>

        <Dropdown right>
          <template #selected>
            <div class="flex items-center justify-between w-32 px-3 py-1 font-medium">
              <div>
                {{ selectedAttribute.name }}
              </div>
              <svg class="svg-icon">
                <use xlink:href="#icon-caret-down"></use>
              </svg>
            </div>
          </template>

          <template #options>
            <div class="w-32">
              <a
                v-for="(option, index) in attributes"
                :key="index"
                href
                class="Dropdown__item"
                :class="{ 'font-bold': option === selectedAttribute }"
                @click.prevent="setAttribute(option)"
              >
                {{ option.name }}
              </a>
            </div>
          </template>
        </Dropdown>
      </div>
    </SectionTitle>

    <div class="Calendar relative border">
      <div class="Calendat__Header sticky flex bg-white" style="top: 64px">
        <div class="md:flex flex flex-col justify-between hidden px-4 py-2 text-xs text-left border-b border-r" :style="{ width: `${100 / 5}%` }">
          Weekly summary
        </div>
        <div v-for="day in weekDays" :key="day" class="Week__header">
          {{ day }}
        </div>
      </div>

      <div v-for="(week, index) in weeks" :key="index" class="Week">
        <div class="Week__summary">
          <div class="md:px-4 flex py-2 text-xs font-bold">
            <div>{{ week.from | date('MMM DD', false) }} – {{ week.to | date('MMM DD YYYY', false) }}</div>
            <div class="md:block hidden ml-auto text-right">
              <span v-if="week.summary && week.summary.moving_time">{{ week.summary.moving_time | format('duration') }}</span>
            </div>
          </div>
          <div class="Week__score">
            <div class="md:mt-0 md:p-8 -mt-6">
              <div class="md:block hidden">
                {{ selectedAttribute.name }}
              </div>
              <div class="md:text-4xl md:leading-normal text-2xl font-bold leading-4">
                <span v-if="week.summary[selectedAttribute.attribute]">
                  {{ week.summary[selectedAttribute.attribute] | format(selectedAttribute.format) }}
                </span>
                <span v-else>–</span>
              </div>
            </div>

            <!-- Training status -->
            <div class="md:block hidden py-4 mx-8 border-t">
              <div class="flex justify-between">
                <div class="w-auto">
                  <div class="font-bold">
                    <svg
                      v-if="trainingStatusIcons[week.trainingStatus]"
                      class="svg-icon svg-icon--sm mr-1"
                      :style="{ color: trainingStatusColors[week.trainingStatus] || '#fcfcfc' }"
                    >
                      <use :xlink:href="`#icon-${trainingStatusIcons[week.trainingStatus]}`"></use>
                    </svg>
                    {{ week.trainingStatus }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-for="(day, dayIndex) in week.days" :key="dayIndex" class="Week__day">
          <CalendarDay :day="day" :selected-attribute="selectedAttribute" :scale="scale" :intensity-record="intensityRecord" :value-record="valueRecord" />
        </div>
      </div>

      <div v-if="currentItems < maxItems" class="py-2 my-2 text-center">Loading more data...</div>

      <IntersectionObserver @intersect="loadItems" />
    </div>
  </div>
</template>

<script>
import bus from 'bus'
import dayjs from 'utils/dayjs'
import chroma from 'chroma-js'

import { isSameOrAfter, generateDateRange } from 'utils/date'

import trainingStatusColors from 'constants/trainingStatusColors'
import trainingStatusIcons from 'constants/trainingStatusIcons'

import SectionTitle from 'components/SectionTitle'
import CalendarDay from 'components/CalendarDay'
import IntersectionObserver from 'components/IntersectionObserver'

const attributes = [
  { name: 'TSS', attribute: 'tss', format: 'number' },
  { name: 'Time', attribute: 'moving_time', format: 'longduration', force: true },
  { name: 'Distance', attribute: 'distance', format: 'longdistance' },
  { name: 'Elevation', attribute: 'total_elevation_gain', format: 'elevation' }
]

const colors = ['#ffaf83', '#F57F45', '#F26B2F', '#ED561B', '#e94200', '#e91500', '#ff0000', '#b60000']

export default {
  components: {
    SectionTitle,
    CalendarDay,
    IntersectionObserver
  },

  props: {
    summary: {
      type: Object,
      required: true
    },
    allActivities: {
      type: Array,
      required: true
    },
    activities: {
      type: Array,
      required: true
    },
    fitness: {
      type: Array,
      required: true
    },
    segmentBy: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      itemsPerPage: 32,
      currentItems: 0,
      maxItems: 256,
      scale: chroma.scale(colors),
      views: ['week', 'month', 'year'],
      view: 'week',
      weeks: [],
      today: new Date(),
      trainingStatusColors,
      trainingStatusIcons,
      attributes,
      selectedAttribute: attributes[0],
      valueRecord: undefined,
      intensityRecord: undefined
    }
  },

  computed: {
    weekDays() {
      return [
        dayjs().weekday(0).format('ddd'),
        dayjs().weekday(1).format('ddd'),
        dayjs().weekday(2).format('ddd'),
        dayjs().weekday(3).format('ddd'),
        dayjs().weekday(4).format('ddd'),
        dayjs().weekday(5).format('ddd'),
        dayjs().weekday(6).format('ddd')
      ]
    }
  },

  mounted() {
    this.valueRecord = Math.max.apply(
      null,
      this.activities.map((activity) => Number(activity[this.selectedAttribute.attribute]))
    )
    this.intensityRecord = Math.max.apply(
      null,
      this.activities.map((activity) => Number(activity.tss_per_hour))
    )
    this.loadItems()
    this.$nextTick(() => bus.$emit('dashboard.calendarLoaded'))
  },

  methods: {
    setAttribute(attribute) {
      this.valueRecord = Math.max.apply(
        null,
        this.activities.map((activity) => Number(activity[attribute.attribute]))
      )
      this.selectedAttribute = attribute
    },

    loadItems() {
      if (this.currentItems >= this.maxItems) {
        return
      }

      const today = dayjs().startOf('day')
      const dates = Object.keys(this.summary[this.view])
        .reverse()
        .slice(this.currentItems, this.currentItems + this.itemsPerPage)

      const records = dates.map((date) => {
        const from = dayjs(date)
        const endOfPeriod = from.endOf(this.view)
        const to = isSameOrAfter(endOfPeriod, today, 'day') ? today : endOfPeriod

        const fitnessFrom = this.fitness.find(({ date }) => date === from.format('YYYY-MM-DD'))
        const fitnessTo = this.fitness.find(({ date }) => date === to.format('YYYY-MM-DD'))
        let summary = this.summary[this.view][date]

        if (this.view == 'week') {
          const days = []
          const dates = generateDateRange(from, to)
          const summaryData = {}

          attributes.forEach((attribute) => {
            summaryData[attribute.attribute] = 0
          })

          dates.forEach((date) => {
            const dateString = dayjs(date).format('YYYY-MM-DD')
            const summary = this.summary['day'][dateString]
            const activities = this.allActivities.filter((a) => a.date === dateString) || []

            attributes.forEach((attribute) => {
              const sum = activities
                .map((a) => a[attribute.attribute])
                .filter(Boolean)
                .reduce((a, b) => Number(a) + Number(b), 0)
              summaryData[attribute.attribute] += sum || 0
            })

            days.push({
              date,
              summary: summary || {},
              activities
            })
          })

          summary = {
            ...summary,
            ...summaryData
          }
          const record = {
            date: date,
            from: from,
            to: to,
            fitnessGain: fitnessTo && fitnessFrom ? Math.round(fitnessTo.ctl - fitnessFrom.ctl) : 0,
            trainingStatus: fitnessTo?.status,
            summary: summary || {},
            data: this.summary[this.view][date],
            days
          }

          return record
        }
      })

      this.weeks.push(...records)

      this.currentItems += this.itemsPerPage
    }
  },

  metaInfo() {
    return {
      title: 'Calendar'
    }
  }
}
</script>

<style lang="postcss" scoped>
.Week {
  @apply flex flex-row flex-wrap w-full;

  @screen md {
    @apply flex flex-row;
  }
}
.Week__summary {
  @apply flex flex-col w-full px-4 bg-white border-b border-r;
  @screen md {
    @apply flex-col h-64 px-0;
    width: 20%;
  }
}
.Week__header {
  @apply flex flex-col items-center justify-between px-4 py-2 text-xs text-center border-b;
  width: calc(100% / 7);
  @screen md {
    width: calc(80% / 7);
  }
}
.Week__score {
  @apply flex flex-row justify-end w-full mb-2 text-sm text-gray-700;
  @screen md {
    @apply flex-col content-around justify-between flex-1 w-full p-0 mb-0 border-t;
  }
}
.Week__day {
  @apply flex flex-col items-center justify-between h-48 px-4 overflow-visible bg-white border-b;
  width: calc(100% / 7);
  @screen md {
    @apply h-64;
    width: calc(80% / 7);
  }
}
</style>

<template>
  <div class="xl:w-192 w-full">
    <div class="p-8 mb-6 bg-white border rounded">
      <h1 class="mb-2 text-3xl">Running Pace Calculator</h1>
      <p class="text-muted mb-4">
        Calculate your finish time or goal pace for all popular race distances - from 5K, 10K, 10 mile to half marathon and marathon. Enter your distance and
        time or pace to see goal pace or pace chart and estimated race times.
      </p>

      <div class="FormGroup FormGroup--last">
        <div class="lg:flex-row flex flex-col items-center justify-between w-full gap-4 text-lg">
          <div
            :class="{ 'bg-orange-200 border-orange-500 hover:bg-orange-200 font-bold': mode == 'pace' }"
            class="hover:bg-gray-200 w-full px-6 py-3 text-center bg-gray-100 border rounded cursor-pointer"
            @click="mode = 'pace'"
          >
            Calculate Goal Pace
          </div>
          <div
            :class="{ 'bg-orange-200 border-orange-500 hover:bg-orange-200 font-bold': mode == 'finish' }"
            class="hover:bg-gray-200 w-full px-6 py-3 text-center bg-gray-100 border rounded cursor-pointer"
            @click="mode = 'finish'"
          >
            Calculate Race Time
          </div>
        </div>
      </div>

      <div v-show="mode == 'pace'" class="">
        <div class="FormGroup__label pt-4 pb-4 font-bold"><span class="text-xl">I Want to Finish</span></div>
        <div class="FormGroup__content flex flex-wrap justify-start w-full gap-6">
          <div>
            <div class="text-muted mb-1 text-sm">Race / Distance</div>
            <SelectInput
              v-model="form.selectedDistance"
              style="min-width: 124px"
              value-key="value"
              label-key="label"
              :options="popularDistances"
              aria-label="Select distance"
            />
          </div>

          <div>
            <div class="text-muted mb-3 text-sm">&nbsp;</div>
            in
          </div>
          <div>
            <div class="text-muted mb-1 text-sm">Hours</div>
            <SelectInput
              v-model.number="form.finishTimeHours"
              style="min-width: 124px"
              value-key="value"
              label-key="label"
              :options="finishTimeHourOptions"
              aria-label="Select hours"
            />
          </div>

          <div>
            <div class="text-muted mb-1 text-sm">Minutes</div>
            <SelectInput
              v-model.number="form.finishTimeMinutes"
              value-key="value"
              label-key="label"
              :options="finishTimeMinutesOptions"
              aria-label="Select minutes"
              style="min-width: 124px"
            />
          </div>
        </div>
      </div>

      <div v-show="mode == 'finish'">
        <div class="FormGroup__label pt-4 pb-4 font-bold"><span class="text-xl">My Running Pace</span></div>
        <div class="FormGroup__content flex flex-wrap w-full gap-6">
          <div>
            <div class="text-muted mb-1 text-sm">Minutes</div>
            <SelectInput
              v-model.number="form.minutes"
              style="min-width: 124px"
              value-key="value"
              label-key="label"
              :options="minutesOptions"
              aria-label="Select minutes"
            />
          </div>

          <div>
            <div class="text-muted mb-1 text-sm">Seconds</div>
            <SelectInput
              v-model.number="form.seconds"
              style="min-width: 124px"
              value-key="value"
              label-key="label"
              :options="secondsOptions"
              aria-label="Select seconds"
            />
          </div>

          <div>
            <div class="text-muted mb-1 text-sm">Per</div>
            <div class="flex gap-4 pt-3">
              <Radiobox :value="form.units" large name="units" option="mi" aria-label="Select min/mi" @onChange="form.units = $event">Mile</Radiobox>
              <Radiobox :value="form.units" large name="units" option="km" aria-label="Select min/km" @onChange="form.units = $event">Km</Radiobox>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-show="mode == 'pace'" class="mb-8">
      <h2>Goal Pace</h2>
      <p class="text-muted">The goal pace you need to maintain to finish your selected distance in the time you entered</p>
      <div class="flex gap-6 my-6">
        <div class="hover:bg-gray-50 w-full p-8 text-xl text-center bg-white border rounded cursor-pointer" @click="changeUnits('mi')">
          <span class="text-4xl font-bold">{{ paceINeedInMilesMinutes }}:{{ paceINeedInMilesSeconds }}</span>
          <br class="md:hidden block" />
          min/mi
        </div>
        <div class="hover:bg-gray-50 w-full p-8 text-xl text-center bg-white border rounded cursor-pointer" @click="changeUnits('km')">
          <span class="text-4xl font-bold">{{ paceIneedMinutes }}:{{ paceIneedSeconds }}</span>
          <br class="md:hidden block" />
          min/km
        </div>
      </div>
    </div>

    <div>
      <h2>Estimated Race Times</h2>
      <p class="text-muted">Estimated finish times for popular races and distances</p>
      <div class="mt-6 overflow-x-auto bg-white border rounded">
        <table class="w-full">
          <thead>
            <tr class="bg-gray-100">
              <th class="p-3 px-4 text-left">Distance</th>
              <th class="p-3 px-4 pr-6 text-right">Estimated Time</th>
              <th class="p-3 px-4 text-right">Run 5s Faster</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(distance, index) in distances" :key="index" class="border-t">
              <td :class="{ 'font-bold bg-orange-100': isHighlighted(distance.value) }" class="p-3 px-4">{{ distance.label }}</td>
              <td :class="{ 'font-bold bg-orange-100': isHighlighted(distance.value) }" class="p-3 px-4 pr-6 text-right">
                {{ calculateTime(distance.value) }}
              </td>
              <td :class="{ 'bg-orange-100': isHighlighted(distance.value) }" class="p-3 px-4 text-right text-gray-600">
                {{ calculateTime(distance.value, -5) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="md:flex-row flex flex-col p-8 my-4 space-x-8 space-y-4 overflow-hidden bg-white border">
      <div class="max-w-md">
        <h2 class="mb-2 text-3xl font-bold leading-tight">Train smarter with Formbeat</h2>
        Start tracking your cycling power and get actionable insights from your training. Our AI-powered tools are designed to help you become a stronger and
        faster cyclist in less than 8 weeks.
        <AppButton class="block mt-8" primary small>
          <router-link :to="{ name: 'join' }" title="Create a free account">Sign up for FREE</router-link>
        </AppButton>
      </div>
      <div class="max-w-md" style="margin-right: -6rem">
        <img :src="require('images/features/training-load-sm.jpg') | imageUrl('w_640')" alt="Cycling analytics dashboard" />
      </div>
    </div>

    <div class="faqs p-8 my-4 overflow-hidden bg-white border">
      <h2>Frequently Asked Questions</h2>
      <h3>What is a running pace calculator?</h3>
      <p>
        A running pace calculator is a tool designed to help runners determine their pace (speed, or time per distance) for popular races and distances. It
        allows you to input different variables such as distance and time to calculate your optimal running pace, or conversely, input your pace to determine
        potential finish times for different race distances. You can use this calculator to help with:
      </p>
      <ol>
        <li>Planning race strategies</li>
        <li>Setting realistic goals</li>
        <li>Tracking performance improvements</li>
        <li>Determining appropriate training paces</li>
      </ol>
      <p>
        Whether you&#39;re preparing for a 5K, marathon, or any distance in between, a running pace calculator can help you optimize your training and race
        performance.
      </p>

      <h3>How do I use running pace calculator?</h3>
      <p>
        Using our Running Pace Calculator is simple! Just enter your desired race distance and time to calculate your goal pace. Alternatively, you can input
        your current pace to determine your estimated finish time for different race distances.
      </p>

      <h3>How does this running pace calculator work?</h3>
      <p>
        Our running pace calculator is a versatile tool designed to help runners of all levels calculate their pace, finish time, or required pace for various
        race distances. Here&#39;s how it works:
      </p>
      <ol>
        <li>You input your desired race distance (e.g., 5K, 10K, half marathon, marathon) and either your goal finish time or your target pace.</li>
        <li>The calculator then computes the corresponding pace or finish time based on your input.</li>
        <li>
          It also provides estimated finish times for other common race distances based on your pace and displays them in a table as a pace chart for easy
          reference.
        </li>
      </ol>
      <h3>What race distances does this calculator support?</h3>
      <p>Our calculator supports a wide range of popular race distances, including:</p>
      <ul>
        <li>5K</li>
        <li>10K</li>
        <li>5 Miles</li>
        <li>10 Miles</li>
        <li>Half Marathon (13.1 miles / 21.1 km)</li>
        <li>Marathon (26.2 miles / 42.2 km)</li>
        <li>50K (ultra-marathon distance)</li>
      </ul>
      <h3>Can I use this calculator for both minutes per mile and minutes per kilometer?</h3>
      <p>
        Yes! Our running pace calculator provides results in both minutes per mile and minutes per kilometer, allowing you to use whichever unit you prefer.
      </p>
      <h3>How can I use this tool to improve my race times?</h3>
      <p>You can use this calculator to:</p>
      <ol>
        <li>Set realistic goal times for your races based on your current fitness level.</li>
        <li>Determine the pace you need to maintain to achieve your goal finish time.</li>
        <li>Estimate your potential finish times for different race distances based on your current pace.</li>
        <li>Track your progress by comparing your average pace across different runs or races.</li>
      </ol>
      <h3>What is &quot;goal pace&quot; and how do I use it?</h3>
      <p>
        Goal pace is the pace you aim to maintain throughout your race to achieve your desired finish time. Use our calculator to determine the goal pace needed
        for your target race distance and finish time.
      </p>
      <h3>Can this calculator help with training paces?</h3>
      <p>
        Yes! While primarily designed for race pace calculations, you can use this tool to determine appropriate paces for various training runs, including easy
        runs, tempo runs, and long runs, which are typically run at different percentages of your race pace.
      </p>
      <h3>How accurate are the race predictions?</h3>
      <p>
        Our race predictions are based on standard pace conversion formulas and assume consistent effort across distances. However, factors like terrain,
        weather, and individual endurance levels can affect actual performance. Use these predictions as a guide rather than a guarantee.
      </p>
      <h3>Can I use this for ultramarathon distances?</h3>
      <p>
        While our calculator is optimized for distances up to the marathon (26.2 miles), you can use it to estimate paces for ultramarathon distances like 50K.
        Keep in mind that pace predictions become less accurate for extremely long distances due to factors like fatigue and terrain variations.
      </p>
      <h3>How does this help with creating a marathon pace chart?</h3>
      <p>
        You can use our calculator to generate pace information for your marathon. By inputting your desired finish time or pace, you can see the corresponding
        pace for each mile or kilometer of the marathon distance.
      </p>
      <h3>Is this suitable for beginners?</h3>
      <p>
        Absolutely! Whether you&#39;re a beginner trying to finish your first 5K or an experienced runner aiming for a new marathon personal best, our running
        pace calculator can help you set realistic goals and plan your training paces.
      </p>
      <p>Remember, consistency in your endurance training is key to improving your running paces and achieving your race goals!</p>
      <p>Happy running!</p>
    </div>
  </div>
</template>

<script>
const milesDistances = [
  { value: 1, label: '1 mi' },
  { value: 2, label: '2 mi' },
  { value: 3, label: '3 mi' },
  { value: 3.10686, label: '5k' },
  { value: 4, label: '4 mi' },
  { value: 5, label: '5 mi' },
  { value: 6, label: '6 mi' },
  { value: 6.21371, label: '10k' },
  { value: 7, label: '7 mi' },
  { value: 8, label: '8 mi' },
  { value: 9, label: '9 mi' },
  { value: 10, label: '10 mi' },
  { value: 11, label: '11 mi' },
  { value: 12, label: '12 mi' },
  { value: 13, label: '13 mi' },
  { value: 13.1094, label: '1/2 Marathon' },
  { value: 14, label: '14 mi' },
  { value: 15, label: '15 mi' },
  { value: 16, label: '16 mi' },
  { value: 17, label: '17 mi' },
  { value: 18, label: '18 mi' },
  { value: 19, label: '19 mi' },
  { value: 20, label: '20 mi' },
  { value: 21, label: '21 mi' },
  { value: 22, label: '22 mi' },
  { value: 23, label: '23 mi' },
  { value: 24, label: '24 mi' },
  { value: 25, label: '25 mi' },
  { value: 26, label: '26 mi' },
  { value: 26.2188, label: 'Marathon' },
  { value: 27, label: '27 mi' },
  { value: 28, label: '28 mi' },
  { value: 29, label: '29 mi' },
  { value: 30, label: '30 mi' },
  { value: 31, label: '31 mi' },
  { value: 31.0685596119, label: '50k' }
]

const kmDistances = [
  { value: 1, label: '1 km' },
  { value: 2, label: '2 km' },
  { value: 3, label: '3 km' },
  { value: 4, label: '4 km' },
  { value: 5, label: '5k' },
  { value: 6, label: '6 km' },
  { value: 7, label: '7 km' },
  { value: 8, label: '8 km' },
  { value: 9, label: '9 km' },
  { value: 10, label: '10k' },
  { value: 11, label: '11 km' },
  { value: 12, label: '12 km' },
  { value: 13, label: '13 km' },
  { value: 14, label: '14 km' },
  { value: 15, label: '15 km' },
  { value: 16, label: '16 km' },
  { value: 16.09344, label: '10 mi' },
  { value: 17, label: '17 km' },
  { value: 18, label: '18 km' },
  { value: 19, label: '19 km' },
  { value: 20, label: '20 km' },
  { value: 21, label: '21 km' },
  { value: 21.0975, label: '1/2 Marathon' },
  { value: 22, label: '22 km' },
  { value: 23, label: '23 km' },
  { value: 24, label: '24 km' },
  { value: 25, label: '25 km' },
  { value: 26, label: '26 km' },
  { value: 27, label: '27 km' },
  { value: 28, label: '28 km' },
  { value: 29, label: '29 km' },
  { value: 30, label: '30 km' },
  { value: 31, label: '31 km' },
  { value: 32, label: '32 km' },
  { value: 33, label: '33 km' },
  { value: 34, label: '34 km' },
  { value: 35, label: '35 km' },
  { value: 36, label: '36 km' },
  { value: 37, label: '37 km' },
  { value: 38, label: '38 km' },
  { value: 39, label: '39 km' },
  { value: 40, label: '40 km' },
  { value: 41, label: '41 km' },
  { value: 42, label: '42 km' },
  { value: 42.195, label: 'Marathon' },
  { value: 43, label: '43 km' },
  { value: 44, label: '44 km' },
  { value: 45, label: '45 km' },
  { value: 46, label: '46 km' },
  { value: 47, label: '47 km' },
  { value: 48, label: '48 km' },
  { value: 49, label: '49 km' },
  { value: 50, label: '50 km' }
]

export default {
  data() {
    return {
      form: {
        units: 'mi',
        minutes: 10,
        seconds: 0,
        finishTimeHours: 1,
        finishTimeMinutes: 40,
        selectedDistance: '16.09344'
      },
      mode: 'pace',
      errors: {},
      result: undefined,
      unitsOptions: [
        { value: 'mi', label: 'per Mile' },
        { value: 'km', label: 'per Kilometer' }
      ],
      hoursOptions: Array.from({ length: 7 }, (_, i) => ({
        value: i,
        label: `${i} h`
      })),
      minutesOptions: Array.from({ length: 30 }, (_, i) => ({
        value: i + 1,
        label: `${i + 1} min`
      })),
      secondsOptions: Array.from({ length: 60 }, (_, i) => ({
        value: i,
        label: `${i} sec`
      })),
      finishTimeHourOptions: Array.from({ length: 7 }, (_, i) => ({
        value: i,
        label: `${i} h`
      })),
      finishTimeMinutesOptions: Array.from({ length: 60 }, (_, i) => ({
        value: i,
        label: `${i} min`
      })),
      highlightedDistances: [3.10686, 6.21371, 13.1094, 26.2188, 5, 10, 20, 16.09344, 21.0975, 42.195],
      popularDistances: [
        { value: '42.195', label: 'Marathon' },
        { value: '21.0975', label: 'Half Marathon' },
        { value: '5', label: '5K' },
        { value: '10', label: '10K' },
        { value: '15', label: '15K' },
        { value: '50', label: '50K' },
        { value: '8.04672', label: '5 Miles' },
        { value: '16.09344', label: '10 Miles' },
        { value: '24.14016', label: '15 Miles' }
      ],
      milesDistances,
      kmDistances
    }
  },

  computed: {
    paceInSeconds() {
      return Number(this.form.minutes) * 60 + Number(this.form.seconds)
    },
    distances() {
      return this.form.units === 'mi' ? this.milesDistances : this.kmDistances
    },
    finishTimeInSeconds() {
      return Number(this.form.finishTimeHours) * 3600 + Number(this.form.finishTimeMinutes) * 60
    },
    paceIneed() {
      // Pace in seconds per kilometer
      return this.finishTimeInSeconds / this.form.selectedDistance
    },
    paceIneedMinutes() {
      return Math.floor(this.paceIneed / 60)
    },
    paceIneedSeconds() {
      return Math.floor(this.paceIneed % 60)
        .toString()
        .padStart(2, '0')
    },
    paceINeedInMiles() {
      return Math.round(this.paceIneed * 1.60934)
    },
    paceINeedInMilesMinutes() {
      return Math.floor(this.paceINeedInMiles / 60)
    },
    paceINeedInMilesSeconds() {
      return Math.floor(this.paceINeedInMiles % 60)
        .toString()
        .padStart(2, '0')
    }
  },

  watch: {
    paceIneed: {
      handler() {
        this.form.minutes = this.form.units == 'mi' ? Number(this.paceINeedInMilesMinutes) : Number(this.paceIneedMinutes)
        this.form.seconds = this.form.units == 'mi' ? Number(this.paceINeedInMilesSeconds) : Number(this.paceIneedSeconds)
      }
    }
  },

  methods: {
    changeUnits(units) {
      this.form.units = units

      if (units === 'mi') {
        this.form.minutes = Number(this.paceINeedInMilesMinutes)
        this.form.seconds = Number(this.paceINeedInMilesSeconds)
      } else {
        this.form.minutes = Number(this.paceIneedMinutes)
        this.form.seconds = Number(this.paceIneedSeconds)
      }
    },

    calculateTime(distance, adjustment = 0) {
      const totalSeconds = (this.paceInSeconds + adjustment) * Number(distance)
      const hours = Math.floor(totalSeconds / 3600)
      const minutes = Math.floor((totalSeconds % 3600) / 60)
      const seconds = Math.floor(totalSeconds % 60)

      let timeString = ''
      if (hours > 0) {
        timeString += `${hours}:`
        timeString += `${minutes.toString().padStart(2, '0')}:`
      } else {
        timeString += `${minutes}:`
      }
      timeString += seconds.toString().padStart(2, '0')

      return timeString
    },

    isHighlighted(distance) {
      return this.highlightedDistances.includes(Number(distance))
    }
  },

  metaInfo() {
    return {
      title: `Running Pace Calculator`,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Calculate your finish time or goal pace for all popular race distances - from 5K, 10K, 10 mile to half marathon and marathon.'
        }
      ]
    }
  }
}
</script>

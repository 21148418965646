<template>
  <div class="w-full">
    <div class="flex mt-0 border-b">
      <div class="md:w-3/5 label w-1/5">Zone</div>
      <div class="md:w-1/5 label w-2/5">Min</div>
      <div class="w-8"></div>
      <div class="label w-1/5">Max</div>
    </div>
    <div
      v-for="(zone, index) in value"
      :key="index"
      class="flex items-center px-8 py-2 -mx-8"
      :class="{ 'bg-gray-100': index % 2 == 1, 'border-b': index < value.length - 1 }"
    >
      <div class="md:text-lg w-1/5 mb-0">
        Z
        <span class="md:inline hidden">one –</span>
        {{ index + 1 }}
      </div>
      <div class="md:block md:w-2/5 hidden mb-0">
        <span class="text-muted">{{ zoneNames[index] }}</span>
      </div>
      <div class="md:w-1/5 w-2/5">
        <input class="input" :placeholder="`Z${index + 1}`" type="number" min="0" max="280" :value="zone['min']" @input="updateValue($event, index, 'min')" />
      </div>
      <div class="text-muted w-8 text-center">–</div>
      <div class="md:w-1/5 w-2/5">
        <div v-if="index == value.length - 1" class="text-muted text-center">Max HR</div>
        <div v-else>
          <input class="input" :placeholder="`Z${index + 1}`" type="number" min="0" max="280" :value="zone['max']" @input="updateValue($event, index, 'max')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const ZONE_NAMES = ['Recovery', 'Endurance', 'Aerobic Capacity', 'Lactate Threshold', 'VO2']
const DEFAULT_ZONES = [
  { min: '', max: '' },
  { min: '', max: '' },
  { min: '', max: '' },
  { min: '', max: '' },
  { min: '', max: -1 }
]

export default {
  props: {
    value: {
      type: Array,
      required: true,
      default: () => DEFAULT_ZONES
    }
  },

  computed: {
    zoneNames() {
      if (this.value.length == 5) {
        return ZONE_NAMES
      }

      return []
    }
  },

  methods: {
    updateValue(e, index, attr) {
      const value = Math.min(e.target.valueAsNumber, 280)
      const zones = this.value.map((zone, i) => {
        if (index === i) {
          // Update current zone
          return { ...zone, [attr]: value }
        } else if (i > 0 && i === index + 1 && attr === 'max') {
          // Update next zone min
          return { ...zone, 'min': value }
        } else if (index > 0 && i === index - 1 && attr === 'min') {
          // Update prev zone max
          return { ...zone, 'max': value }
        } else {
          // Unchanged
          return zone
        }
      })

      this.$emit('input', zones)
    }
  }
}
</script>

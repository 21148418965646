import { urlFor, pathFor } from 'webpacker-routes'
const parent_spec = null
const default_url_options = {}
export const activities_spec = ["/api/v1/activities/index(.:format)", ["format"], { ...default_url_options, ...{"format":"json"} }, parent_spec]
export const activities_url = (...args) => urlFor(activities_spec, ...args)
export const activities_path = (...args) => pathFor(activities_spec, ...args)
export const activities_summary_spec = ["/api/v1/activities/summary(.:format)", ["format"], { ...default_url_options, ...{"format":"json"} }, parent_spec]
export const activities_summary_url = (...args) => urlFor(activities_summary_spec, ...args)
export const activities_summary_path = (...args) => pathFor(activities_summary_spec, ...args)
export const activity_detail_spec = ["/api/v1/activities/:id(.:format)", ["id","format"], { ...default_url_options, ...{"format":"json"} }, parent_spec]
export const activity_detail_url = (...args) => urlFor(activity_detail_spec, ...args)
export const activity_detail_path = (...args) => pathFor(activity_detail_spec, ...args)
export const admin_list_users_spec = ["/admin/api/v1/users(.:format)", ["format"], { ...default_url_options, ...{"format":"json"} }, parent_spec]
export const admin_list_users_url = (...args) => urlFor(admin_list_users_spec, ...args)
export const admin_list_users_path = (...args) => pathFor(admin_list_users_spec, ...args)
export const admin_sign_in_as_spec = ["/admin/api/v1/users/sign_in_as(.:format)", ["format"], { ...default_url_options, ...{"format":"json"} }, parent_spec]
export const admin_sign_in_as_url = (...args) => urlFor(admin_sign_in_as_spec, ...args)
export const admin_sign_in_as_path = (...args) => pathFor(admin_sign_in_as_spec, ...args)
export const admin_statistics_spec = ["/admin/api/v1/users/statistics(.:format)", ["format"], { ...default_url_options, ...{"format":"json"} }, parent_spec]
export const admin_statistics_url = (...args) => urlFor(admin_statistics_spec, ...args)
export const admin_statistics_path = (...args) => pathFor(admin_statistics_spec, ...args)
export const ahoy_engine_spec = ["/ahoy", [], { ...default_url_options, ...{} }, parent_spec]
export const ahoy_engine_url = (...args) => urlFor(ahoy_engine_spec, ...args)
export const ahoy_engine_path = (...args) => pathFor(ahoy_engine_spec, ...args)
export const all_activities_spec = ["/api/v1/activities/all(.:format)", ["format"], { ...default_url_options, ...{"format":"json"} }, parent_spec]
export const all_activities_url = (...args) => urlFor(all_activities_spec, ...args)
export const all_activities_path = (...args) => pathFor(all_activities_spec, ...args)
export const authenticate_user_spec = ["/api/v1/user/authenticate(.:format)", ["format"], { ...default_url_options, ...{"format":"json"} }, parent_spec]
export const authenticate_user_url = (...args) => urlFor(authenticate_user_spec, ...args)
export const authenticate_user_path = (...args) => pathFor(authenticate_user_spec, ...args)
export const create_activity_spec = ["/api/v1/activities/create(.:format)", ["format"], { ...default_url_options, ...{"format":"json"} }, parent_spec]
export const create_activity_url = (...args) => urlFor(create_activity_spec, ...args)
export const create_activity_path = (...args) => pathFor(create_activity_spec, ...args)
export const create_mobile_sign_in_link_spec = ["/api/v1/sign-in/create_mobile(.:format)", ["format"], { ...default_url_options, ...{"format":"json"} }, parent_spec]
export const create_mobile_sign_in_link_url = (...args) => urlFor(create_mobile_sign_in_link_spec, ...args)
export const create_mobile_sign_in_link_path = (...args) => pathFor(create_mobile_sign_in_link_spec, ...args)
export const create_push_token_spec = ["/api/v1/user/push_tokens(.:format)", ["format"], { ...default_url_options, ...{"format":"json"} }, parent_spec]
export const create_push_token_url = (...args) => urlFor(create_push_token_spec, ...args)
export const create_push_token_path = (...args) => pathFor(create_push_token_spec, ...args)
export const create_sign_in_link_spec = ["/api/v1/sign-in/create(.:format)", ["format"], { ...default_url_options, ...{"format":"json"} }, parent_spec]
export const create_sign_in_link_url = (...args) => urlFor(create_sign_in_link_spec, ...args)
export const create_sign_in_link_path = (...args) => pathFor(create_sign_in_link_spec, ...args)
export const create_user_ftp_spec = ["/api/v1/user/create_ftp(.:format)", ["format"], { ...default_url_options, ...{"format":"json"} }, parent_spec]
export const create_user_ftp_url = (...args) => urlFor(create_user_ftp_spec, ...args)
export const create_user_ftp_path = (...args) => pathFor(create_user_ftp_spec, ...args)
export const create_user_weight_spec = ["/api/v1/user/create_weight(.:format)", ["format"], { ...default_url_options, ...{"format":"json"} }, parent_spec]
export const create_user_weight_url = (...args) => urlFor(create_user_weight_spec, ...args)
export const create_user_weight_path = (...args) => pathFor(create_user_weight_spec, ...args)
export const delete_account_spec = ["/api/v1/user/destroy(.:format)", ["format"], { ...default_url_options, ...{"format":"json"} }, parent_spec]
export const delete_account_url = (...args) => urlFor(delete_account_spec, ...args)
export const delete_account_path = (...args) => pathFor(delete_account_spec, ...args)
export const destroy_activity_spec = ["/api/v1/activities/destroy(.:format)", ["format"], { ...default_url_options, ...{"format":"json"} }, parent_spec]
export const destroy_activity_url = (...args) => urlFor(destroy_activity_spec, ...args)
export const destroy_activity_path = (...args) => pathFor(destroy_activity_spec, ...args)
export const destroy_user_ftp_spec = ["/api/v1/user/destroy_ftp(.:format)", ["format"], { ...default_url_options, ...{"format":"json"} }, parent_spec]
export const destroy_user_ftp_url = (...args) => urlFor(destroy_user_ftp_spec, ...args)
export const destroy_user_ftp_path = (...args) => pathFor(destroy_user_ftp_spec, ...args)
export const destroy_user_weight_spec = ["/api/v1/user/destroy_weight(.:format)", ["format"], { ...default_url_options, ...{"format":"json"} }, parent_spec]
export const destroy_user_weight_url = (...args) => urlFor(destroy_user_weight_spec, ...args)
export const destroy_user_weight_path = (...args) => pathFor(destroy_user_weight_spec, ...args)
export const edit_activity_spec = ["/api/v1/activities/edit(.:format)", ["format"], { ...default_url_options, ...{"format":"json"} }, parent_spec]
export const edit_activity_url = (...args) => urlFor(edit_activity_spec, ...args)
export const edit_activity_path = (...args) => pathFor(edit_activity_spec, ...args)
export const edit_rails_conductor_inbound_email_spec = ["/rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const edit_rails_conductor_inbound_email_url = (...args) => urlFor(edit_rails_conductor_inbound_email_spec, ...args)
export const edit_rails_conductor_inbound_email_path = (...args) => pathFor(edit_rails_conductor_inbound_email_spec, ...args)
export const edit_user_ftp_spec = ["/api/v1/user/edit_ftp(.:format)", ["format"], { ...default_url_options, ...{"format":"json"} }, parent_spec]
export const edit_user_ftp_url = (...args) => urlFor(edit_user_ftp_spec, ...args)
export const edit_user_ftp_path = (...args) => pathFor(edit_user_ftp_spec, ...args)
export const edit_user_weight_spec = ["/api/v1/user/edit_weight(.:format)", ["format"], { ...default_url_options, ...{"format":"json"} }, parent_spec]
export const edit_user_weight_url = (...args) => urlFor(edit_user_weight_spec, ...args)
export const edit_user_weight_path = (...args) => pathFor(edit_user_weight_spec, ...args)
export const garmin_authorize_success_spec = ["/api/v1/garmin/authorize_success(.:format)", ["format"], { ...default_url_options, ...{"format":"json"} }, parent_spec]
export const garmin_authorize_success_url = (...args) => urlFor(garmin_authorize_success_spec, ...args)
export const garmin_authorize_success_path = (...args) => pathFor(garmin_authorize_success_spec, ...args)
export const garmin_authorize_url_spec = ["/api/v1/garmin/authorize_url(.:format)", ["format"], { ...default_url_options, ...{"format":"json"} }, parent_spec]
export const garmin_authorize_url_url = (...args) => urlFor(garmin_authorize_url_spec, ...args)
export const garmin_authorize_url_path = (...args) => pathFor(garmin_authorize_url_spec, ...args)
export const garmin_deauthorize_spec = ["/api/v1/garmin/deauthorize(.:format)", ["format"], { ...default_url_options, ...{"format":"json"} }, parent_spec]
export const garmin_deauthorize_url = (...args) => urlFor(garmin_deauthorize_spec, ...args)
export const garmin_deauthorize_path = (...args) => pathFor(garmin_deauthorize_spec, ...args)
export const home_spec = ["/", [], { ...default_url_options, ...{} }, parent_spec]
export const home_url = (...args) => urlFor(home_spec, ...args)
export const home_path = (...args) => pathFor(home_spec, ...args)
export const new_rails_conductor_inbound_email_spec = ["/rails/conductor/action_mailbox/inbound_emails/new(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const new_rails_conductor_inbound_email_url = (...args) => urlFor(new_rails_conductor_inbound_email_spec, ...args)
export const new_rails_conductor_inbound_email_path = (...args) => pathFor(new_rails_conductor_inbound_email_spec, ...args)
export const new_rails_conductor_inbound_email_source_spec = ["/rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const new_rails_conductor_inbound_email_source_url = (...args) => urlFor(new_rails_conductor_inbound_email_source_spec, ...args)
export const new_rails_conductor_inbound_email_source_path = (...args) => pathFor(new_rails_conductor_inbound_email_source_spec, ...args)
export const pay_spec = ["/pay", [], { ...default_url_options, ...{} }, parent_spec]
export const pay_url = (...args) => urlFor(pay_spec, ...args)
export const pay_path = (...args) => pathFor(pay_spec, ...args)
export const pg_hero_spec = ["/pghero", [], { ...default_url_options, ...{} }, parent_spec]
export const pg_hero_url = (...args) => urlFor(pg_hero_spec, ...args)
export const pg_hero_path = (...args) => pathFor(pg_hero_spec, ...args)
export const rails_blob_representation_spec = ["/rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)", ["signed_blob_id","variation_key","filename","format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_blob_representation_url = (...args) => urlFor(rails_blob_representation_spec, ...args)
export const rails_blob_representation_path = (...args) => pathFor(rails_blob_representation_spec, ...args)
export const rails_blob_representation_proxy_spec = ["/rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)", ["signed_blob_id","variation_key","filename","format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_blob_representation_proxy_url = (...args) => urlFor(rails_blob_representation_proxy_spec, ...args)
export const rails_blob_representation_proxy_path = (...args) => pathFor(rails_blob_representation_proxy_spec, ...args)
export const rails_conductor_inbound_email_spec = ["/rails/conductor/action_mailbox/inbound_emails/:id(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_conductor_inbound_email_url = (...args) => urlFor(rails_conductor_inbound_email_spec, ...args)
export const rails_conductor_inbound_email_path = (...args) => pathFor(rails_conductor_inbound_email_spec, ...args)
export const rails_conductor_inbound_email_reroute_spec = ["/rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)", ["inbound_email_id","format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_conductor_inbound_email_reroute_url = (...args) => urlFor(rails_conductor_inbound_email_reroute_spec, ...args)
export const rails_conductor_inbound_email_reroute_path = (...args) => pathFor(rails_conductor_inbound_email_reroute_spec, ...args)
export const rails_conductor_inbound_email_sources_spec = ["/rails/conductor/action_mailbox/inbound_emails/sources(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_conductor_inbound_email_sources_url = (...args) => urlFor(rails_conductor_inbound_email_sources_spec, ...args)
export const rails_conductor_inbound_email_sources_path = (...args) => pathFor(rails_conductor_inbound_email_sources_spec, ...args)
export const rails_conductor_inbound_emails_spec = ["/rails/conductor/action_mailbox/inbound_emails(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_conductor_inbound_emails_url = (...args) => urlFor(rails_conductor_inbound_emails_spec, ...args)
export const rails_conductor_inbound_emails_path = (...args) => pathFor(rails_conductor_inbound_emails_spec, ...args)
export const rails_direct_uploads_spec = ["/rails/active_storage/direct_uploads(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_direct_uploads_url = (...args) => urlFor(rails_direct_uploads_spec, ...args)
export const rails_direct_uploads_path = (...args) => pathFor(rails_direct_uploads_spec, ...args)
export const rails_disk_service_spec = ["/rails/active_storage/disk/:encoded_key/*filename(.:format)", ["encoded_key","filename","format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_disk_service_url = (...args) => urlFor(rails_disk_service_spec, ...args)
export const rails_disk_service_path = (...args) => pathFor(rails_disk_service_spec, ...args)
export const rails_mailgun_inbound_emails_spec = ["/rails/action_mailbox/mailgun/inbound_emails/mime(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_mailgun_inbound_emails_url = (...args) => urlFor(rails_mailgun_inbound_emails_spec, ...args)
export const rails_mailgun_inbound_emails_path = (...args) => pathFor(rails_mailgun_inbound_emails_spec, ...args)
export const rails_mandrill_inbound_emails_spec = ["/rails/action_mailbox/mandrill/inbound_emails(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_mandrill_inbound_emails_url = (...args) => urlFor(rails_mandrill_inbound_emails_spec, ...args)
export const rails_mandrill_inbound_emails_path = (...args) => pathFor(rails_mandrill_inbound_emails_spec, ...args)
export const rails_mandrill_inbound_health_check_spec = ["/rails/action_mailbox/mandrill/inbound_emails(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_mandrill_inbound_health_check_url = (...args) => urlFor(rails_mandrill_inbound_health_check_spec, ...args)
export const rails_mandrill_inbound_health_check_path = (...args) => pathFor(rails_mandrill_inbound_health_check_spec, ...args)
export const rails_postmark_inbound_emails_spec = ["/rails/action_mailbox/postmark/inbound_emails(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_postmark_inbound_emails_url = (...args) => urlFor(rails_postmark_inbound_emails_spec, ...args)
export const rails_postmark_inbound_emails_path = (...args) => pathFor(rails_postmark_inbound_emails_spec, ...args)
export const rails_relay_inbound_emails_spec = ["/rails/action_mailbox/relay/inbound_emails(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_relay_inbound_emails_url = (...args) => urlFor(rails_relay_inbound_emails_spec, ...args)
export const rails_relay_inbound_emails_path = (...args) => pathFor(rails_relay_inbound_emails_spec, ...args)
export const rails_sendgrid_inbound_emails_spec = ["/rails/action_mailbox/sendgrid/inbound_emails(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_sendgrid_inbound_emails_url = (...args) => urlFor(rails_sendgrid_inbound_emails_spec, ...args)
export const rails_sendgrid_inbound_emails_path = (...args) => pathFor(rails_sendgrid_inbound_emails_spec, ...args)
export const rails_service_blob_spec = ["/rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)", ["signed_id","filename","format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_service_blob_url = (...args) => urlFor(rails_service_blob_spec, ...args)
export const rails_service_blob_path = (...args) => pathFor(rails_service_blob_spec, ...args)
export const rails_service_blob_proxy_spec = ["/rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)", ["signed_id","filename","format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_service_blob_proxy_url = (...args) => urlFor(rails_service_blob_proxy_spec, ...args)
export const rails_service_blob_proxy_path = (...args) => pathFor(rails_service_blob_proxy_spec, ...args)
export const robots_spec = ["/robots.txt(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const robots_url = (...args) => urlFor(robots_spec, ...args)
export const robots_path = (...args) => pathFor(robots_spec, ...args)
export const sidekiq_spec = ["/sidekiq(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const sidekiq_url = (...args) => urlFor(sidekiq_spec, ...args)
export const sidekiq_path = (...args) => pathFor(sidekiq_spec, ...args)
export const sidekiq_web_spec = ["/sidekiq", [], { ...default_url_options, ...{} }, parent_spec]
export const sidekiq_web_url = (...args) => urlFor(sidekiq_web_spec, ...args)
export const sidekiq_web_path = (...args) => pathFor(sidekiq_web_spec, ...args)
export const similar_activities_spec = ["/api/v1/activities/similar-activities(.:format)", ["format"], { ...default_url_options, ...{"format":"json"} }, parent_spec]
export const similar_activities_url = (...args) => urlFor(similar_activities_spec, ...args)
export const similar_activities_path = (...args) => pathFor(similar_activities_spec, ...args)
export const strava_authorize_success_spec = ["/api/v1/strava/authorize_success(.:format)", ["format"], { ...default_url_options, ...{"format":"json"} }, parent_spec]
export const strava_authorize_success_url = (...args) => urlFor(strava_authorize_success_spec, ...args)
export const strava_authorize_success_path = (...args) => pathFor(strava_authorize_success_spec, ...args)
export const strava_authorize_url_spec = ["/api/v1/strava/authorize_url(.:format)", ["format"], { ...default_url_options, ...{"format":"json"} }, parent_spec]
export const strava_authorize_url_url = (...args) => urlFor(strava_authorize_url_spec, ...args)
export const strava_authorize_url_path = (...args) => pathFor(strava_authorize_url_spec, ...args)
export const strava_deauthorize_spec = ["/api/v1/strava/deauthorize(.:format)", ["format"], { ...default_url_options, ...{"format":"json"} }, parent_spec]
export const strava_deauthorize_url = (...args) => urlFor(strava_deauthorize_spec, ...args)
export const strava_deauthorize_path = (...args) => pathFor(strava_deauthorize_spec, ...args)
export const strava_sync_spec = ["/api/v1/strava/sync(.:format)", ["format"], { ...default_url_options, ...{"format":"json"} }, parent_spec]
export const strava_sync_url = (...args) => urlFor(strava_sync_spec, ...args)
export const strava_sync_path = (...args) => pathFor(strava_sync_spec, ...args)
export const strava_webhooks_spec = ["/strava/webhooks(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const strava_webhooks_url = (...args) => urlFor(strava_webhooks_spec, ...args)
export const strava_webhooks_path = (...args) => pathFor(strava_webhooks_spec, ...args)
export const tools_ftp_calulator_spec = ["/tools/ftp-calulator(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const tools_ftp_calulator_url = (...args) => urlFor(tools_ftp_calulator_spec, ...args)
export const tools_ftp_calulator_path = (...args) => pathFor(tools_ftp_calulator_spec, ...args)
export const tools_power_to_weight_calulator_spec = ["/tools/power-to-weight-calulator(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const tools_power_to_weight_calulator_url = (...args) => urlFor(tools_power_to_weight_calulator_spec, ...args)
export const tools_power_to_weight_calulator_path = (...args) => pathFor(tools_power_to_weight_calulator_spec, ...args)
export const unsubscribe_spec = ["/api/v1/unsubscribe(.:format)", ["format"], { ...default_url_options, ...{"format":"json"} }, parent_spec]
export const unsubscribe_url = (...args) => urlFor(unsubscribe_spec, ...args)
export const unsubscribe_path = (...args) => pathFor(unsubscribe_spec, ...args)
export const update_activity_note_spec = ["/api/v1/activities/update-note(.:format)", ["format"], { ...default_url_options, ...{"format":"json"} }, parent_spec]
export const update_activity_note_url = (...args) => urlFor(update_activity_note_spec, ...args)
export const update_activity_note_path = (...args) => pathFor(update_activity_note_spec, ...args)
export const update_rails_disk_service_spec = ["/rails/active_storage/disk/:encoded_token(.:format)", ["encoded_token","format"], { ...default_url_options, ...{} }, parent_spec]
export const update_rails_disk_service_url = (...args) => urlFor(update_rails_disk_service_spec, ...args)
export const update_rails_disk_service_path = (...args) => pathFor(update_rails_disk_service_spec, ...args)
export const update_user_email_spec = ["/api/v1/user/update_email(.:format)", ["format"], { ...default_url_options, ...{"format":"json"} }, parent_spec]
export const update_user_email_url = (...args) => urlFor(update_user_email_spec, ...args)
export const update_user_email_path = (...args) => pathFor(update_user_email_spec, ...args)
export const update_user_profile_spec = ["/api/v1/user/update(.:format)", ["format"], { ...default_url_options, ...{"format":"json"} }, parent_spec]
export const update_user_profile_url = (...args) => urlFor(update_user_profile_spec, ...args)
export const update_user_profile_path = (...args) => pathFor(update_user_profile_spec, ...args)
export const upload_activity_spec = ["/api/v1/activities/upload(.:format)", ["format"], { ...default_url_options, ...{"format":"json"} }, parent_spec]
export const upload_activity_url = (...args) => urlFor(upload_activity_spec, ...args)
export const upload_activity_path = (...args) => pathFor(upload_activity_spec, ...args)
export const user_achievements_spec = ["/api/v1/user/achievements(.:format)", ["format"], { ...default_url_options, ...{"format":"json"} }, parent_spec]
export const user_achievements_url = (...args) => urlFor(user_achievements_spec, ...args)
export const user_achievements_path = (...args) => pathFor(user_achievements_spec, ...args)
export const user_profile_spec = ["/api/v1/user/profile(.:format)", ["format"], { ...default_url_options, ...{"format":"json"} }, parent_spec]
export const user_profile_url = (...args) => urlFor(user_profile_spec, ...args)
export const user_profile_path = (...args) => pathFor(user_profile_spec, ...args)
export const user_sign_out_spec = ["/api/v1/user/sign-out(.:format)", ["format"], { ...default_url_options, ...{"format":"json"} }, parent_spec]
export const user_sign_out_url = (...args) => urlFor(user_sign_out_spec, ...args)
export const user_sign_out_path = (...args) => pathFor(user_sign_out_spec, ...args)
export const user_subscription_spec = ["/api/v1/user/user_subscription(.:format)", ["format"], { ...default_url_options, ...{"format":"json"} }, parent_spec]
export const user_subscription_url = (...args) => urlFor(user_subscription_spec, ...args)
export const user_subscription_path = (...args) => pathFor(user_subscription_spec, ...args)
export const validate_sign_in_link_spec = ["/api/v1/sign-in/validate(.:format)", ["format"], { ...default_url_options, ...{"format":"json"} }, parent_spec]
export const validate_sign_in_link_url = (...args) => urlFor(validate_sign_in_link_spec, ...args)
export const validate_sign_in_link_path = (...args) => pathFor(validate_sign_in_link_spec, ...args)

import dayjs from 'utils/dayjs'

export function isSameOrAfter(date1, date2, granularity = null) {
  return dayjs(date1).isSameOrAfter(dayjs(date2), granularity)
}

export function isSameOrBefore(date1, date2, granularity = null) {
  return dayjs(date1).isSameOrBefore(dayjs(date2), granularity)
}

export function dateIsBetween(date, from, to, granularity = null) {
  return dayjs(date).isBetween(dayjs(from), dayjs(to), granularity, '[]')
}

export function generateDateRange(from, to) {
  const range = []
  let current = new Date(from)

  while (current <= to) {
    range.push(new Date(current))
    current.setDate(current.getDate() + 1)
  }

  return range
}

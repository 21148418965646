<template>
  <div class="CreateActivity">
    <SectionTitle title="Upload Your Activities" />

    <div class="w-full px-8 py-4 mb-16 bg-white shadow-md">
      <input id="files" ref="files" class="ActivityUploadInput" type="file" multiple accept=".fit" @change="handleFilesUpload()" />

      <div
        v-if="!files.length > 0"
        class="p-16 mx-2 my-4 text-center bg-white border border-gray-400 border-dashed rounded"
        @dragover="dragover"
        @dragleave="dragleave"
        @drop="drop"
      >
        <div class="my-4 text-xl font-bold cursor-pointer" @click="addFiles()">
          Drag &amp; Drop or
          <span class="border-primary text-primary border-b">Select Files</span>
          to Upload
        </div>
        <div class="text-muted flex items-center justify-center mt-6 text-center">
          <svg class="svg-icon svg-icon--sm mr-2">
            <use xlink:href="#icon-info"></use>
          </svg>
          We will recognize and won't import any duplicate activities.
        </div>
        <div class="text-muted mt-2">
          Supported File Formats:
          <span class="px-2 ml-1 text-sm font-bold text-gray-700 bg-white border rounded">FIT</span>
        </div>
      </div>

      <div v-else>
        <div class="my-4 text-xl font-bold">Selected Files</div>

        <div v-for="(file, key) in files" :key="key" class="file-listing flex items-center px-2 py-3 border-t border-b">
          <div class="font-bold">
            {{ file.file.name }}
          </div>
          <div class="ml-auto">
            <span v-if="file.status === 'Uploading'">Uploading...</span>
            <span v-else-if="file.status === 'Uploaded'">
              <router-link v-if="file.activity" :to="{ name: 'dashboard.activity', params: { id: file.activity.id } }" target="_blank" class="text-primary">
                Open Activity &raquo;
              </router-link>
            </span>
            <span v-else-if="file.status === 'Error'">
              {{ file.error || 'Error' }}
            </span>
            <span v-else class="text-red-500 cursor-pointer" @click="removeFile(key)">Remove</span>
          </div>
        </div>

        <div class="FormGroup FormGroup--last">
          <div class="FormGroup__content flex w-full space-x-2">
            <AppButton class="ml-auto" :disabled="isUploading" primary @click="submit()">Upload Activities</AppButton>
            <AppButton secondary @click="cancel()">Cancel</AppButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import SectionTitle from 'components/SectionTitle'
import ProfileService from 'services/ProfileService'

export default {
  components: {
    SectionTitle
  },

  data() {
    return {
      isUploading: false,
      files: [],
      errors: {}
    }
  },

  mounted() {
    this.files = []
  },

  methods: {
    ...mapActions('profile', ['loadProfile']),

    addFiles() {
      this.$refs.files.click()
    },

    cancel() {
      if (this.files.length == 0) {
        this.$router.push({ name: 'dashboard.activities' })
      } else {
        this.files = []
      }
    },

    async submit() {
      this.isUploading = true

      for (var i = 0; i < this.files.length; i++) {
        let file = this.files[i]

        if (file.status === 'Pending') {
          this.files[i].status = 'Uploading'

          try {
            const result = await ProfileService.uploadActivity(file.file)
            this.files[i].activity = result
            this.files[i].status = 'Uploaded'
          } catch (e) {
            this.files[i].status = 'Error'
            this.files[i].error = e?.response?.data?.message
          }
        }
      }

      await this.loadProfile()
      this.isUploading = false
    },

    handleFilesUpload() {
      let uploadedFiles = this.$refs.files.files

      for (var i = 0; i < uploadedFiles.length; i++) {
        const isFitFile = uploadedFiles[i].name.toLowerCase().includes('.fit')
        const isAllowedSize = uploadedFiles[i].size < 1024 * 8192

        if (isFitFile && isAllowedSize) {
          this.files.push({
            status: 'Pending',
            file: uploadedFiles[i]
          })
        }
      }
    },

    removeFile(key) {
      this.files = this.files.filter((file, index) => index !== key)
    },

    dragover(event) {
      event.preventDefault()
      if (!event.currentTarget.classList.contains('border-primary')) {
        event.currentTarget.classList.remove('border-gray-400')
        event.currentTarget.classList.add('border-blue-500')
        event.currentTarget.classList.add('bg-gray-100')
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add('border-gray-400')
      event.currentTarget.classList.remove('border-blue-500')
      event.currentTarget.classList.remove('bg-gray-100')
    },
    drop(event) {
      event.preventDefault()
      this.$refs.files.files = event.dataTransfer.files
      this.handleFilesUpload()
      // Clean up
      event.currentTarget.classList.add('border-gray-400')
      event.currentTarget.classList.remove('border-blue-500')
      event.currentTarget.classList.remove('bg-gray-100')
    }
  },

  metaInfo() {
    return {
      title: 'Upload an Activity'
    }
  }
}
</script>

<style lang="postcss" scoped>
.ActivityUploadInput {
  position: absolute;
  top: -500px;
}
</style>

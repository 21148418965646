<template>
  <div class="ActivityNote">
    <div v-if="truncated" class="ActivityNote__content">
      {{ excerpt | truncate(128, '') }}
      <span v-if="shouldTruncateNote" class="ActivityNote__showMore" @click="truncated = false">&hellip;</span>
    </div>
    <div v-else class="ActivityNote__content">{{ note }}</div>
  </div>
</template>

<script>
const MAX_NOTE_LENGTH = 128

export default {
  props: {
    note: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      truncated: true
    }
  },

  computed: {
    isMultiLineNote() {
      return this.note.includes('\n')
    },

    isNoteTooLong() {
      return this.note.length > MAX_NOTE_LENGTH
    },

    shouldTruncateNote() {
      return this.isMultiLineNote || this.isNoteTooLong
    },

    excerpt() {
      if (this.isMultiLineNote) {
        return this.note.split('\n').slice(0, 2).join('\n')
      } else {
        return this.note
      }
    }
  }
}
</script>

<style lang="postcss" scoped>
.ActivityNote {
  &__content {
    white-space: break-spaces;
  }

  &__showMore {
    @apply px-1 text-sm bg-gray-200 rounded cursor-pointer;

    &:hover {
      @apply bg-gray-300;
    }
  }
}
</style>

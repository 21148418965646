import Vue from 'vue'

import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

import config from 'config'
import router from 'router'

Sentry.init({
  Vue,
  dsn: config.sentry_dsn_js,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['formbeat.com', /^\//]
    })
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.25
})

export default Sentry

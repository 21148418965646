var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full"},[(_vm.user.has_powermeter)?_c('div',[_c('PeaksChart',{attrs:{"title":"Peak Power","data":_vm.currentSummary,"segment-by":_vm.segmentBy,"attribute":"peak_power","format":"power"}}),_vm._v(" "),_c('HeartRateZonesChart',{attrs:{"title":"Time in Power Zones ","data":_vm.currentSummary,"segment-by":_vm.segmentBy,"attributes":[
        'time_in_power_zone_1',
        'time_in_power_zone_2',
        'time_in_power_zone_3',
        'time_in_power_zone_4',
        'time_in_power_zone_5',
        'time_in_power_zone_6',
        'time_in_power_zone_7'
      ]}}),_vm._v(" "),_c('StatsSection',{attrs:{"title":"Normalized Power"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('InfoIcon',{attrs:{"term":"normalized-power"}})]},proxy:true}],null,false,3822642755)},[_c('Chart',{attrs:{"title":"NP","data":_vm.currentSummary,"segment-by":_vm.segmentBy,"attribute":"computed_weighted_power","format":"power"}})],1),_vm._v(" "),_c('StatsSection',{attrs:{"title":"Intensity Factor"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('InfoIcon',{attrs:{"term":"intensity"}})]},proxy:true}],null,false,1269101503)},[_c('Chart',{attrs:{"title":"Intensity","data":_vm.currentSummary,"segment-by":_vm.segmentBy,"attribute":"average_intensity","format":"percent"}})],1),_vm._v(" "),_c('StatsSection',{attrs:{"title":"Variability Index"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('InfoIcon',{attrs:{"term":"variability-index"}})]},proxy:true}],null,false,268868013)},[_c('Chart',{attrs:{"title":"Variability Index","data":_vm.currentSummary,"segment-by":_vm.segmentBy,"attribute":"variability_index","format":"percent"}})],1),_vm._v(" "),_c('StatsSection',{attrs:{"title":"Efficiency factor"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('InfoIcon',{attrs:{"term":"efficiency-factor"}})]},proxy:true}],null,false,366964743)},[_c('Chart',{attrs:{"title":"Efficiency factor","data":_vm.currentSummary,"segment-by":_vm.segmentBy,"attribute":"efficiency_factor","decimal-places":2,"format":"numeric"}})],1)],1):_c('div',[_c('div',{staticClass:"text-muted p-12 text-center bg-white rounded shadow"},[_vm._v("\n      Add activities with power data and\n      "),_c('router-link',{staticClass:"text-link",attrs:{"to":{ name: 'settings.power' }}},[_vm._v("set your FTP")]),_vm._v("\n      to see you power profile and trends.\n    ")],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }
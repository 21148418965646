<template>
  <StatsSection :title="title">
    <template #header>
      <ChartNavigation :options="chartOptions" :selected-option="selectedChartOption" @input="setChartOption" />
    </template>

    <chart :options="chartData"></chart>
  </StatsSection>
</template>

<script>
import * as chartConfig from 'constants/chartConfig'

import dayjs from 'utils/dayjs'
import dateFormat from 'utils/dateFormat'

import chroma from 'chroma-js'

import formatUnits from 'filters/formatUnits'
import formatLabels from 'filters/formatLabels'
import heartRateZones from 'constants/heartRateZones'
import powerZones from 'constants/powerZones'

import StatsSection from 'components/StatsSection'

export default {
  components: {
    StatsSection
  },

  props: {
    data: {
      type: Object,
      required: true
    },
    segmentBy: {
      type: String,
      required: true
    },
    attributes: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    height: {
      type: Number,
      required: false,
      default: 500
    },
    decimalPlaces: {
      type: Number,
      required: false,
      default: 0
    }
  },

  data() {
    return {
      selectedChartOption: 'Stacked',
      chartOptions: ['Stacked', 'Unstacked']
    }
  },

  computed: {
    chartUnits() {
      return formatUnits('percent')
    },

    chartData() {
      const source = this.data[this.segmentBy]
      const keys = Object.keys(source)
      const stacking = this.selectedChartOption === 'Stacked' ? 'normal' : undefined

      const resolveNullValue = (value) => {
        if (Number(value) >= 0) {
          return Number(Number(value).toFixed(2))
        } else {
          return 0
        }
      }

      const isHeartRate = this.attributes[0].indexOf('time_in_hr_zone') > -1

      let colors, seriesNames

      if (isHeartRate) {
        colors = chroma.scale(['#FBA570', '#e91500']).colors(5)
        seriesNames = heartRateZones
      } else {
        colors = chroma.scale(['#FBA570', '#e91500']).colors(7)
        seriesNames = powerZones
      }

      if (this.$isMobile()) {
        seriesNames = Array.from({ length: seriesNames.length }).map((_, index) => `Z${index + 1}`)
      }

      const series = this.attributes.map((attribute, index) => {
        return {
          name: seriesNames[index],
          data: keys.map((key) => {
            return {
              x: Date.UTC(dayjs(key).year(), dayjs(key).month(), dayjs(key).date()),
              y: source[key] ? resolveNullValue(source[key][attribute]) : null
            }
          })
        }
      })

      const { formatter } = formatLabels(series[0].data.length, this.segmentBy, this.$isMobile())

      const options = {
        ...chartConfig.defaultOptions,
        ...chartConfig.withLegend,

        chart: {
          ...chartConfig.chart,
          height: this.$isMobile() ? this.height / 2 : this.height,
          marginLeft: 75,
          type: 'column'
        },
        exporting: {
          ...chartConfig.exporting,
          enabled: !this.$isMobile()
        },
        tooltip: {
          ...chartConfig.withTooltip,
          valueDecimals: this.decimalPlaces,
          valueSuffix: this.chartUnits ? ` ${this.chartUnits}` : '',
          xDateFormat: dateFormat(this.segmentBy)
        },

        colors: colors,
        xAxis: {
          type: 'datetime',
          startOfWeek: Number(dayjs().weekday(0).format('d')),
          margin: 0,
          padding: 0,
          labels: {
            formatter: formatter,
            autoRotation: false,
            padding: 0
          }
        },
        yAxis: [
          {
            floor: 0,
            ceiling: 100,
            margin: 0,
            padding: 0,
            reversedStacks: false,
            title: {
              enabled: false
            },
            labels: {
              format: this.chartUnits ? `{value} ${this.chartUnits}` : '{value}',
              padding: 0
            }
          }
        ],
        plotOptions: {
          series: {
            shadow: false,
            pointPadding: 0.05,
            groupPadding: 0.05,
            borderWidth: 1,
            maxPointWidth: 148,
            marker: {
              enabled: false,
              symbol: 'circle'
            }
          },
          column: {
            stacking: stacking
          }
        },
        series: series
      }

      return options
    }
  },

  methods: {
    setChartOption(option) {
      this.selectedChartOption = option
    }
  }
}
</script>

export default [
  { label: '12:00 AM', value: 0 },
  { label: '12:30 AM', value: 0.5 },
  { label: '01:00 AM', value: 1 },
  { label: '01:30 AM', value: 1.5 },
  { label: '02:00 AM', value: 2 },
  { label: '02:30 AM', value: 2.5 },
  { label: '03:00 AM', value: 3 },
  { label: '03:30 AM', value: 3.5 },
  { label: '04:00 AM', value: 4 },
  { label: '04:30 AM', value: 4.5 },
  { label: '05:00 AM', value: 5 },
  { label: '05:30 AM', value: 5.5 },
  { label: '06:00 AM', value: 6 },
  { label: '06:30 AM', value: 6.5 },
  { label: '07:00 AM', value: 7 },
  { label: '07:30 AM', value: 7.5 },
  { label: '08:00 AM', value: 8 },
  { label: '08:30 AM', value: 8.5 },
  { label: '09:00 AM', value: 9 },
  { label: '09:30 AM', value: 9.5 },
  { label: '10:00 AM', value: 10 },
  { label: '10:30 AM', value: 10.5 },
  { label: '11:00 AM', value: 11 },
  { label: '11:30 AM', value: 11.5 },
  { label: '12:00 PM', value: 12 },
  { label: '12:30 PM', value: 12.5 },
  { label: '01:00 PM', value: 13 },
  { label: '01:30 PM', value: 13.5 },
  { label: '02:00 PM', value: 14 },
  { label: '02:30 PM', value: 14.5 },
  { label: '03:00 PM', value: 15 },
  { label: '03:30 PM', value: 15.5 },
  { label: '04:00 PM', value: 16 },
  { label: '04:30 PM', value: 16.5 },
  { label: '05:00 PM', value: 17 },
  { label: '05:30 PM', value: 17.5 },
  { label: '06:00 PM', value: 18 },
  { label: '06:30 PM', value: 18.5 },
  { label: '07:00 PM', value: 19 },
  { label: '07:30 PM', value: 19.5 },
  { label: '08:00 PM', value: 20 },
  { label: '08:30 PM', value: 20.5 },
  { label: '09:00 PM', value: 21 },
  { label: '09:30 PM', value: 21.5 },
  { label: '10:00 PM', value: 22 },
  { label: '10:30 PM', value: 22.5 },
  { label: '11:00 PM', value: 23 },
  { label: '11:30 PM', value: 23.5 }
]

<template>
  <div>
    <StatsSection title="Total ascent">
      <Chart title="Elevation gain" :data="currentSummary" :segment-by="segmentBy" attribute="total_elevation_gain" format="elevation" />
    </StatsSection>

    <!-- <StatsSection title="Average ascent">
      <Chart title="Elevation gain" :data="currentSummary" :segment-by="segmentBy" attribute="average_elevation_gain" format="elevation" />
    </StatsSection> -->

    <StatsSection title="Average VAM">
      <Chart title="Average VAM" :data="currentSummary" :segment-by="segmentBy" attribute="average_vam" format="vam" />
    </StatsSection>
  </div>
</template>

<script>
import Chart from 'components/charts/Chart'
import StatsSection from 'components/StatsSection'

export default {
  components: {
    Chart,
    StatsSection
  },

  props: {
    summary: {
      type: Object,
      required: true
    },
    currentSummary: {
      type: Object,
      required: true
    },
    activities: {
      type: Array,
      required: true
    },
    dateRange: {
      type: Object,
      required: true
    },
    segmentBy: {
      type: String,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },

  metaInfo() {
    return {
      title: 'Climbing Analysis'
    }
  }
}
</script>

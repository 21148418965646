<template>
  <div class="AllTimeStatsCard">
    <h3 class="label flex items-center">
      <svg class="svg-icon svg-icon--sm text-primary flex-shrink-0 mr-2">
        <use :xlink:href="`#icon-${icon}`"></use>
      </svg>
      {{ title }}
    </h3>
    <div class="AllTimeStatsCard__score">{{ score | format(type) }}</div>
    <h4 class="mb-4 text-sm">{{ (score / weeks) | format(type) }} / week</h4>
    <div>
      <div class="AllTimeStatsCard__sectionTitle label">{{ achievement }}</div>
      <h4 class="-mt-2">
        <div v-if="activity">
          <router-link :to="{ name: 'dashboard.activity', params: { id: activity.id } }" class="hover:text-primary">
            <span class="font-bold">{{ activity[attribute] | format(type) }}</span>
            ,
            <span class="text-primary">{{ activity.name }}</span>
          </router-link>
        </div>
        <div v-else>–</div>
      </h4>
    </div>
  </div>
</template>

<script>
import dayjs from 'utils/dayjs'

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    activities: {
      type: Array,
      required: true
    },
    achievement: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    attribute: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    year: {
      type: String,
      required: true
    }
  },

  computed: {
    yearActivities() {
      return this.activities.filter((a) => new Date(a.start_date_local).getFullYear() === Number(this.year))
    },

    yearData() {
      const key = Object.keys(this.data['year']).find((key) => dayjs(key).format('YYYY') === this.year)
      return this.data['year'][key]
    },

    score() {
      return (this.yearData && this.yearData[this.attribute]) || 0
    },

    activity() {
      const sorted = [...this.yearActivities].sort((a, b) => b[this.attribute] - a[this.attribute])
      return sorted && sorted[0]
    },

    weeks() {
      return Number(this.year) === Number(new Date().getFullYear()) ? dayjs().week() : 52
    }
  }
}
</script>

<style lang="postcss">
.AllTimeStatsCard {
  @apply p-6 border-r;

  &__score {
    @apply mt-1 text-3xl font-bold;
  }
}
</style>

<template>
  <div class="Home">
    <Header />

    <HomepageHero />

    <div id="features" class="lg:py-24 py-16 bg-white">
      <FeaturesDashboard />
    </div>

    <div class="py-16 bg-white">
      <FeaturesAnalytics />
    </div>

    <div class="md:py-16 md:pt-32 py-6 bg-white">
      <FeaturesMetrics class="xl:w-256 w-full mx-auto" />
    </div>

    <div class="py-16 bg-white">
      <Testimonials class="xl:w-256 w-full mx-auto" />

      <div class="my-6 font-bold text-center">
        <router-link :to="{ name: 'join' }" class="sm:w-auto w-auto mt-2">
          <AppButton primary>Get Started</AppButton>
        </router-link>
      </div>
    </div>

    <div class="md:space-x-8 md:flex-row md:py-32 lg:px-32 bg-deepblue flex flex-col w-full px-8 py-16 text-white">
      <Newsletter class="md:block md:order-first md:w-1/2 order-last hidden" />
      <Faq class="md:order-last md:w-1/2 order-first" />
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from 'components/Header'
import Footer from 'components/Footer'

import HomepageHero from 'components/HomepageHero'
import FeaturesDashboard from 'components/FeaturesDashboard'
import FeaturesMetrics from 'components/FeaturesMetrics'
import FeaturesAnalytics from 'components/FeaturesAnalytics'
import Testimonials from 'components/Testimonials'
import Newsletter from 'components/Newsletter'
import Faq from 'components/Faq'

export default {
  components: {
    Header,
    Footer,

    HomepageHero,
    FeaturesDashboard,
    FeaturesMetrics,
    FeaturesAnalytics,
    Testimonials,
    Newsletter,
    Faq
  },

  watch: {
    '$route.query'() {
      this.scrollToSection()
    }
  },

  mounted() {
    setTimeout(() => {
      this.scrollToSection()
    }, 0)
  },

  methods: {
    scrollToSection() {
      if (!this.$route.query.section) {
        return
      }

      if (['features', 'pricing'].includes(this.$route.query.section)) {
        document.querySelector(`#${this.$route.query.section}`)?.scrollIntoView({
          behavior: 'smooth'
        })
      }
    }
  },

  metaInfo() {
    return {
      title: 'FormBeat Cycling Analytics: Training Dashboard for Cyclists',
      titleTemplate: '%s',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content:
            'Cycling analytics designed to help cyclists of any fitness level focus on productive and sustainable training. Improve your performance and cycling form with our science based tools and metrics.'
        }
      ]
    }
  }
}
</script>

<style lang="postcss" scoped>
.Home {
  @apply w-full bg-white;
}
</style>

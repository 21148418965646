<template>
  <img :src="logoUrl" alt="FormBeat - Advanced Cycling Analytics" width="164" />
</template>

<script>
const logoUrl = require('images/formbeat-logo.svg')
const signUrl = require('images/formbeat-sign.svg')
export default {
  props: {
    sign: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    logoUrl() {
      return this.sign ? signUrl : logoUrl
    }
  }
}
</script>

<template>
  <Changelog />
</template>

<script>
import Changelog from 'components/Changelog'

export default {
  components: {
    Changelog
  }
}
</script>

import dayjs from 'utils/dayjs'

export default function formatLabels(dataPoints, segmentBy, isMobile = false) {
  let step
  let format
  let formatter

  if (dataPoints > 360) {
    step = isMobile ? 48 : 31
  } else if (dataPoints > 89) {
    step = isMobile ? 16 : 7
  } else if (dataPoints > 50) {
    step = isMobile ? 8 : 4
  } else if (dataPoints >= 28) {
    step = isMobile ? 4 : 2
  } else if (dataPoints >= 12) {
    step = isMobile ? 2 : 1
  } else {
    step = 1
  }

  switch (segmentBy) {
    case 'day':
      format = 'DD<br>MMM'
      break
    case 'week':
      format = 'DD<br>MMM'
      break
    case 'month':
      format = 'MMM<br>YYYY'
      break
    case 'year':
      format = 'YYYY'
      break
    default:
      format = 'DD MMM, YYYY'
  }

  formatter = (object) => {
    return dayjs(object.value).format(format)
  }

  return { step, formatter }
}

<template>
  <chart :options="chartData"></chart>
</template>

<script>
import * as chartConfig from 'constants/chartConfig'

import chroma from 'chroma-js'

import heartRateZones from 'constants/heartRateZones'
import powerZones from 'constants/powerZones'

export default {
  props: {
    data: {
      type: Object,
      required: false
    },
    activity: {
      type: Object,
      required: false
    },
    attribute: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    height: {
      type: Number,
      required: false,
      default: 300
    },
    zones: {
      type: Array,
      required: false
    },
    segmentBy: {
      type: String,
      required: false
    },
    format: {
      type: String,
      required: false
    },
    units: {
      type: String,
      required: false
    }
  },

  computed: {
    chartData() {
      const keys = Array.from({ length: this.zones.length }, (v, i) => i)
      const source = this.activity ? this.activity : this.data[this.segmentBy]

      const getAttrValue = (source, key) => {
        return source[`${this.attribute}_${key + 1}`] ? Number(source[`${this.attribute}_${key + 1}`]) : 0
      }

      const data = keys.map((key) => {
        if (this.activity) {
          return [key, getAttrValue(source, key)]
        } else {
          const validDataPoints = Object.values(source).filter(Boolean) || []
          const sum = validDataPoints.reduce((a, b) => a + getAttrValue(b, key), 0)
          return [key, Math.round(sum / validDataPoints.length)]
        }
      })

      const scale = chroma.scale(['#FBA570', '#e91500'])
      let colors = scale.colors(this.attribute === 'time_in_hr_zone' ? 5 : 7)

      const attribute = this.attribute
      const getXAxisLabel = (index, attribute) => {
        if (attribute === 'time_in_hr_zone') {
          const value = this.zones[index + 1] ? `${this.zones[index]} – ${this.zones[index + 1]}` : `> ${this.zones[index]}`
          return `<div style="width: 110px"><span class="font-bold">${heartRateZones[index]}</span><span class="md:inline hidden"><br><span class="text-muted">${value}</span></span></div>`
        } else if (attribute === 'time_in_power_zone') {
          const value = this.zones[index + 1] ? `${this.zones[index]} – ${this.zones[index + 1]} W` : `> ${this.zones[index]} W`
          return `<div style="width: 110px"><span class="font-bold">${powerZones[index]}</span><span class="md:inline hidden"><br><span class="text-muted">${value}</span></span></div>`
        }
      }

      return {
        ...chartConfig.defaultOptions,

        chart: {
          ...chartConfig.chart,
          height: this.$isMobile() ? this.height / 2 : this.height,
          spacing: [10, 10, 15, 30]
        },
        exporting: {
          ...chartConfig.exporting,
          enabled: !this.$isMobile()
        },

        colors: colors,
        xAxis: {
          categories: this.zones,
          labels: {
            align: 'left',
            useHTML: true,
            reserveSpace: true,
            style: {
              width: 110
            },
            formatter: function () {
              return getXAxisLabel(this.pos, attribute)
            }
          }
        },
        yAxis: [
          {
            title: {
              enabled: false
            },
            labels: {
              format: this.units ? `{value} ${this.units}` : '{value}'
            }
          }
        ],
        plotOptions: {
          series: {
            shadow: false,
            pointPadding: 0,
            groupPadding: 0,
            borderWidth: 1,
            maxPointWidth: 148,
            marker: {
              enabled: false
            }
          }
        },
        series: [
          {
            type: 'bar',
            colorByPoint: true,
            data: data,
            dataLabels: {
              x: 8,
              enabled: true,
              position: 'left',
              align: 'left',
              format: '{point.y}%'
            }
          }
        ]
      }
    }
  }
}
</script>

import axios from 'axios'

import { strava_authorize_success_path, strava_authorize_url_path, strava_deauthorize_path, strava_sync_path } from 'routes'

export default {
  connect(privateScope = true) {
    return axios.post(strava_authorize_url_path(), { private_scope: privateScope }).then((response) => (window.location = response.data.url))
  },

  authorize(code, scope = '') {
    return axios.post(strava_authorize_success_path(), { code, scope }).then((response) => response.data.user)
  },

  deauthorize() {
    return axios.post(strava_deauthorize_path()).then((response) => response.data)
  },

  sync() {
    return axios.post(strava_sync_path()).then((response) => response.data)
  }
}

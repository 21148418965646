<template>
  <div class="StatsCard h-full">
    <div class="StatsCard__title">
      <div>{{ title }}</div>
      <slot name="tooltip"></slot>
    </div>
    <h2 class="StatsCard__score">
      <span class="text-4xl">{{ score | format }}</span>
      <span v-if="trend" class="ml-1 text-sm">
        <span v-if="trend >= 0" class="text-green-500">
          <svg class="svg-icon svg-icon--xs">
            <use xlink:href="#icon-arrow-up-right"></use>
          </svg>
          +{{ trend }}
        </span>
        <span v-else class="text-red-500">
          <svg class="svg-icon svg-icon--xs">
            <use xlink:href="#icon-arrow-down-right"></use>
          </svg>
          {{ trend }}
        </span>
        <span class="StatsCard__hover text-xs">{{ comparePeriod }}</span>
      </span>
    </h2>
  </div>
</template>

<script>
import dayjs from 'utils/dayjs'
import { dateIsBetween } from 'utils/date'

export default {
  props: {
    dateRange: {
      type: Object,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    attribute: {
      type: String,
      required: true
    }
  },

  computed: {
    from() {
      return this.dateRange.from
    },

    to() {
      return dayjs()
    },

    isCtl() {
      return this.attribute === 'ctl'
    },

    isTss() {
      return this.attribute === 'tss'
    },

    score() {
      if (this.isTss) {
        return this.scoreByDay.reduce((a, b) => a + parseFloat(b[this.attribute]), 0)
      } else {
        const record = this.data.find(({ date }) => date === dayjs(this.to).format('YYYY-MM-DD'))
        return record ? record[this.attribute] : 0
      }
    },

    previousScore() {
      if (this.isTss) {
        return this.previousScoreByDay.reduce((a, b) => a + parseFloat(b[this.attribute]), 0)
      } else {
        const record = this.data.find(({ date }) => date === dayjs(this.from).format('YYYY-MM-DD'))
        return record ? record[this.attribute] : 0
      }
    },

    comparePeriod() {
      if (this.dateRange.days) {
        return `past ${this.dateRange.days} days`
      } else if (this.dateRange.id == 'ytd') {
        return `this year`
      } else {
        return ''
      }
    },

    scoreByDay() {
      return this.data.filter(({ date }) => dateIsBetween(date, this.dateRange.from, this.dateRange.to))
    },

    previousScoreByDay() {
      return this.data.filter(({ date }) => dateIsBetween(date, this.dateRange.compareTo.from, this.dateRange.compareTo.to))
    },

    trend() {
      if (!this.previousScore || !this.isCtl || this.dateRange.id === 'last_year') {
        return
      }

      return Math.round(this.score - this.previousScore)
    }
  }
}
</script>

<style lang="postcss" scoped>
.StatsCard:hover {
  .StatsCard__hover {
    opacity: 1;
  }
}

.StatsCard__hover {
  font-weight: normal;
  color: #ccc;
  opacity: 0;
}
</style>

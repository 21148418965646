<template>
  <div>
    <StatsSection title="Training Stress Score">
      <Chart title="TSS" :data="currentSummary" :segment-by="segmentBy" attribute="tss" />
      <template #header><InfoIcon term="tss" /></template>
    </StatsSection>

    <StatsSection title="Training Punch Card">
      <PunchCardChart title="Training Punch Card" :data="activities" :date-range="dateRange" />
    </StatsSection>

    <StatsSection title="Training Time">
      <Chart title="Training Time" :data="currentSummary" :segment-by="segmentBy" attribute="training_hours" format="hours" />
    </StatsSection>

    <StatsSection title="Total Work">
      <Chart title="Total Work" :data="currentSummary" :segment-by="segmentBy" attribute="kilojoules" format="work" />
    </StatsSection>
  </div>
</template>

<script>
import Chart from 'components/charts/Chart'
import StatsSection from 'components/StatsSection'
import PunchCardChart from 'components/charts/PunchCardChart'

export default {
  components: {
    Chart,
    PunchCardChart,
    StatsSection
  },

  props: {
    summary: {
      type: Object,
      required: true
    },
    currentSummary: {
      type: Object,
      required: true
    },
    activities: {
      type: Array,
      required: true
    },
    dateRange: {
      type: Object,
      required: true
    },
    segmentBy: {
      type: String,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },

  metaInfo() {
    return {
      title: 'Fitness'
    }
  }
}
</script>

<template>
  <modal-layout title="Delete Activity">
    <div class="FormGroup border-t">
      <p class="my-6 text-base">
        Do you really want to
        <b>delete</b>
        "{{ resource.name }}"?
      </p>
    </div>

    <template slot="footer">
      <div class="flex justify-end">
        <AppButton class="mr-2" primary small @click="cancel()">No</AppButton>
        <AppButton primary small @click="submit()">Yes</AppButton>
      </div>
    </template>
  </modal-layout>
</template>

<script>
export default {
  props: {
    resource: {
      type: Object,
      required: true
    }
  },

  methods: {
    cancel() {
      this.$emit('close', false)
    },
    submit() {
      this.$emit('close', true)
    }
  }
}
</script>

<template>
  <div>
    <Header />

    <div class="Glossary__picture">
      <picture>
        <source media="(max-width: 600px)" :srcset="require('images/tools-bg-600w.jpg') | imageUrl" />
        <img :src="require('images/tools-bg-1440w.jpg') | imageUrl" />
      </picture>
    </div>

    <div class="md:hidden p-6 pb-0">
      <Dropdown class="w-full">
        <template #selected>
          <div class="flex items-center justify-between w-full px-4 py-2 font-medium">
            <div>What is {{ selectedTerm.title }}</div>
            <svg class="svg-icon">
              <use xlink:href="#icon-caret-down"></use>
            </svg>
          </div>
        </template>

        <template #options>
          <div>
            <router-link
              v-for="(item, index) in glossary"
              :key="index"
              class="Dropdown__item"
              :to="{ name: 'training-glossary', params: { term: index } }"
              :class="{ 'font-bold': index == term }"
              :title="item.title"
            >
              {{ item.title }}
            </router-link>
          </div>
        </template>
      </Dropdown>
    </div>

    <div class="Glossary">
      <div class="Glossary__sidebar">
        <h2 class="mb-4 text-3xl font-bold">Cycling Glossary</h2>
        <p class="mb-8">What are foundational cycling metrics and what they mean?</p>

        <nav>
          <router-link
            v-for="(item, index) in glossary"
            :key="index"
            :to="{ name: 'training-glossary', params: { term: index } }"
            class="Glossary__term"
            :class="{ 'font-bold text-primary': index == term }"
            :title="`What is ${item.title}`"
          >
            {{ item.title }}
          </router-link>
        </nav>
      </div>
      <div class="Glossary__content">
        <h1 class="text-3xl">{{ selectedTerm.title }}</h1>
        <div v-if="selectedTerm.image" class="w-5/5 p-4 my-8 bg-white shadow">
          <img :src="selectedTerm.image | imageUrl" :alt="selectedTerm.title" />
        </div>
        <div class="Markdown" v-html="description"></div>
        <div class="Markdown py-4 my-8 border-t">
          <router-link :to="{ name: 'join' }" class="link" title="Create a free account">Sign up for free</router-link>
          to start tracking your
          <span class="font-bold">{{ selectedTerm.title }}</span>
          .
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import marked from 'marked'
import infoTooltips from 'constants/infoTooltips'

import Header from 'components/Header'
import Footer from 'components/Footer'

export default {
  components: {
    Header,
    Footer
  },

  props: {
    term: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      glossary: infoTooltips
    }
  },

  computed: {
    selectedTerm() {
      return this.glossary[this.term] || Object.values(this.glossary)[0]
    },

    description() {
      return `<div>${marked(this.selectedTerm.description)}</div>`
    }
  },

  created() {
    if (!this.term) {
      this.$router.replace({ name: 'training-glossary', params: { term: Object.keys(this.glossary)[0] } })
    }
  },

  metaInfo() {
    return {
      title: `What is ${this.selectedTerm.title}`,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.selectedTerm.description.replace('**', '').split(/\r?\n/)[0]
        }
      ]
    }
  }
}
</script>

<style lang="postcss">
.Markdown {
  @apply text-lg;

  strong {
    @apply font-bold;
  }
  p {
    max-width: 640px;
  }
  p + p {
    @apply mt-3;
  }
  ul {
    @apply mt-3;
  }
  h3 {
    @apply mt-4 mb-0;
  }
}
.Glossary {
  @apply relative flex flex-row p-6 pb-32 mx-auto border-b;
  @screen md {
    @apply p-12;
  }
  &__picture {
    @apply relative block;
    height: 320px;

    img {
      @apply object-cover w-full h-full;
      object-position: center 15%;
    }

    &:after {
      @apply absolute top-0 z-10 w-full h-full;
      content: '';
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3));
    }
  }

  &__sidebar {
    @apply hidden;
    @screen md {
      @apply flex-shrink-0 block;
      width: 300px;
    }
  }
  &__content {
    max-width: 960px;
    @screen md {
      @apply mx-8;
    }
    @screen lg {
      @apply mx-32;
    }
  }
  &__term {
    @apply block py-1 text-sm text-current border-b;
    @screen md {
      @apply py-2 text-lg;
    }

    &.router-link-active {
      @apply text-primary font-bold;
    }
  }
}
</style>

import dayjs from 'utils/dayjs'

const calculateAllTimeRange = () => {
  const to = dayjs().endOf('day')
  const from = dayjs('01-01-1970')

  return {
    title: 'All Time',
    longTitle: 'All Time',
    id: 'all',
    from: from,
    to: to,
    compareTo: {
      from: from,
      to: to
    }
  }
}

const calculateLastYearRange = () => {
  const to = dayjs().startOf('year').subtract(1, 'day').endOf('day')
  const from = dayjs(to).startOf('year')

  const previousPeriodTo = to.subtract(1, 'year')
  const previousPeriodFrom = to.subtract(1, 'year').startOf('year')

  return {
    title: 'Last Year',
    longTitle: 'Last Year',
    id: 'last_year',
    from: from,
    to: to,
    compareTo: {
      from: previousPeriodFrom,
      to: previousPeriodTo
    }
  }
}

const calculateYearToDayRange = () => {
  const to = dayjs().endOf('day')
  const from = dayjs().startOf('year')

  const previousPeriodTo = to.subtract(1, 'year')
  const previousPeriodFrom = to.subtract(1, 'year').startOf('year')

  return {
    title: 'This Year',
    longTitle: 'This Year',
    id: 'ytd',
    from: from,
    to: to,
    compareTo: {
      from: previousPeriodFrom,
      to: previousPeriodTo
    }
  }
}

const calculateDayRange = (days) => {
  const to = dayjs().endOf('day')
  const from = to.subtract(days - 1, 'day').startOf('day')

  const previousPeriodTo = from.subtract(1, 'day').endOf('day')
  const previousPeriodFrom = previousPeriodTo.subtract(days - 1, 'day').startOf('day')

  return {
    title: `${days} days`,
    longTitle: `Past ${days} days`,
    id: `${days}d`,
    days: days,
    from: from,
    to: to,
    compareTo: {
      from: previousPeriodFrom,
      to: previousPeriodTo
    }
  }
}

export default function calculateRange() {
  let ranges = []

  // Last 7 days
  ranges.push(calculateDayRange(7))
  // Last 14 days
  ranges.push(calculateDayRange(14))
  // Last 30 days
  ranges.push(calculateDayRange(30))
  // Last 90 days
  ranges.push(calculateDayRange(90))
  // Last 365 days
  ranges.push(calculateDayRange(365))
  // YTD
  ranges.push(calculateYearToDayRange())
  // Last Year
  ranges.push(calculateLastYearRange())
  // All Time
  ranges.push(calculateAllTimeRange())

  return ranges
}

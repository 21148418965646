<template>
  <div>
    <ActivityLapsChart :laps="laps" :elevation="elevation" :is-from-splits="!hasLaps" />
  </div>
</template>

<script>
import ActivityLapsChart from 'components/charts/ActivityLapsChart'

export default {
  components: {
    ActivityLapsChart
  },

  props: {
    activity: {
      type: Object,
      required: true
    },
    activities: {
      type: Array,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    units: {
      type: String,
      required: true
    }
  },

  computed: {
    hasLaps() {
      return this.activity.laps && this.activity.laps[0] && this.activity.laps[0].moving_time && this.activity.laps.length > 1
    },
    laps() {
      return this.hasLaps ? this.activity.laps : this.activity.splits_metric
    },
    elevation() {
      return this.activity.streams?.altitude?.data || []
    }
  },

  mounted() {
    if (!this.activity.splits_metric && !this.activity.laps) {
      this.$router.push({ name: 'dashboard.activity', params: { id: this.activity.id } })
    }
  },

  metaInfo() {
    return {
      title: this.activity?.name
    }
  }
}
</script>

import ProfileService from 'services/ProfileService'
import ValidationService, { isRequired, isValidEmail, isMin, isMax, isValidHeartRateZones } from 'services/ValidationService'

import config from 'config'

const types = {
  PREFERENCES_SET: 'PREFERENCES_SET',
  ERRORS_SET: 'ERRORS_SET'
}

const DEFAULT_ZONES = [
  { min: '', max: '' },
  { min: '', max: '' },
  { min: '', max: '' },
  { min: '', max: '' },
  { min: '', max: -1 }
]

const initFromUser = (user) => {
  let zones = DEFAULT_ZONES

  if (user.heartrate_zones && user.heartrate_zones.length > 0) {
    zones = (user.heartrate_zones || []).map((value, index) => {
      const min = value
      const max = index < user.heartrate_zones.length - 1 ? user.heartrate_zones[index + 1] : '-1'

      return {
        min,
        max
      }
    })
  }

  return {
    sex: user.sex,
    weight: user.weight,
    weight_units: user.measurement_preference == 'feet' ? 'lb' : 'kg',
    hasPowerMeter: Boolean(user.ftp),
    ftp: user.ftp,
    user_ftps: user.user_ftps,
    user_weights: user.user_weights,
    max_hr: user.max_hr,
    rest_hr: user.rest_hr,
    lactate_threshold: user.lactate_threshold,
    measurement_preference: user.measurement_preference,
    email_notifications: user.email_notifications,
    email: user.email,
    running_ftp: user.running_ftp,
    swimming_ftp: user.swimming_ftp,
    zones: zones,
    beginning_of_week: user.beginning_of_week
  }
}

const defaultState = () => ({
  preferences: {}
})

const userPreferences = () => ({
  preferences: config.user ? initFromUser(config.user) : {}
})

const getInitialState = () => ({
  ...defaultState(),
  ...userPreferences(),
  errors: {}
})

const state = getInitialState()

const actions = {
  updatePreferences({ commit }, preferences) {
    commit(types.PREFERENCES_SET, { preferences })
  },

  async validateProfilePreferences({ dispatch }) {
    const rules = {
      sex: [isRequired()],
      weight: [isRequired(), isMin(20)],
      measurement_preference: [isRequired()]
    }

    return dispatch('validatePreferences', rules)
  },

  async validateHeartRatePreferences({ dispatch }) {
    const minHR = 20
    const maxHR = 280

    const rules = {
      max_hr: [isRequired(), isMin(minHR), isMax(maxHR)],
      rest_hr: [isRequired(), isMin(minHR), isMax(maxHR)]
    }

    return dispatch('validatePreferences', rules)
  },

  async validateHeartRateZonesPreferences({ dispatch }) {
    const rules = {
      zones: [isRequired(), isValidHeartRateZones()]
    }

    return dispatch('validatePreferences', rules)
  },

  async validatePowerPreferences({ state, dispatch }) {
    const rules = {
      ftp: state.preferences.hasPowerMeter == '1' ? [isRequired(), isMin(80)] : []
    }

    return dispatch('validatePreferences', rules)
  },

  async validateAccountPreferences({ dispatch }) {
    const rules = {
      email: [isRequired(), isValidEmail()]
    }

    return dispatch('validatePreferences', rules)
  },

  async validatePreferences({ state, dispatch }, rules) {
    dispatch('clearErrors')

    rules = rules || {
      sex: [isRequired()],
      weight: [isRequired()],
      measurement_preference: [isRequired()],
      email: [isRequired(), isValidEmail()],
      max_hr: [isRequired()],
      rest_hr: [isRequired()],
      zones: [isRequired(), isValidHeartRateZones()],
      running_ftp: [isRequired(), isMin(120), isMax(999)],
      swimming_ftp: [isRequired(), isMin(20), isMax(500)]
    }

    const result = await ValidationService.validate(state.preferences, rules)

    dispatch('setErrors', result.errors)

    return result
  },

  async savePreferences({ state, dispatch, commit }) {
    const { isValid } = await dispatch('validatePreferences')

    if (!isValid) {
      return
    }

    const result = await ProfileService.saveUserPreferences(state.preferences)

    result && commit(types.PREFERENCES_SET, { preferences: initFromUser(result.user) })

    return result
  },

  async saveEmail({ state, dispatch }) {
    const { isValid } = await dispatch('validateAccountPreferences')

    if (!isValid) {
      return
    }

    ProfileService.saveUserEmail(state.preferences.email)
  },

  clearErrors({ commit }) {
    commit(types.ERRORS_SET, { errors: {} })
  },

  setErrors({ commit, state }, errors) {
    commit(types.ERRORS_SET, { errors: { ...state.errors, ...errors } })
  }
}

const mutations = {
  [types.PREFERENCES_SET](state, { preferences }) {
    state.preferences = preferences
  },

  [types.ERRORS_SET](state, { errors }) {
    state.errors = errors
  }
}

const getters = {
  preferences(state) {
    return state.preferences
  },

  errors(state) {
    return state.errors
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

<template>
  <div v-if="activity" class="pb-12">
    <SectionTitle :title="activity.name" :class="{ 'SectionTitle--fixed': isAnalyzeView }">
      <div class="flex">
        <div v-if="hasStreams" class="md:block hidden">
          <router-link v-if="!isAnalyzeView" key="toggleAnalyzeMode" :to="{ name: 'activity.analyze', params: { id: activity.id } }">
            <AppButton primary small>Analyze</AppButton>
          </router-link>
          <router-link v-else key="toggleAnalyzeMode" :to="{ name: 'activity.overview', params: { id: activity.id } }">
            <AppButton primary small>Close (Esc)</AppButton>
          </router-link>
        </div>

        <Dropdown v-if="!isAnalyzeView" right class="outline-0 h-full ml-4 border-0">
          <template #selected>
            <AppButton light block medium class="whitespace-nowrap">
              <svg class="svg-icon">
                <use xlink:href="#icon-more"></use>
              </svg>
            </AppButton>
          </template>

          <template #options>
            <div class="w-56">
              <div v-if="activity.note" class="Dropdown__item py-3 cursor-pointer" @click="editActivityNote">
                <svg class="svg-icon mr-1">
                  <use xlink:href="#icon-comment"></use>
                </svg>
                Edit note
              </div>
              <div class="Dropdown__item py-3 cursor-pointer" @click="editActivity">
                <svg class="svg-icon mr-1">
                  <use xlink:href="#icon-edit"></use>
                </svg>
                Edit Activity
              </div>
              <div class="Dropdown__item py-3 cursor-pointer" @click="deleteActivity">
                <svg class="svg-icon mr-1">
                  <use xlink:href="#icon-delete"></use>
                </svg>
                Delete Activity
              </div>
            </div>
          </template>
        </Dropdown>
      </div>
    </SectionTitle>

    <div v-if="!isAnalyzeView">
      <!-- Activity note -->
      <ActivityNote v-if="activity.note" :note="activity.note" class="max-w-screen-sm mb-8 -mt-6 italic" />

      <div class="flex pt-1 mb-6 -mt-8">
        <span v-if="activity.manual" class="inline-block px-2 py-1 mt-1 mr-1 text-sm font-bold text-gray-800 bg-orange-200 rounded">Manual</span>
        <span class="text-muted text-sm">{{ activity.start_date_local | relativeTime }}, {{ activity.start_date_local | datetime }}</span>
        <span v-if="activity.city" class="text-muted text-sm">, {{ activity.city }}</span>
        <span v-if="!activity.note" class="text-muted text-sm">
          ,
          <span class="hover:text-black underline cursor-pointer" @click="editActivityNote">Add a note&hellip;</span>
        </span>
      </div>

      <div v-if="!activity.analyzed && activity.is_cycling" class="p-4 mb-4 font-medium text-blue-800 bg-blue-100 border border-blue-200 rounded">
        We're analyzing this activity. Please check back soon.
      </div>

      <div v-if="activity.analyzed && !activity.manual && activity.is_cycling" class="Subnavigation">
        <router-link :to="{ name: 'activity.overview', params: { id: activity.id } }" class="Subnavigation__item">Overview</router-link>

        <router-link v-if="hasPower" :to="{ name: 'activity.power', params: { id: activity.id } }" class="Subnavigation__item">Power</router-link>

        <router-link v-if="hasHeartRate" :to="{ name: 'activity.heartrate', params: { id: activity.id } }" class="Subnavigation__item">Heart Rate</router-link>

        <router-link :to="{ name: 'activity.profile', params: { id: activity.id } }" class="Subnavigation__item">Ride Profile</router-link>

        <router-link v-if="hasLaps || hasSplits" :to="{ name: 'activity.laps', params: { id: activity.id } }" class="Subnavigation__item">Laps</router-link>

        <router-link
          v-if="similarActivities.length > 1"
          :to="{ name: 'activity.similar', params: { id: activity.id } }"
          class="Subnavigation__item items center flex"
        >
          <div>Similar Rides</div>
          <div class="inline-block w-6 h-6 ml-2 text-sm font-bold text-center text-gray-800 bg-gray-200 rounded-full">{{ similarActivities.length - 1 }}</div>
        </router-link>

        <div class="Subnavigation__item Subnavigation__item--right">
          <a
            v-if="activity.strava_id"
            target="_blank"
            :href="`https://www.strava.com/activities/${activity.strava_id}`"
            class="Subnavigation__item Subnavigation__item--right text-primary font-bold"
          >
            <span class="text-primary">View on Strava &raquo;</span>
          </a>
        </div>
      </div>
    </div>

    <router-view
      :activity="activity"
      :units="units"
      :activities="activities"
      :similar-activities="similarActivities"
      :user="user"
      :is-analyze-view="isAnalyzeView"
      class="mb-8"
    />

    <ActivityKeyboardShortcuts v-show="!isAnalyzeView" :activity="activity" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import ProfileService from 'services/ProfileService'
import EditActivityModal from 'views/Modals/EditActivityModal'
import DeleteActivityModal from 'views/Modals/DeleteActivityModal'
import ActivityNoteModal from 'views/Modals/ActivityNoteModal'
import SectionTitle from 'components/SectionTitle'
import ActivityNote from 'components/ActivityNote'
import ActivityKeyboardShortcuts from 'components/ActivityKeyboardShortcuts'

export default {
  components: {
    ActivityKeyboardShortcuts,
    ActivityNote,
    SectionTitle
  },

  props: {
    activityId: {
      type: String,
      required: true
    },
    activities: {
      type: Array,
      required: true
    },
    units: {
      type: String,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      activity: undefined,
      similarActivities: []
    }
  },

  computed: {
    isAnalyzeView() {
      return this.$route.name === 'activity.analyze'
    },
    hasHeartRate() {
      return this.activity.has_heartrate
    },
    hasPower() {
      return this.activity.computed_weighted_power
    },
    hasLaps() {
      return this.activity.laps && this.activity.laps[0] && this.activity.laps[0].moving_time && this.activity.laps.length > 1
    },
    hasSplits() {
      return this.activity.splits_metric
    },
    hasStreams() {
      return Object.keys(this.activity.streams).length && this.activity.is_cycling
    }
  },

  watch: {
    activityId: {
      immediate: false,
      async handler() {
        this.setAppLoading(true)
        this.activity = undefined
        this.activity = await ProfileService.getActivity(this.activityId)
        this.setAppLoading(false)
      }
    }
  },

  async mounted() {
    this.$Progress.start()
    this.setAppLoading(true)
    this.activity = await ProfileService.getActivity(this.activityId)
    this.setAppLoading(false)
    this.$Progress.finish()
    this.similarActivities = await ProfileService.getSimilarActivities(this.activityId)
  },

  methods: {
    ...mapActions('profile', ['loadProfile', 'setAppLoading']),

    async editActivity() {
      const activity = await this.$modal(EditActivityModal, { resource: this.activity })
      if (activity) {
        this.setAppLoading(true)
        try {
          this.activity = await ProfileService.editActivity(activity)
        } catch {
          // noop
        }
        this.setAppLoading(false)
      }
    },

    async deleteActivity() {
      const confirmed = await this.$modal(DeleteActivityModal, { resource: this.activity })
      if (confirmed === true) {
        this.setAppLoading(true)
        try {
          await ProfileService.destroyActivity(this.activity.id)
          await this.loadProfile()
          this.$router.push({ name: 'dashboard.activities' })
        } catch {
          // noop
        }
        this.setAppLoading(false)
      }
    },

    async editActivityNote() {
      const result = await this.$modal(ActivityNoteModal, { note: this.activity.note })
      if (result !== false) {
        try {
          this.activity = {
            ...this.activity,
            note: result.note
          }
          this.activity = await ProfileService.updateActivityNote(this.activity.id, result.note)
        } catch {
          // noop
        }
      }
    }
  },

  metaInfo() {
    return {
      title: this.activity?.name
    }
  }
}
</script>

<style lang="postcss" scoped>
.SectionTitle.SectionTitle--fixed {
  @apply fixed top-0 right-0 px-8 border-l;
  left: 14rem;
  z-index: 999;
}
</style>

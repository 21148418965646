<template>
  <component
    :is="tagName"
    class="AppButton button"
    :class="{
      'isPrimary': primary,
      'isSecondary': secondary,
      'isLight': light,
      'isSmall': small,
      'isMedium': medium,
      'isExtraSmall': xsmall,
      'isExtraExtraSmall': xxsmall
    }"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    medium: {
      type: Boolean
    },

    small: {
      type: Boolean
    },

    xsmall: {
      type: Boolean
    },

    xxsmall: {
      type: Boolean
    },

    primary: {
      type: Boolean
    },

    secondary: {
      type: Boolean
    },

    light: {
      type: Boolean
    }
  },

  computed: {
    tagName() {
      return this.$attrs.href ? 'a' : 'button'
    }
  }
}
</script>

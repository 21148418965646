<template>
  <div v-if="user && user.admin" class="Dashboard">
    <div class="Dashboard__header w-full">
      <router-link :to="{ name: 'dashboard.trends' }" class="mr-16">
        <Logo />
      </router-link>

      <div class="hover-text-primary font-extralight md:hidden px-4 ml-auto -mr-4 text-4xl cursor-pointer select-none" @click="toggleNavigation()">=</div>
    </div>
    <div class="Dashboard__content">
      <div class="Dashboard__sidebar" :class="{ 'Dashboard__sidebar--isOpen': isNavigationOpen }" @click="toggleNavigation(false)">
        <nav class="Navigation md:fixed md:p-8 md:pl-0 flex-col justify-between">
          <div class="flex flex-col flex-grow">
            <router-link :to="{ name: 'admin.users' }" class="Navigation__item">Users</router-link>
            <a href="/sidekiq" class="Navigation__item">Sidekiq</a>
          </div>
          <div class="md:mb-0 flex flex-col mb-8">
            <router-link :to="{ name: 'settings' }" class="Navigation__item flex">
              <img :src="user.profile" class="w-8 h-8 mr-2 rounded-full" />
              {{ user.first_name }}
            </router-link>
            <a class="Navigation__item" @click="logout()">Logout</a>
          </div>
        </nav>
      </div>
      <div class="Dashboard__main">
        <router-view />
        <LoadingIcon v-if="isLoading" class="mx-auto mt-64">Loading your data…</LoadingIcon>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
      isNavigationOpen: false
    }
  },

  computed: {
    ...mapGetters('profile', ['user', 'isLoading'])
  },

  methods: {
    ...mapActions('profile', ['signOut']),

    toggleNavigation(isOpen) {
      this.isNavigationOpen = isOpen === undefined ? !this.isNavigationOpen : isOpen
    },

    async logout() {
      await this.signOut()
      const { href } = this.$router.resolve({ name: 'home' })
      window.location = window.location.origin + href
    }
  },

  metaInfo() {
    return {
      title: 'Admin'
    }
  }
}
</script>

<style lang="postcss" scoped>
.Dashboard {
  @apply flex flex-col w-full h-full;

  &__header {
    @apply fixed top-0 z-50 flex items-center justify-between h-16 px-8 bg-white shadow;
  }

  &__content {
    @apply flex w-full h-full pt-16;
  }

  &__sidebar {
    @apply fixed inset-x-0 top-0 bottom-0 right-0 z-40 hidden mt-16 bg-white select-none;

    &--isOpen {
      @apply block;
    }

    @screen md {
      @apply relative block bg-transparent;
      width: 8rem;
      flex: 0 0 auto;
    }
  }

  &__main {
    @apply relative w-full p-4;

    @screen md {
      @apply p-8;
      width: calc(100% - 8rem);
    }
  }
}

.Logo {
  @apply pl-8 mt-2 mb-4 font-bold;
}

.Navigation {
  @apply flex flex-col;
  top: theme('spacing.16');
  height: calc(100vh - theme('spacing.16'));

  &__item {
    @apply p-3 pl-6 no-underline border-b;
    border-left: 4px solid transparent;

    @screen md {
      @apply p-4 pl-8 border-b-0;
    }

    &.router-link-active {
      @apply font-medium;
      border-left-color: theme('colors.primary');
    }
  }
}
</style>

<template>
  <div v-if="preferences" class="Onboarding">
    <div class="Onboarding__wrapper">
      <!-- Title -->
      <h1 class="text-5xl text-white">Welcome{{ form.firstname ? ` ${form.firstname}` : '' }}!</h1>
      <div class="mb-8 text-lg leading-relaxed text-white">
        Thank you for Signing up! Please tell us a little bit about yourself so we can analyze your performance and calculate your fitness.
      </div>

      <router-view :form="form" :errors="errors" @update="update" />

      <div class="OnboardingFooter">
        <LoadingIcon v-if="isLoading" class="mx-auto">Setting up your account…</LoadingIcon>
        <div v-else class="flex justify-between">
          <AppButton secondary class="w-1/2 mr-2" :class="{ 'hidden': isAccountStep }" @click="back()">&laquo; Back</AppButton>
          <AppButton :disabled="isLoading" primary class="w-1/2 ml-auto" :class="{ 'w-full': isAccountStep }" @click="submit()">Continue</AppButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import AnalyticsService from 'services/AnalyticsService'

export default {
  data() {
    return {
      form: {},
      isLoading: false
    }
  },

  computed: {
    ...mapGetters('preferences', ['preferences', 'errors']),

    ...mapGetters('profile', ['user']),

    isProfileStep() {
      return this.$route.name === 'onboarding.profile'
    },
    isHeartrateStep() {
      return this.$route.name === 'onboarding.heartrate'
    },
    isHeartrateZonesStep() {
      return this.$route.name === 'onboarding.heartrate-zones'
    },
    isPowerStep() {
      return this.$route.name === 'onboarding.power'
    },
    isAccountStep() {
      return this.$route.name === 'onboarding.account'
    }
  },

  watch: {
    preferences: {
      immediate: true,
      handler() {
        this.form = { ...this.preferences }
      }
    }
  },

  mounted() {
    AnalyticsService.trackEvent('onboarding', 'onboarding-started')
    AnalyticsService.ahoyTrackEvent('onboarding-started', { user_id: this.user.id })
  },

  methods: {
    ...mapActions('preferences', [
      'updatePreferences',
      'savePreferences',
      'saveEmail',
      'validateProfilePreferences',
      'validateHeartRatePreferences',
      'validateHeartRateZonesPreferences',
      'validatePowerPreferences',
      'validateAccountPreferences'
    ]),

    update(preferences) {
      this.updatePreferences(preferences)
    },

    back() {
      if (this.isHeartrateStep) {
        this.$router.push({ name: 'onboarding.profile' })
      } else if (this.isHeartrateZonesStep) {
        this.$router.push({ name: 'onboarding.heartrate' })
      } else if (this.isPowerStep) {
        this.$router.push({ name: 'onboarding.heartrate-zones' })
      } else if (this.isProfileStep) {
        this.$router.push({ name: 'onboarding.account' })
      }
    },

    async submit() {
      let validation

      // Validate "Account / Email" step
      if (this.isAccountStep) {
        validation = await this.validateAccountPreferences()
        validation.isValid && this.$router.push({ name: 'onboarding.profile' })
        this.saveEmail()
      } else if (this.isProfileStep) {
        validation = await this.validateProfilePreferences()
        validation.isValid && this.$router.push({ name: 'onboarding.heartrate' })

        // Validate "Heartrate" step
      } else if (this.isHeartrateStep) {
        validation = await this.validateHeartRatePreferences()
        validation.isValid && this.$router.push({ name: 'onboarding.heartrate-zones' })

        // Validate "Heartrate" step
      } else if (this.isHeartrateZonesStep) {
        validation = await this.validateHeartRateZonesPreferences()
        validation.isValid && this.$router.push({ name: 'onboarding.power' })

        // Validate "Power" step & Submit
      } else if (this.isPowerStep) {
        validation = await this.validatePowerPreferences()

        if (validation.isValid) {
          try {
            this.isLoading = true

            await this.savePreferences()
            const { href } = this.$router.resolve({ name: 'dashboard' })

            AnalyticsService.trackEvent('onboarding', 'onboarding-completed')
            AnalyticsService.ahoyTrackEvent('onboarding-completed', { user_id: this.user.id })

            setTimeout(() => {
              window.location = window.location.origin + href
            }, 8000)
          } catch (e) {
            // TODO
            this.isLoading = false
          }
        }
      }
    }
  },

  metaInfo() {
    return {
      title: 'Welcome!'
    }
  }
}
</script>

<style lang="postcss">
.Onboarding {
  position: relative;
  z-index: 0;

  &::before,
  &::after {
    position: fixed;
    top: 0;
    left: 0;
    content: '';
    width: 100vw;
    height: 100vh;

    z-index: -1;
  }

  &::before {
    background: url('~images/featured.jpg');
    background-size: cover;
    background-position: center;
  }

  &::after {
    background: rgba(34, 34, 34, 0.4);
  }
}

.Onboarding__wrapper {
  @apply device-scrolling flex flex-col px-8 py-16 m-auto;
  max-width: 40rem;

  @screen md {
    @apply px-0;
  }
}

.OnboardingStep {
  @apply w-full px-8 py-4 mb-0 bg-white shadow-md;

  &__header {
    @apply flex items-center pb-3 mb-4 border-b;

    div {
      @apply ml-auto text-sm text-gray-500;
    }
  }
}

.OnboardingFooter {
  @apply w-full px-8 py-4 mb-0 bg-white bg-gray-100 border-t shadow-md;
}
</style>

<template>
  <StatsSection v-if="selectedAttribute" title="Year Over Year">
    <template #header>
      <div class="flex items-center">
        <ChartNavigation :options="availableAttributes" :selected-option="selectedAttribute" label-attribute="name" @input="setAttribute" />

        <Dropdown right class="md:ml-6 ml-4">
          <template #selected>
            <div class="flex items-center justify-between px-3 py-1 font-medium">
              <div>
                {{ yearsToShow.label }}
              </div>
              <svg class="svg-icon">
                <use xlink:href="#icon-caret-down"></use>
              </svg>
            </div>
          </template>

          <template #options>
            <div class="w-32">
              <a
                v-for="(option, index) in yearsToShowOptions"
                :key="index"
                href
                class="Dropdown__item"
                :class="{ 'font-bold': option === yearsToShow }"
                @click.prevent="yearsToShow = option"
              >
                {{ option.label }}
              </a>
            </div>
          </template>
        </Dropdown>
      </div>
    </template>

    <chart ref="chart" :options="chartData" :callback="setChart"></chart>
  </StatsSection>
</template>

<script>
import * as chartConfig from 'constants/chartConfig'

import dayjs from 'utils/dayjs'

import format from 'filters/format'

import { generateDateRange } from 'utils/date'

import StatsSection from 'components/StatsSection'

const yearsToShowOptions = [
  {
    value: '3',
    label: '3 years'
  },
  {
    value: '20',
    label: 'All time'
  }
]

const availableAttributes = [
  {
    name: 'Distance',
    attribute: 'distance',
    format: 'distance'
  },
  {
    name: 'Elevation',
    attribute: 'total_elevation_gain',
    format: 'elevation'
  },
  {
    name: 'Moving Time',
    attribute: 'moving_time',
    format: 'duration'
  },
  {
    name: 'Training Stress Score',
    attribute: 'tss',
    format: 'numeric'
  }
]

export default {
  components: {
    StatsSection
  },

  props: {
    data: {
      type: Object,
      required: true
    },
    segmentBy: {
      type: String,
      required: false,
      default: 'day'
    },
    height: {
      type: Number,
      required: false,
      default: 400
    }
  },

  data() {
    return {
      chart: undefined,
      drag: false,
      yearsToShowOptions: yearsToShowOptions,
      availableAttributes: availableAttributes,
      yearsToShow: yearsToShowOptions[0],
      selectedAttribute: availableAttributes[0]
    }
  },

  computed: {
    format() {
      return this.selectedAttribute.format
    },

    attribute() {
      return this.selectedAttribute.attribute
    },

    chartData() {
      const source = this.data[this.segmentBy]
      const keys = Object.keys(source)

      const allYears = keys.map((key) => new Date(key).getFullYear())
      let years = allYears.filter((item, index) => allYears.indexOf(item) === index)
      if (this.yearsToShow) {
        years = years.filter((item) => item > new Date().getFullYear() - Number(this.yearsToShow.value))
      }

      const tickPositions = []

      const valueFormatter = (point) => {
        return `${format(point.value, this.format)}`
      }

      const series = years
        .map((year, index) => {
          const today = new Date()
          const from = new Date(`${year}-01-01`)
          const to = new Date(`${year}-12-31`)
          const days = generateDateRange(from, to)
          let acc = 0

          const data = days
            .map((day, i) => {
              if (index == years.length - 1 && new Date(day).getDate() == 1) {
                tickPositions.push(i)
              }

              if (day > today) {
                return
              }
              const key = day.toISOString().slice(0, 10)

              if (source[key]) {
                let value = Number(source[key][this.attribute])
                acc += value
              }

              return [key, Math.round(acc)]
            })
            .filter(Boolean)

          return {
            name: year,
            zIndex: index,
            fillOpacity: index == years.length - 1 ? 0.25 : 0.2,
            data
          }
        })
        .reverse()

      return {
        ...chartConfig.defaultOptions,
        ...chartConfig.withLegend,

        chart: {
          ...chartConfig.chart,
          height: this.$isMobile() ? this.height / 2 : this.height,
          marginLeft: 100,
          type: 'area',
          zoomType: 'x'
        },
        exporting: {
          ...chartConfig.exporting,
          enabled: !this.$isMobile()
        },
        tooltip: {
          ...chartConfig.withTooltip,
          valueDecimals: 0,
          pointFormatter: function () {
            return `<span style="color:${this.color}">●</span> ${this.series.name}: <b>${valueFormatter({ value: this.y })}</b><br/>`
          }
        },
        colors: ['#ff3300', '#80a7b1', '#d7cfb8', '#ED561B', '#443be0', '#48856b', '#8b2477', '#f5cb5c'],
        xAxis: {
          crosshair: {
            width: 1,
            color: '#000',
            enabled: true,
            zIndex: 99,
            snap: false
          },
          type: 'datetime',
          startOfWeek: Number(dayjs().weekday(0).format('d')),
          tickPositions: tickPositions,
          labels: {
            formatter: function () {
              return dayjs().startOf('year').add(this.value, 'day').format('MMM')
            }
          },
          lineColor: '#ccc',
          margin: 0,
          padding: 0
        },
        yAxis: {
          margin: 0,
          padding: 0,
          title: {
            enabled: false
          },
          labels: {
            formatter: valueFormatter
          }
        },
        plotOptions: {
          area: {
            lineWidth: 2,
            borderWidth: 2,
            borderColor: 'transparent',
            borderRadius: 2,
            shadow: false,
            lineOpacity: 0.5,
            fillOpacity: 0.25,
            marker: {
              enabled: false
            }
          }
        },
        series: series
      }
    }
  },

  mounted() {
    this.$el.addEventListener('mousemove', () => (this.drag = true))
    this.$el.addEventListener('mousedown', () => (this.drag = false))
    this.$el.addEventListener('mouseup', () => !this.drag && this.resetZoom())
  },

  methods: {
    setChart(chart) {
      this.chart = chart
    },
    resetZoom() {
      this.chart.xAxis[0].setExtremes && this.chart.xAxis[0].setExtremes(null, null, false)
      this.chart.redraw()
    },
    setAttribute(attribute) {
      this.selectedAttribute = attribute
    }
  }
}
</script>

<template>
  <div v-if="subscriptionsPlans" class="p-4">
    <div v-if="isProcessingPayment" class="p-16 text-center">
      <LoadingIcon class="mx-auto my-4">Processing payment…</LoadingIcon>
    </div>
    <div v-else>
      <div class="md:space-x-4 flex flex-wrap justify-center">
        <div v-for="plan in subscriptionsPlans" :key="plan.id" class="md:w-96 w-full p-8 mb-4 text-center bg-white border rounded">
          <div class="text-2xl text-center">{{ plan.type === 'yearly' ? 'Annual' : 'Monthly' }} Plan</div>
          <hr class="my-4" />
          <div v-if="discount">{{ discount * 100 }}% OFF</div>
          <div class="text-5xl font-bold text-center">
            <span v-if="discount">{{ ((1 - discount) * plan.price) | currency }}</span>
            <span v-else>{{ plan.price | currency }}</span>
          </div>
          <span class="label mb-6">USD / {{ plan.type === 'yearly' ? 'year' : 'month' }}</span>
          <router-link :to="{ name: 'join' }">
            <AppButton small primary class="w-full mt-3" @click.prevent="subscribe(plan.id)">Subscribe</AppButton>
          </router-link>
        </div>
      </div>

      <div class="mx-auto my-8">
        <p class="text-muted text-center">
          🌲 We plant
          <a target="_blank" class="underline" href="https://ecologi.com/formbeat">one tree</a>
          for every new subscription.
        </p>
      </div>

      <div class="my-8 text-center">
        <a
          href="https://ecologi.com/formbeat?r=61b9ebfdd3c429b59ed3949a"
          target="_blank"
          rel="noopener noreferrer"
          title="View our Ecologi profile"
          style="width: 200px; display: inline-block"
        >
          <img
            alt="We offset our carbon footprint via Ecologi"
            src="https://ecologi-assets.imgix.net/badges/climate-positive-workforce-black-landscape.png"
            style="width: 200px"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileService from 'services/ProfileService'

export default {
  props: {
    email: {
      type: String,
      required: true
    },

    passthrough: {
      type: String,
      required: true
    },

    discount: {
      type: Number,
      required: false,
      default: 0
    },

    coupon: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      isProcessingPayment: false,
      subscriptionsPlans: undefined
    }
  },

  async mounted() {
    this.subscriptionsPlans = ProfileService.getActiveSubscriptionPlans()
  },

  methods: {
    subscribe(planId) {
      Paddle.Checkout.open({
        product: planId,
        coupon: this.coupon,
        passthrough: this.passthrough,
        email: this.email,
        allowQuantity: false,
        disableLogout: true,
        successCallback: (result) => {
          if (result.checkout && result.checkout.completed === true) {
            this.isProcessingPayment = true
            setTimeout(() => {
              this.$emit('subscribed')
            }, 3000)
          }
        }
      })
    }
  }
}
</script>

<template>
  <StatsSection :title="title">
    <template #header>
      <ChartNavigation :options="intervals" :selected-option="selectedInterval" @input="setInterval" />
    </template>

    <chart :options="chartData"></chart>
  </StatsSection>
</template>

<script>
import * as chartConfig from 'constants/chartConfig'

import dayjs from 'utils/dayjs'
import dateFormat from 'utils/dateFormat'
import chroma from 'chroma-js'

import formatUnits from 'filters/formatUnits'
import formatLabels from 'filters/formatLabels'
import StatsSection from 'components/StatsSection'

export default {
  components: {
    StatsSection
  },

  props: {
    data: {
      type: Object,
      required: true
    },
    segmentBy: {
      type: String,
      required: true
    },
    attribute: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    height: {
      type: Number,
      required: false,
      default: 400
    },
    format: {
      type: String,
      required: false
    },
    decimalPlaces: {
      type: Number,
      required: false,
      default: 0
    }
  },

  data() {
    return {
      selectedInterval: '1m',
      intervals: ['1s', '5s', '10s', '30s', '1m', '5m', '20m', '60m']
    }
  },

  computed: {
    chartUnits() {
      return formatUnits(this.format)
    },

    currentAttribute() {
      return [this.attribute, this.selectedInterval].join('_')
    },

    chartData() {
      const source = this.data[this.segmentBy]
      const keys = Object.keys(source)
      const data = keys.map((key) => {
        return [dayjs(key).valueOf(), source[key] ? Number(source[key][this.currentAttribute]) : 0]
      })

      const dataPoints = data.map((d) => d[1] || 0)
      const minPositiveValue = Math.min.apply(
        null,
        dataPoints.filter((v) => v > 0)
      )
      const minValue = 0.8 * minPositiveValue
      // const minValue = 0
      const maxValue = Math.max.apply(null, dataPoints)

      const sum = dataPoints.reduce((a, b) => a + b, 0)
      const averageValue = (sum / dataPoints.filter(Boolean).length).toFixed(2)

      const { formatter } = formatLabels(dataPoints.length, this.segmentBy, this.$isMobile())

      let colors = chroma.scale(['#ff4800', '#f4b792']).colors(8)
      const chartColor = colors[this.intervals.findIndex((i) => i === this.selectedInterval)]

      return {
        ...chartConfig.defaultOptions,

        chart: {
          ...chartConfig.chart,
          height: this.$isMobile() ? this.height / 2 : this.height,
          marginLeft: 75,
          animation: true,
          type: 'column'
        },
        exporting: {
          ...chartConfig.exporting,
          enabled: !this.$isMobile()
        },
        tooltip: {
          ...chartConfig.withTooltip,
          xDateFormat: dateFormat(this.segmentBy),
          valueDecimals: this.decimalPlaces,
          valueSuffix: this.chartUnits ? ` ${this.chartUnits}` : ''
        },

        colors: [chartColor],
        xAxis: {
          type: 'datetime',
          startOfWeek: Number(dayjs().weekday(0).format('d')),
          color: '#ff3300',
          margin: 0,
          padding: 0,
          labels: {
            formatter: formatter,
            autoRotation: false,
            padding: 0
          }
        },
        yAxis: [
          {
            floor: 0,
            min: minValue,
            max: maxValue,
            margin: 0,
            padding: 0,
            title: {
              enabled: false
            },
            labels: {
              format: this.chartUnits ? `{value} ${this.chartUnits}` : '{value}'
            },
            plotLines: [
              {
                color: '#ff8200',
                dashStyle: 'Dash',
                value: averageValue,
                width: 1,
                zIndex: 4
              }
            ]
          }
        ],
        plotOptions: {
          column: {
            crisp: false
          }
        },
        series: [
          {
            yAxis: 0,
            shadow: false,
            pointPadding: 0.05,
            groupPadding: 0.05,
            borderWidth: 1,
            maxPointWidth: 148,
            type: 'column',
            name: this.title,
            data: data
          }
        ]
      }
    }
  },

  methods: {
    setInterval(interval) {
      this.selectedInterval = interval
    }
  }
}
</script>

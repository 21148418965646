<template>
  <div v-if="dashboardCurrentSummary" class="w-full">
    <SectionTitle title="Dashboard">
      <TimeRangeFilter :segment-by="segmentBy" :date-range="dashboardDateRange" date-range-type="dashboard" class="ml-auto" :segment-by-enabled="false" />
    </SectionTitle>

    <div class="md:block text-muted hidden mb-8 -mt-6 text-sm">{{ dashboardDateRange.from | date }} – {{ dashboardDateRange.to | date }}</div>

    <TrialExpiresMessage v-if="!user.premium && user.on_trial" :trial-ends-at="user.trial_ends_at" />

    <div class="flex flex-wrap mb-8 -mx-2">
      <div class="md:w-1/4 self-stretch w-full px-2 mb-3">
        <StatsCardSummaryTrainingStatus title="Training Status" :data="fitness">
          <template #tooltip>
            <InfoIcon class="ml-2" term="training-status" :small="true" />
          </template>
        </StatsCardSummaryTrainingStatus>
      </div>

      <div class="md:w-1/4 w-full px-2 mb-3">
        <StatsCardSummaryFitness title="Today's Fitness" :data="fitness" attribute="ctl" :date-range="dashboardDateRange">
          <template #tooltip>
            <InfoIcon class="ml-2" term="form-fitness" :small="true" />
          </template>
        </StatsCardSummaryFitness>
      </div>

      <div class="md:w-1/4 w-full px-2 mb-3">
        <StatsCardSummaryFitness title="Today's Fatigue" :data="fitness" attribute="atl" :date-range="dashboardDateRange">
          <template #tooltip>
            <InfoIcon class="ml-2" term="form-fitness" :small="true" />
          </template>
        </StatsCardSummaryFitness>
      </div>

      <div class="md:w-1/4 w-full px-2 mb-3">
        <StatsCardSummaryFitness title="Today's Form" :data="fitness" attribute="tsb" :date-range="dashboardDateRange">
          <template #tooltip>
            <InfoIcon class="ml-2" term="form-fitness" :small="true" />
          </template>
        </StatsCardSummaryFitness>
      </div>
    </div>

    <StatsSection title="Weekly Training Load">
      <TrainingLoadChart :fitness="fitness" :date-range="dashboardDateRange" />
      <template #header>
        <div class="flex items-center space-x-2">
          <div class="text-sm text-gray-400">
            <span v-if="['7d', '14d', '30d'].includes(dashboardDateRange.id)">Past 3 months</span>
            <span v-else>{{ dashboardDateRange.longTitle }}</span>
          </div>
          <InfoIcon term="training-load" />
        </div>
      </template>
    </StatsSection>

    <StatsSection title="Form & Fitness">
      <FitnessChart :fitness="fitness" :date-range="dashboardDateRange" />
      <template #header><InfoIcon term="form-fitness" /></template>
    </StatsSection>

    <SectionTitle :title="`Statistics – ${dashboardDateRange.longTitle}`" :sticky="false">
      <span class="text-muted">{{ dashboardDateRange.from | date }} – {{ dashboardDateRange.to | date }}</span>
    </SectionTitle>

    <div class="flex flex-wrap mb-8 -mx-2">
      <div class="md:w-1/4 w-full px-2 mb-3">
        <StatsCardSummary title="Duration" :data="summary" attribute="moving_time" :date-range="dashboardDateRange" format="duration" />
      </div>

      <div class="md:w-1/4 w-full px-2 mb-3">
        <StatsCardSummary title="Distance" :data="summary" attribute="distance" :date-range="dashboardDateRange" format="distance" />
      </div>

      <div class="md:w-1/4 w-full px-2 mb-3">
        <StatsCardSummary title="Elevation" :data="summary" attribute="total_elevation_gain" :date-range="dashboardDateRange" format="elevation" />
      </div>

      <div class="md:w-1/4 w-full px-2 mb-3">
        <StatsCardSummary title="Average Speed" :data="summary" attribute="average_speed" type="average" :date-range="dashboardDateRange" format="speed" />
      </div>
    </div>

    <div v-if="user.has_powermeter">
      <h3 class="border-primary pl-2 mt-12 mb-6 border-l-4">Power</h3>

      <PowerCurveChart title="Power Curve" :data="activities" :date-range="dashboardDateRange" :weight="user.weight_metric" />

      <div class="flex flex-wrap mb-8 -mx-2">
        <div class="md:w-1/4 w-full px-2 mb-3">
          <StatsCardSummary
            title="Average Normalized Power"
            :data="summary"
            attribute="computed_weighted_power"
            type="average"
            :date-range="dashboardDateRange"
            format="power"
          >
            <template #tooltip>
              <InfoIcon class="ml-2" term="normalized-power" :small="true" />
            </template>
          </StatsCardSummary>
        </div>

        <div class="md:w-1/4 w-full px-2 mb-3">
          <StatsCardSummary title="Max 5s power" :data="summary" attribute="peak_power_5s" type="max" :date-range="dashboardDateRange" format="power">
            <template #tooltip>
              <InfoIcon class="ml-2" term="max-power" :small="true" />
            </template>
          </StatsCardSummary>
        </div>

        <div class="md:w-1/4 w-full px-2 mb-3">
          <StatsCardSummary title="Intensity" :data="summary" attribute="average_intensity" type="average" :date-range="dashboardDateRange" format="is_percent">
            <template #tooltip>
              <InfoIcon class="ml-2" term="intensity" :small="true" />
            </template>
          </StatsCardSummary>
        </div>

        <div class="md:w-1/4 w-full px-2 mb-3">
          <StatsCardSummary
            title="Variability Index"
            :data="summary"
            attribute="variability_index"
            type="average"
            :date-range="dashboardDateRange"
            format="is_percent"
          >
            <template #tooltip>
              <InfoIcon class="ml-2" term="variability-index" :small="true" />
            </template>
          </StatsCardSummary>
        </div>
      </div>

      <StatsSection title="Time in Power Zone">
        <TimeInZoneChart
          :data="dashboardCurrentSummary"
          :segment-by="segmentBy"
          attribute="time_in_power_zone"
          :zones="user.power_zones"
          units="%"
          :height="400"
        />
      </StatsSection>
    </div>

    <div>
      <h3 class="border-primary pl-2 mt-12 mb-6 leading-tight border-l-4">Heart Rate</h3>
      <StatsSection title="Time in Heart Rate Zone">
        <TimeInZoneChart :data="dashboardCurrentSummary" :segment-by="segmentBy" attribute="time_in_hr_zone" :zones="user.heartrate_zones" units="%" />
      </StatsSection>
    </div>
  </div>
</template>

<script>
import SystemMessage from 'components/SystemMessage'
import FitnessChart from 'components/charts/FitnessChart'
import TrainingLoadChart from 'components/charts/TrainingLoadChart'
import PowerCurveChart from 'components/charts/PowerCurveChart'
import TimeInZoneChart from 'components/charts/TimeInZoneChart'
import StatsCardSummary from 'components/StatsCardSummary'
import StatsCardSummaryFitness from 'components/StatsCardSummaryFitness'
import StatsCardSummaryTrainingStatus from 'components/StatsCardSummaryTrainingStatus'
import SectionTitle from 'components/SectionTitle'
import StatsSection from 'components/StatsSection'
import TimeRangeFilter from 'components/TimeRangeFilter'
import TrialExpiresMessage from 'components/TrialExpiresMessage'

export default {
  components: {
    SystemMessage,
    TrialExpiresMessage,
    StatsCardSummary,
    StatsCardSummaryFitness,
    StatsCardSummaryTrainingStatus,
    StatsSection,
    SectionTitle,
    TimeRangeFilter,
    TimeInZoneChart,
    FitnessChart,
    TrainingLoadChart,
    PowerCurveChart
  },

  props: {
    summary: {
      type: Object,
      required: true
    },
    dashboardCurrentSummary: {
      type: Object,
      required: true
    },
    user: {
      type: Object
    },
    activities: {
      type: Array,
      required: true
    },
    fitness: {
      type: Array,
      required: true
    },
    segmentBy: {
      type: String,
      required: true
    },
    dashboardDateRange: {
      type: Object,
      required: true
    }
  }
}
</script>

<template>
  <div @click.prevent="openModal()">
    <svg v-if="!text" :class="{ 'svg-icon--xs': small }" class="svg-icon svg-icon--sm hover:text-primary text-gray-400 cursor-pointer">
      <use xlink:href="#icon-info"></use>
    </svg>
    <span v-if="text" class="cursor-help inline-block border-b border-dotted">
      {{ text }}
    </span>
  </div>
</template>

<script>
import TooltipModal from 'views/Modals/TooltipModal'

export default {
  props: {
    term: {
      type: String,
      required: true
    },

    text: {
      type: String,
      required: false
    },

    small: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  methods: {
    openModal() {
      this.$modal(TooltipModal, { term: this.term })
    }
  }
}
</script>

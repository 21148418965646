import store from 'store'

import DashboardView from 'views/Dashboard/DashboardView'
import DashboardActivitiesView from 'views/Dashboard/DashboardActivitiesView'
import DashboardCreateActivityView from 'views/Dashboard/DashboardCreateActivityView'
import DashboardUploadActivityView from 'views/Dashboard/DashboardUploadActivityView'
import DashboardFitnessView from 'views/Dashboard/DashboardFitnessView'
import DashboardYearOverYearView from 'views/Dashboard/DashboardYearOverYearView'
import DashboardAnalyzeView from 'views/Dashboard/DashboardAnalyzeView'
import DashboardPowerView from 'views/Dashboard/DashboardPowerView'
import DashboardPowerProfileView from 'views/Dashboard/DashboardPowerProfileView'
import DashboardSpeedView from 'views/Dashboard/DashboardSpeedView'
import DashboardDistanceView from 'views/Dashboard/DashboardDistanceView'
import DashboardHeartrateView from 'views/Dashboard/DashboardHeartrateView'
import DashboardClimbingView from 'views/Dashboard/DashboardClimbingView'
import DashboardCalendarView from 'views/Dashboard/DashboardCalendarView'
import DashboardTrendsView from 'views/Dashboard/DashboardTrendsView'
import DashboardAchievementsView from 'views/Dashboard/DashboardAchievementsView'
import DashboardChangelogView from 'views/Dashboard/DashboardChangelogView'
import DashboardActivityDetailView from 'views/Dashboard/DashboardActivityDetailView'

import DashboardActivityAnalyzeView from 'views/Dashboard/Activity/ActivityAnalyzeView'
import DashboardActivityPowerView from 'views/Dashboard/Activity/ActivityPowerView'
import DashboardActivityHeartRateView from 'views/Dashboard/Activity/ActivityHeartRateView'
import DashboardActivityLapsView from 'views/Dashboard/Activity/ActivityLapsView'
import DashboardActivityProfileView from 'views/Dashboard/Activity/ActivityProfileView'
import DashboardActivitySimilarRidesView from 'views/Dashboard/Activity/DashboardActivitySimilarRidesView'

import HomepageView from 'views/HomepageView'
import OAuthSuccess from 'views/OAuthSuccess'
import PrivacyView from 'views/PrivacyView'
import MobileAppView from 'views/MobileAppView'
import TodaysPlanView from 'views/TodaysPlanView'
import PricingView from 'views/PricingView'
import HelpView from 'views/HelpView'
import TermsView from 'views/TermsView'
import DPAView from 'views/DPAView'
import TrainingGlossaryView from 'views/TrainingGlossaryView'
import ChangelogView from 'views/ChangelogView'
import UnsubscribeView from 'views/UnsubscribeView'

import ToolsView from 'views/ToolsView'
import ToolsTSSCalculator from 'views/Tools/TSSCalculatorView'
import ToolsVariabilityIndexCalculator from 'views/Tools/VariabilityIndexCalculatorView'
import ToolsIntensityFactorCalculator from 'views/Tools/IntensityFactorCalculatorView'
import ToolsFTPCalculator from 'views/Tools/FTPCalculatorView'
import ToolsPowerToWeightCalculator from 'views/Tools/PowerToWeightCalculatorView'
import ToolsPowerZonesCalculator from 'views/Tools/PowerZonesCalculatorView'
import ToolsHRZonesCalculator from 'views/Tools/HRZonesCalculatorView'
import ToolsGoodFTPCalculator from 'views/Tools/GoodFTPCalculatorView'
import ToolsZone2Training from 'views/Tools/Zone2Training'
import ToolsRunningPaceCalculator from 'views/Tools/RunningPaceCalculatorView'
import UpgradeAccountView from 'views/UpgradeAccountView'

import SignInView from 'views/Auth/SignInView'
import SignUpView from 'views/Auth/SignUpView'
import ReauthorizeView from 'views/Auth/ReauthorizeView'
import VerifySignInTokenView from 'views/Auth/VerifySignInTokenView'

import AdminView from 'views/Admin/AdminView'
import AdminUsersView from 'views/Admin/AdminUsersView'

import OnboardingView from 'views/Onboarding/OnboardingView'
import SettingsProfile from 'views/Settings/SettingsProfile'
import SettingsHeartRate from 'views/Settings/SettingsHeartRate'
import SettingsHeartRateZones from 'views/Settings/SettingsHeartRateZones'
import SettingsPower from 'views/Settings/SettingsPower'
import SettingsFtp from 'views/Settings/SettingsFtp'
import SettingsWeight from 'views/Settings/SettingsWeight'
import SettingsAccount from 'views/Settings/SettingsAccount'
import SettingsConnections from 'views/Settings/SettingsConnections'
import SettingsMembership from 'views/Settings/SettingsMembership'
import SettingsNotifications from 'views/Settings/SettingsNotifications'
import SettingsOtherSports from 'views/Settings/SettingsOtherSports'

import SettingsView from 'views/Settings/SettingsView'
import NotFoundView from 'views/NotFoundView'

export default [
  {
    path: '/',
    component: HomepageView,
    name: 'home'
  },
  {
    path: '/join',
    component: SignUpView,
    name: 'join'
  },
  {
    path: '/sign-in',
    component: SignInView,
    name: 'signIn'
  },
  {
    path: '/connect-with-strava',
    component: ReauthorizeView,
    name: 'reauthorize'
  },
  {
    path: '/select-plan',
    component: UpgradeAccountView,
    name: 'upgrade'
  },
  {
    path: '/magic-link',
    component: VerifySignInTokenView,
    name: 'verifySignInToken',
    props: (route) => ({
      token: String(route.query.token),
      mobile: route.query.mobile,
      redirect: route.query.redirect
    })
  },
  {
    path: '/magic-link-mobile',
    component: VerifySignInTokenView,
    name: 'verifySignInTokenMobile',
    props: (route) => ({
      token: String(route.query.token),
      mobile: route.query.mobile,
      redirect: route.query.redirect
    })
  },
  {
    path: '/app',
    component: DashboardView,
    name: 'dashboard',
    beforeEnter: async (to, from, next) => {
      const user = store.getters['profile/user']

      // Redirect to homepage unauthorized
      if (!user) {
        return next({ name: 'home' })
      }

      if (!user.initialized) {
        return next({ name: 'onboarding' })
      }

      if (user.no_account_connected && to.name.indexOf('settings') != 0) {
        return next({ name: 'reauthorize' })
      }

      if (!user.premium && !user.on_trial && to.name.indexOf('settings') != 0) {
        return next({ name: 'upgrade' })
      }

      next()
    },
    redirect: {
      name: 'dashboard.trends'
    },
    children: [
      {
        path: '/app/trends',
        component: DashboardTrendsView,
        name: 'dashboard.trends'
      },
      {
        path: '/app/activities',
        component: DashboardActivitiesView,
        name: 'dashboard.activities'
      },
      {
        path: '/app/activities/new',
        component: DashboardCreateActivityView,
        name: 'dashboard.createActivity'
      },
      {
        path: '/app/activities/upload',
        component: DashboardUploadActivityView,
        name: 'dashboard.uploadActivity'
      },
      {
        path: '/app/analyze',
        component: DashboardAnalyzeView,
        name: 'dashboard.analyze',
        redirect: {
          name: 'dashboard.fitness'
        },
        children: [
          {
            path: '/app/analyze/fitness',
            component: DashboardFitnessView,
            name: 'dashboard.fitness'
          },
          {
            path: '/app/analyze/powerProfile',
            component: DashboardPowerProfileView,
            name: 'dashboard.powerProfile'
          },
          {
            path: '/app/analyze/power',
            component: DashboardPowerView,
            name: 'dashboard.power'
          },
          {
            path: '/app/analyze/speed',
            component: DashboardSpeedView,
            name: 'dashboard.speed'
          },
          {
            path: '/app/analyze/distance',
            component: DashboardDistanceView,
            name: 'dashboard.distance'
          },
          {
            path: '/app/analyze/heartrate',
            component: DashboardHeartrateView,
            name: 'dashboard.heartrate'
          },
          {
            path: '/app/analyze/climbing',
            component: DashboardClimbingView,
            name: 'dashboard.climbing'
          },
          {
            path: '/app/analyze/year-over-year',
            component: DashboardYearOverYearView,
            name: 'dashboard.year-over-year'
          }
        ]
      },
      {
        path: '/app/calendar',
        component: DashboardCalendarView,
        name: 'dashboard.calendar'
      },
      {
        path: '/app/achievements',
        component: DashboardAchievementsView,
        name: 'dashboard.achievements'
      },
      {
        path: '/app/what-is-new',
        component: DashboardChangelogView,
        name: 'dashboard.changelog'
      },
      {
        path: '/app/activities/:id',
        component: DashboardActivityDetailView,
        name: 'dashboard.activity',
        props: (route) => ({
          activityId: String(route.params.id)
        }),
        redirect: {
          name: 'activity.overview'
        },
        children: [
          {
            path: '/app/activities/:id',
            name: 'activity.overview',
            component: DashboardActivityAnalyzeView
          },
          {
            path: '/app/activities/:id/analyze',
            name: 'activity.analyze',
            component: DashboardActivityAnalyzeView,
            meta: {
              hideSidebar: true
            }
          },
          {
            path: '/app/activities/:id/power',
            name: 'activity.power',
            component: DashboardActivityPowerView
          },
          {
            path: '/app/activities/:id/heart-rate',
            name: 'activity.heartrate',
            component: DashboardActivityHeartRateView
          },
          {
            path: '/app/activities/:id/laps',
            name: 'activity.laps',
            component: DashboardActivityLapsView
          },
          {
            path: '/app/activities/:id/profile',
            name: 'activity.profile',
            component: DashboardActivityProfileView
          },
          {
            path: '/app/activities/:id/similar',
            name: 'activity.similar',
            component: DashboardActivitySimilarRidesView
          }
        ]
      },
      {
        path: '/app/settings',
        component: SettingsView,
        name: 'settings',
        redirect: {
          name: 'settings.profile'
        },
        children: [
          {
            path: '/settings/profile',
            name: 'settings.profile',
            component: SettingsProfile
          },
          {
            path: '/settings/heartrate',
            name: 'settings.heartrate',
            component: SettingsHeartRate
          },
          {
            path: '/settings/heartrate-zones',
            name: 'settings.heartrate-zones',
            component: SettingsHeartRateZones
          },
          {
            path: '/settings/power',
            name: 'settings.power',
            component: SettingsFtp
          },
          {
            path: '/settings/weight',
            name: 'settings.weight',
            component: SettingsWeight
          },
          {
            path: '/settings/account',
            name: 'settings.account',
            component: SettingsAccount
          },
          {
            path: '/settings/connections',
            name: 'settings.connections',
            component: SettingsConnections
          },
          {
            path: '/settings/membership',
            name: 'settings.membership',
            component: SettingsMembership
          },
          {
            path: '/settings/notifications',
            name: 'settings.notifications',
            component: SettingsNotifications
          },
          {
            path: '/settings/other-sports',
            name: 'settings.other-sports',
            component: SettingsOtherSports
          }
        ]
      }
    ]
  },
  {
    path: '/hello',
    component: OnboardingView,
    name: 'onboarding',
    beforeEnter: async (to, from, next) => {
      const user = store.getters['profile/user']

      // Redirect to homepage unauthorized
      if (!user) {
        return next({ name: 'home' })
      }

      // if (user.initialized) {
      //   return next({ name: 'dashboard' })
      // }

      next()
    },
    redirect: {
      name: 'onboarding.account'
    },
    children: [
      {
        path: '/hello/profile',
        name: 'onboarding.profile',
        component: SettingsProfile
      },
      {
        path: '/hello/heartrate',
        name: 'onboarding.heartrate',
        component: SettingsHeartRate
      },
      {
        path: '/hello/heartrate-zones',
        name: 'onboarding.heartrate-zones',
        component: SettingsHeartRateZones
      },
      {
        path: '/hello/power',
        name: 'onboarding.power',
        component: SettingsPower
      },
      {
        path: '/hello/account',
        name: 'onboarding.account',
        component: SettingsAccount
      }
    ]
  },
  {
    path: '/oauth/strava/success',
    name: 'oauth.success.strava',
    component: OAuthSuccess
  },
  {
    path: '/oauth/garmin/success',
    name: 'oauth.success.garmin',
    component: OAuthSuccess
  },

  // Static pages
  {
    path: '/privacy',
    name: 'privacy',
    component: PrivacyView
  },
  {
    path: '/terms',
    name: 'terms',
    component: TermsView
  },
  {
    path: '/dpa',
    name: 'dpa',
    component: DPAView
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: PricingView
  },
  {
    path: '/help',
    name: 'help',
    component: HelpView
  },
  {
    path: '/mobile-app',
    name: 'mobile',
    component: MobileAppView
  },
  {
    path: '/todays-plan-alternative',
    name: 'todaysPlan',
    component: TodaysPlanView
  },
  {
    path: '/training-glossary/:term?',
    name: 'training-glossary',
    component: TrainingGlossaryView,
    props: (route) => ({
      term: route.params.term
    })
  },
  {
    path: '/tools',
    name: 'tools',
    component: ToolsView,
    redirect: {
      name: 'tools.ftp'
    },
    children: [
      {
        path: '/tools/tss-calculator',
        name: 'tools.tss',
        component: ToolsTSSCalculator
      },
      {
        path: '/tools/variability-index-calculator',
        name: 'tools.variabilityIndex',
        component: ToolsVariabilityIndexCalculator
      },
      {
        path: '/tools/intensity-factor-calculator',
        name: 'tools.intensityFactor',
        component: ToolsIntensityFactorCalculator
      },
      {
        path: '/tools/ftp-calculator',
        name: 'tools.ftp',
        component: ToolsFTPCalculator
      },
      {
        path: '/tools/power-to-weight-calculator',
        name: 'tools.powerToWeight',
        component: ToolsPowerToWeightCalculator
      },
      {
        path: '/tools/power-training-zones-calculator',
        name: 'tools.powerZones',
        component: ToolsPowerZonesCalculator
      },
      {
        path: '/tools/heart-rate-zones-calculator',
        name: 'tools.heartRateZones',
        component: ToolsHRZonesCalculator
      },
      {
        path: '/tools/how-good-is-my-ftp',
        name: 'tools.goodFtp',
        component: ToolsGoodFTPCalculator
      },
      {
        path: '/tools/zone-2-training',
        name: 'tools.zone2Training',
        component: ToolsZone2Training
      },
      {
        path: '/tools/running-pace-calculator',
        name: 'tools.runningPace',
        component: ToolsRunningPaceCalculator
      }
    ]
  },
  {
    path: '/what-is-new',
    name: 'changelog',
    component: ChangelogView
  },
  {
    path: '/unsubscribe/:id/:email',
    name: 'unsubscribe',
    component: UnsubscribeView,
    props: (route) => ({
      email: route.params.email,
      id: route.params.id
    })
  },

  // Admin
  {
    path: '/admin',
    component: AdminView,
    name: 'admin',
    beforeEnter: async (to, from, next) => {
      const user = store.getters['profile/user']

      // Redirect to homepage unauthorized
      if (!user) {
        return next({ name: 'signIn' })
      }

      if (!user.admin) {
        return next({ name: 'home' })
      }

      next()
    },
    redirect: {
      name: 'admin.users'
    },
    children: [
      {
        path: '/admin/users',
        component: AdminUsersView,
        name: 'admin.users'
      }
    ]
  },

  // 404
  {
    path: '/not-found',
    name: 'notFound',
    component: NotFoundView
  },
  {
    path: '*',
    component: NotFoundView
  }
]

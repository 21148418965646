<template>
  <div class="OnboardingStep">
    <div class="OnboardingStep__header">
      <h2>Weight</h2>
    </div>

    <h3>Weight History</h3>

    <div class="mt-8 mb-4">
      <div class="flex border-b">
        <div class="label w-64">Weight</div>
        <div class="label w-64">Effective since</div>
        <div class="label flex-grow text-right">Action</div>
      </div>

      <div v-for="(record, index) in userWeightValues" :key="record.id" class="flex py-2 border-b">
        <div class="w-64" :class="{ 'font-bold': index === 0 }">{{ record.weight | format('weight') }}</div>
        <div class="w-64">
          <div v-if="form.user_weights.length > 1">
            <span v-if="record.effective_from == '1970-01-01T00:00:00.000Z'" key="more_ftp_readings">Initial value</span>
            <span v-else key="more_ftp_readings">{{ record.effective_from | date }}</span>
          </div>
          <div v-else>
            <span>Your current weight</span>
          </div>
        </div>
        <div class="flex-grow text-right">
          <a class="link text-sm" href @click.prevent="editRecord(record)">Edit</a>
          <a v-if="index !== userWeightValues.length - 1" class="link ml-2 text-sm" href @click.prevent="deleteRecord(record)">Delete</a>
        </div>
      </div>
    </div>

    <AppButton xsmall class="my-4" @click="addNewRecord()">Add new weight</AppButton>
  </div>
</template>

<script>
import ProfileService from 'services/ProfileService'

import AddWeightModal from 'views/Modals/AddWeightModal'
import EditWeightModal from 'views/Modals/EditWeightModal'
import DeleteWeightModal from 'views/Modals/DeleteWeightModal'

export default {
  props: {
    form: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      required: false
    }
  },

  computed: {
    userWeightValues() {
      return [...this.form.user_weights]
    }
  },

  methods: {
    async addNewRecord() {
      const weight = await this.$modal(AddWeightModal)
      if (!weight) {
        return
      }

      await ProfileService.createUserWeight(weight)
      window.location.reload()
    },

    async editRecord(data) {
      const weight = await this.$modal(EditWeightModal, { weight: data })
      if (!weight) {
        return
      }

      await ProfileService.editUserWeight(weight)
      window.location.reload()
    },

    async deleteRecord(data) {
      const weight = await this.$modal(DeleteWeightModal, { weight: data })
      if (!weight) {
        return
      }

      await ProfileService.destroyUserWeight(weight)
      window.location.reload()
    }
  }
}
</script>

<template>
  <StatsSection :title="title">
    <template #header>
      <ChartNavigation :options="intervals" :selected-option="selectedInterval" @input="setInterval" />
    </template>

    <chart ref="chart" :options="chartData" :callback="setChart"></chart>
  </StatsSection>
</template>

<script>
import * as chartConfig from 'constants/chartConfig'

import dayjs from 'utils/dayjs'
import dateFormat from 'utils/dateFormat'
import formatUnits from 'filters/formatUnits'
import StatsSection from 'components/StatsSection'

export default {
  components: {
    StatsSection
  },

  props: {
    data: {
      type: Object,
      required: true
    },
    attribute: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    height: {
      type: Number,
      required: false,
      default: 400
    },
    format: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      chart: undefined,
      drag: false,
      selectedInterval: '1m',
      intervals: ['1s', '5s', '10s', '30s', '1m', '5m', '20m', '60m']
    }
  },

  computed: {
    chartUnits() {
      return formatUnits(this.format)
    },

    currentAttribute() {
      return [this.attribute, this.selectedInterval].join('_')
    },

    chartData() {
      let source = this.data['week']
      const series = []

      // Trim activities without power data
      const trimFrom = Object.entries(source).findIndex(([, value]) => {
        return value && value[this.currentAttribute] > 0
      })

      const dataPoints = []

      Object.entries(source).forEach(([key, value], index) => {
        if (index >= trimFrom) {
          dataPoints.push({
            date: key,
            value: value
          })
        }
      })

      let maxPower = 0

      series.push({
        name: 'Power Record',
        type: 'line',
        data: dataPoints.map(({ date, value }) => {
          const val = value && value[this.currentAttribute] && value[this.currentAttribute] > maxPower ? value[this.currentAttribute] : maxPower
          maxPower = val
          return [dayjs(date).valueOf(), val]
        })
      })

      return {
        ...chartConfig.defaultOptions,

        chart: {
          ...chartConfig.chart,
          height: this.$isMobile() ? this.height / 2 : this.height,
          marginLeft: 75,
          type: 'line',
          zoomType: 'x'
        },
        exporting: {
          ...chartConfig.exporting,
          enabled: !this.$isMobile()
        },
        tooltip: {
          ...chartConfig.withTooltip,
          xDateFormat: dateFormat(this.segmentBy),
          valueSuffix: this.chartUnits ? ` ${this.chartUnits}` : ''
        },

        colors: ['#ff5933'],
        xAxis: {
          type: 'datetime',
          startOfWeek: Number(dayjs().weekday(0).format('d')),
          crosshair: {
            width: 1,
            color: '#000',
            enabled: true,
            zIndex: 4,
            snap: false
          },
          lineColor: '#ccc',
          margin: 0,
          padding: 0,
          labels: {
            formatter: function () {
              return dayjs(this.value).format('MMM<br>YYYY')
            },
            autoRotation: false,
            padding: 0
          }
        },
        yAxis: {
          margin: 0,
          padding: 0,
          title: {
            enabled: false
          },
          labels: {
            format: this.chartUnits ? `{value} ${this.chartUnits}` : '{value}'
          }
        },
        plotOptions: {
          line: {
            lineWidth: 3,
            borderWidth: 2,
            borderColor: 'transparent',
            borderRadius: 2,
            shadow: false,
            lineOpacity: 0.5,
            fillOpacity: 0.25,
            marker: {
              enabled: false
            }
          }
        },
        series: series
      }
    }
  },

  mounted() {
    this.$el.addEventListener('mousemove', () => (this.drag = true))
    this.$el.addEventListener('mousedown', () => (this.drag = false))
    this.$el.addEventListener('mouseup', () => !this.drag && this.resetZoom())
  },

  methods: {
    setInterval(interval) {
      this.selectedInterval = interval
    },

    setChart(chart) {
      this.chart = chart
    },

    resetZoom() {
      this.chart.xAxis[0].setExtremes && this.chart.xAxis[0].setExtremes(null, null, false)
      this.chart.redraw()
    }
  }
}
</script>

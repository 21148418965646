import Route from 'route-parser';
function _typeof(obj) {
  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function (obj) {
      return typeof obj;
    };
  } else {
    _typeof = function (obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }
  return _typeof(obj);
}
function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
}
function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread2(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    if (i % 2) {
      ownKeys(source, true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(source).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }
  return target;
}
function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;
  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }
  return target;
}
function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};
  var target = _objectWithoutPropertiesLoose(source, excluded);
  var key, i;
  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }
  return target;
}
function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}
function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread();
}
function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];
    return arr2;
  }
}
function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}
function _iterableToArray(iter) {
  if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter);
}
function _iterableToArrayLimit(arr, i) {
  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;
  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);
      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }
  return _arr;
}
function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance");
}
function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}
var reservedOptions = ['anchor', 'domain', 'host', 'only_path', 'original_script_name', 'params', 'port', 'protocol', 'relative_url_root', 'script_name', 'subdomain', 'tld_length', 'trailing_slash'];
var unsupportedOptions = ['domain', 'original_script_name', 'subdomain', 'tld_length'];
var handlePositionalArgs = function handlePositionalArgs(segmentKeys, defaults, args, options) {
  var out = {};
  if (args.length > 0) {
    var nonFormatSegmentKeysLength = segmentKeys.includes('format') ? segmentKeys.length - 1 : segmentKeys.length;
    var params = segmentKeys.filter(function (segmentKey) {
      return !options.hasOwnProperty(segmentKey) && (args.length >= nonFormatSegmentKeysLength || !defaults.hasOwnProperty(segmentKey));
    });
    args.forEach(function (arg, index) {
      var param = params[index];
      if (param != null) {
        out[param] = arg;
      }
    });
  }
  return Object.assign({}, defaults, out, options);
};
var routeCache = {};
var generate = function generate(spec, options) {
  if (!routeCache.hasOwnProperty(spec)) {
    routeCache[spec] = new Route(spec);
  }
  var path = routeCache[spec].reverse(options);
  if (path === false) {
    throw new Error("Unable to generate path for route: ".concat(spec, " using options: ").concat(JSON.stringify(options)));
  }
  return path;
};
var generatePath = function generatePath(options) {
  var script_name = options.script_name,
    path = options.path,
    trailing_slash = options.trailing_slash,
    params = options.params,
    anchor = options.anchor;
  var out = '';
  if (script_name) {
    out += script_name.replace(/\/$/, '');
  }
  if (path) {
    out += path;
  }
  if (trailing_slash && path.indexOf('?') !== -1) {
    out = out.replace(/\?/, '/$&');
  } else if (trailing_slash && path.indexOf('.') === -1) {
    out = out.replace(/[^\/]$|^$/, '$&/');
  }
  if (params) {
    var query = Object.keys(params).map(function (key) {
      return "".concat(encodeURIComponent(key), "=").concat(encodeURIComponent(params[key]));
    });
    if (query.length) {
      out += "?".concat(query.join('&'));
    }
  }
  if (anchor) {
    out += "#".concat(encodeURIComponent(anchor));
  }
  return out;
};
var generateUrl = function generateUrl(options) {
  var host = options.host,
    port = options.port,
    protocol = options.protocol;
  var user = options.user,
    password = options.password;
  if (!host) {
    throw new Error('Missing host');
  }
  var match = host.match(/(^[^:]+:\/\/)?(\[[^\]]+\]|[^:]+)(?::(\d+$))?/);
  if (match) {
    if (protocol == null) {
      protocol = match[1];
    }
    host = match[2];
    if (typeof port === 'undefined') {
      port = match[3];
    }
  }
  var out = '';
  if (protocol && protocol !== '//') {
    out += "".concat(protocol.replace(/:(\/\/)?$/, ''), ":");
  }
  out += '//';
  if (user && password) {
    out += "".concat(encodeURIComponent(user), ":").concat(encodeURIComponent(password), "@");
  }
  if (host) {
    out += host;
  }
  if (port) {
    out += ":".concat(port);
  }
  out += generatePath(options);
  return out;
};
var flattenSegmentKeys = function flattenSegmentKeys(_ref) {
  var _ref2 = _slicedToArray(_ref, 4),
    _spec = _ref2[0],
    segmentKeys = _ref2[1],
    _defaults$$1 = _ref2[2],
    parent = _ref2[3];
  return parent ? [].concat(_toConsumableArray(segmentKeys), _toConsumableArray(flattenSegmentKeys(parent))) : segmentKeys;
};
var urlFor = function urlFor(_ref3) {
  var _ref4 = _slicedToArray(_ref3, 4),
    spec = _ref4[0],
    segmentKeys = _ref4[1],
    defaults = _ref4[2],
    parent = _ref4[3];
  for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    args[_key - 1] = arguments[_key];
  }
  var lastArgOptions = args[args.length - 1] && _typeof(args[args.length - 1]) === 'object' ? args.pop() : {};
  var _handlePositionalArgs = handlePositionalArgs(segmentKeys, defaults, args, lastArgOptions),
    user = _handlePositionalArgs.user,
    password = _handlePositionalArgs.password,
    script_name = _handlePositionalArgs.script_name,
    relative_url_root = _handlePositionalArgs.relative_url_root,
    _handlePositionalArgs2 = _handlePositionalArgs.params,
    params = _handlePositionalArgs2 === void 0 ? {} : _handlePositionalArgs2,
    options = _objectWithoutProperties(_handlePositionalArgs, ["user", "password", "script_name", "relative_url_root", "params"]);
  if (parent && !script_name) {
    var parentOptions = {};
    var parentSegmentKeys = flattenSegmentKeys(parent);
    for (var _i = 0, _Object$entries = Object.entries(options); _i < _Object$entries.length; _i++) {
      var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
        key = _Object$entries$_i[0],
        value = _Object$entries$_i[1];
      if (parentSegmentKeys.includes(key)) {
        parentOptions[key] = value;
        delete options[key];
      }
    }
    script_name = pathFor(parent, parentOptions);
  }
  Object.keys(options).forEach(function (option) {
    if (unsupportedOptions.includes(option)) {
      throw new Error("Unsupported option: ".concat(option));
    }
  });
  var nonReservedOptions = {};
  Object.keys(options).filter(function (option) {
    return !reservedOptions.includes(option);
  }).forEach(function (option) {
    nonReservedOptions[option] = options[option];
  });
  var queryParams = {};
  Object.keys(nonReservedOptions).filter(function (option) {
    return !segmentKeys.includes(option) && !defaults.hasOwnProperty(option);
  }).forEach(function (option) {
    queryParams[option] = nonReservedOptions[option];
  });
  Object.assign(queryParams, params);
  Object.assign(options, {
    user: user,
    password: password,
    script_name: script_name || relative_url_root,
    params: queryParams,
    path: generate(spec, nonReservedOptions)
  });
  return options.only_path ? generatePath(options) : generateUrl(options);
};
var pathFor = function pathFor(_ref5) {
  var _ref6 = _slicedToArray(_ref5, 4),
    spec = _ref6[0],
    segmentKeys = _ref6[1],
    defaults = _ref6[2],
    parent = _ref6[3];
  for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
    args[_key2 - 1] = arguments[_key2];
  }
  return urlFor.apply(void 0, [[spec, segmentKeys, _objectSpread2({
    only_path: true
  }, defaults), parent]].concat(args));
};
export { urlFor, pathFor };
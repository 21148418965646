<template>
  <modal-layout :title="note ? 'Edit Activity Note' : 'Add Activity Note'">
    <div class="w-full">
      <textarea v-model="form.note" rows="6" class="input w-full" placeholder="Write a note" />
    </div>
    <template slot="footer">
      <div class="flex items-center">
        <div class="text-sm text-gray-600">
          <svg class="svg-icon svg-icon--sm mr-1">
            <use xlink:href="#icon-info"></use>
          </svg>
          Notes are private and visible only to you
        </div>
        <div class="ml-auto">
          <AppButton class="mr-2" secondary small @click="cancel()">Cancel</AppButton>
          <AppButton primary small @click="submit()">Save</AppButton>
        </div>
      </div>
    </template>
  </modal-layout>
</template>

<script>
export default {
  props: {
    note: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      form: {
        note: this.note
      }
    }
  },

  methods: {
    cancel() {
      this.$emit('close', false)
    },
    submit() {
      this.$emit('close', this.form)
    }
  }
}
</script>

<template>
  <div class="TextAreaInput">
    <textarea class="input mb-px" :class="{ 'isInvalid': error }" :value="value" v-bind="$attrs" v-on="listeners"></textarea>
    <InputErrorMessage :error="error" />
  </div>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    value: {
      type: String,
      required: false
    },

    error: {
      type: Array | String,
      required: false
    }
  },

  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: (e) => this.$emit('input', e.target.value)
      }
    }
  }
}
</script>

<template>
  <div class="xl:w-192 w-full">
    <div class="p-8 mb-6 bg-white border rounded">
      <h1 class="mb-2 text-3xl">How good is my FTP?</h1>
      <p class="text-muted mb-8 text-sm">
        Training with a power meter is without a doubt the best way to structure and improve your cycling performance, and FTP provides and easy way to tailor
        the training to your current level of fitness. But what is a good FTP, and how does it compare to some of the best cyclists in the world?
      </p>

      <div class="FormGroup FormGroup--first">
        <div class="FormGroup__label">
          Your FTP (Functional Threshold Power)
          <InputErrorMessage v-if="errors.ftp" :error="errors.ftp" />
        </div>
        <div class="FormGroup__content md:w-2/5">
          <TextInput v-model="form.ftp" class="w-full" placeholder="e.g. 290" type="number" aria-label="Enter your FTP" />
        </div>
      </div>

      <div class="FormGroup">
        <div class="FormGroup__label">
          Your Weight
          <InputErrorMessage v-if="errors.weight" :error="errors.weight" />
        </div>
        <div class="FormGroup__content md:w-2/5">
          <TextInput
            v-model="form.weight"
            class="w-full mr-2"
            :placeholder="form.weight_units == 'lb' ? 'e.g. 165' : 'e.g. 75'"
            type="number"
            aria-label="Enter your weight"
          />

          <SelectInput
            v-model="form.weight_units"
            value-key="value"
            label-key="label"
            class="md:w-2/3 w-3/5"
            :options="weightUnitOptions"
            aria-label="Select weight units"
          />
        </div>
      </div>

      <div class="FormGroup">
        <div class="FormGroup__label">
          Gender
          <InputErrorMessage v-if="errors.sex" :error="errors.sex" />
        </div>
        <div class="FormGroup__content md:w-3/5 lg:w-2/5">
          <label class="RadioLabel" :class="{ 'RadioLabel--selected': form.sex == 'M' }">
            <input v-model="form.sex" type="radio" value="M" class="hidden" />
            Male
          </label>

          <label class="RadioLabel" :class="{ 'RadioLabel--selected': form.sex == 'F' }">
            <input v-model="form.sex" type="radio" value="F" class="hidden" />
            Female
          </label>
        </div>
      </div>

      <AppButton class="mt-4" primary small @click="calculate()">Calculate</AppButton>
    </div>

    <div v-if="result">
      <div class="p-6 pb-0 mt-6 bg-white border rounded">
        <h2 class="mb-2 text-4xl font-bold text-center">
          {{ result.currentIndex >= 0 ? result.ranges[result.currentIndex].title : 'Your FTP' }}
        </h2>
        <p class="text-muted max-w-xs mx-auto mb-4 text-center">The result is based on your power to weight ratio of {{ result.wkg }} W/kg</p>

        <div
          v-for="(category, index) in result.ranges"
          :key="index"
          class="flex items-center p-4 px-6 -mx-6 border-b"
          :class="category.current ? 'bg-orange-100' : ''"
        >
          <div class="">
            <div class="font-bold">{{ category.title }}</div>
            <div class="text-muted text-sm">{{ category.subtitle }}</div>
          </div>
          <div v-if="category.current">
            <div class="bg-green-600 text-white font-medium mr-2 px-2.5 py-0.5 rounded ml-8">
              <span class="md:inline hidden">Your result</span>
              <span class="md:hidden">You</span>
            </div>
          </div>
          <div class="ml-auto">{{ category.from | format('decimal') }} – {{ category.to | format('decimal') }} W/kg</div>
        </div>

        <div v-if="result.howToImprove && result.howToImprove.improvementInPercent >= 0" class="flex items-center p-4 px-6 -mx-6 space-x-2 bg-orange-100">
          <svg class="svg-icon svg-icon--sm text-primary flex-shrink-0">
            <use xlink:href="#icon-info"></use>
          </svg>
          <div>
            Improving your FTP by {{ result.howToImprove.improvementInPercent | format('percent') }} to
            {{ result.howToImprove.nextCategoryFTP | format('numeric') }} will get you to {{ result.howToImprove.nextCategory }} category.
          </div>
        </div>
      </div>
    </div>

    <div class="md:flex-row flex flex-col p-8 my-4 space-x-8 space-y-4 overflow-hidden bg-white border">
      <div class="max-w-md">
        <h2 class="mb-2 text-3xl font-bold leading-snug">
          Improve your FTP
          <br />
          in less than 8 weeks!
        </h2>
        <p>
          Our AI-powered tools are designed to help you become a stronger and faster cyclist. Track your progress, analyze your data, and get personalized
          training load to take your cycling to the next level.
        </p>
        <AppButton class="block mt-8" primary small>
          <router-link :to="{ name: 'join' }" title="Create a free account">Sign up for FREE</router-link>
        </AppButton>
      </div>
      <div class="max-w-md" style="margin-right: -6rem">
        <img :src="require('images/features/training-load-sm.jpg') | imageUrl('w_640')" alt="Cycling analytics dashboard" />
      </div>
    </div>
  </div>
</template>

<script>
import ValidationService, { isRequired, isMin, isMax } from 'services/ValidationService'
import { getElitePowerProfiles } from 'utils/powerCurve'

export default {
  data() {
    return {
      form: {
        weight_units: 'lb'
      },
      errors: {},
      result: undefined,
      weightUnitOptions: [
        {
          value: 'kg',
          label: 'Kg'
        },
        {
          value: 'lb',
          label: 'Lb'
        }
      ],
      categories: [
        ['Novice', 'Non-racer'],
        ['Fair', 'Cat 5'],
        ['Moderate', 'Cat 4'],
        ['Good', 'Cat 3'],
        ['Very Good', 'Cat 2'],
        ['Excellent', 'Cat 1'],
        ['Exceptional', 'Domestic Pro'],
        ['World Class', 'International Pro']
      ]
    }
  },

  methods: {
    async calculate() {
      this.errors = {}

      const rules = {
        ftp: [isRequired(), isMin(1), isMax(999)],
        weight: [isRequired(), isMin(1), isMax(400)],
        sex: [isRequired()]
      }

      const result = await ValidationService.validate(this.form, rules)

      if (!result.isValid) {
        this.result = undefined
        this.errors = result.errors
      } else {
        const ftp = this.form.ftp
        const weightInKilograms = this.form.weight_units == 'lb' ? (1 / 2.2046226218) * this.form.weight : this.form.weight
        const wkg = parseFloat(ftp / weightInKilograms).toFixed(2)

        const eliteProfiles = getElitePowerProfiles(this.form.sex)
        const FTPDataset = eliteProfiles[3]

        const min = FTPDataset[FTPDataset.length - 1]
        const max = FTPDataset[0]
        const step = (max - min) / 8
        const ranges = []

        // Calculate power profile category
        for (var i = 0; i < 8; i++) {
          const from = (min + i * step).toFixed(2)
          const to = (min + step + i * step).toFixed(2)
          const current = wkg >= from && wkg < to
          ranges.push({
            from,
            to,
            current,
            title: this.categories[i][0],
            subtitle: this.categories[i][1]
          })
        }

        const currentIndex = ranges.findIndex(({ current }) => current)

        let howToImprove = undefined

        if (currentIndex && currentIndex < ranges.length - 1) {
          const nextCategory = ranges[currentIndex + 1].subtitle
          const nextCategoryWkg = ranges[currentIndex + 1].from
          const nextCategoryFTP = nextCategoryWkg * weightInKilograms
          const improvementInPercent = (nextCategoryFTP - ftp) / ftp

          howToImprove = {
            nextCategory,
            nextCategoryWkg,
            nextCategoryFTP,
            improvementInPercent
          }
        }

        this.result = {
          ftp,
          wkg,
          ranges,
          currentIndex,
          howToImprove
        }
      }
    }
  },

  metaInfo() {
    return {
      title: `How good is my FTP?`,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'What is a good FTP, and how does your FTP compare to some of the best cyclists in the world?'
        }
      ]
    }
  }
}
</script>

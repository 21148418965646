<template>
  <div class="OnboardingStep">
    <div class="OnboardingStep__header">
      <h2>Heart Rate Zones</h2>
      <div>Step 4 / 5</div>
    </div>

    <div class="FormGroup FormGroup--last -mt-4">
      <ZonesTable :value="form.zones" @input="update" />
    </div>

    <InputErrorMessage v-if="errors.zones" class="mb-4" :error="errors.zones" />
  </div>
</template>

<script>
import ZonesTable from 'components/ZonesTable'

export default {
  components: {
    ZonesTable
  },

  props: {
    form: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      required: false
    }
  },

  methods: {
    update(zones) {
      this.$emit('update', {
        ...this.form,
        zones: zones
      })
    }
  }
}
</script>

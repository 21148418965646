<template>
  <StatsSection title="Power Curve">
    <chart ref="chart" :options="chartData" :callback="setChart"></chart>
    <template #header>
      <div class="flex items-center space-x-3">
        <div class="text-muted text-xs font-bold uppercase">Compare to</div>
        <Dropdown right>
          <template #selected>
            <div class="text-muted flex items-center justify-between w-auto px-3 py-1 font-medium">
              <div>
                {{ compareToRange.title }}
              </div>
              <svg class="svg-icon">
                <use xlink:href="#icon-caret-down"></use>
              </svg>
            </div>
          </template>

          <template #options>
            <div class="w-auto">
              <a
                v-for="(option, index) in compareToRangeOptions"
                :key="index"
                href
                class="Dropdown__item"
                :class="{ 'font-bold': compareTo === option.id }"
                @click.prevent="compareTo = option.id"
              >
                {{ option.title }}
              </a>
            </div>
          </template>
        </Dropdown>

        <Dropdown right>
          <template #selected>
            <div class="text-muted flex items-center justify-between w-auto px-3 py-1 font-medium">
              <div>
                {{ mode.symbol }}
              </div>
              <svg class="svg-icon">
                <use xlink:href="#icon-caret-down"></use>
              </svg>
            </div>
          </template>

          <template #options>
            <div class="w-32">
              <a
                v-for="(option, index) in chartModes"
                :key="index"
                href
                class="Dropdown__item"
                :class="{ 'font-bold': mode.id === option.id }"
                @click.prevent="mode = option"
              >
                {{ option.name }}
              </a>
            </div>
          </template>
        </Dropdown>
      </div>
    </template>
  </StatsSection>
</template>

<script>
import * as chartConfig from 'constants/chartConfig'

import { mapGetters } from 'vuex'

import formatUnits from 'filters/formatUnits'
import date from 'filters/date'
import truncate from 'filters/truncate'
import { dateIsBetween } from 'utils/date'
import { formatSeconds } from 'utils/time'
import { getPowerCurve, getTickPositions } from 'utils/powerCurve'

import StatsSection from 'components/StatsSection'

const chartModes = [
  {
    id: 'watts',
    name: 'Watts',
    symbol: 'W',
    format: 'power'
  },
  {
    id: 'wattsperkilo',
    name: 'W/kg',
    symbol: 'W/kg',
    format: 'power_per_kg'
  },
  {
    id: 'relative',
    name: 'Relative',
    symbol: '%',
    format: 'percent'
  }
]

export default {
  components: {
    StatsSection
  },

  props: {
    data: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    dateRange: {
      type: Object,
      required: false
    },
    activityId: {
      type: Number,
      required: false
    },
    weight: {
      type: Number | String,
      required: false,
      default: undefined
    },
    height: {
      type: Number,
      required: false,
      default: 400
    },
    format: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      mode: chartModes[0],
      chartModes: chartModes,
      chart: undefined,
      drag: false,
      compareTo: 'all'
    }
  },

  computed: {
    ...mapGetters('profile', ['dateRangeOptions']),

    chartUnits() {
      return formatUnits(this.mode.format)
    },

    compareAllowed() {
      return this.activityId || this.dateRange.id !== this.compareToRange.id
    },

    compareToRangeOptions() {
      let defaultOptions
      if (this.activityId) {
        defaultOptions = ['all', 'ytd', 'last_year', '30d', '90d', '365d']
      } else {
        defaultOptions = ['all', 'ytd', 'last_year']
      }
      const options = [...this.dateRangeOptions].filter(({ id }) => defaultOptions.includes(id))

      if (!this.dateRange) {
        return options
      }

      if (this.dateRange.days) {
        options.push({
          id: 'previous',
          title: `Previous ${this.dateRange.days} days`,
          longTitle: `Previous ${this.dateRange.days} days`,
          from: this.dateRange.compareTo.from,
          to: this.dateRange.compareTo.to
        })
      }

      return options
    },

    compareToRange() {
      return this.compareToRangeOptions.find(({ id }) => id === this.compareTo)
    },

    currentData() {
      return getPowerCurve(this.currentActivities, this.weight)
    },

    compareToData() {
      const powerCurve = getPowerCurve(this.compareToActivities, this.weight)
      return powerCurve.slice(0, this.currentData.length)
    },

    compareToActivities() {
      return this.data.filter((a) => a.power_curve && dateIsBetween(a.start_date_local, this.compareToRange.from, this.compareToRange.to))
    },

    currentActivities() {
      if (this.activityId) {
        return this.data.filter((a) => a.power_curve).filter(({ id }) => id === this.activityId)
      } else {
        return this.data.filter((a) => a.power_curve).filter(({ start_date_local }) => dateIsBetween(start_date_local, this.dateRange.from, this.dateRange.to))
      }
    },

    getSeries() {
      let series = []

      if (this.mode.id === 'relative') {
        const seriesData = []

        this.compareToData.forEach((value, index) => {
          const relativeValue = this.currentData[index] ? Math.round(100 * (this.currentData[index].y / value.y)) : null

          seriesData.push({
            ...value,
            s: this.currentData[index] ? this.currentData[index].s : null,
            d: this.currentData[index] ? this.currentData[index].d : null,
            w: this.currentData[index] ? this.currentData[index].y : null,
            y: relativeValue
          })
        })

        if (this.currentActivities.length) {
          series.push({
            data: seriesData,
            name: this.currentTitle,
            fillcolor: this.compareAllowed ? 'transparent' : '#e6f0f5',
            color: '#ff5933',
            borderColor: '#ff5933'
          })
        }

        const allTimeData = this.compareToData.map((item) => {
          return {
            ...item,
            y: 100
          }
        })
        series.unshift({
          data: allTimeData,
          type: 'area',
          name: this.compareToRange.title,
          fillColor: '#e6f0f5',
          opacity: 0.5,
          color: '#e6f0f5',
          borderColor: '#e6f0f5'
        })
      } else if (this.mode.id === 'wattsperkilo') {
        if (this.currentActivities.length) {
          series.push({
            data: this.currentData.map((i) => {
              return { ...i, y: i.wkg }
            }),
            name: this.currentTitle,
            fillcolor: this.compareAllowed ? 'transparent' : '#e6f0f5',
            color: '#ff5933',
            borderColor: '#ff5933'
          })
        }

        // Build comparative series
        if (this.compareAllowed) {
          series.unshift({
            data: this.compareToData.map((i) => {
              return { ...i, y: i.wkg }
            }),
            type: 'area',
            name: this.compareToRange.title,
            fillColor: '#e6f0f5',
            opacity: 0.5,
            color: '#e6f0f5',
            borderColor: '#e6f0f5'
          })
        }
      } else {
        if (this.currentActivities.length) {
          series.push({
            data: this.currentData,
            name: this.currentTitle,
            fillcolor: this.compareAllowed ? 'transparent' : '#e6f0f5',
            color: '#ff5933',
            borderColor: '#ff5933'
          })
        }

        // Build comparative series
        if (this.compareAllowed) {
          series.unshift({
            data: this.compareToData,
            type: 'area',
            name: this.compareToRange.title,
            fillColor: '#e6f0f5',
            opacity: 0.5,
            color: '#e6f0f5',
            borderColor: '#e6f0f5'
          })
        }
      }

      return series
    },

    currentTitle() {
      if (this.activityId) {
        return 'This ride'
      } else {
        return this.dateRange.title
      }
    },

    chartData() {
      // Build All-Time series
      const series = this.getSeries

      const categories = this.compareToData.map(({ name }) => name)
      const tickPositions = getTickPositions(this.compareToData)

      const singleActivity = this.activityId

      let units = 'W'
      if (this.mode.id === 'wattsperkilo') {
        units = 'W/kg'
      } else if (this.mode.id === 'relative') {
        units = '%'
      }

      return {
        ...chartConfig.defaultOptions,

        chart: {
          ...chartConfig.chart,
          height: this.$isMobile() ? this.height / 2 : this.height,
          marginLeft: 75,
          type: 'line',
          zoomType: 'x'
        },
        exporting: {
          ...chartConfig.exporting,
          enabled: !this.$isMobile()
        },
        tooltip: {
          ...chartConfig.withTooltip,
          valueDecimals: this.mode.id === 'wattsperkilo' ? 2 : 0,
          positioner: function (labelWidth, labelHeight, point) {
            var leftThird = point.plotX < this.chart.plotWidth / 3
            return {
              x: leftThird ? point.plotX + this.chart.plotLeft + 20 : this.chart.plotLeft + point.plotX - (labelWidth + 20),
              y: units === '%' ? 164 : 32
            }
          },
          formatter: function () {
            let str = `<div class="w-56 mb-1 -mt-2 text-base font-bold">${formatSeconds(this.x)} Power</div>`
            this.points.forEach((point, index) => {
              str += `<div class="flex items-center py-1 text-sm border-t">
                <div class="flex items-center">
                  <svg height="14" width="14"><circle cx="8" cy="8" r="4" fill="${point.series.userOptions.borderColor}" /></svg>
                  <span class="ml-1">${point.series.name}</span>
                  <span class="text-muted ml-1 mr-6">${
                    singleActivity && index == 1 ? '' : `(${truncate(point.point.options.s, 32)}, ${date(point.point.options.d)})`
                  }</span>
                </div>
                <div class="ml-auto">
                  ${units === '%' ? point.point.options.w : point.y} ${units === '%' ? 'W' : units}
                </div>
              </div>`
            })
            if (this.points[0] && this.points.length > 1) {
              let diff = this.points[this.points.length - 1].y - this.points[0].y
              if (units === 'W/kg') {
                diff = diff.toFixed(2)
              }
              str += `<div class="flex items-center text-sm bg-gray-100 border-t" style="padding: 6px 16px; margin: 4px -16px -16px -15px;">
                <div>
                  ${this.points[this.points.length - 1].series.name} vs ${this.points[0].series.name}
                </div>
                <div class="ml-auto">
                  ${diff > 0 ? '+' : ''}${diff} ${units}
                </div>
              </div>`
            }

            return str
          }
        },

        xAxis: {
          crosshair: {
            width: 1,
            color: '#000',
            enabled: true,
            zIndex: 99,
            snap: false
          },
          plotLines: tickPositions.map((k) => {
            return {
              color: '#eee',
              width: 1,
              value: k
            }
          }),
          labels: {
            formatter: function () {
              return formatSeconds(this.value)
            }
          },
          categories: categories,
          tickPositions: tickPositions,
          margin: 0,
          padding: 0
        },
        yAxis: [
          {
            title: {
              enabled: false
            },
            labels: {
              format: this.chartUnits ? `{value} ${this.chartUnits}` : '{value}'
            }
          }
        ],
        plotOptions: {
          series: {
            marker: {
              symbol: 'circle',
              enabled: false
            }
          },

          area: {
            lineWidth: 3,
            shadow: false,
            states: {
              hover: {
                enabled: false
              }
            }
          },

          line: {
            lineWidth: 3,
            shadow: false,
            lineOpacity: 0.5,
            fillOpacity: 0.15,
            states: {
              hover: {
                halo: {
                  size: 0
                }
              },
              inactive: {
                opacity: 1
              }
            }
          }
        },
        series: series
      }
    }
  },

  mounted() {
    this.$el.addEventListener('mousemove', () => (this.drag = true))
    this.$el.addEventListener('mousedown', () => (this.drag = false))
    this.$el.addEventListener('mouseup', () => !this.drag && this.resetZoom())
  },

  methods: {
    setChart(chart) {
      this.chart = chart
    },
    resetZoom() {
      this.chart.xAxis[0].setExtremes && this.chart.xAxis[0].setExtremes(null, null, false)
      this.chart.redraw()
    }
  }
}
</script>

<template>
  <div class="ActivityKeyboardShortcuts flex items-center justify-start text-xs text-center text-gray-500">
    Use
    <span class="flex items-center p-1 ml-2 mr-1 border rounded">
      <svg class="svg-icon svg-icon--xs transform rotate-180">
        <use xlink:href="#icon-arrow-right"></use>
      </svg>
    </span>
    <span class="flex items-center p-1 mr-2 border rounded">
      <svg class="svg-icon svg-icon--xs">
        <use xlink:href="#icon-arrow-right"></use>
      </svg>
    </span>
    to move to previous / next activity
  </div>
</template>

<script>
export default {
  props: {
    activity: {
      type: Object,
      required: true
    }
  },

  created() {
    window.addEventListener('keydown', this.keyPressedListener)
  },

  destroyed() {
    window.removeEventListener('keydown', this.keyPressedListener)
  },

  methods: {
    keyPressedListener(event) {
      if (event.metaKey || event.shiftKey || event.altKey || event.ctrlKey) {
        return
      }

      if (event.target && (/textarea|select/i.test(event.target.nodeName) || event.target.type === 'text')) {
        return
      }

      if ([37, 39].includes(event.keyCode)) {
        let routeId

        if (event.keyCode === 37) {
          // left arrow (previous activity)
          routeId = this.activity.previous_activity_id
        } else if (event.keyCode === 39) {
          // right arrow (next activity)
          routeId = this.activity.next_activity_id
        }

        if (routeId) {
          this.$router.push({ name: this.$route.name, params: { id: routeId } })
        }
      }
    }
  }
}
</script>

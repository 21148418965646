<template>
  <div class="Home">
    <Header />

    <div class="Hero">
      <LoadingIcon v-if="isLoading" class="m-auto">Authorizing&hellip;</LoadingIcon>
      <div v-if="error">
        <h1>Ooops, an error occured.</h1>
        <p class="text-red text-xl">{{ error }}</p>

        <router-link :to="{ name: 'signIn' }">
          <AppButton primary class="w-full mt-8">Request a new sign-in link</AppButton>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileService from 'services/ProfileService'
import Header from 'components/Header'

export default {
  components: {
    Header
  },

  props: {
    token: {
      type: String,
      required: true
    },
    mobile: {
      type: Boolean,
      required: false
    },
    redirect: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      isLoading: true,
      error: undefined
    }
  },

  async mounted() {
    this.error = undefined

    if (this.mobile && this.redirect) {
      return window.location.replace(`${this.redirect}&token=${this.token}`)
    }

    try {
      await ProfileService.validateSignInLink(this.token)
      const { href } = this.$router.resolve({ name: 'dashboard' })
      window.location = window.location.origin + href
    } catch (e) {
      const { message } = (e.response && e.response.data) || {}

      this.error = message || 'Unknown error'
    } finally {
      this.isLoading = false
    }
  }
}
</script>

<style lang="postcss" scoped>
.Hero {
  @apply flex items-center justify-center w-full;
  min-height: 80vh;
}
</style>

<template>
  <div>
    <h2 class="md:text-5xl md:-mt-12 mb-16 text-4xl font-bold leading-tight text-center">
      {{ title }}
    </h2>

    <div class="flex flex-wrap">
      <div v-for="(feature, index) in features" :key="index" class="md:w-1/2 lg:w-1/3 flex w-full mb-8">
        <div class="flex-shrink-0 w-16 text-center">
          <svg class="svg-icon text-primary">
            <use :xlink:href="`#icon-${feature.icon}`"></use>
          </svg>
        </div>
        <div class="w-auto pr-6">
          <h4 class="mb-2 text-lg font-bold">{{ feature.title }}</h4>
          <p class="text-sm">
            {{ feature.description }}
          </p>
        </div>
      </div>
    </div>

    <div class="my-6 font-bold text-center">
      <router-link class="inline-block py-1" :to="{ name: 'training-glossary' }" title="Cycling and Training Glossary">& more&hellip;</router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
      default: 'Features & Metrics'
    }
  },

  data() {
    return {
      features: [
        {
          title: 'Form & Fitness',
          icon: 'arrow-up-right',
          description: 'Keep track of your fitness, fatigue and your current form. Form prediction helps you to plan ahead for your next event or a race.'
        },
        {
          title: 'Weekly Training Status',
          icon: 'calendar',
          description: 'See your progress week by week – Training status will evaluate your weekly progress and show you how productive was your training.'
        },
        {
          title: 'Training Stress Score®',
          icon: 'progress',
          description: 'Training Stress Score is an estimate of overal training load for a given training session, calculated from its duration and intensity.'
        },
        {
          title: 'Normalized Power®',
          icon: 'normalized-power',
          description: 'Normalized Power smooths out the variability in the power of your ride caused by different factors (e.g. terrain, wind, grade, etc.)'
        },
        {
          title: 'Intensity Factor®',
          icon: 'intensity',
          description: 'Use Intensity Factor to easily see how hard was a ride compared to your overall fitness.'
        },
        {
          title: 'Variability Index',
          icon: 'variability-index',
          description: 'Variability Index shows how evenly paced was your power output during your ride or race.'
        },
        {
          title: 'Power Analysis',
          icon: 'power',
          description: 'Explore your power curve and keep track of your records - see how your maximum power change over time.'
        },
        {
          title: 'Zone Analysis',
          icon: 'zone-analysis',
          description: 'Analyze the time you spend in each heart rate or power zone.'
        },
        {
          title: 'Activity Analysis',
          icon: 'medal',
          description: 'Easily compare the characteristics of each ride with other rides from the same period.'
        }
      ]
    }
  }
}
</script>

<style lang="postcss" scoped></style>

<template>
  <div class="Faq">
    <h2 class="mb-8 text-3xl font-bold leading-tight">Frequently Asked Questions</h2>

    <div v-for="(faq, index) in faqs" :key="index" class="Faq__item" :class="{ 'Faq__item--open': faq.isOpen }">
      <h4 class="Faq__title" @click="expand(index)">
        <svg class="Faq__icon svg-icon">
          <use xlink:href="#icon-caret-down"></use>
        </svg>
        {{ faq.title }}
      </h4>
      <p class="Faq__content">
        {{ faq.content }}
      </p>
    </div>
  </div>
</template>

<script>
const faqs = [
  {
    title: "I'm new to cycling – is it for me?",
    content:
      'Athletes of any fitness level can use FormBeat to track their progress and training load. If you are new to cycling, our dashboard will guide you through different metrics and explain why they are important for your training.',
    isOpen: false
  },
  {
    title: 'What equipment do I need to use FormBeat?',
    content:
      'Our analytics and optimal training load prediction works without any sensors. For best results, we recommend using activities with data from heart rate and/or power meter sensors.',
    isOpen: false
  },
  {
    title: "What's the difference between FormBeat and other apps?",
    content:
      'Our mission is to build the most intuitive cycling analytics platform. Even though we love other cycling apps like Strava (we even think their Premium subscription is worth every penny), we are designing analytics centered around training and personal improvement.',
    isOpen: false
  }
]

export default {
  data() {
    return {
      faqs
    }
  },

  methods: {
    expand(index) {
      this.faqs = this.faqs.map((item, i) => {
        return {
          ...item,
          isOpen: i === index
        }
      })
    }
  }
}
</script>

<style lang="postcss" scoped>
.Faq {
  &__item {
    & + & {
      @apply mt-4;
    }
  }

  &__title {
    @apply flex items-center text-lg font-medium cursor-pointer select-none;
  }

  &__icon {
    @apply flex-shrink-0 w-8 m-0 -ml-3 text-lg font-bold;
    transform: rotate(-90deg);
    transition: transform 0.2s;
  }

  &__content {
    @apply hidden pl-5 my-4;
  }

  &__item--open {
    .Faq__content {
      @apply block;
    }

    .Faq__icon {
      transform: rotate(0deg);
    }
  }
}
</style>

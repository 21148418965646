import axios from 'axios'

const element = document.getElementById('config')
const config = element ? JSON.parse(element.innerText) : {}

// Set authenticity_token
if (document.head.querySelector('meta[name=csrf-token]')) {
  axios.defaults.headers.common['X-CSRF-Token'] = document.head.querySelector('meta[name=csrf-token]').content
}

export default {
  ...config
}

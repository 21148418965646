<template>
  <div class="flex">
    <div>
      <Dropdown right>
        <template #selected>
          <div class="md:w-48 flex items-center justify-between w-auto px-4 py-2 font-medium">
            <div>
              {{ dateRange.longTitle }}
            </div>
            <svg class="svg-icon">
              <use xlink:href="#icon-caret-down"></use>
            </svg>
          </div>
        </template>

        <template #options>
          <div>
            <a
              v-for="range in dateRangeOptions"
              :key="range.id"
              href
              class="Dropdown__item"
              :class="{ 'font-bold': range.id === dateRange.id }"
              @click.prevent="setDateRange({ dateRange: range, type: dateRangeType })"
            >
              {{ range.title }}
            </a>
          </div>
        </template>
      </Dropdown>
    </div>

    <div v-if="segmentByEnabled" class="flex ml-4">
      <Dropdown right>
        <template #selected>
          <div class="flex items-center justify-between w-32 px-4 py-2 font-medium">
            <div>By {{ segmentBy }}</div>
            <svg class="svg-icon">
              <use xlink:href="#icon-caret-down"></use>
            </svg>
          </div>
        </template>

        <template #options>
          <div class="w-32">
            <a
              v-for="option in segmentByOptions"
              :key="option"
              href
              class="Dropdown__item"
              :class="{ 'font-bold': option === segmentBy, 'Dropdown__item--disabled': isDisabled(option, dateRange.id) }"
              @click.prevent="setSegmentBy(option)"
            >
              {{ option | capitalize }}
            </a>
          </div>
        </template>
      </Dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    dateRange: {
      type: Object,
      required: true
    },

    segmentBy: {
      type: String,
      required: true
    },

    segmentByEnabled: {
      type: Boolean,
      default: true,
      required: false
    },

    dateRangeType: {
      type: String,
      required: false
    }
  },

  computed: {
    ...mapGetters('profile', ['dateRangeOptions', 'segmentByOptions'])
  },

  methods: {
    ...mapActions('profile', ['setDateRange', 'setSegmentBy']),

    isDisabled(segmentBy, dateRange) {
      if (!this.segmentByEnabled) {
        return false
      }

      let disabled = false

      // Disable day & week granularity
      if (['all'].includes(dateRange) && ['day', 'week'].includes(segmentBy)) {
        disabled = true
      }

      // Disable day granularity
      if (['ytd', 'last_year', '365d'].includes(dateRange) && ['day'].includes(segmentBy)) {
        disabled = true
      }

      // Disable year granularity
      if (['ytd', 'last_year', '365d', '90d'].includes(dateRange) && ['year'].includes(segmentBy)) {
        disabled = true
      }

      // Disable year and month
      if (['30d'].includes(dateRange) && ['year', 'month'].includes(segmentBy)) {
        disabled = true
      }

      // Disable week, month and year
      if (['7d', '14d'].includes(dateRange) && ['week', 'month', 'year'].includes(segmentBy)) {
        disabled = true
      }

      return disabled
    }
  }
}
</script>

<style lang="postcss" scoped>
.DateRangeNav {
  &__item {
    @apply py-1 ml-4 cursor-pointer;

    &--selected {
      @apply border-primary text-primary font-bold border-b-2;
    }
  }
}
</style>

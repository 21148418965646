<template>
  <RideProfileChart :activity="activity" :data="activities" />
</template>

<script>
import RideProfileChart from 'components/charts/RideProfileChart'

export default {
  components: {
    RideProfileChart
  },

  props: {
    activity: {
      type: Object,
      required: true
    },
    activities: {
      type: Array,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    units: {
      type: String,
      required: true
    }
  },

  mounted() {
    if (!this.activity.is_cycling) {
      this.$router.push({ name: 'dashboard.activity', params: { id: this.activity.id } })
    }
  },

  metaInfo() {
    return {
      title: this.activity?.name
    }
  }
}
</script>

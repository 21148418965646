<template>
  <div class="xl:w-192 w-full">
    <div class="p-8 mb-6 bg-white border rounded">
      <h1 class="mb-2 text-3xl">Training Stress Score Calculator</h1>
      <p class="text-muted mb-4 text-sm">
        The Training Stress Score Calculator provides a method for estimating the intensity and the resulting physiological stress of a training session. Enter
        Duration, Normalized Power and your FTP to calculate Training Stress Score of your cycling workout.
      </p>

      <div class="FormGroup">
        <div class="FormGroup__label">
          Duration in Minutes
          <InputErrorMessage v-if="errors.minutes" :error="errors.minutes" />
        </div>
        <div class="FormGroup__content md:w-1/4">
          <TextInput v-model="form.minutes" class="w-full" placeholder="e.g. 120" type="number" aria-label="Enter duration" />
        </div>
      </div>

      <div class="FormGroup">
        <div class="FormGroup__label">
          Normalized Power
          <div class="text-muted text-sm">Enter normalized power of the ride in watts</div>
          <InputErrorMessage v-if="errors.power" :error="errors.power" />
        </div>
        <div class="FormGroup__content md:w-1/4">
          <TextInput v-model="form.power" class="w-full" placeholder="e.g. 220" type="number" aria-label="Enter normalized power" />
        </div>
      </div>

      <div class="FormGroup">
        <div class="FormGroup__label">
          Your FTP
          <div class="text-muted text-sm">Enter your most recent cycling FTP</div>
          <InputErrorMessage v-if="errors.ftp" :error="errors.ftp" />
        </div>
        <div class="FormGroup__content md:w-1/4">
          <TextInput v-model="form.ftp" class="w-full" placeholder="e.g. 300" type="number" aria-label="Enter your FTP" />
        </div>
      </div>

      <AppButton class="mt-4" primary small @click="calculate()">Calculate TSS</AppButton>
    </div>

    <div v-if="result" class="p-6 mt-6 text-center bg-white border rounded">
      <div class="label">Training Stress Score (TSS)</div>
      <div class="-mt-4 text-5xl font-bold">
        {{ result }}
      </div>
    </div>

    <div class="md:flex-row flex flex-col p-8 my-4 space-x-8 space-y-4 overflow-hidden bg-white border">
      <div class="max-w-md">
        <h2 class="mb-2 text-3xl font-bold leading-tight">Training with a power meter?</h2>
        Start tracking your Training Stress Score and get actionable insights from your training. Our AI-powered tools are designed to help you become a
        stronger and faster cyclist in less than 8 weeks.
        <AppButton class="block mt-8" primary small>
          <router-link :to="{ name: 'join' }" title="Create a free account">Sign up for FREE</router-link>
        </AppButton>
      </div>
      <div class="max-w-md" style="margin-right: -6rem">
        <img :src="require('images/features/training-load-sm.jpg') | imageUrl('w_640')" alt="Cycling analytics dashboard" />
      </div>
    </div>
  </div>
</template>

<script>
import ValidationService, { isRequired, isMin, isMax } from 'services/ValidationService'

export default {
  data() {
    return {
      form: {},
      errors: {},
      result: undefined
    }
  },

  methods: {
    async calculate() {
      this.errors = {}

      const rules = {
        minutes: [isRequired(), isMin(1), isMax(5000)],
        power: [isRequired(), isMin(1), isMax(999)],
        ftp: [isRequired(), isMin(1), isMax(999)]
      }

      const result = await ValidationService.validate(this.form, rules)

      if (!result.isValid) {
        this.result = undefined
        this.errors = result.errors
      } else {
        const intensity = this.form.power / this.form.ftp
        this.result = Math.round(((this.form.minutes * 60 * this.form.power * intensity) / (this.form.ftp * 3600)) * 100)
      }
    }
  },

  metaInfo() {
    return {
      title: `TSS Calculator`,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Online Training Stress Score (TSS) calculator to easily get Training Stress Score of any ride'
        }
      ]
    }
  }
}
</script>

<template>
  <div class="bg-gray-100">
    <Header />

    <div class="container">
      <div class="lg:flex-row flex flex-col items-center">
        <div class="lg:w-1/2 min-h-128 w-3/4 pt-16">
          <img src="~/images/features/mobile-app.png" alt="FormBeat cycling analytics app" />
        </div>
        <div class="lg:w-1/2 lg:-mt-16 w-3/4">
          <h1 class="text-5xl leading-tight">Today's Plan Alternative</h1>
          <p class="lg:w-96 text-xl">
            With the recent closure of Today's Plan, we at FormBeat extend a warm welcome to all self-coached athletes looking for an easy-to-use smart training
            platform.
          </p>
          <div class="flex space-x-4">
            <AppButton class="block mt-8" primary small>
              <router-link :to="{ name: 'home' }" title="Create a free account">Sign up for FREE</router-link>
            </AppButton>
          </div>
        </div>
      </div>
    </div>

    <div class="md:py-16 md:pt-32 lg:py-24 py-6 bg-white">
      <FeaturesMetrics title="Explore Features" class="xl:w-256 w-full mx-auto" />
    </div>

    <Testimonials />

    <Footer />
  </div>
</template>

<script>
import Header from 'components/Header'
import Footer from 'components/Footer'
import FeaturesMetrics from 'components/FeaturesMetrics'

export default {
  components: {
    Header,
    Footer,
    FeaturesMetrics
  },
  metaInfo() {
    return {
      title: "Today's Plan Alternative",
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content:
            "With the recent closure of Today's Plan, we at FormBeat extend a warm welcome to all self-coached athletes looking for an easy-to-use smart training platform."
        }
      ]
    }
  }
}
</script>

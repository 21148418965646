<template>
  <div>
    <Header />

    <div class="md:hidden p-6 pb-0">
      <Dropdown class="w-full">
        <template #selected>
          <div class="flex items-center justify-between w-full px-4 py-2 font-medium">
            <div>Training Tools</div>
            <svg class="svg-icon">
              <use xlink:href="#icon-caret-down"></use>
            </svg>
          </div>
        </template>

        <template #options>
          <div>
            <router-link :to="{ name: 'tools.ftp' }" class="Dropdown__item" title="Cycling FTP Power Calculator">FTP calculator</router-link>

            <router-link :to="{ name: 'tools.tss' }" class="Dropdown__item" title="Cycling TSS Calculator">TSS calculator</router-link>

            <router-link :to="{ name: 'tools.variabilityIndex' }" class="Dropdown__item" title="Cycling Variability Index Calculator">
              Variability Index calculator
            </router-link>

            <router-link :to="{ name: 'tools.intensityFactor' }" class="Dropdown__item" title="Cycling Intensity Factor Calculator">
              Intensity Factor calculator
            </router-link>

            <router-link :to="{ name: 'tools.powerToWeight' }" class="Dropdown__item" title="Power to Weight Calculator">
              Power to Weight calculator
            </router-link>

            <router-link :to="{ name: 'tools.powerZones' }" class="Dropdown__item" title="Cycling Power Training Zones Calculator">
              Cycling Power Zones Calculator
            </router-link>

            <router-link :to="{ name: 'tools.heartRateZones' }" class="Dropdown__item" title="Cycling Heart Rate Zones Calculator">
              Heart Rate Zones Calculator
            </router-link>

            <router-link :to="{ name: 'tools.zone2Training' }" class="Dropdown__item" title="Zone 2 Training">Zone 2 Training</router-link>

            <router-link :to="{ name: 'tools.goodFtp' }" class="Dropdown__item" title="How Good is My FTP">How good is my FTP?</router-link>
            <router-link :to="{ name: 'tools.runningPace' }" class="Dropdown__item" title="Running Pace Calculator">Running Pace Calculator</router-link>
          </div>
        </template>
      </Dropdown>
    </div>

    <div class="Glossary">
      <div class="Glossary__sidebar">
        <h2 class="mb-2 text-3xl font-bold">Training Tools</h2>

        <p class="text-muted mb-8 text-lg">Useful tools to calculate foundational cycling training metrics.</p>

        <nav>
          <router-link :to="{ name: 'tools.ftp' }" class="Glossary__term" title="Cycling FTP Power Calculator">FTP calculator</router-link>

          <router-link :to="{ name: 'tools.tss' }" class="Glossary__term" title="Cycling TSS Calculator">TSS calculator</router-link>

          <router-link :to="{ name: 'tools.variabilityIndex' }" class="Glossary__term" title="Cycling Variability Index Calculator">
            Variability Index Calculator
          </router-link>

          <router-link :to="{ name: 'tools.intensityFactor' }" class="Glossary__term" title="Cycling Intensity Factor Calculator">
            Intensity Factor Calculator
          </router-link>

          <router-link :to="{ name: 'tools.powerToWeight' }" class="Glossary__term" title="Power to Weight Calculator">Power to Weight Calculator</router-link>

          <router-link :to="{ name: 'tools.powerZones' }" class="Glossary__term" title="Cycling Power Training Zones Calculator">
            Cycling Power Zones Calculator
          </router-link>

          <router-link :to="{ name: 'tools.heartRateZones' }" class="Glossary__term" title="Cycling Heart Rate Zones Calculator">
            Heart Rate Zones Calculator
          </router-link>

          <router-link :to="{ name: 'tools.zone2Training' }" class="Glossary__term" title="Zone 2 Training">Zone 2 Training</router-link>

          <router-link :to="{ name: 'tools.goodFtp' }" class="Glossary__term" title="How Good is My FTP">How good is my FTP?</router-link>
          <router-link :to="{ name: 'tools.runningPace' }" class="Glossary__term" title="Running Pace Calculator">Running Pace Calculator</router-link>
        </nav>
      </div>
      <div class="Glossary__content">
        <router-view />

        <div class="md:hidden p-8 my-4 overflow-hidden bg-white border">
          <div>
            <h2 class="mb-4 text-3xl font-bold leading-tight">More Training Tools</h2>
          </div>
          <ul class="ml-4 list-disc">
            <li>
              <router-link :to="{ name: 'tools.ftp' }" class="block py-2 text-lg" title="Cycling FTP Power Calculator">FTP calculator</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'tools.tss' }" class="block py-2 text-lg" title="Cycling TSS Calculator">TSS calculator</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'tools.variabilityIndex' }" class="block py-2 text-lg" title="Cycling Variability Index Calculator">
                Variability Index calculator
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'tools.intensityFactor' }" class="block py-2 text-lg" title="Cycling Intensity Factor Calculator">
                Intensity Factor calculator
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'tools.powerToWeight' }" class="block py-2 text-lg" title="Power to Weight Calculator">
                Power to Weight calculator
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'tools.powerZones' }" class="block py-2 text-lg" title="Cycling Power Training Zones Calculator">
                Cycling Power Zones Calculator
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'tools.heartRateZones' }" class="block py-2 text-lg" title="Cycling Heart Rate Zones Calculator">
                Heart Rate Zones Calculator
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'tools.goodFtp' }" class="block py-2 text-lg" title="How Good is My FTP">How good is my FTP?</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'tools.runningPace' }" class="block py-2 text-lg" title="Running Pace Calculator">Running Pace Calculator</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from 'components/Header'
import Footer from 'components/Footer'

export default {
  components: {
    Header,
    Footer
  },

  metaInfo() {
    return {
      title: `Cycling Analytics Tools and Calculators`,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: ''
        }
      ]
    }
  }
}
</script>

<style>
.articles,
.faqs {
  p,
  ul,
  ol {
    @apply mb-4;
  }
  ul {
    @apply ml-5 list-disc;
  }
  ol {
    @apply ml-5 list-decimal;
  }
  h3 {
    @apply mt-8 mb-2 text-2xl;
  }
}

.articles {
  h2 {
    @apply mt-8 mb-2 text-2xl;
  }
}
</style>

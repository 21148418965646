import config from 'config'

import dayjs from 'dayjs'
import 'dayjs/locale/en-gb'
import 'dayjs/locale/en'

dayjs.locale(config?.user?.beginning_of_week === 'sunday' ? 'en' : 'en-gb')

const pluginIsBetween = require('dayjs/plugin/isBetween')
const pluginIsSameOrAfter = require('dayjs/plugin/isSameOrAfter')
const pluginIsSameOrBefore = require('dayjs/plugin/isSameOrBefore')
const pluginWeekOfYear = require('dayjs/plugin/weekOfYear')
const pluginRelativeTime = require('dayjs/plugin/relativeTime')
const pluginDuration = require('dayjs/plugin/duration')
const pluginUtc = require('dayjs/plugin/utc')
const weekDay = require('dayjs/plugin/weekday')

dayjs.extend(pluginIsBetween)
dayjs.extend(pluginIsSameOrAfter)
dayjs.extend(pluginIsSameOrBefore)
dayjs.extend(pluginWeekOfYear)
dayjs.extend(pluginRelativeTime)
dayjs.extend(pluginDuration)
dayjs.extend(pluginUtc)
dayjs.extend(weekDay)

export default dayjs

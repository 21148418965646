import ahoy from 'ahoy.js'
import config from 'config'

export default {
  trackPageView(path) {
    window.dataLayer = window.dataLayer || []
    const eventData = {
      'event': 'virtualPageView',
      'page': path,
      'location': window.location.origin + path
    }

    // Send page view
    window.dataLayer.push(eventData)
  },

  trackEvent(eventCategory, eventAction = undefined, eventLabel = undefined, eventValue = undefined) {
    window.dataLayer = window.dataLayer || []
    const eventData = {
      'event': 'eventTracking',
      'category': eventCategory,
      'action': eventAction,
      'label': eventLabel,
      'value': eventValue
    }

    // Send custom event
    window.dataLayer.push(eventData)
  },

  ahoyTrackEvent(event, data = {}) {
    ahoy.track(event, data)
  },

  ahoyTrackPageView(title) {
    ahoy.trackView({ title })
  },

  trackConversion() {
    window.dataLayer = window.dataLayer || []
  },

  // ---------------------------------- */
  // Google Optimize
  // ---------------------------------- */

  // Activate Google Optimize Experiment via GTM
  activateExperiment(name) {
    window.dataLayer.push({
      'event': `optimize.activate.${name}`
    })
  },

  getExperimentValue(id) {
    return new Promise((resolve) => {
      window.dataLayer.push('event', 'optimize.callback', {
        name: id,
        callback: (value) => resolve(value)
      })
    })
  },

  // ---------------------------------- */
  // End of Google Optimize
  // ---------------------------------- */

  resetDatalayer() {
    try {
      if (!config.google_tag_manager_id) {
        return
      }

      if (!window.google_tag_manager) {
        return
      }

      if (!window.google_tag_manager[config.google_tag_manager_id]) {
        return
      }

      window.google_tag_manager[config.google_tag_manager_id].dataLayer.reset()
    } catch (e) {
      throw new Error('Failed to reset dataLayer')
    }
  }
}

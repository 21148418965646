import dayjs from 'utils/dayjs'
import { dateIsBetween } from 'utils/date'

export function parseSummary(summary, keys) {
  let parsed = {}

  Object.keys(summary).forEach((key) => {
    parsed[key] = {}

    Object.keys(summary[key]).forEach((date) => {
      parsed[key][date] = null

      if (summary[key][date]) {
        parsed[key][date] = {}

        keys.forEach((attr, index) => {
          parsed[key][date][attr] = summary[key][date][index]
        })
      }
    })
  })

  return parsed
}

export function calculateCurrentSummary(summary, from, to) {
  const filterByDate = (data, from, to, granularity) => {
    switch (granularity) {
      case 'year':
        from = dayjs(from).startOf('year')
        to = dayjs(to).endOf('year')
        break
      case 'month':
        from = dayjs(from).startOf('month')
        to = dayjs(to).endOf('month')
        break
      case 'week':
        from = dayjs(from).startOf('week')
        to = dayjs(to).endOf('week')
        break
      case 'day':
        from = dayjs(from).startOf('day')
        to = dayjs(to).endOf('day')
        break
    }

    return Object.keys(data)
      .filter((key) => {
        return dateIsBetween(key, from, to, granularity, '[]')
      })
      .reduce((obj, key) => {
        obj[key] = data[key]
        return obj
      }, {})
  }

  return {
    year: filterByDate(summary.year, from, to, 'year'),
    month: filterByDate(summary.month, from, to, 'month'),
    week: filterByDate(summary.week, from, to, 'week'),
    day: filterByDate(summary.day, from, to, 'day')
  }
}

export function calculateSplits(splits, chunkBy = 5) {
  let i, j

  const laps = []
  const attributes = ['distance', 'elapsed_time', 'moving_time', 'total_elevation_gain', 'average_speed', 'average_heartrate']

  for (i = 0, j = splits.length; i < j; i += chunkBy) {
    const chunk = splits.slice(i, i + chunkBy)
    let lap = {}

    attributes.forEach((attribute) => {
      lap[attribute] = chunk.reduce((a, b) => a + parseFloat(b[attribute]), 0)
    })

    lap = {
      ...lap,
      lap_index: i / chunkBy + 1,
      split: i / chunkBy + 1,
      distance: lap.distance,
      average_speed: lap.average_speed / chunk.length,
      average_heartrate: lap.average_heartrate / chunk.length
    }

    laps.push(lap)
  }

  return laps
}

<template>
  <div class="ActivitySummary md:mt-0 md:overflow-x-visible flex justify-between flex-1 w-full mt-6 overflow-x-scroll overflow-y-visible">
    <div></div>

    <div class="ActivitySummary__item">
      <div><InfoIcon term="tss" text="TSS" /></div>
      <div class="ActivitySummary__number text-primary">{{ activity.tss | format }}</div>
    </div>

    <div v-if="activity.intensity" class="border-r border-gray-300" style="width: 1px"></div>

    <div v-if="activity.intensity" class="ActivitySummary__item">
      <div><InfoIcon term="intensity" text="Intensity" /></div>
      <div class="ActivitySummary__number">{{ activity.intensity | format('is_percent') }}</div>
    </div>

    <div class="border-r border-gray-300" style="width: 1px"></div>

    <div class="ActivitySummary__item">
      <div>Distance</div>
      <div class="ActivitySummary__number">{{ activity.distance | format('distance') }}</div>
    </div>

    <div class="border-r border-gray-300" style="width: 1px"></div>

    <div class="ActivitySummary__item">
      <div>Moving Time</div>
      <div class="ActivitySummary__number">{{ activity.moving_time | format('duration') }}</div>
    </div>

    <div class="border-r border-gray-300" style="width: 1px"></div>

    <div v-if="activity.is_cycling" key="activity_type" class="ActivitySummary__item">
      <div>Speed</div>
      <div class="ActivitySummary__number">{{ activity.average_speed | format('speed') }}</div>
    </div>

    <div v-else-if="activity.is_running" key="activity_type" class="ActivitySummary__item">
      <div>Pace</div>
      <div class="ActivitySummary__number">{{ activity.average_speed | format('running_pace') }}</div>
    </div>

    <div v-else-if="activity.is_swimming" key="activity_type" class="ActivitySummary__item">
      <div>Pace</div>
      <div class="ActivitySummary__number">{{ activity.average_speed | format('swimming_pace') }}</div>
    </div>

    <div v-if="!activity.is_swimming" class="border-r border-gray-300" style="width: 1px"></div>

    <div v-if="!activity.is_swimming" class="ActivitySummary__item">
      <div>Elevation</div>
      <div class="ActivitySummary__number">{{ activity.total_elevation_gain | format('elevation') }}</div>
    </div>

    <div></div>
  </div>
</template>

<script>
export default {
  props: {
    activity: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="postcss" scoped>
.ActivitySummary {
  &__item {
    @apply whitespace-nowrap w-auto mb-4 mr-6;
    @screen md {
      @apply px-8 mb-0 mr-0;
    }
  }

  &__number {
    @apply text-3xl font-medium;
  }
}
</style>

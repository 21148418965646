<template>
  <div class="OnboardingStep">
    <div class="OnboardingStep__header">
      <h2>Account</h2>
      <div>Step 1 / 5</div>
    </div>

    <div class="FormGroup FormGroup--last">
      <div class="FormGroup__label">
        Your Email
        <InputErrorMessage v-if="errors.email" error="Please enter valid email" />
      </div>
      <div class="FormGroup__content md:w-3/5">
        <TextInput v-model="form.email" class="w-full" placeholder="email@example.com" type="email" autocomplete="email" @change="update()" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      required: false
    }
  },

  methods: {
    update() {
      this.$emit('update', this.form)
    }
  }
}
</script>

import Vue from 'vue'

import date from './date'
import relativeTime from './relativeTime'
import datetime from './datetime'
import dayDate from './dayDate'
import format from './format'
import formatUnits from './formatUnits'
import truncate from './truncate'
import numeric from './numeric'
import currency from './currency'
import capitalize from './capitalize'
import pluralize from './pluralize'
import imageUrl from './imageUrl'

const filters = {
  date,
  datetime,
  dayDate,
  format,
  formatUnits,
  truncate,
  numeric,
  currency,
  capitalize,
  pluralize,
  relativeTime,
  imageUrl
}

Object.entries(filters).forEach(([name, method]) => {
  Vue.filter(name, method)
})

export default filters

import EMAIL_REGEXP from 'constants/emailRegexp'

const ValidationService = {
  _validateAttrValue(data, attr, validator) {
    const value = data[attr]

    if (typeof validator !== 'function') {
      return undefined
    }

    return new Promise((resolve) => {
      validator({ ...validator, value, data, resolve })
    })
  },

  async validate(data, validators) {
    const promises = Object.entries(validators).reduce((promises, [attr, validators]) => {
      const promise = Promise.all(validators.map((validator) => this._validateAttrValue(data, attr, validator))).then((results) => {
        const errors = results.filter(Boolean)
        return errors.length ? { attr, errors } : undefined
      })

      return promises.concat([promise])
    }, [])

    const errors = (await Promise.all(promises)).filter(Boolean).reduce((errorsByAttr, error) => ({ ...errorsByAttr, [error.attr]: error.errors.shift() }), {})

    return {
      isValid: !Object.keys(errors).length,
      errors
    }
  }
}

export default ValidationService

// Validators

export const isRequired = (message) => {
  return ({ value, resolve }) => {
    !value && value !== 0 ? resolve(message || 'This field is required') : resolve()
  }
}

export const isValidEmail = () => {
  return async ({ value, resolve }) => {
    EMAIL_REGEXP.test(value) ? resolve() : resolve({ email: 'Please enter valid email' })
  }
}

export const isMin = (min, message) => {
  return async ({ value, resolve }) => {
    Number(value) < min ? resolve(message || 'Value is too low') : resolve()
  }
}

export const isMax = (max, message) => {
  return async ({ value, resolve }) => {
    Number(value) > max ? resolve(message || 'Value is too high') : resolve()
  }
}

export const maxLength = (max, message) => {
  return async ({ value, resolve }) => {
    String(value).length > max ? resolve(message || `Please enter maximum of ${max} characters`) : resolve()
  }
}

export const minLength = (min, message) => {
  return async ({ value, resolve }) => {
    String(value).length < min ? resolve(message || `Please enter at least ${min} characters`) : resolve()
  }
}

export const isValidHeartRateZones = () => {
  return async ({ value, resolve }) => {
    const zonesCount = value.length

    // Empty power zones
    if (!value.length) {
      return resolve('Please enter heart rate zones')
    }

    // Empty values
    const missingValues = value.some(({ min, max }) => (!min || !max) && min !== 0 && max != -1)
    if (missingValues) {
      return resolve('Please provide all heart rate zones')
    }

    // Last zone maximum is not set
    if (value[value.length - 1].max != '-1') {
      return resolve("Maximum of the last zone can't be set")
    }

    // Proper zone distribution
    const allBreakpoints = value.map(({ min, max }) => [Number(min), Number(max)]).flat()
    const uniqueBreakpoints = allBreakpoints.filter((item, index) => allBreakpoints.indexOf(item) === index)

    // Verify continuity of unique breakpoints
    if (uniqueBreakpoints.length != zonesCount + 1) {
      return resolve('Please validate your heart rate zones settings')
    }

    // Validate zone values are in ascending order
    const validBreakpoints = allBreakpoints.filter((v) => v != -1)
    if (JSON.stringify(validBreakpoints) !== JSON.stringify([...validBreakpoints].sort((a, b) => a - b))) {
      return resolve('Please validate your heart zones settings')
    }

    resolve()
  }
}

<template>
  <intersect root-margin="800px 800px 800px 800px" @enter.once="load">
    <div class="LazyImage" :class="{ isLoading }">
      <div class="LazyImage__content">
        <div class="LazyImage__imageWrapper">
          <img
            v-if="isVisible"
            ref="image"
            class="LazyImage__image"
            :class="{ 'is-cover': fit === 'cover', 'is-contain': fit === 'contain' }"
            :src="src"
            :alt="alt"
          />
        </div>

        <activity-indicator v-if="isLoading && loader" class="LazyImage__loading" />
      </div>
    </div>
  </intersect>
</template>

<script>
import loadImage from 'utils/loadImage'
import Intersect from 'vue-intersect'

const SHOW_LOADING_TIMEOUT = 100

const alreadyLoaded = {}

export default {
  components: {
    Intersect
  },

  props: {
    src: {
      type: String
    },

    fit: {
      type: String,
      validate: (value) => ['cover', 'contain'].includes(value)
    },

    loader: {
      type: Boolean
    },

    alt: {
      type: String
    }
  },

  data() {
    return {
      isVisible: true,
      isLoading: !alreadyLoaded[this.src]
    }
  },

  watch: {
    'src': 'load'
  },

  methods: {
    async load() {
      if (!this.src || alreadyLoaded[this.src]) {
        return
      }

      this.isVisible = false

      const timeout = setTimeout(() => {
        this.isLoading = true
      }, SHOW_LOADING_TIMEOUT)

      try {
        await loadImage(this.src)
        this.isVisible = true
        alreadyLoaded[this.src] = true
      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading = false
        clearTimeout(timeout)
      }
    }
  }
}
</script>

<style>
.LazyImage {
  width: 100%;
}

.LazyImage__content {
  position: relative;
  min-width: 100%;
  min-height: 100%;
}

.LazyImage__imageWrapper {
  opacity: 1;
  transition: opacity 0.25s;

  .LazyImage.isLoading & {
    opacity: 0;
    transition: none;
  }
}

.LazyImage__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &.is-cover {
    object-fit: cover;
  }

  &.is-contain {
    object-fit: contain;
  }
}

.LazyImage__loading {
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
}
</style>

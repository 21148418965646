<template>
  <div>
    <SectionTitle title="Analysis">
      <TimeRangeFilter :segment-by="segmentBy" :date-range="dateRange" class="ml-auto" />
    </SectionTitle>

    <div class="Subnavigation">
      <router-link :to="{ name: 'dashboard.fitness' }" class="Subnavigation__item">Training</router-link>
      <router-link v-if="user.has_powermeter" :to="{ name: 'dashboard.powerProfile' }" class="Subnavigation__item">Power Profile</router-link>
      <router-link v-if="user.has_powermeter" :to="{ name: 'dashboard.power' }" class="Subnavigation__item">Power</router-link>
      <router-link :to="{ name: 'dashboard.heartrate' }" class="Subnavigation__item">Heart Rate</router-link>
      <router-link :to="{ name: 'dashboard.climbing' }" class="Subnavigation__item">Climbing</router-link>
      <router-link :to="{ name: 'dashboard.speed' }" class="Subnavigation__item">Speed</router-link>
      <router-link :to="{ name: 'dashboard.distance' }" class="Subnavigation__item">Distance</router-link>
      <router-link v-if="!user.has_powermeter" :to="{ name: 'dashboard.power' }" class="Subnavigation__item">Power</router-link>
      <router-link :to="{ name: 'dashboard.year-over-year' }" class="Subnavigation__item">Year Over Year</router-link>
    </div>

    <router-view
      :user="user"
      :activities="activities"
      :summary="summary"
      :fitness="fitness"
      :current-summary="currentSummary"
      :segment-by="segmentBy"
      :date-range="dateRange"
      :units="units"
    />
  </div>
</template>

<script>
import SectionTitle from 'components/SectionTitle'
import TimeRangeFilter from 'components/TimeRangeFilter'

export default {
  components: {
    SectionTitle,
    TimeRangeFilter
  },

  props: {
    summary: {
      type: Object,
      required: true
    },
    currentSummary: {
      type: Object,
      required: true
    },
    activities: {
      type: Array,
      required: true
    },
    fitness: {
      type: Array,
      required: true
    },
    segmentBy: {
      type: String,
      required: true
    },
    dateRange: {
      type: Object,
      required: true
    },
    units: {
      type: String,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },

  metaInfo() {
    return {
      title: 'Speed Analysis'
    }
  }
}
</script>

<template>
  <div>
    <PeaksChart title="Peak Heart Rate" :data="currentSummary" :segment-by="segmentBy" attribute="peak_hr" />

    <HeartRateZonesChart
      title="Time in Heart Rate Zones"
      :data="currentSummary"
      :segment-by="segmentBy"
      :attributes="['time_in_hr_zone_1', 'time_in_hr_zone_2', 'time_in_hr_zone_3', 'time_in_hr_zone_4', 'time_in_hr_zone_5']"
    />

    <StatsSection title="Average Heart Rate">
      <Chart title="Average Heart Rate" :data="currentSummary" :segment-by="segmentBy" attribute="computed_average_heartrate" />
    </StatsSection>

    <StatsSection v-if="user.has_powermeter" title="Aerobic Decoupling">
      <Chart title="Aerobic Decoupling" :data="currentSummary" :segment-by="segmentBy" attribute="aerobic_decoupling" format="percent" />
      <template #header>
        <InfoIcon term="aerobic-decoupling" />
      </template>
    </StatsSection>

    <!-- <StatsSection title="Max Heart Rate">
      <Chart title="Max Heart Rate" :data="currentSummary" :segment-by="segmentBy" attribute="computed_max_heartrate" />
    </StatsSection> -->

    <!-- <StatsSection title="Heart Rate Stress Score">
      <Chart title="Max Heart Rate" :data="currentSummary" :segment-by="segmentBy" attribute="hrss" />
      <template #header>
        <InfoIcon term="hrss" />
      </template>
    </StatsSection>

    <StatsSection title="Average Heart Rate Stress Score per Hour">
      <Chart title="Average Heart Rate Stress Score per Hour" :data="currentSummary" :segment-by="segmentBy" attribute="hrss_per_hour" />
    </StatsSection> -->
  </div>
</template>

<script>
import Chart from 'components/charts/Chart'
import PeaksChart from 'components/charts/PeaksChart'
import HeartRateZonesChart from 'components/charts/HeartRateZonesChart'
import StatsSection from 'components/StatsSection'

export default {
  components: {
    Chart,
    PeaksChart,
    StatsSection,
    HeartRateZonesChart
  },

  props: {
    summary: {
      type: Object,
      required: true
    },
    currentSummary: {
      type: Object,
      required: true
    },
    activities: {
      type: Array,
      required: true
    },
    dateRange: {
      type: Object,
      required: true
    },
    segmentBy: {
      type: String,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },

  metaInfo() {
    return {
      title: 'Heart Rate Analysis'
    }
  }
}
</script>

<template>
  <div class="Features">
    <div class="lg:px-0 px-8">
      <h2 class="lg:text-5xl lg:w-128 mx-auto mb-4 text-4xl font-bold leading-tight text-center">Everything You Need to Train Smarter</h2>
      <p class="lead lg:w-128 mx-auto text-center">Unique set of tools designed to help you focus on your training and see your progress.</p>
    </div>

    <div v-for="(item, index) in features">
      <div class="lg:flex-row gap-x-16 xl:gap-x-0 lg:p-24 flex flex-col items-start px-8 py-12" :class="index % 2 != 0 ? 'bg-gray-100' : ''">
        <div class="lg:w-2/3 xl:px-16">
          <img
            :src="item.imageUrl | imageUrl"
            :class="item.shadow ? 'border border-gray-200 shadow-sm rounded' : ''"
            loading="lazy"
            class="w-full"
            :alt="item.title"
          />
        </div>
        <div class="lg:w-96 lg:px-0 w-auto px-8 pt-8 mx-auto" :class="index % 2 != 0 ? 'lg:order-first' : ''">
          <h3 class="md:text-3xl text-2xl">{{ item.title }}</h3>
          <div class="text-muted md:text-lg w-full mx-auto mt-4" v-html="item.description"></div>
        </div>
      </div>
    </div>

    <div class="md:hidden md:mt-24 md:-mb-8 flex justify-center block mt-8 -mb-16 space-x-4">
      <a href="https://apps.apple.com/us/app/formbeat/id1539444595" target="_blank">
        <img src="~/images/icons/download-app-store.svg" alt="Cycling training app for iOS" class="h-12" />
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.formbeat.app" target="_blank">
        <img src="~/images/icons/download-google-play.svg" alt="Cycling training app for Android" class="h-12" />
      </a>
    </div>
  </div>
</template>

<script>
const image1 = require('images/features/training-load.jpg')
const image2 = require('images/features/form-and-fitness.jpg')
const image3 = require('images/features/calendar.jpg')
const image4 = require('images/features/power-profile.jpg')
const image7 = require('images/features/interval-training-analysis.jpg')
const image8 = require('images/features/activity-analysis-detail.jpg')
const image10 = require('images/features/time-based-analysis.jpg')

export default {
  data() {
    return {
      features: [
        {
          title: 'Performance Tracking',
          description:
            'See how your power, heart rate, FTP, or average speed improved over the past weeks, months, or years. Use our training and performance analysis to see your fitness improve over time.',
          imageUrl: image10,
          width: 1596,
          height: 1156
        },
        {
          title: 'Training Calendar',
          description:
            "The calendar view aggregates the most important metrics of your training and makes it easy to compare each week's impact on your form and fitness.",
          imageUrl: image3,
          shadow: true,
          width: 1269,
          height: 676
        },
        {
          title: 'Form & Fitness',
          description:
            'Our Form & Fitness chart tracks your level of fitness and fatigue and predicts your cycling form to help you schedule your workouts and plan for a race.',
          imageUrl: image2,
          shadow: true,
          width: 1280,
          height: 623
        },
        {
          title: 'Activity Analysis',
          description:
            'State-of-the-art activity analysis dives into the details of your workouts. Quickly jump into your power and heart rate peaks and analyze interval workouts.',
          imageUrl: image8,
          shadow: true,
          width: 1302,
          height: 732
        },
        {
          title: 'Power Data Analysis',
          description:
            'Track all power metrics over time – see how your power curve, peak power levels, FTP, and W/kg improve over time and compare to the riders ranging from novice to the world-class, pro road cyclists.',
          imageUrl: image4,
          shadow: true,
          width: 1276,
          height: 679
        },
        {
          title: 'Interval Training',
          description:
            'Use laps & intervals to analyze the structure of your interval training. Interval workouts include an alternating pattern of high-intensity efforts and easier recovery riding.',
          imageUrl: image7,
          shadow: true,
          width: 1302,
          height: 699
        },
        {
          title: 'Weekly Training Load',
          description:
            'Training Load represents the accumulated <a class="underline" href="https://www.formbeat.com/training-glossary/tss">Training Stress Score</a> from your workouts and shows how hard you have trained each week. FormBeat will calculate your optimal training load to help you make steady progress and improve your performance.',
          imageUrl: image1,
          width: 2552,
          height: 1448
        }
      ]
    }
  }
}
</script>

<style lang="postcss" scoped>
.Features {
  @apply w-full mx-auto;

  @screen md {
  }
}
</style>

<template>
  <div ref="counterElement" class="FTPCounter">
    <div class="FTPCounter__Status">New FTP Detected!</div>
    <div class="FTPCounter__Box">
      <div class="FTPCounter__Title">New FTP</div>
      <div class="FTPCounter__Value">{{ displayValue }}</div>
      <div class="FTPCounter__Change">+ {{ changePercentage }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FTPCounter',
  props: {
    startValue: {
      type: Number,
      required: true
    },
    endValue: {
      type: Number,
      required: true
    },
    duration: {
      type: Number,
      default: 1000
    }
  },
  data() {
    return {
      displayValue: this.startValue,
      observer: null,
      hasAnimated: false
    }
  },
  computed: {
    changePercentage() {
      return `${Math.round(((this.endValue - this.startValue) / this.startValue) * 100)}%`
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.setupIntersectionObserver()
    })
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect()
    }
  },
  methods: {
    setupIntersectionObserver() {
      this.observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && !this.hasAnimated) {
            this.animateValue()
            this.hasAnimated = true
          }
        },
        { threshold: 0.1 }
      )
      this.observer.observe(this.$refs.counterElement)
    },
    animateValue() {
      const start = this.startValue
      const end = this.endValue
      const range = end - start
      const increment = range / (this.duration / 16)
      let current = start

      const updateValue = () => {
        current += increment
        if ((increment > 0 && current >= end) || (increment < 0 && current <= end)) {
          this.displayValue = end
        } else {
          this.displayValue = Math.round(current)
          requestAnimationFrame(updateValue)
        }
      }

      requestAnimationFrame(updateValue)
    }
  }
}
</script>

<style lang="postcss" scoped>
.FTPCounter {
  @apply w-full;

  &__Status {
    @apply rounded-xl block px-4 py-1 mx-auto mb-4 text-xs font-bold text-center text-green-800 transition-colors bg-green-200;
  }

  &__Title {
    @apply text-xl font-bold;
  }

  &__Box {
    @apply p-4 text-center bg-white border rounded-lg;
  }

  &__Value {
    @apply py-1 -mt-3 text-5xl font-bold;
    line-height: 1.25;
  }
}
</style>
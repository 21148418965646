<template>
  <div class="OnboardingStep">
    <div class="OnboardingStep__header">
      <h2>Heart Rate</h2>
      <div>Step 3 / 5</div>
    </div>

    <div class="FormGroup">
      <div class="FormGroup__label">
        Maximum Heart Rate
        <InputErrorMessage v-if="errors.max_hr" :error="errors.max_hr" />
      </div>
      <div class="FormGroup__content md:w-1/4">
        <TextInput v-model="form.max_hr" class="w-full" placeholder="e.g. 190" type="number" @change="update()" />
      </div>
    </div>

    <div class="FormGroup">
      <div class="FormGroup__label">
        Resting Heart Rate
        <InputErrorMessage v-if="errors.rest_hr" :error="errors.rest_hr" />
      </div>
      <div class="FormGroup__content md:w-1/4">
        <TextInput v-model="form.rest_hr" class="w-full" placeholder="e.g. 55" type="number" @change="update()" />
      </div>
    </div>

    <div class="FormGroup FormGroup--last">
      <div class="FormGroup__label">
        <div>Lactate Threshold</div>
        <div v-if="estimated_lthr" class="text-muted text-base">Estimated LTHR: {{ estimated_lthr }}</div>
        <InputErrorMessage v-if="errors.lactate_threshold" :error="errors.lactate_threshold" />
      </div>
      <div class="FormGroup__content md:w-1/4">
        <TextInput v-model="form.lactate_threshold" class="w-full" :placeholder="`e.g. ${estimated_lthr || '170'}`" type="number" @change="update()" />
      </div>
    </div>
  </div>
</template>

<script>
import { defaultLTHRKarvonenFactor } from 'constants/heartRate'
export default {
  props: {
    form: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      required: false
    }
  },

  computed: {
    estimated_lthr() {
      if (!this.form.max_hr || !this.form.rest_hr) {
        return
      }

      if (this.form.max_hr < 100 || this.form.rest_hr < 10) {
        return
      }

      return Math.round(Number(this.form.rest_hr) + defaultLTHRKarvonenFactor * (this.form.max_hr - this.form.rest_hr))
    }
  },

  methods: {
    update() {
      this.$emit('update', this.form)
    }
  }
}
</script>

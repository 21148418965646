<template>
  <!-- Profile -->
  <div class="OnboardingStep">
    <div class="OnboardingStep__header">
      <h2>Profile</h2>
      <div>Step 2 / 5</div>
    </div>

    <div class="FormGroup">
      <div class="FormGroup__label">Gender</div>
      <div class="FormGroup__content md:w-3/5 lg:w-2/5">
        <label class="RadioLabel" :class="{ 'RadioLabel--selected': form.sex == 'M' }">
          <input v-model="form.sex" type="radio" value="M" class="hidden" @change="update()" />
          Male
        </label>

        <label class="RadioLabel" :class="{ 'RadioLabel--selected': form.sex == 'F' }">
          <input v-model="form.sex" type="radio" value="F" class="hidden" @change="update()" />
          Female
        </label>
      </div>
    </div>

    <div class="FormGroup Settings--isHidden">
      <div class="FormGroup__label">
        Weight
        <InputErrorMessage v-if="errors.weight" :error="errors.weight" />
      </div>
      <div class="FormGroup__content md:w-3/5 lg:w-2/5 w-full">
        <div class="flex w-full space-x-2">
          <TextInput v-model="form.weight" placeholder="Weight" type="number" class="md:w-1/2 w-3/5" @change="update()" />

          <SelectInput
            v-model="form.weight_units"
            v-bind="$attrs"
            value-key="value"
            label-key="label"
            class="md:w-1/2 w-2/5"
            :options="weightUnitOptions"
            v-on="$listeners"
            @input="updateWeightUnits()"
          />
        </div>
      </div>
    </div>

    <div class="FormGroup">
      <div class="FormGroup__label">
        Units &amp; Measurements
        <InputErrorMessage v-if="errors.measurement_preference" :error="errors.measurement_preference" />
      </div>
      <div class="FormGroup__content md:w-3/5 lg:w-2/5">
        <SelectInput
          v-model="form.measurement_preference"
          value-key="value"
          label-key="label"
          class="w-full"
          :options="measurementsOptions"
          @input="update()"
        />
      </div>
    </div>

    <div class="FormGroup FormGroup--last">
      <div class="FormGroup__label">
        First Day of the Week
        <InputErrorMessage v-if="errors.beginning_of_week" :error="errors.beginning_of_week" />
      </div>
      <div class="FormGroup__content md:w-3/5 lg:w-2/5">
        <SelectInput v-model="form.beginning_of_week" value-key="value" label-key="label" class="w-full" :options="beginningOfWeekOptions" @input="update()" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      required: false
    }
  },

  data() {
    return {
      weightUnitOptions: [
        {
          value: 'kg',
          label: 'Kg'
        },
        {
          value: 'lb',
          label: 'Lb'
        }
      ],
      measurementsOptions: [
        {
          value: 'meters',
          label: 'Kilometers and Kilograms'
        },
        {
          value: 'feet',
          label: 'Miles and Pounds'
        }
      ],
      beginningOfWeekOptions: [
        {
          value: 'monday',
          label: 'Monday'
        },
        {
          value: 'sunday',
          label: 'Sunday'
        }
      ],
      measurementsOptionsChanged: false
    }
  },

  methods: {
    update() {
      this.$emit('update', this.form)
    },

    updateWeightUnits() {
      if (!this.measurementsOptionsChanged) {
        this.form.measurement_preference = this.form.weight_units === 'lb' ? 'feet' : 'meters'
        this.measurementsOptionsChanged = true
      }

      this.update()
    }
  }
}
</script>

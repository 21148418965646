<template>
  <div>
    <div v-if="activity.computed_weighted_power">
      <div class="flex flex-wrap mb-8 -mx-2">
        <div class="md:w-auto md:mb-0 flex-1 w-1/2 px-2 mb-4">
          <StatsCard title="Normalized Power" term="normalized-power" :score="activity.computed_weighted_power" format="power" />
        </div>

        <div class="md:w-auto md:mb-0 flex-1 w-1/2 px-2 mb-4">
          <StatsCard title="Average Power" :score="activity.computed_avg_watts" format="power" />
        </div>

        <div class="md:w-auto flex-1 w-1/2 px-2">
          <StatsCard title="Intensity" term="intensity" :score="activity.intensity" format="is_percent" />
        </div>

        <div class="md:w-auto flex-1 w-1/2 px-2">
          <StatsCard title="Variability Index" term="variability-index" :score="activity.variability_index" format="is_percent" />
        </div>

        <div v-if="activity.efficiency_factor" class="md:block md:w-auto flex-1 hidden w-1/2 px-2">
          <StatsCard title="Efficiency Factor" term="efficiency-factor" :score="activity.efficiency_factor" format="decimal" />
        </div>
      </div>

      <PowerCurveChart title="Power Curve" :data="activities" :activity-id="activity.id" :weight="user.weight_metric" />

      <StatsSection title="Time in Zone – Power" size="small">
        <TimeInZoneChart v-if="user.power_zones.length" :activity="activity" attribute="time_in_power_zone" :zones="user.power_zones" units="%" :height="400" />
        <div v-else class="text-muted p-6 text-center">
          <router-link class="text-link" :to="{ name: 'settings.power' }">Set your FTP</router-link>
          in order see you power zones breakdown.
        </div>
      </StatsSection>
    </div>
  </div>
</template>

<script>
import StatsCard from 'components/StatsCard'
import StatsSection from 'components/StatsSection'
import PowerCurveChart from 'components/charts/PowerCurveChart'
import TimeInZoneChart from 'components/charts/TimeInZoneChart'

export default {
  components: {
    StatsCard,
    StatsSection,
    PowerCurveChart,
    TimeInZoneChart
  },

  props: {
    activity: {
      type: Object,
      required: true
    },
    activities: {
      type: Array,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    units: {
      type: String,
      required: true
    }
  },

  mounted() {
    if (!this.activity.computed_weighted_power) {
      this.$router.push({ name: 'dashboard.activity', params: { id: this.activity.id } })
    }
  },

  metaInfo() {
    return {
      title: this.activity?.name
    }
  }
}
</script>

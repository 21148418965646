// Finds and returns a local maxima of n-values in an array
// Given an array, this function returns an index (a staring position)
// of a subarray where the sum of consecutive n-values is greatest
export function localMaximaArrayIndex(arr, n) {
  if (arr.length < n) return null

  let pos = 0
  let ret = 0
  let temp = 0

  for (let i = 0; i < n; i++) ret += arr[i]

  temp = ret
  for (let i = n; i < arr.length; i++) {
    temp = temp - arr[i - n] + arr[i]
    if (temp > ret) {
      pos = i - n
    }
    ret = Math.max(ret, temp)
  }

  return pos
}

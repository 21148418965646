<template>
  <label class="Checkbox" :class="{ 'isLarge': large }">
    <input class="Checkbox__input" type="checkbox" :checked="value" :disabled="disabled" @change="change" />
    <span class="Checkbox__virtualInput"></span>
    <span v-if="$slots.default" class="Checkbox__label">
      <slot />
    </span>
  </label>
</template>

<script>
export default {
  props: {
    value: Boolean,
    large: Boolean,
    disabled: Boolean
  },

  methods: {
    change(e) {
      this.$emit('input', e.target.checked)
    }
  }
}
</script>

<style>
.Checkbox {
  @apply inline-flex items-center align-middle select-none;
}

.Checkbox__input {
  @apply absolute opacity-0;
}

.Checkbox__virtualInput {
  @apply relative flex items-center justify-center w-5 h-5 transition-colors duration-150 border rounded cursor-pointer;
  top: -1px;

  .Checkbox.isLarge & {
    @apply w-6 h-6;
  }

  .Checkbox__input + &::after {
    @apply block w-4 h-4 m-auto transition-transform duration-150;
    content: '';
    background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E")
      no-repeat center / 62%;
    background-clip: padding-box;
  }

  .Checkbox__input:checked + & {
    @apply bg-link border-link;
  }

  .Checkbox__input:not(:checked) + &::after {
    transform: scale(0);
  }
}

.Checkbox__label {
  @apply inline-block ml-2 cursor-pointer;
}
</style>

import { render, staticRenderFns } from "./StatsCardSummaryFitness.vue?vue&type=template&id=5095dec2&scoped=true&"
import script from "./StatsCardSummaryFitness.vue?vue&type=script&lang=js&"
export * from "./StatsCardSummaryFitness.vue?vue&type=script&lang=js&"
import style0 from "./StatsCardSummaryFitness.vue?vue&type=style&index=0&id=5095dec2&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5095dec2",
  null
  
)

export default component.exports
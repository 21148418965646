<template>
  <div v-if="isVisible" class="flex items-center p-4 px-6 my-6 bg-orange-200 border rounded">
    <div>
      Your Free Trial ends {{ trialEnds }}. Please
      <router-link :to="{ name: 'settings.membership' }">select a plan</router-link>
      to continue using FormBeat.
    </div>
    <div class="ml-auto">
      <router-link :to="{ name: 'settings.membership' }">
        <AppButton primary xsmall class="mr-1">Learn More</AppButton>
      </router-link>
    </div>
  </div>
</template>

<script>
import dayjs from 'utils/dayjs'

export default {
  props: {
    trialEndsAt: {
      type: String,
      required: true
    }
  },

  computed: {
    trialEnds() {
      if (this.remainingDays == 0) {
        return 'today'
      } else if (this.remainingDays == 1) {
        return 'tomorrow'
      } else {
        return `in ${this.remainingDays} days`
      }
    },

    remainingDays() {
      return Math.floor(dayjs(this.trialEndsAt).diff(dayjs(), 'day', true))
    },

    isVisible() {
      return this.remainingDays >= 0 && this.remainingDays < 9
    }
  }
}
</script>

<template>
  <div class="Activities">
    <SectionTitle v-if="activities.length > 0" title="Activities">
      <div class="flex-nowrap flex space-x-2">
        <div v-if="user.authorized_with_strava">
          <Tooltip text="Re-sync your Strava activities">
            <AppButton :disabled="syncRequested" secondary block medium class="whitespace-nowrap" @click="syncStrava">
              {{ syncRequested ? 'Sync Requested' : 'Sync Strava' }}
            </AppButton>
          </Tooltip>
        </div>

        <Dropdown class="md:block outline-0 hidden h-full ml-8 border-0">
          <template #selected>
            <AppButton primary block medium class="whitespace-nowrap">Add activity</AppButton>
          </template>

          <template #options>
            <div class="w-56">
              <router-link :to="{ name: 'dashboard.createActivity' }" title="Add activity" class="Dropdown__item py-3">
                <svg class="svg-icon mr-1">
                  <use xlink:href="#icon-add"></use>
                </svg>
                Add Manual Activity
              </router-link>
              <router-link :to="{ name: 'dashboard.uploadActivity' }" title="Upload activity files" class="Dropdown__item py-3">
                <svg class="svg-icon mr-1">
                  <use xlink:href="#icon-upload"></use>
                </svg>
                Upload Activities
              </router-link>
            </div>
          </template>
        </Dropdown>

        <input v-model="searchQuery" class="input isMedium" type="search" placeholder="Search activities" />
      </div>
    </SectionTitle>

    <vue-good-table
      class="mb-16"
      :columns="columns"
      :rows="activities"
      style-class="vgt-table"
      compact
      :row-style-class="rowStyleClassFn"
      :sort-options="{
        enabled: true,
        initialSortBy: initialSortBy
      }"
      :search-options="{
        enabled: false,
        skipDiacritics: false,
        externalQuery: searchQuery,
        placeholder: 'Search this table'
      }"
      :pagination-options="{
        enabled: true,
        mode: 'records',
        perPage: 50,
        position: 'bottom',
        dropdownAllowAll: false,
        setCurrentPage: currentPage,
        nextLabel: 'Next',
        prevLabel: 'Prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page', // for 'pages' mode
        allLabel: 'All',
        perPageDropdown: [50, 100, 250]
      }"
      @on-row-mouseenter="onRowMouseover"
      @on-row-mouseleave="onRowMouseleave"
      @on-row-click="onRowClick"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
    >
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field == 'start_date_local'">
          <div class="flex items-center w-auto">
            <router-link :to="{ name: 'dashboard.activity', params: { id: props.row.id } }" class="w-24 h-24 ml-1 mr-3 bg-gray-100 rounded-lg">
              <img
                v-if="props.row.map_summary_polyline"
                class="w-full h-full rounded-lg"
                :src="`https://www.formbeat.com/staticmap/?sensor=false&size=200x200&maptype=roadmap&path=weight:4|color:0xff5933FF|enc:${props.row.map_summary_polyline}`"
                loading="lazy"
                alt=""
              />
            </router-link>
            <div class="w-64 ml-2 truncate">
              <router-link :to="{ name: 'dashboard.activity', params: { id: props.row.id } }" class="hover:text-primary text-lg font-bold">
                {{ props.row.name }}
              </router-link>
              <div class="text-sm text-gray-600">
                <span v-if="props.row.manual" class="px-1 mr-1 text-xs font-bold text-gray-800 bg-orange-200 rounded">Manual</span>
                {{ props.row.start_date_local | date('dddd, MMM DD, YYYY, HH:mm') }}
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="props.column.field == 'moving_time'">
          {{ props.row.moving_time | format('duration') }}
        </div>
        <div v-else-if="props.column.field == 'is_cycling'">
          {{ props.row.activity_type }}
          <svg v-if="props.row.is_cycling" class="svg-icon text-gray-600">
            <use xlink:href="#icon-cycling"></use>
          </svg>
          <svg v-else-if="props.row.is_running" class="svg-icon text-gray-600">
            <use xlink:href="#icon-running"></use>
          </svg>
          <svg v-else-if="props.row.is_swimming" class="svg-icon text-gray-600">
            <use xlink:href="#icon-swimming"></use>
          </svg>
        </div>
        <div v-else-if="props.column.field == 'distance'">
          {{ props.row.distance | format('distance') }}
        </div>
        <div v-else-if="props.column.field == 'average_speed'">
          <span v-if="props.row.is_cycling">
            {{ props.row.average_speed | format('speed') }}
          </span>
          <span v-else-if="props.row.is_running">
            {{ props.row.average_speed | format('running_pace') }}
          </span>
          <span v-else-if="props.row.is_swimming">
            {{ props.row.average_speed | format('swimming_pace') }}
          </span>
        </div>
        <div v-else-if="props.column.field == 'total_elevation_gain'">
          <span v-if="props.row.total_elevation_gain && props.row.total_elevation_gain > 0">{{ props.row.total_elevation_gain | format('elevation') }}</span>
          <span v-else>-</span>
        </div>
        <div v-else-if="props.column.field == 'computed_weighted_power'">
          <span v-if="props.row.computed_weighted_power > 0">
            {{ props.row.computed_weighted_power | format('power') }}
          </span>
          <span v-else>-</span>
        </div>
        <div v-else-if="props.column.field == 'tss_per_hour'">
          <div class="w-12 h-1 ml-auto mr-2 bg-red-200">
            <div class="bg-primary h-1" :style="{ width: `${(100 * props.row.tss_per_hour) / maxStressScore}%` }"></div>
          </div>
        </div>
        <div v-else-if="props.column.field == 'tss'">
          <div class="w-auto ml-auto font-bold">
            {{ props.row.tss | format }}
          </div>
        </div>
        <div v-else-if="props.column.field == 'has_activity_records'">
          <span v-if="props.row.has_activity_records">
            <svg class="svg-icon text-primary">
              <use xlink:href="#icon-medal"></use>
            </svg>
          </span>
        </div>
        <div v-else>
          {{ props.row[props.column.field] | format }}
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import bus from 'bus'
import ProfileService from 'services/ProfileService'
import StravaService from 'services/StravaService'

import SectionTitle from 'components/SectionTitle'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

export default {
  components: {
    SectionTitle,
    VueGoodTable
  },

  props: {
    segmentBy: {
      type: String,
      required: true
    },

    user: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      activities: [],
      searchQuery: undefined,
      highlightedRowIndex: undefined,
      maxStressScore: undefined,
      initialSortBy: undefined,
      currentSoryBy: undefined,
      currentPage: 1,
      syncRequested: false,
      columns: [
        {
          label: 'ID',
          field: 'id',
          hidden: true,
          globalSearchDisabled: true
        },
        {
          label: 'Name',
          field: 'name',
          hidden: true
        },
        {
          label: 'Activity',
          field: 'start_date_local',
          globalSearchDisabled: true,
          sortable: true,
          firstSortType: 'desc'
        },
        {
          label: '',
          field: 'has_activity_records',
          type: 'boolean',
          globalSearchDisabled: true
        },
        {
          label: '',
          field: 'is_cycling',
          globalSearchDisabled: true
        },
        {
          label: 'TSS',
          field: 'tss',
          type: 'number',
          globalSearchDisabled: true,
          sortable: true,
          firstSortType: 'desc'
        },
        {
          label: 'Duration',
          field: 'moving_time',
          type: 'number',
          globalSearchDisabled: true,
          sortable: true,
          firstSortType: 'desc'
        },
        {
          label: 'Distance',
          field: 'distance',
          type: 'decimal',
          globalSearchDisabled: true,
          sortable: true,
          firstSortType: 'desc'
        },
        {
          label: 'Speed',
          field: 'average_speed',
          type: 'decimal',
          globalSearchDisabled: true,
          sortable: true,
          firstSortType: 'desc'
        },
        {
          label: 'Elevation',
          field: 'total_elevation_gain',
          type: 'number',
          globalSearchDisabled: true,
          sortable: true,
          firstSortType: 'desc'
        },
        {
          label: 'Normalized Power',
          field: 'computed_weighted_power',
          type: 'number',
          globalSearchDisabled: true,
          sortable: true,
          firstSortType: 'desc'
        },
        {
          label: 'Intensity',
          field: 'tss_per_hour',
          type: 'decimal',
          globalSearchDisabled: true,
          sortable: true,
          firstSortType: 'desc'
        }
      ]
    }
  },

  async mounted() {
    this.$Progress.start()
    this.setAppLoading(true)
    this.activities = await ProfileService.getActivities()
    this.maxStressScore = Math.max.apply(
      null,
      this.activities.map(({ tss_per_hour }) => Number(tss_per_hour))
    )
    this.setAppLoading(false)
    this.$Progress.finish()
    this.initializeTable()
    this.$nextTick(() => bus.$emit('dashboard.activitiesLoaded'))
  },

  methods: {
    ...mapActions('profile', ['setAppLoading']),

    onRowMouseover(params) {
      this.highlightedRowIndex = params.row.originalIndex
    },

    onRowMouseleave() {
      this.highlightedRowIndex = null
    },

    rowStyleClassFn(row) {
      return row.originalIndex === this.highlightedRowIndex ? 'bg-gray-100 cursor-pointer content-visibility-auto' : 'content-visibility-auto'
    },

    onRowClick(params) {
      if (params.event.metaKey) {
        const { href } = this.$router.resolve({ name: 'dashboard.activity', params: { id: params.row.id } })
        window.open(window.location.origin + href)
      } else {
        this.$router.push({ name: 'dashboard.activity', params: { id: params.row.id } })
      }
    },

    onPageChange(params) {
      // params.currentPage - current page that pagination is at
      // params.prevPage - previous page
      // params.currentPerPage - number of items per page
      // params.total - total number of items in the table
      const currentQuery = this.$route.query || {}
      const query =
        params.currentPage === 1 ? { ...currentQuery, page: undefined } : { ...currentQuery, page: (params.currentPage - 1) * params.currentPerPage }
      this.$router.push({ name: 'dashboard.activities', query })

      window.scrollTo(0, 0)
    },

    onSortChange(params) {
      // params[0].sortType - ascending or descending
      // params[0].columnIndex - index of column being sorted
      const currentQuery = this.$route.query || {}
      let query
      if (params) {
        const sortOptionsToUrl = params
          .filter(({ type }) => ['asc', 'desc'].includes(type))
          .map(({ field, type }) => `${field}~${type}`)
          .join(',')

        const sort = sortOptionsToUrl.length ? sortOptionsToUrl : undefined
        query = { ...currentQuery, page: undefined, sort }
      } else {
        query = { ...currentQuery, sort: undefined }
      }

      this.$router.push({ name: 'dashboard.activities', query })
    },

    initializeTable() {
      // Set sorting from URL
      if (this.$route.query.sort) {
        this.initialSortBy = this.$route.query.sort.split(',').map((param) => {
          const [field, type] = param.split('~')
          return { field, type }
        })
      }

      // Set page from URL
      if (this.$route.query.page) {
        this.currentPage = Math.ceil((Number(this.$route.query.page) + 1) / 50)
      }
    },

    syncStrava() {
      this.syncRequested = true
      StravaService.sync()
    }
  },

  metaInfo() {
    return {
      title: 'Activities'
    }
  }
}
</script>

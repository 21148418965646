<template>
  <div class="bg-gray-100">
    <Header />

    <div class="max-w-screen-sm p-8 mx-auto my-32 text-center">
      <LoadingIcon v-if="isLoading" class="mx-auto" />

      <div v-else-if="success">
        <h1 class="heading-1">Unsubscribe Successful</h1>
        You have unsubscribed from FormBeats's activity summary emails. If you change your mind, you can re-subscribe by visiting your account settings.
      </div>

      <div v-else-if="error">
        <h1 class="heading-1">Whoops</h1>
        Your unsubscribe link seems to be invalid.
        <br />
        Please
        <a class="underline" href="mailto:support@formbeat.com">contact support</a>
        to get additional help.
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import ProfileService from 'services/ProfileService'

import Header from 'components/Header'
import Footer from 'components/Footer'

export default {
  components: {
    Header,
    Footer
  },

  props: {
    email: {
      required: true,
      type: String
    },
    id: {
      required: true,
      type: Number
    }
  },

  data() {
    return {
      isLoading: false,
      success: undefined,
      error: undefined
    }
  },

  async mounted() {
    if (this.email && this.id) {
      this.isLoading = true

      try {
        await ProfileService.unsubscribeFromEmails(this.email, this.id)
        this.success = true
      } catch (e) {
        this.error = true
      }

      this.isLoading = false
    } else {
      this.error = true
    }
  },

  metaInfo() {
    return {
      title: 'Unsubscribe',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Update your email settings'
        }
      ]
    }
  }
}
</script>

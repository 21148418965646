<template>
  <div>
    <Header />

    <div class="Auth">
      <div class="Auth__image"></div>
      <div class="Auth__form">
        <!-- Form -->
        <div v-if="!success" class="w-full">
          <h1 class="md:text-4xl mb-3 text-3xl font-extrabold leading-tight">Welcome Back!</h1>
          <p class="mb-6 text-lg">Please enter your e-mail to sign in.</p>

          <TextInput
            v-model="email"
            class="w-full"
            placeholder="email@example.com"
            type="email"
            autocomplete="email"
            @keyup.enter="!isSubmitting && submitEmail()"
          />

          <div v-if="error" class="FormGroup__label my-2">
            <InputErrorMessage :error="error" />
          </div>

          <AppButton primary class="w-full mt-4" :disabled="isSubmitting" @click="submitEmail">Continue</AppButton>

          <p class="pt-6 mt-8 border-t">
            Don't have an account?
            <router-link :to="{ name: 'join' }" class="text-link font-bold">Sign up &raquo;</router-link>
          </p>
        </div>

        <!-- Result -->
        <div v-else class="w-full">
          <h1 class="md:text-4xl mb-3 text-3xl font-extrabold leading-tight">Success</h1>
          <p class="text-lg">Please check your inbox to access your account. We've just emailed you a secure sign-in link.</p>
          <AppButton secondary class="w-full mt-8" @click="success = false">&larr; Go back</AppButton>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import EMAIL_REGEXP from 'constants/emailRegexp'

import ProfileService from 'services/ProfileService'
import Header from 'components/Header'
import Footer from 'components/Footer'

export default {
  components: {
    Header,
    Footer
  },

  data() {
    return {
      error: undefined,
      email: undefined,
      isSubmitting: false,
      success: false
    }
  },

  computed: {
    ...mapGetters('profile', ['user'])
  },

  mounted() {
    if (this.user) {
      this.$router.replace({ name: 'dashboard' })
    }
  },

  methods: {
    async submitEmail() {
      this.error = undefined
      this.isSubmitting = true
      if (this.email && EMAIL_REGEXP.test(this.email)) {
        try {
          await ProfileService.createSignInLink(this.email)
          this.success = true
        } catch (e) {
          const { message } = (e.response && e.response.data) || {}

          this.error = message || 'Unknown error'
        }
      } else {
        this.error = 'Please enter valid email'
      }

      this.isSubmitting = false
    }
  },

  metaInfo() {
    return {
      title: 'Sign-In',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Sign in to FormBeat to access your Dashboard'
        }
      ]
    }
  }
}
</script>

export default function truncate(text = '', length = 64, clamp = '…') {
  const output = text.slice(0, length - clamp.length)
  let last = output.length - 1

  if (text.length <= length) {
    return text
  }

  while (last > 0 && output[last] !== ' ' && output[last] !== clamp[0]) {
    last -= 1
  }

  last = last || length - clamp.length

  return output.slice(0, last) + clamp
}

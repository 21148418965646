export const defaultOptions = {
  legend: {
    enabled: false
  },
  credits: {
    enabled: false
  },
  title: {
    enabled: false,
    text: ''
  },
  tooltip: {
    enabled: false
  }
}

export const withTooltip = {
  backgroundColor: 'white',
  borderColor: '#ccc',
  borderWidth: 1,
  headerFormat: '<span class="text-sm font-bold block mb-2">{point.key}</span>',
  padding: 16,
  shadow: false,
  shared: true,
  style: {
    fontSize: '14px',
    lineHeight: '1.75'
  },
  useHTML: true,
  zIndex: 5,
  xDateFormat: '%b %d %Y'
}

export const chart = {
  animation: false,
  backgroundColor: 'white',
  resetZoomButton: {
    position: {
      x: -9999,
      y: -9999
    }
  },
  style: {
    fontFamily: 'soleil, sans-serif',
    userSelect: 'none'
  }
}

export const exporting = {
  enabled: true,
  sourceWidth: 1280,
  sourceHeight: 720,
  buttons: {
    contextButton: {
      symbolStroke: '#cccccc',
      symbolStrokeWidth: 2,
      symbolFill: '#cccccc',
      height: 18,
      y: 12,
      x: -12
    }
  }
}

export const withLegend = {
  legend: {
    enabled: true,
    backgroundColor: 'white'
  }
}

<template>
  <div>
    <PeaksChart title="Peak Speed" :data="currentSummary" :segment-by="segmentBy" attribute="peak_speed" format="speed" :decimal-places="1" />

    <StatsSection title="Average Speed">
      <Chart title="Avg Speed" :data="currentSummary" :segment-by="segmentBy" attribute="average_speed" format="speed" :decimal-places="1" />
    </StatsSection>

    <StatsSection title="Max speed">
      <Chart title="Max speed" :data="currentSummary" :segment-by="segmentBy" attribute="max_speed" format="speed" :decimal-places="1" />
    </StatsSection>
  </div>
</template>

<script>
import Chart from 'components/charts/Chart'
import PeaksChart from 'components/charts/PeaksChart'
import StatsSection from 'components/StatsSection'

export default {
  components: {
    Chart,
    PeaksChart,
    StatsSection
  },

  props: {
    summary: {
      type: Object,
      required: true
    },
    currentSummary: {
      type: Object,
      required: true
    },
    activities: {
      type: Array,
      required: true
    },
    segmentBy: {
      type: String,
      required: true
    },
    dateRange: {
      type: Object,
      required: true
    },
    units: {
      type: String,
      required: true
    }
  },

  metaInfo() {
    return {
      title: 'Speed Analysis'
    }
  }
}
</script>

<template>
  <div>
    <AllTimeStatsChart :data="summary" :activities="activities" />
    <YearOverYearChart :data="summary" />
  </div>
</template>

<script>
import YearOverYearChart from 'components/charts/YearOverYearChart'
import AllTimeStatsChart from 'components/charts/AllTimeStatsChart'

export default {
  components: {
    YearOverYearChart,
    AllTimeStatsChart
  },

  props: {
    activities: {
      type: Array,
      required: true
    },
    summary: {
      type: Object,
      required: true
    },
    segmentBy: {
      type: String,
      required: true
    }
  },

  data() {
    return {}
  },

  metaInfo() {
    return {
      title: 'Year over Year'
    }
  }
}
</script>

<template>
  <div>
    <div v-if="error">
      {{ error }}
    </div>
    <LoadingIcon v-else />
  </div>
</template>

<script>
import GarminService from 'services/GarminService'
import StravaService from 'services/StravaService'

export default {
  data() {
    return {
      error: null
    }
  },

  async mounted() {
    try {
      // Handle Strava authorization
      if (this.$route.name === 'oauth.success.strava') {
        await StravaService.authorize(this.$route.query.code, this.$route.query.scope)
      }

      // Handle Garmin authorization
      if (this.$route.name === 'oauth.success.garmin') {
        await GarminService.authorize(this.$route.query.oauth_verifier)
      }

      // Redirect to dashbobard
      const { href } = this.$router.resolve({ name: 'dashboard' })
      window.location = window.location.origin + href
    } catch (e) {
      this.error = 'Oops, something wrong happened.'
    }
  }
}
</script>

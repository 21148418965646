import config from 'config'

import dayjs from 'utils/dayjs'
import numeric from './numeric'
import convertUnits from './convertUnits'

export default function format(value, type, convert = false, withUnits = true) {
  let result
  let units

  const unitFormat = config.user?.measurement_preference || 'meters'

  const imperial = unitFormat == 'feet'
  value = convert ? convertUnits(value, type, unitFormat) : value

  let duration

  if (['duration', 'longduration', 'digital_duration'].includes(type)) {
    duration = dayjs.duration(value, 'seconds')
  }

  switch (type) {
    case 'distance':
      result = value > 1000 ? numeric(value, '0,0') : numeric(value, '0,0.0')
      units = imperial ? 'mi' : 'km'
      break
    case 'longdistance':
      result = numeric(value, '0,0')
      units = imperial ? 'mi' : 'km'
      break
    case 'speed':
      result = numeric(value, '0,0.0')
      units = imperial ? 'mi/h' : 'km/h'
      break
    case 'swimming_pace':
      if (value && value > 0) {
        result = dayjs.utc(dayjs.duration(((1 / value) * 60 * 60) / 10, 'seconds').asMilliseconds()).format('m:ss')
      } else {
        result = '0:00'
      }
      units = '/100m'
      break
    case 'running_pace':
      if (value && value > 0) {
        result = dayjs.utc(dayjs.duration((1 / value) * 60 * 60, 'seconds').asMilliseconds()).format('m:ss')
      } else {
        result = '0:00'
      }
      units = '/km'
      break
    case 'elevation':
      result = numeric(value, '0,0')
      units = imperial ? 'ft' : 'm'
      break
    case 'percent':
      result = numeric(100 * value, '0,0')
      units = '%'
      break
    case 'is_percent':
      result = numeric(value, '0,0')
      units = '%'
      break
    case 'grade':
      result = numeric(value, '0,0.0')
      units = '%'
      break
    case 'duration':
      if (duration.asDays() >= 1) {
        result = duration.days() + 'd ' + dayjs.utc(duration.asMilliseconds()).format('H[h] m[m]')
      } else if (duration.asHours() >= 1) {
        result = dayjs.utc(duration.asMilliseconds()).format('H[h] m[m]')
      } else {
        result = dayjs.utc(duration.asMilliseconds()).format('m[m] s[s]')
      }
      break
    case 'digital_duration':
      result = dayjs.utc(duration.asMilliseconds()).format('HH:mm:ss')
      break
    case 'longduration':
      if (duration.asDays() >= 1) {
        result = duration.days() + 'd ' + dayjs.utc(duration.asMilliseconds()).format('H[h] m[m]')
      } else if (duration.asHours() >= 1) {
        result = dayjs.utc(duration.asMilliseconds()).format('H[h] m[m]')
      } else {
        result = dayjs.utc(duration.asMilliseconds()).format('m[m]')
      }
      break
    case 'power':
      result = numeric(value, '0,0')
      units = 'W'
      break
    case 'power_per_kg':
      result = numeric(value, '0,0.00')
      units = 'W/kg'
      break
    case 'decimal':
      result = numeric(value, '0,0.00')
      break
    case 'short_decimal':
      result = numeric(value, '0,0.0')
      break
    case 'tss':
      result = numeric(value, '0,0')
      units = 'TSS'
      break
    case 'weight':
      result = numeric(value, '0.0')
      units = imperial ? 'lb' : 'kg'
      break
    case 'cadence':
      result = numeric(value, '0.0')
      units = 'rpm'
      break
    case 'heartrate':
      result = numeric(value, '0,0')
      units = 'bpm'
      break
    default:
      result = numeric(value, '0,0')
  }

  return [result, withUnits ? units : null].filter(Boolean).join(' ')
}

import axios from 'axios'

import { admin_list_users_path, admin_statistics_path, admin_sign_in_as_path } from 'routes'

export default {
  getUsers() {
    return axios.get(admin_list_users_path()).then((response) => response.data)
  },

  getStatistics() {
    return axios.get(admin_statistics_path()).then((response) => response.data)
  },

  signInAs(user_id) {
    return axios.post(admin_sign_in_as_path(), { user_id }).then((response) => response.data)
  }
}

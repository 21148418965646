<template>
  <div class="StatsCard">
    <div class="StatsCard__title">
      <div>{{ title }}</div>
      <slot name="tooltip"></slot>
    </div>
    <h2 class="StatsCard__score">
      {{ score | format(format) }}
      <div class="h-4">
        <div v-if="trend" class="text-sm">
          <span v-if="trend >= 0" class="text-green-500">
            <svg class="svg-icon svg-icon--xs">
              <use xlink:href="#icon-arrow-up-right"></use>
            </svg>
            +{{ trend }}%
          </span>
          <span v-else class="text-red-500">
            <svg class="svg-icon svg-icon--xs">
              <use xlink:href="#icon-arrow-down-right"></use>
            </svg>
            {{ trend }}%
          </span>
          <span class="StatsCard__hover text-xs">{{ comparePeriod }}</span>
        </div>
      </div>
    </h2>
  </div>
</template>

<script>
import { dateIsBetween } from 'utils/date'
const calculateScore = (activities, type, attribute) => {
  const score = activities.reduce((a, b) => a + parseFloat(b[attribute]), 0)
  const elligibleActivities = activities.filter((a) => a[attribute])
  let result

  switch (type) {
    case 'max':
      result =
        Math.max.apply(
          null,
          activities.map((a) => a[attribute])
        ) || 0
      break
    case 'sum':
      result = score || 0
      break
    case 'average':
      result = score / elligibleActivities.length || 0
      break
  }

  return result
}

export default {
  props: {
    dateRange: {
      type: Object,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    attribute: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: false,
      default: 'sum'
    },
    format: {
      type: String,
      required: false
    }
  },

  computed: {
    activitiesByDay() {
      return Object.entries(this.data['day'])
    },

    score() {
      return calculateScore(this.currentActivities, this.type, this.attribute)
    },

    previousScore() {
      return calculateScore(this.previousActivities, this.type, this.attribute)
    },

    trend() {
      if (!this.previousScore) {
        return
      }

      return Math.round((this.score / this.previousScore - 1) * 100)
    },

    comparePeriod() {
      if (this.dateRange.days) {
        return `vs. previous ${this.dateRange.days} days`
      } else if (this.dateRange.id == 'last_year') {
        return `vs. year before`
      } else if (this.dateRange.id == 'ytd') {
        return `vs. same period last year`
      }
      return ''
    },

    currentActivities() {
      return this.activitiesByDay.filter(([day, data]) => data && dateIsBetween(day, this.dateRange.from, this.dateRange.to)).map(([, data]) => data)
    },

    previousActivities() {
      return this.activitiesByDay
        .filter(([day, data]) => data && dateIsBetween(day, this.dateRange.compareTo.from, this.dateRange.compareTo.to))
        .map(([, data]) => data)
    }
  }
}
</script>

<style lang="postcss" scoped>
.StatsCard {
  padding-bottom: 1.6rem;
}

.StatsCard:hover {
  .StatsCard__efforts {
    height: 1.5rem;
    opacity: 1;
  }
  .StatsCard__hover {
    opacity: 1;
  }
}

.StatsCard__hover {
  font-weight: normal;
  color: #ccc;
  opacity: 0;
}
</style>

<template>
  <div class="w-full overflow-visible">
    <div v-if="value && color" class="md:h-64 flex flex-col items-center justify-between h-48">
      <div class="text-muted w-full p-2 text-xs text-center">
        {{ day.date | date('DD', false) }}
      </div>

      <div class="flex items-center space-x-2">
        <div v-if="cyclingActivities.length">
          <a href class="hover:no-underline" @click.prevent="openActivity">
            <div
              class="Day flex items-center justify-center w-16 h-16 rounded-full"
              :style="{ width: `${value}px`, height: `${value}px`, backgroundColor: color || '#dddddd' }"
            >
              <div v-if="cyclingEffort && cyclingEffort > 0" class="md:text-sm text-xs font-medium leading-tight text-center text-white">
                {{ cyclingEffort | format(selectedAttribute.force ? selectedAttribute.format : 'numeric') }}
              </div>
            </div>
          </a>
        </div>

        <div v-if="runningActivities.length">
          <a href class="hover:no-underline" @click.prevent="openActivity">
            <div
              class="Day flex items-center justify-center w-16 h-16 rounded-full"
              :style="{ width: `${runningWidth}px`, height: `${runningWidth}px`, backgroundColor: runningColor }"
            >
              <div v-if="runningEffort && runningEffort > 0" class="md:text-sm text-xs font-medium leading-tight text-center text-white">
                {{ runningEffort | format(selectedAttribute.force ? selectedAttribute.format : 'numeric') }}
              </div>
            </div>
          </a>
        </div>

        <div v-if="swimmingActivities.length">
          <a href class="hover:no-underline" @click.prevent="openActivity">
            <div
              class="Day flex items-center justify-center w-16 h-16 rounded-full"
              :style="{ width: `${swimmingWidth}px`, height: `${swimmingWidth}px`, backgroundColor: swimmingColor }"
            >
              <div v-if="swimmingEffort && swimmingEffort > 0" class="md:text-sm text-xs font-medium leading-tight text-center text-white">
                {{ swimmingEffort | format(selectedAttribute.force ? selectedAttribute.format : 'numeric') }}
              </div>
            </div>
          </a>
        </div>
      </div>

      <div v-if="day.activities.length" class="w-full p-2 py-4 text-xs text-center truncate">
        <a href class="md:block hidden w-full truncate" @click.prevent="openActivity">
          <span v-if="day.activities.length > 1" class="font-bold">({{ day.activities.length }})</span>
          {{ day.activities[0].name }}
        </a>
      </div>
    </div>
    <IntersectionObserver @intersect="render" />
  </div>
</template>

<script>
import IntersectionObserver from 'components/IntersectionObserver'
import ActivityPickerModal from 'views/Modals/ActivityPickerModal'

export default {
  components: {
    ActivityPickerModal,
    IntersectionObserver
  },

  props: {
    day: {
      type: Object,
      required: true
    },
    selectedAttribute: {
      type: Object,
      required: true
    },
    valueRecord: {
      type: Number,
      required: false
    },
    intensityRecord: {
      type: Number,
      required: false
    },
    scale: {
      type: Function,
      required: false
    }
  },

  data() {
    return {
      value: undefined,
      color: undefined
    }
  },

  computed: {
    cyclingActivities() {
      return this.day.activities.filter((a) => a.is_cycling) || []
    },
    runningActivities() {
      return this.day.activities.filter((a) => a.is_running) || []
    },
    swimmingActivities() {
      return this.day.activities.filter((a) => a.is_swimming) || []
    },

    cyclingEffort() {
      const effort = this.cyclingActivities.map((a) => a[this.selectedAttribute.attribute]).filter(Boolean) || []
      return effort.reduce((a, b) => Number(a) + Number(b), 0)
    },
    runningEffort() {
      const effort = this.runningActivities.map((a) => a[this.selectedAttribute.attribute]).filter(Boolean) || []
      return effort.reduce((a, b) => Number(a) + Number(b), 0)
    },
    swimmingEffort() {
      const effort = this.swimmingActivities.map((a) => a[this.selectedAttribute.attribute]).filter(Boolean) || []
      return effort.reduce((a, b) => Number(a) + Number(b), 0)
    },

    runningWidth() {
      return this.$isMobile() ? 32 : 36
    },

    runningColor() {
      return '#5fa883'
    },

    swimmingWidth() {
      return this.$isMobile() ? 32 : 36
    },

    swimmingColor() {
      return '#5274a2'
    }
  },

  watch: {
    selectedAttribute: {
      immediate: false,
      handler() {
        const distance = this.$el.getBoundingClientRect()
        const inView =
          distance.top >= 0 &&
          distance.left >= 0 &&
          distance.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          distance.right <= (window.innerWidth || document.documentElement.clientWidth)

        if (inView) {
          this.render()
        } else {
          this.value = undefined
          this.color = undefined
        }
      }
    }
  },

  methods: {
    render() {
      if (this.day.activities.length === 0) {
        this.value = 0
        this.color = '#90cdf4'
      }

      const maxValue = Math.max.apply(
        null,
        this.day.activities.map((activity) => Number(activity[this.selectedAttribute.attribute]))
      )
      const maxIntensity = Math.max.apply(
        null,
        this.day.activities.map((activity) => Number(activity.tss_per_hour))
      )

      const maxSize = this.$isMobile() ? 64 : 112
      const minSize = this.$isMobile() ? 0.4 : 0.3

      let scale = minSize
      let color = '#90cdf4'

      if (this.valueRecord && maxValue) {
        const value = maxValue / this.valueRecord
        scale = maxSize * (minSize + 0.8 * value)
      }

      if (this.intensityRecord && maxIntensity) {
        color = this.scale(maxIntensity / this.intensityRecord).hex()
      }

      this.value = scale
      this.color = color
    },

    openActivity(e) {
      if (this.day.activities.length === 1) {
        const route = this.$router.resolve({ name: 'dashboard.activity', params: { id: this.day.activities[0].id } })

        if (e.metaKey) {
          window.open(route.href, '_blank')
        } else {
          this.$router.push(route.href)
        }
      } else {
        this.$modal(ActivityPickerModal, { activities: this.day.activities })
      }
    }
  }
}
</script>

<style lang="postcss" scoped>
.Day {
  @apply overflow-visible;
  transition: width 0.2s, height 0.2s;
}
</style>

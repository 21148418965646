<template>
  <div class="OnboardingStep">
    <div class="OnboardingStep__header">
      <h2>Power</h2>
      <div>Step 5 / 5</div>
    </div>

    <div class="FormGroup" :class="{ 'FormGroup--last': form.hasPowerMeter == '0' }">
      <div class="FormGroup__label">Do you train with a power meter?</div>
      <div class="FormGroup__content md:w-2/5">
        <label class="RadioLabel" :class="{ 'RadioLabel--selected': form.hasPowerMeter == '1' }">
          <input v-model="form.hasPowerMeter" type="radio" value="1" class="hidden" @change="update()" />
          Yes
        </label>

        <label class="RadioLabel" :class="{ 'RadioLabel--selected': form.hasPowerMeter == '0' }">
          <input v-model="form.hasPowerMeter" type="radio" value="0" class="hidden" @change="update()" />
          No
        </label>
      </div>
    </div>

    <div v-if="form.hasPowerMeter == 1" class="FormGroup FormGroup--last">
      <div class="FormGroup__label">
        Your FTP
        <div class="text-muted text-base">Enter your most recent FTP</div>

        <InputErrorMessage v-if="errors.ftp" :error="errors.ftp" />
      </div>
      <div class="FormGroup__content md:w-2/5">
        <TextInput v-model="form.ftp" class="w-full" placeholder="e.g. 300" type="number" @change="update()" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      required: false
    }
  },

  methods: {
    update() {
      this.$emit('update', this.form)
    }
  }
}
</script>

<template>
  <div v-if="chartData" class="TrainingLoadChart">
    <chart ref="chart" :options="chartData" :callback="setChart"></chart>
  </div>
</template>

<script>
import * as chartConfig from 'constants/chartConfig'

import dayjs from 'utils/dayjs'
import { isSameOrAfter, isSameOrBefore } from 'utils/date'
import trainingStatusColors from 'constants/trainingStatusColors'
import formatLabels from 'filters/formatLabels'

export default {
  props: {
    fitness: {
      type: Array,
      required: true
    },
    dateRange: {
      type: Object,
      required: true
    },
    height: {
      type: Number,
      required: false,
      default: 440
    }
  },

  data() {
    return {
      chart: undefined,
      drag: false
    }
  },

  computed: {
    hasMinimumPeriod() {
      return !['7d', '14d', '30d'].includes(this.dateRange.id)
    },

    fitnessData() {
      const from = this.hasMinimumPeriod ? this.dateRange.from : dayjs().subtract(12, 'week')
      const data = this.fitness.filter(({ date }) => isSameOrBefore(date, dayjs()) && isSameOrAfter(date, dayjs(from).startOf('week')))
      if (this.dateRange.id === 'all') {
        const firstRecord = data.findIndex(({ ctl }) => ctl > 0)
        return data.slice(firstRecord)
      } else {
        return data
      }
    },

    chartData() {
      // Calculate training load by week
      const recommendedLoad = {}
      let trainingStress = {}
      const keys = []

      const trainingStatusColor = (status) => {
        return trainingStatusColors[status] || '#e8e8e8'
      }

      this.fitnessData.forEach((day) => {
        const key = dayjs(day.date).startOf('week').format('YYYY-MM-DD')
        const lastDay = dayjs(day.date).endOf('week').format('YYYY-MM-DD')

        if (day.date == key) {
          recommendedLoad[key] = {
            x: dayjs(key).valueOf(),
            low: Math.round(day.min_load),
            high: Math.round(day.max_load),
            custom: {
              status: '-'
            }
          }

          keys.push(key)
        }

        if (day.date == lastDay && recommendedLoad[key]) {
          recommendedLoad[key].custom = {
            status: day.status
          }
        }

        if (!trainingStress[key]) {
          trainingStress[key] = {
            x: dayjs(key).utc().valueOf(),
            y: 0
          }
        }

        trainingStress[key].y += day.tss
      })

      const { formatter } = formatLabels(keys.length, 'week', this.$isMobile())

      const series = [
        {
          name: 'Training Load',
          data: Object.values(trainingStress),
          type: 'column',
          lineWidth: 3,
          borderWidth: 0,
          fillOpacity: 0,
          color: '#ff5933',
          zIndex: 1,
          states: {
            hover: {
              lineWidth: 3
            }
          }
        },
        {
          name: 'Optimal Load',
          data: Object.values(recommendedLoad),
          type: 'arearange',
          // linkedTo: ':previous',
          color: '#bfdaca',
          fillOpacity: 0.8,
          zIndex: 0,
          states: {
            hover: {
              enabled: false,
              lineWidth: 0
            }
          }
        }
      ]

      const options = {
        ...chartConfig.defaultOptions,
        ...chartConfig.withLegend,

        chart: {
          ...chartConfig.chart,
          height: this.$isMobile() ? this.height / 2 : this.height,
          zoomType: 'x'
        },
        exporting: {
          ...chartConfig.exporting,
          enabled: !this.$isMobile()
        },

        tooltip: {
          ...chartConfig.withTooltip,
          positioner: function (labelWidth, labelHeight, point) {
            var leftThird = point.plotX < this.chart.plotWidth / 2
            return {
              x: leftThird ? point.plotX + this.chart.plotLeft + 80 : this.chart.plotLeft + point.plotX - (labelWidth + 80),
              y: 20
            }
          },
          formatter: function () {
            const formattedDate = dayjs(this.x).format('dddd, MMM DD YYYY')
            return `<div class="w-48 mb-1 text-base font-bold">${formattedDate}</div>
              <div class="flex items-center text-sm font-medium">
                <svg class="mr-1" height="16" width="16"><circle cx="8" cy="8" r="4" fill="rgb(242,107,47)" /></svg>
                <span class="font-bold">${this.points[0].series.name}:</span>&nbsp; ${Math.round(this.points[0].y)}
              </div>
              <div class="flex items-center text-sm font-medium">
                <svg class="mr-1" height="16" width="16"><circle cx="8" cy="8" r="4" fill="#bfdaca" /></svg>
                <span class="font-bold">${this.points[1].series.name}:</span>&nbsp; ${Math.round(this.points[1].point.low)}–${Math.round(
              this.points[1].point.high
            )}
              </div>
              <div class="flex items-center text-sm font-medium">
                <svg class="mr-1" height="16" width="16"><circle cx="8" cy="8" r="4" fill="${trainingStatusColor(
                  this.points[1]?.point?.custom?.status
                )}" /></svg>
                <span class="font-bold">Status:</span>&nbsp; ${this.points[1]?.point?.custom?.status}
              </div>`
          }
        },
        xAxis: [
          {
            crosshair: {
              width: 1,
              color: '#000',
              enabled: true,
              zIndex: 99,
              snap: true
            },
            type: 'datetime',
            startOfWeek: Number(dayjs().weekday(0).format('d')),
            lineColor: '#ccc',
            gridLineDashStyle: 'Dot',
            gridLineColor: '#999',
            margin: 0,
            padding: 0,
            labels: {
              formatter: formatter,
              autoRotation: false,
              padding: 0
            }
          }
        ],
        yAxis: [
          {
            gridLineDashStyle: 'Dot',
            min: 0,
            title: {
              enabled: false
            }
          }
        ],
        plotOptions: {
          series: {
            shadow: false,
            marker: {
              enabled: false,
              symbol: 'circle',
              states: {
                hover: {
                  enabled: true
                }
              }
            },
            states: {
              hover: {
                halo: {
                  size: 0
                }
              }
            }
          }
        },
        series: series
      }

      return options
    }
  },

  mounted() {
    this.$el.addEventListener('mousemove', () => (this.drag = true))
    this.$el.addEventListener('mousedown', () => (this.drag = false))
    this.$el.addEventListener('mouseup', () => !this.drag && this.resetZoom())
  },

  methods: {
    setChart(chart) {
      this.chart = chart
    },
    resetZoom() {
      this.chart.xAxis[0].setExtremes && this.chart.xAxis[0].setExtremes(null, null, false)
      this.chart.redraw()
    }
  }
}
</script>

<template>
  <div class="w-full">
    <SectionTitle title="Achievements">
      <ChartNavigation :options="filterOptions" :selected-option="selectedFilterOption" @input="setFilter" />
    </SectionTitle>

    <vue-good-table v-if="achievements" class="mb-16" :columns="columns" :rows="currentAchievements" style-class="vgt-table striped">
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field == 'record_duration'">
          <div v-if="props.row.record_type == 'hr'">
            <svg class="svg-icon svg-icon--sm mx-2 text-gray-600">
              <use xlink:href="#icon-heart"></use>
            </svg>
            {{ props.row.record_duration }}
            Heart Rate
          </div>
          <div v-if="props.row.record_type == 'ftp'">
            <svg class="svg-icon svg-icon--sm text-primary mx-2">
              <use xlink:href="#icon-power"></use>
            </svg>
            Estimated FTP
          </div>
          <div v-if="props.row.record_type == 'power'">
            <svg class="svg-icon svg-icon--sm mx-2 text-gray-600">
              <use xlink:href="#icon-power"></use>
            </svg>
            {{ props.row.record_duration }}
            Power
          </div>
        </div>
        <div v-else-if="props.column.field == 'record_value'">
          <span class="font-bold">
            {{ props.row.record_value | format(`${props.row.record_type == 'power' || props.row.record_type == 'ftp' ? 'power' : 'numeric'}`) }}
          </span>
        </div>
        <div v-else-if="props.column.field == 'date'">
          {{ props.row.date | relativeTime }}
        </div>
        <div v-else-if="props.column.field == 'activity_name'">
          <div class="flex flex-col">
            <div class="">
              <router-link :to="{ name: 'dashboard.activity', params: { id: props.row.activity_id } }">
                {{ props.row.activity_name }}
              </router-link>
            </div>
            <div class="text-muted text-sm">
              {{ props.row.date | date }}
            </div>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import SectionTitle from 'components/SectionTitle'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

export default {
  components: {
    SectionTitle,
    VueGoodTable
  },

  props: {
    summary: {
      type: Object,
      required: true
    },
    currentSummary: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    activities: {
      type: Array,
      required: true
    },
    segmentBy: {
      type: String,
      required: true
    },
    dateRange: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      filterOptions: ['All', 'Power', 'Heart Rate', 'FTP'],
      selectedFilterOption: 'All',
      columns: [
        {
          label: 'Record',
          field: 'record_duration'
        },
        {
          label: 'Value',
          field: 'record_value',
          type: 'number'
        },
        {
          label: 'Date',
          field: 'date'
        },
        {
          label: 'Activity',
          field: 'activity_name',
          type: 'string'
        }
      ]
    }
  },

  computed: {
    ...mapGetters('profile', ['achievements']),

    currentAchievements() {
      if (this.selectedFilterOption == 'Power') {
        return this.achievements.filter(({ record_type }) => record_type == 'power')
      } else if (this.selectedFilterOption == 'Heart Rate') {
        return this.achievements.filter(({ record_type }) => record_type == 'hr')
      } else if (this.selectedFilterOption == 'FTP') {
        return this.achievements.filter(({ record_type }) => record_type == 'ftp')
      } else {
        return this.achievements
      }
    }
  },

  mounted() {
    this.loadAchievements()
  },

  methods: {
    ...mapActions('profile', ['loadAchievements']),

    setFilter(option) {
      this.selectedFilterOption = option
    }
  }
}
</script>

<style lang="postcss" scoped>
.FilterOption {
  @apply py-1 ml-4 no-underline;

  &--selected {
    @apply text-primary border-primary font-bold border-b-2;
  }
}
</style>

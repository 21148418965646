<template>
  <div class="xl:w-192 w-full">
    <div class="p-8 mb-6 bg-white border rounded">
      <h1 class="mb-2 text-3xl">Functional threshold power (FTP) Calculator</h1>
      <p class="text-muted mb-8 text-sm">
        Functional threshold power (also called FTP) is one of the most important metrics used in cycling. It is the maximum amount of power that can be
        sustainably produced for one hour, measured in watts. This is one of the most important values in sports performance testing because it is a benchmark
        that can be used to measure fitness and predict performance. Knowing your functional threshold power is crucial for every cyclist, pro or amateur. Use
        our online Cycling FTP Calculator to find out what is your current FTP and how it compares with some of the best cyclists in the world.
      </p>

      <div class="FormGroup FormGroup--first">
        <div class="FormGroup__label">
          Your Best 20 min. power
          <InputErrorMessage v-if="errors.power" :error="errors.power" />
        </div>
        <div class="FormGroup__content md:w-2/5">
          <TextInput v-model="form.power" class="w-full" placeholder="e.g. 300" type="number" aria-label="Enter power" />
        </div>
      </div>

      <div class="FormGroup">
        <div class="FormGroup__label">
          Your Weight
          <InputErrorMessage v-if="errors.weight" :error="errors.weight" />
        </div>
        <div class="FormGroup__content md:w-2/5">
          <TextInput
            v-model="form.weight"
            class="w-full mr-2"
            :placeholder="form.weight_units == 'lb' ? 'e.g. 165' : 'e.g. 75'"
            type="number"
            aria-label="Enter weight"
          />

          <SelectInput
            v-model="form.weight_units"
            value-key="value"
            label-key="label"
            class="md:w-2/3 w-3/5"
            aria-label="Select weight units"
            :options="weightUnitOptions"
          />
        </div>
      </div>

      <div class="FormGroup">
        <div class="FormGroup__label">
          Gender
          <InputErrorMessage v-if="errors.sex" :error="errors.sex" />
        </div>
        <div class="FormGroup__content md:w-3/5 lg:w-2/5">
          <label class="RadioLabel" :class="{ 'RadioLabel--selected': form.sex == 'M' }">
            <input v-model="form.sex" type="radio" value="M" class="hidden" />
            Male
          </label>

          <label class="RadioLabel" :class="{ 'RadioLabel--selected': form.sex == 'F' }">
            <input v-model="form.sex" type="radio" value="F" class="hidden" />
            Female
          </label>
        </div>
      </div>

      <AppButton class="mt-4" primary small @click="calculate()">Calculate FTP</AppButton>
    </div>

    <div v-if="result">
      <div class="flex p-6 mt-6 text-center bg-white border rounded">
        <div class="w-1/2 text-center">
          <div class="label">Estimated FTP</div>
          <div class="-mt-4 text-5xl font-bold">
            {{ result.ftp }}
          </div>
        </div>
        <div class="w-1/2 text-center border-l">
          <div class="label">W/Kg</div>
          <div class="-mt-4 text-5xl font-bold">
            {{ result.wkg }}
          </div>
        </div>
      </div>

      <div class="p-6 pb-0 mt-6 bg-white border rounded">
        <div class="label text-center">Your power category</div>

        <h2 class="mb-2 text-4xl font-bold text-center">
          {{ result.currentIndex >= 0 ? result.ranges[result.currentIndex].title : 'How good is your FTP?' }}
        </h2>

        <div
          v-for="(category, index) in result.ranges"
          :key="index"
          class="flex items-center p-4 px-6 -mx-6 border-b"
          :class="category.current ? 'bg-orange-100' : ''"
        >
          <div class="">
            <div class="font-bold">{{ category.title }}</div>
            <div class="text-muted text-sm">{{ category.subtitle }}</div>
          </div>
          <div v-if="category.current">
            <div class="bg-green-600 text-white font-medium mr-2 px-2.5 py-0.5 rounded ml-8">
              <span class="md:inline hidden">Your result</span>
              <span class="md:hidden">You</span>
            </div>
          </div>
          <div class="ml-auto">{{ category.from | format('decimal') }} – {{ category.to | format('decimal') }} W/kg</div>
        </div>

        <div
          v-if="result.howToImprove && result.howToImprove.improvementInPercent >= 0"
          class="flex items-center p-4 px-6 -mx-6 space-x-2 text-lg bg-orange-100"
        >
          <svg class="svg-icon svg-icon--sm text-primary flex-shrink-0">
            <use xlink:href="#icon-info"></use>
          </svg>
          <div>
            Improving your FTP by {{ result.howToImprove.improvementInPercent | format('percent') }} to
            {{ result.howToImprove.nextCategoryFTP | format('numeric') }} will get you to {{ result.howToImprove.nextCategory }} category.
          </div>
        </div>
      </div>
    </div>

    <div class="md:flex-row md:space-x-8 md:justify-between flex flex-col p-8 my-4 space-y-4 overflow-hidden bg-white border">
      <div class="max-w-md">
        <h2 class="mb-2 text-3xl font-bold leading-snug">AI FTP Detection</h2>
        <p>FormBeat's AI-powered FTP detection tool will automatically analyze your power data and detect improvements in your FTP.</p>
        <AppButton class="block mt-8" secondary small>
          <router-link :to="{ name: 'join' }" title="Create a free account">Try FormBeat</router-link>
        </AppButton>
      </div>
      <div class="max-w-md">
        <FTPCounter :start-value="225" :end-value="242" :duration="1500" />
      </div>
    </div>

    <div class="md:flex-row flex flex-col p-8 my-4 space-x-8 space-y-4 overflow-hidden bg-white border">
      <div class="max-w-md">
        <h2 class="mb-2 text-3xl font-bold leading-snug">
          Improve your FTP
          <br />
          in less than 8 weeks!
        </h2>
        <p>
          Our AI-powered tools are designed to help you become a stronger and faster cyclist. Track your progress, analyze your data, and get personalized
          training load to take your cycling to the next level.
        </p>
        <AppButton class="block mt-8" primary small>
          <router-link :to="{ name: 'join' }" title="Create a free account">Sign up for FREE</router-link>
        </AppButton>
      </div>
      <div class="max-w-md" style="margin-right: -6rem">
        <img :src="require('images/features/training-load-sm.jpg') | imageUrl('w_640')" alt="Cycling analytics dashboard" />
      </div>
    </div>
  </div>
</template>

<script>
import ValidationService, { isRequired, isMin, isMax } from 'services/ValidationService'
import { getElitePowerProfiles } from 'utils/powerCurve'
import FTPCounter from 'components/FTPCounter'

export default {
  components: {
    FTPCounter
  },
  data() {
    return {
      form: {
        weight_units: 'lb'
      },
      errors: {},
      result: undefined,
      weightUnitOptions: [
        {
          value: 'kg',
          label: 'Kg'
        },
        {
          value: 'lb',
          label: 'Lb'
        }
      ],
      categories: [
        ['Novice', 'Non-racer'],
        ['Fair', 'Cat 5'],
        ['Moderate', 'Cat 4'],
        ['Good', 'Cat 3'],
        ['Very Good', 'Cat 2'],
        ['Excellent', 'Cat 1'],
        ['Exceptional', 'Domestic Pro'],
        ['World Class', 'International Pro']
      ]
    }
  },

  methods: {
    async calculate() {
      this.errors = {}

      const rules = {
        power: [isRequired(), isMin(1), isMax(999)],
        weight: [isRequired(), isMin(1), isMax(400)],
        sex: [isRequired()]
      }

      const result = await ValidationService.validate(this.form, rules)

      if (!result.isValid) {
        this.result = undefined
        this.errors = result.errors
      } else {
        const ftp = Math.round(this.form.power * 0.95)
        const weightInKilograms = this.form.weight_units == 'lb' ? (1 / 2.2046226218) * this.form.weight : this.form.weight
        const wkg = parseFloat(ftp / weightInKilograms).toFixed(2)

        const eliteProfiles = getElitePowerProfiles(this.form.sex)
        const FTPDataset = eliteProfiles[3]

        const min = FTPDataset[FTPDataset.length - 1]
        const max = FTPDataset[0]
        const step = (max - min) / 8
        const ranges = []

        // Calculate power profile category
        for (var i = 0; i < 8; i++) {
          const from = (min + i * step).toFixed(2)
          const to = (min + step + i * step).toFixed(2)
          const current = wkg >= from && wkg < to
          ranges.push({
            from,
            to,
            current,
            title: this.categories[i][0],
            subtitle: this.categories[i][1]
          })
        }

        const currentIndex = ranges.findIndex(({ current }) => current)

        let howToImprove = undefined

        if (currentIndex && currentIndex < ranges.length - 1) {
          const nextCategory = ranges[currentIndex + 1].subtitle
          const nextCategoryWkg = ranges[currentIndex + 1].from
          const nextCategoryFTP = nextCategoryWkg * weightInKilograms
          const improvementInPercent = (nextCategoryFTP - ftp) / ftp

          howToImprove = {
            nextCategory,
            nextCategoryWkg,
            nextCategoryFTP,
            improvementInPercent
          }
        }

        this.result = {
          ftp,
          wkg,
          ranges,
          currentIndex,
          howToImprove
        }
      }
    }
  },

  metaInfo() {
    return {
      title: `FTP Calculator`,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content:
            'Online Functional threshold power calculator. Find out how good is your FTP and how does it compare to the different cycling levels and world elite.'
        }
      ]
    }
  }
}
</script>

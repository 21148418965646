<template>
  <div v-if="initialized">
    <div ref="analysis" class="ActivityAnalysis flex flex-col border-t" :class="{ 'ActivityAnalysis--full': isAnalyzeView }">
      <div v-if="!isAnalyzeView" class="w-full p-4 mb-6 bg-white rounded shadow">
        <ActivitySummary :activity="activity" />
      </div>

      <div v-if="activity.has_activity_records && !isAnalyzeView" class="flex items-center p-4 mb-6 bg-white border rounded">
        <svg class="svg-icon text-primary mr-2 text-xl">
          <use xlink:href="#icon-medal"></use>
        </svg>
        <span class="mr-2 font-bold">You set a new record!</span>
        <span>
          {{ activity.activity_records.map(({ message }) => message).join(', ') }}
        </span>
      </div>

      <div v-if="activity.map_summary_polyline" class="ActivityAnalysis__map flex">
        <div class="relative flex-grow w-auto">
          <Map
            v-if="activity.map_summary_polyline"
            :streams="streams"
            :polyline="activity.map_summary_polyline"
            :marker-position="markerPosition"
            :highlighted-points="highlightedPoints"
            :units="user.measurement_preference"
            class="mb-0 overflow-hidden border"
            style="width: 100%"
            :style="{ height: isAnalyzeView ? '100%' : '422px' }"
            :scroll-zoom="isAnalyzeView"
            @onmaploaded="handleMapLoad"
          />
        </div>
      </div>

      <div v-if="hasStreams" class="ActivityAnalysis__charts relative flex w-full overflow-hidden bg-white border border-t-0">
        <div ref="chartContainer" class="relative w-full">
          <div v-if="hasWattsStream || hasHeartRateStream" class="lg:block absolute top-0 right-0 z-10 hidden mt-4 mr-48 text-xs">
            <div class="flex items-center h-4 space-x-6">
              <div v-if="isAnalyzeView" class="lg:block text-muted hidden -mr-4">Zoom to</div>
              <div v-if="hasWattsStream" class="text-muted flex items-center">
                <svg class="svg-icon svg-icon--xs flex-shrink-0 mx-1 opacity-50" :style="{ color: streamColors.power }">
                  <use xlink:href="#icon-power"></use>
                </svg>
                <a
                  v-if="activity.moving_time >= 5"
                  title="Zoom to 5 second max power"
                  class="px-1 cursor-pointer"
                  @click="highlightPower(5, true)"
                  @mouseout="highlightPower(null)"
                  @mouseover="highlightPower(5)"
                >
                  5s
                </a>
                <a
                  v-if="activity.moving_time >= 1 * 60"
                  title="Zoom to 1 minute max power"
                  class="px-1 cursor-pointer"
                  @click="highlightPower(60, true)"
                  @mouseout="highlightPower(null)"
                  @mouseover="highlightPower(60)"
                >
                  1m
                </a>
                <a
                  v-if="activity.moving_time >= 5 * 60"
                  title="Zoom to 5 minute max power"
                  class="px-1 cursor-pointer"
                  @click="highlightPower(60 * 5, true)"
                  @mouseout="highlightPower(null)"
                  @mouseover="highlightPower(60 * 5)"
                >
                  5m
                </a>
                <a
                  v-if="activity.moving_time >= 20 * 60"
                  title="Zoom to 20 minute max power"
                  class="px-1 cursor-pointer"
                  @click="highlightPower(60 * 20, true)"
                  @mouseout="highlightPower(null)"
                  @mouseover="highlightPower(60 * 20)"
                >
                  20m
                </a>
              </div>

              <div v-if="hasHeartRateStream" class="text-muted flex items-center">
                <svg class="svg-icon svg-icon--xs flex-shrink-0 mx-1 opacity-50" :style="{ color: streamColors.heartrate }">
                  <use xlink:href="#icon-heart"></use>
                </svg>
                <a
                  v-if="activity.moving_time >= 5"
                  title="Zoom to 5 second max heart rate"
                  class="px-1 cursor-pointer"
                  @click="highlightHR(5, true)"
                  @mouseout="highlightHR(null)"
                  @mouseover="highlightHR(5)"
                >
                  5s
                </a>
                <a
                  v-if="activity.moving_time >= 1 * 60"
                  title="Zoom to 1 minute max heart rate"
                  class="px-1 cursor-pointer"
                  @click="highlightHR(60, true)"
                  @mouseout="highlightHR(null)"
                  @mouseover="highlightHR(60)"
                >
                  1m
                </a>
                <a
                  v-if="activity.moving_time >= 5 * 60"
                  title="Zoom to 5 minute max heart rate"
                  class="px-1 cursor-pointer"
                  @click="highlightHR(60 * 5, true)"
                  @mouseout="highlightHR(null)"
                  @mouseover="highlightHR(60 * 5)"
                >
                  5m
                </a>
                <a
                  v-if="activity.moving_time >= 20 * 60"
                  title="Zoom to 20 minute max heart rate"
                  class="px-1 cursor-pointer"
                  @click="highlightHR(60 * 20, true)"
                  @mouseout="highlightHR(null)"
                  @mouseover="highlightHR(60 * 20)"
                >
                  20m
                </a>
              </div>
            </div>
          </div>
          <AllStreamsChart
            v-if="activity.streams"
            :user="user"
            :activity="activity"
            :highlighted-points="highlightedPoints"
            :streams="streams"
            :units="units"
            :height="chartHeight"
            :smoothing-options="smoothingOptions"
            :selected-smoothing="selectedSmoothing"
            :zoom-to="zoomTo"
            :show-navigator="isAnalyzeView"
            @onsmoothingchanged="handleSmoothingChange"
            @onpositionchanged="handlePositionChange"
            @onselectionchange="handleSelectionChange"
            @onchartloaded="handleChartLoad"
          />
        </div>

        <div
          v-if="isAnalyzeView"
          class="lg:flex flex-col hidden p-4 bg-white border-l"
          :style="{ width: `${chartSummaryWidth}px`, 'min-width': `${chartSummaryWidth}px` }"
        >
          <AllStreamsChartSummary
            :has-watts-stream="hasWattsStream"
            :has-heart-rate-stream="hasHeartRateStream"
            :has-cadence-stream="hasCadenceStream"
            :has-grade-stream="hasGradeStream"
            :has-velocity-stream="hasVelocityStream"
            :selected-area-summary="selectedAreaSummary"
            :user="user"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import smooth from 'array-smooth'
import chroma from 'chroma-js'
import { localMaximaArrayIndex } from 'utils/array'
import convertUnits from 'filters/convertUnits'
import streamColors from 'constants/streamColors'
import ActivitySummary from 'components/ActivitySummary'
import AllStreamsChart from 'components/charts/AllStreamsChart'
import AllStreamsChartSummary from 'components/charts/AllStreamsChartSummary'
import Map from 'components/Map'

const allowedStreams = ['altitude', 'heartrate', 'watts', 'velocity_smooth', 'grade_smooth', 'cadence', 'distance', 'time', 'latlng']

const CHART_SUMMARY_WIDTH = 286
const CHART_HEIGHT_SM = 360
const CHART_HEIGHT_LG = 360

export default {
  components: {
    Map,
    ActivitySummary,
    AllStreamsChart,
    AllStreamsChartSummary
  },

  props: {
    user: {
      type: Object,
      required: true
    },
    activity: {
      type: Object,
      required: true
    },
    activities: {
      type: Array,
      required: true
    },
    units: {
      type: String,
      required: true
    },
    isAnalyzeView: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      mouseEvent: undefined,
      markerPosition: undefined,
      pointPosition: undefined,
      smoothingOptions: [
        { smoothing: 0, sampling: 1 },
        { smoothing: 4, sampling: 4 },
        { smoothing: 8, sampling: 4 },
        { smoothing: 12, sampling: 8 },
        { smoothing: 16, sampling: 12 },
        { smoothing: 24, sampling: 12 },
        { smoothing: 32, sampling: 16 },
        { smoothing: 64, sampling: 16 }
      ],
      selectedSmoothing: 7,
      highlightedPoints: [],
      previousHighlightedPoints: [],
      allowedStreams,
      streamColors,
      zoomTo: undefined,
      map: undefined,
      chart: undefined,
      hr_colors: chroma.scale(['#ffeadd', '#e91500']).colors(5),
      power_colors: chroma.scale(['#e2e4ff', '#4d1bf5']).colors(7),
      chartHeightLarge: CHART_HEIGHT_LG,
      chartHeightSmall: CHART_HEIGHT_SM,
      chartHeight: undefined,
      chartSummaryWidth: CHART_SUMMARY_WIDTH,
      initialized: false
    }
  },

  computed: {
    hasStreams() {
      return Object.keys(this.activity.streams).length && this.activity.is_cycling
    },

    smoothing() {
      return this.smoothingOptions[this.selectedSmoothing].smoothing
    },

    samplingRatio() {
      return (this.$isMobile() ? 10 : 1) * this.smoothingOptions[this.selectedSmoothing].sampling
    },

    hasGradeStream() {
      return Boolean(this.activity.streams.grade_smooth?.data?.length)
    },

    hasDistanceStream() {
      return Boolean(this.activity.streams.distance?.data?.length)
    },

    hasAltitudeStream() {
      return Boolean(this.activity.streams.altitude?.data?.length)
    },

    hasTimeStream() {
      return Boolean(this.activity.streams.time?.data?.length)
    },

    hasWattsStream() {
      return Boolean(this.activity.streams.watts?.data?.length)
    },

    hasVelocityStream() {
      return Boolean(this.activity.streams.velocity_smooth?.data?.length)
    },

    hasCadenceStream() {
      return Boolean(this.activity.streams.cadence?.data?.length)
    },

    hasHeartRateStream() {
      return Boolean(this.activity.streams.heartrate?.data?.length)
    },

    activityStreams() {
      return this.activity.streams
    },

    currentPoint() {
      if (typeof this.pointPosition === 'undefined') {
        return undefined
      }

      return this.pointPosition
    },

    selectedAreaSummary() {
      if (!this.hasStreams || !this.highlightedPoints || !this.highlightedPoints.length) {
        return this.activity
      } else {
        const length = this.processedStreams.distance.data.length - 1
        // TODO FIXME
        const relativePoints = this.highlightedPoints.map(([min, max, minRelative, maxRelative, shouldZoom]) => [
          Math.ceil(minRelative * length),
          Math.ceil(maxRelative * length)
        ])
        this.relativePoints = relativePoints
        // const relativePoints = this.highlightedPoints.map(([min, max]) => [min * this.samplingRatio, max * this.samplingRatio])

        let result = {
          total_elevation_gain: undefined,
          moving_time: undefined,
          distance: undefined,
          computed_avg_watts: undefined,
          max_watts: undefined,
          computed_average_heartrate: undefined,
          max_heartrate: undefined,
          average_speed: undefined,
          average_grade: undefined,
          max_speed: undefined,
          average_cadence: undefined,
          max_cadence: undefined
        }

        if (this.hasDistanceStream) {
          result.distance =
            relativePoints.map(([min, max]) => this.processedStreams.distance.data[max] - this.processedStreams.distance.data[min]).reduce((a, b) => a + b, 0) /
            1000
        }

        if (this.hasTimeStream) {
          result.moving_time = relativePoints
            .map(([min, max]) => this.processedStreams.time.data[max] - this.processedStreams.time.data[min])
            .reduce((a, b) => a + b, 0)
        }

        // if (this.hasGradeStream) {
        //   const grade = relativePoints.map(([min, max]) => this.processedStreams.grade_smooth.data.slice(min, max)).flat()
        //   result.max_grade = Math.max.apply(null, grade)
        //   result.average_grade = grade.reduce((a,b) => a + b, 0) / grade.length
        // }

        if (this.hasWattsStream) {
          const watts = relativePoints.map(([min, max]) => this.processedStreams.watts.data.slice(min, max)).flat()
          result.max_watts = Math.max.apply(null, watts)
          result.computed_avg_watts = watts.reduce((a, b) => a + b, 0) / watts.length
        }

        if (this.hasHeartRateStream) {
          const heartrate = relativePoints.map(([min, max]) => this.processedStreams.heartrate.data.slice(min, max)).flat()
          result.max_heartrate = Math.max.apply(null, heartrate)
          result.computed_average_heartrate = heartrate.reduce((a, b) => a + b, 0) / heartrate.length
        }

        if (this.hasVelocityStream) {
          const velocity_smooth = relativePoints.map(([min, max]) => this.processedStreams.velocity_smooth.data.slice(min, max)).flat()
          result.max_speed = Math.max.apply(null, velocity_smooth)
          result.average_speed = velocity_smooth.reduce((a, b) => a + b, 0) / velocity_smooth.length
        }

        if (this.hasCadenceStream) {
          const cadence = relativePoints.map(([min, max]) => this.processedStreams.cadence.data.slice(min, max)).flat()
          result.max_cadence = Math.max.apply(null, cadence)
          result.average_cadence = cadence.reduce((a, b) => a + b, 0) / cadence.length
        }

        if (this.hasGradeStream) {
          const grade = relativePoints.map(([min, max]) => this.processedStreams?.grade_smooth?.data.slice(min, max)).flat()
          result.average_grade = grade.reduce((a, b) => a + b, 0) / grade.length
        }

        if (this.hasAltitudeStream) {
          const altitude = relativePoints
            .map(([min, max]) => {
              const values = this.processedStreams.altitude.data.slice(min, max)
              let sum = 0

              values.forEach((value, index) => {
                if (index > 0 && value > values[index - 1]) {
                  const diff = Number(value) - Number(values[index - 1])
                  sum += diff
                }
              })

              return sum
            })
            .reduce((a, b) => Number(a) + Number(b), 0)

          result.total_elevation_gain = altitude
        }

        if (this.hasAltitudeStream && this.hasTimeStream) {
          result.vam = Math.round(result.total_elevation_gain / (result.moving_time / 3600))
        }

        return result
      }
    },

    processedStreams() {
      if (!this.hasStreams) {
        return {}
      }

      let originalStreams = { ...this.activity.streams }
      const incorrectDataIndex = []

      // Remove all points with null distance values
      originalStreams.distance.data.forEach((value, index) => {
        if (index !== 0 && Number(value) === 0) {
          incorrectDataIndex.push(index)
        }
      })

      incorrectDataIndex.reverse().forEach((index) => {
        this.allowedStreams.forEach((key) => {
          if (originalStreams[key]?.data && originalStreams[key]?.data.length) {
            originalStreams[key].data.splice(index, 1)
          }
        })
      })

      this.allowedStreams.forEach((key) => {
        if (['velocity_smooth'].includes(key)) {
          originalStreams[key].data = originalStreams[key].data.map((v) => v * 3.6)
        }

        if (this.units === 'feet') {
          if (['velocity_smooth'].includes(key)) {
            originalStreams[key].data = originalStreams[key].data.map((v) => convertUnits(v, 'speed', this.units))
          }

          if (['distance'].includes(key)) {
            originalStreams[key].data = originalStreams[key].data.map((v) => convertUnits(v, 'distance', this.units))
          }

          if (['altitude'].includes(key)) {
            originalStreams[key].data = originalStreams[key].data.map((v) => convertUnits(v, 'elevation', this.units))
          }
        }
      })

      return originalStreams
    },

    streams() {
      if (!this.hasStreams) {
        return {}
      }

      const streams = {}

      this.allowedStreams.forEach((key) => {
        let data = [...(this.processedStreams[key]?.data || [])]

        if (!['time', 'distance', 'elevation', 'watts', 'latlng'].includes(key)) {
          data = smooth(data, this.smoothing)
        }

        if (['watts'].includes(key)) {
          data = smooth(data, this.smoothing)
        }

        data = data.filter((_, i) => i % this.samplingRatio == 0)

        streams[key] = {
          data: data
        }
      })

      // XXX: ?
      // const lengths = Object.values(streams).map((s) => s.data.length)
      // const maxLength = Math.max.apply(null, lengths)
      //
      // Object.keys(streams).forEach((key) => {
      //   if (streams[key].data.length < maxLength) {
      //     for (var i = 0; i < maxLength; i++) {
      //       if (!streams[key].data[i]) {
      //         streams[key].data[i] = null
      //       }
      //     }
      //   }
      // })

      return streams
    }
  },

  watch: {
    isAnalyzeView: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          if (!this.hasStreams) {
            return
          }

          let chartWidth
          let chartHeight = (this.$isMobile() ? 2 : 1) * (this.isAnalyzeView ? this.chartHeightLarge : this.chartHeightSmall)

          if (!this.activity.map_summary_polyline && this.isAnalyzeView) {
            chartHeight = window.innerHeight - 64
          }

          if (this.isAnalyzeView) {
            chartWidth = window.innerWidth - (this.$isMobile() ? 0 : this.chartSummaryWidth)
          } else {
            chartWidth = this.$refs.analysis?.clientWidth
          }

          this.chartHeight = chartHeight
          this.chart?.setSize(chartWidth, chartHeight)
          this.map?.resize()
        })
      }
    }
  },

  mounted() {
    this.initialize()
    this.initialized = true
  },

  created() {
    document.querySelector('#tidio-chat')?.classList.add('hidden')
    window.addEventListener('keydown', this.keyPressedListener)
  },

  destroyed() {
    document.querySelector('#tidio-chat')?.classList.remove('hidden')
    window.removeEventListener('keydown', this.keyPressedListener)
  },

  methods: {
    handleSelectionChange(value) {
      this.highlightedPoints = value
    },

    handleSmoothingChange(value) {
      this.selectedSmoothing = value
      this.previousHighlightedPoints = []
    },

    handlePositionChange(e) {
      const [absolute, relative] = e
      this.markerPosition = relative
      this.pointPosition = absolute
    },

    handleMapLoad(e) {
      this.map = e
    },

    handleChartLoad(e) {
      this.chart = e
    },

    highlightStream(type, seconds, shouldZoom) {
      // if (!shouldZoom) {
      //   return
      // }

      if (!seconds) {
        this.highlightedPoints = this.previousHighlightedPoints || []
        this.previousHighlightedPoints = []
        return
      }

      if (seconds <= 60 && shouldZoom) {
        this.handleSmoothingChange(0)
      }

      let timeBasis = 1

      if (this.activity.streams.time?.data?.length) {
        const movingTime = this.activity.moving_time
        const datapoints = this.activity.streams.time.data.length

        timeBasis = Math.round(movingTime / datapoints)
      }

      const timePoints = Math.floor(seconds / timeBasis)

      // FIXME Why + 1 works?
      const index = localMaximaArrayIndex(this.processedStreams[type].data, timePoints) + 1

      const from = Math.floor(index / this.samplingRatio)
      const to = Math.floor((index + timePoints) / this.samplingRatio)
      const length = this.processedStreams[type].data.length - 1
      const fromRelative = index / length
      const toRelative = (index + timePoints) / length

      this.previousHighlightedPoints = [...this.highlightedPoints]
      if (this.previousHighlightedPoints.length) {
        this.previousHighlightedPoints[0][5] = false
      }
      this.highlightedPoints = [[from, to, fromRelative, toRelative, length, shouldZoom]]

      if (shouldZoom) {
        this.previousHighlightedPoints = [...this.highlightedPoints]
        this.zoomTo = this.highlightedPoints
      }
    },

    highlightHR(seconds, shouldZoom) {
      this.highlightStream('heartrate', seconds, shouldZoom)
    },

    highlightPower(seconds, shouldZoom) {
      this.highlightStream('watts', seconds, shouldZoom)
    },

    keyPressedListener(event) {
      if (event.metaKey || event.shiftKey || event.altKey || event.ctrlKey) {
        return
      }

      if (event.target && (/textarea|select/i.test(event.target.nodeName) || event.target.type === 'text')) {
        return
      }

      if ([27].includes(event.keyCode) && this.isAnalyzeView) {
        this.$router.push({ name: 'dashboard.activity', params: { id: this.activity.id } })
      }
    },

    initialize() {
      // TODO: This needs to be improved

      if (!this.hasStreams) {
        return
      }

      if (!this.activity.distance || !this.activity.streams?.distance?.data.length) {
        return
      }

      let baseDistances
      let smoothingOptions

      let minSampling = 1
      let defaultOption = 4

      if (this.activity.distance > 300) {
        baseDistances = [0.025, 0.05, 0.1, 0.25, 0.5]
        minSampling = 2
        defaultOption = 7
      } else if (this.activity.distance > 200) {
        baseDistances = [0.0125, 0.025, 0.05, 0.1, 0.25]
        minSampling = 2
        defaultOption = 6
      } else if (this.activity.distance > 100) {
        baseDistances = [0.0125, 0.0125, 0.025, 0.05, 0.1]
        minSampling = 2
        defaultOption = 5
      } else if (this.activity.distance > 75) {
        baseDistances = [0.0125, 0.0125, 0.025, 0.05, 0.1]
        defaultOption = 5
      } else if (this.activity.distance > 50) {
        baseDistances = [0.0125, 0.0125, 0.025, 0.05, 0.1]
        defaultOption = 4
      } else if (this.activity.distance > 25) {
        baseDistances = [0.0125, 0.0125, 0.025, 0.05, 0.1]
        defaultOption = 3
      } else if (this.activity.distance > 10) {
        baseDistances = [0.0125, 0.0125, 0.025, 0.05, 0.1]
        defaultOption = 2
      } else {
        baseDistances = [0.0125, 0.0125, 0.025, 0.05, 0.1]
        defaultOption = 0
      }

      let samplings = [
        minSampling,
        Math.ceil(this.activity.streams.distance.data.length / (this.activity.distance / baseDistances[0])),
        Math.ceil(this.activity.streams.distance.data.length / (this.activity.distance / baseDistances[1])),
        Math.ceil(this.activity.streams.distance.data.length / (this.activity.distance / baseDistances[2])),
        Math.ceil(this.activity.streams.distance.data.length / (this.activity.distance / baseDistances[3])),
        Math.ceil(this.activity.streams.distance.data.length / (this.activity.distance / baseDistances[4])),
        Math.ceil(this.activity.streams.distance.data.length / (this.activity.distance / baseDistances[4])),
        Math.ceil(this.activity.streams.distance.data.length / (this.activity.distance / baseDistances[4]))
      ]

      smoothingOptions = [
        { smoothing: 0, sampling: samplings[0] },
        { smoothing: 4, sampling: samplings[1] },
        { smoothing: 8, sampling: samplings[2] },
        { smoothing: 12, sampling: samplings[3] },
        { smoothing: 16, sampling: samplings[4] },
        { smoothing: 24, sampling: samplings[5] },
        { smoothing: 32, sampling: samplings[6] },
        { smoothing: 64, sampling: samplings[7] }
      ]

      this.selectedSmoothing = defaultOption
      this.smoothingOptions = smoothingOptions
    }
  },

  metaInfo() {
    return {
      title: this.activity?.name
    }
  }
}
</script>

<style lang="postcss" scoped>
.ActivityAnalysis.ActivityAnalysis--full {
  @apply fixed bottom-0 left-0 right-0;

  z-index: 999;
  top: 64px;

  .ActivityAnalysis__map {
    @apply flex-1;
  }
  .ActivityAnalysis__charts {
  }
}
</style>

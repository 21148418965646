<template>
  <div class="Dropdown">
    <div class="Dropdown__selected" :class="{ isOpen }" @mousedown="toggle()">
      <slot name="selected" />
    </div>

    <SlideDownTransition>
      <div v-if="isOpen" class="Dropdown__menu" :class="{ isOpen, isOnRight: right }" @click="close()">
        <slot name="options" :close="close" />
      </div>
    </SlideDownTransition>
  </div>
</template>

<script>
import SlideDownTransition from 'components/transitions/SlideDownTransition'

export default {
  components: {
    SlideDownTransition
  },

  props: {
    right: Boolean
  },

  data() {
    return {
      isOpen: false
    }
  },

  mounted() {
    window.addEventListener('mousedown', this.closeByMousedown, true)
    window.addEventListener('keydown', this.closeByEsc, true)
  },

  destroyed() {
    window.removeEventListener('mousedown', this.closeByMousedown, true)
    window.removeEventListener('keydown', this.closeByEsc, true)
  },

  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    },

    close() {
      this.isOpen = false
    },

    closeByMousedown(e) {
      if (!this.$el.contains(e.target)) {
        this.close()
      }
    },

    closeByEsc(e) {
      if (e.code === 'Escape') {
        this.close()
      }
    }
  }
}
</script>

<style>
.Dropdown {
  @apply relative;
}

.Dropdown__selected {
  @apply whitespace-nowrap relative flex items-center bg-white border rounded cursor-pointer select-none;

  &:hover {
    @apply text-black border-gray-400;
  }

  &.isOpen {
    @apply border-gray-600 shadow-none outline-none;
  }
}

.Dropdown__item {
  @apply whitespace-nowrap block px-4 py-2 font-normal rounded;

  &:hover {
    @apply text-black no-underline bg-gray-200;
  }

  & + & {
    /* @apply border-t; */
  }

  &--disabled {
    @apply font-normal text-gray-400 pointer-events-none;
  }
}

.Dropdown__menu {
  @apply top-full absolute left-0 z-10 hidden min-w-full p-2 my-2 bg-white border rounded-lg shadow-lg;

  &.isOpen {
    @apply block;
  }

  &.isOnRight {
    @apply right-0 left-auto;
  }
}
</style>

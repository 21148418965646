import { render, staticRenderFns } from "./PrivacyView.vue?vue&type=template&id=77d1ca0f&"
import script from "./PrivacyView.vue?vue&type=script&lang=js&"
export * from "./PrivacyView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
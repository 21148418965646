<template>
  <modal-layout :title="`Activities on ${date}`">
    <router-link
      v-for="activity in activities"
      :key="activity.id"
      :to="{ name: 'dashboard.activity', params: { id: activity.id } }"
      class="ActivityRow"
      @click.native="closeModal"
    >
      <h4 class="mb-0 text-lg font-bold">{{ activity.name }}</h4>
      <div class="flex w-full text-sm">
        <div>
          <svg v-if="activity.is_cycling" class="svg-icon svg-icon--sm mr-1 text-gray-600">
            <use xlink:href="#icon-cycling"></use>
          </svg>
          <svg v-else-if="activity.is_running" class="svg-icon svg-icon--sm mr-1 text-gray-600">
            <use xlink:href="#icon-running"></use>
          </svg>
          <svg v-else-if="activity.is_swimming" class="svg-icon svg-icon--sm mr-1 text-gray-600">
            <use xlink:href="#icon-swimming"></use>
          </svg>
          {{ activity.start_date_local | datetime }}
        </div>
        <div class="ml-auto">
          {{ activity.moving_time | format('duration') }}
          <span class="mx-1">/</span>
          {{ activity.distance | format('longdistance') }}
        </div>
      </div>
    </router-link>
  </modal-layout>
</template>

<script>
import date from 'filters/date'
export default {
  props: {
    activities: {
      type: Array,
      required: true
    }
  },

  computed: {
    date() {
      return date(this.activities[0].start_date_local)
    }
  },

  methods: {
    closeModal(e) {
      e.metaKey === false && this.$emit('close', false)
    }
  }
}
</script>

<style lang="postcss" scoped>
.ActivityRow {
  @apply block p-4 px-6 -mx-6 border-t cursor-pointer;

  &:hover {
    @apply no-underline bg-gray-100;
  }

  &:last-child {
    @apply border-b;
  }
}
</style>

<template>
  <div class="bg-gray-100">
    <Header />

    <div class="lg:py-32 w-full p-4 py-8 bg-gray-100">
      <h1 class="md:text-5xl mb-8 text-4xl font-bold leading-tight text-center">
        Need help
        <br />
        or have questions?
      </h1>
      <div class="md:w-128 mx-auto mb-8">
        <p class="text-2xl font-bold text-center">
          Get in touch with us at
          <a class="text-primary underline cursor-pointer" href="mailto:hello@formbeat.com">support@formbeat.com</a>
        </p>
      </div>

      <div class="max-w-xl mx-auto mt-32">
        <h2 class="mb-8 text-4xl font-bold leading-tight">Frequently Asked Questions</h2>

        <div v-for="(faq, index) in faqs" :key="index" class="mb-8">
          <h4 class="mb-2 text-xl font-bold">
            {{ faq.title }}
          </h4>
          <p class="text-lg text-gray-800">
            {{ faq.content }}
          </p>
        </div>
      </div>
    </div>

    <div class="py-16 bg-white">
      <Testimonials class="xl:w-256 w-full mx-auto" />
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from 'components/Header'
import Footer from 'components/Footer'
import Testimonials from 'components/Testimonials'

const faqs = [
  {
    title: "I'm new to cycling – is it for me?",
    content:
      'Athletes of any fitness level can use FormBeat to track their progress and training load. If you are new to cycling, our dashboard will guide you through different metrics and explain why they are important for your training.',
    isOpen: false
  },
  {
    title: 'What equipment do I need to use FormBeat?',
    content:
      'Our analytics and optimal training load prediction works without any sensors. For best results, we recommend using activities with data from heart rate and/or power meter sensors.',
    isOpen: false
  }
]

export default {
  components: {
    Header,
    Footer,
    Testimonials
  },

  data() {
    return {
      faqs
    }
  },
  metaInfo() {
    return {
      title: 'Help & Support',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Try FromBeat for 30 days - no credit card required'
        }
      ]
    }
  }
}
</script>

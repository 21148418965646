<template>
  <i class="Icon icon" :class="['icon-' + name]" :style="{ fontSize: size ? `${size}px` : '' }"></i>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },

    size: {
      type: [Number, String]
    }
  }
}
</script>

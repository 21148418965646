<template>
  <div class="bg-gray-100">
    <Header />

    <Pricing id="pricing" class="lg:py-32 w-full py-8 bg-gray-100" />

    <div class="py-16 bg-white">
      <Testimonials class="xl:w-256 w-full mx-auto" />
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from 'components/Header'
import Footer from 'components/Footer'
import Pricing from 'components/Pricing'
import Testimonials from 'components/Testimonials'

export default {
  components: {
    Header,
    Footer,
    Pricing,
    Testimonials
  },
  metaInfo() {
    return {
      title: 'Pricing',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Try FromBeat for 30 days - no credit card required'
        }
      ]
    }
  }
}
</script>

<template>
  <div v-if="user" class="Dashboard" :class="{ 'Dashboard--no-sidebar': isSidebarHidden }">
    <div class="Dashboard__header w-full">
      <router-link :to="{ name: 'dashboard.trends' }" class="mr-16">
        <Logo />
      </router-link>

      <div class="hover-text-primary font-extralight md:hidden px-4 ml-auto -mr-4 text-4xl cursor-pointer select-none" @click="toggleNavigation()">=</div>

      <!-- <portal-target name="header" class="flex-grow"> -->
      <!--
        This component can be located anywhere in your App.
        The slot content of the above portal component will be rendered here.
        -->
      <!-- </portal-target> -->
    </div>
    <div class="Dashboard__content">
      <div class="Dashboard__sidebar" :class="{ 'Dashboard__sidebar--isOpen': isNavigationOpen }" @click="toggleNavigation(false)">
        <nav class="Navigation md:fixed md:p-8 md:pl-0 flex-col justify-between">
          <div class="flex flex-col flex-grow">
            <router-link :to="{ name: 'dashboard.trends' }" class="Navigation__item">
              <svg class="svg-icon svg-icon--sm mr-3 text-gray-800">
                <use xlink:href="#icon-power"></use>
              </svg>
              Dashboard
            </router-link>
            <router-link :to="{ name: 'dashboard.analyze' }" class="Navigation__item">
              <svg class="svg-icon svg-icon--sm mr-3 text-gray-800">
                <use xlink:href="#icon-zone-analysis"></use>
              </svg>
              Analysis
            </router-link>
            <router-link :to="{ name: 'dashboard.calendar' }" class="Navigation__item">
              <svg class="svg-icon svg-icon--sm mr-3 text-gray-800">
                <use xlink:href="#icon-calendar"></use>
              </svg>
              Calendar
            </router-link>
            <router-link :to="{ name: 'dashboard.activities' }" class="Navigation__item">
              <svg class="svg-icon svg-icon--sm mr-3 text-gray-800">
                <use xlink:href="#icon-list"></use>
              </svg>
              Activities
            </router-link>
            <router-link :to="{ name: 'dashboard.achievements' }" class="Navigation__item">
              <svg class="svg-icon svg-icon--sm mr-3 text-gray-800">
                <use xlink:href="#icon-medal"></use>
              </svg>
              Achievements
            </router-link>
            <router-link v-if="user.admin" :to="{ name: 'admin.users' }" class="Navigation__item">
              <svg class="svg-icon svg-icon--sm mr-3 text-gray-800">
                <use xlink:href="#icon-info"></use>
              </svg>
              Admin
            </router-link>
          </div>
          <div class="md:mb-0 flex flex-col mb-12">
            <router-link :to="{ name: 'settings' }" class="Navigation__item flex">
              <img v-if="user.profile.includes('http')" :src="user.profile" class="w-8 h-8 mr-2 rounded-full" />
              <img v-else src="~/images/icons/default-avatar.jpg" class="w-8 h-8 mr-2 rounded-full" />
              {{ user.first_name }}
            </router-link>
            <router-link :to="{ name: 'dashboard.changelog' }" class="Navigation__item md:block hidden">What's New</router-link>
            <a href class="Navigation__item" @click.prevent="logout()">Logout</a>
          </div>
        </nav>
      </div>
      <div class="Dashboard__main">
        <router-view
          v-if="activities"
          v-show="!isLoading"
          :user="user"
          :activities="activities"
          :all-activities="allActivities"
          :summary="summary"
          :fitness="fitness"
          :date-range="dateRange"
          :current-summary="currentSummary"
          :dashboard-date-range="dashboardDateRange"
          :dashboard-current-summary="dashboardCurrentSummary"
          :segment-by="segmentBy"
          :units="user.measurement_preference"
        />
        <div v-if="isLoading">
          <ContentPlaceholder />
        </div>
        <img v-if="!isLoading" src="~images/strava-badge-alt.svg" alt="" class="w-24 my-2 ml-auto" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ContentPlaceholder from 'components/ContentPlaceholder'

export default {
  components: {
    ContentPlaceholder
  },

  channels: {
    WebNotificationsChannel: {
      connected() {},
      rejected() {},
      received(data) {},
      disconnected() {}
    }
  },

  data() {
    return {
      isNavigationOpen: false
    }
  },

  computed: {
    ...mapGetters('profile', [
      'user',
      'summary',
      'activities',
      'allActivities',
      'fitness',
      'segmentBy',
      'dateRange',
      'currentSummary',
      'dashboardDateRange',
      'dashboardCurrentSummary',
      'isLoading'
    ]),

    isSidebarHidden() {
      return this.$route.matched.some((route) => route.meta.hideSidebar === true)
    }
  },

  mounted() {
    this.loadProfile()

    this.$cable.subscribe({
      channel: 'WebNotificationsChannel'
    })
  },

  methods: {
    ...mapActions('profile', ['loadProfile', 'signOut']),

    toggleNavigation(isOpen) {
      this.isNavigationOpen = isOpen === undefined ? !this.isNavigationOpen : isOpen
    },

    async logout() {
      await this.signOut()
      const { href } = this.$router.resolve({ name: 'home' })

      window.location = window.location.origin + href
    }
  },

  metaInfo() {
    return {
      title: 'Dashboard'
    }
  }
}
</script>

<style lang="postcss" scoped>
.Dashboard {
  @apply flex flex-col w-full h-full;

  &__header {
    @apply fixed top-0 z-50 flex items-center justify-between h-16 px-8 bg-white shadow;
  }

  &__content {
    @apply flex w-full pt-16;
  }

  &__sidebar {
    @apply fixed inset-x-0 top-0 bottom-0 right-0 z-40 hidden mt-16 bg-white select-none;

    &--isOpen {
      @apply block;
    }

    @screen md {
      @apply relative block bg-transparent;
      width: 14rem;
      flex: 0 0 auto;
    }
  }

  &__main {
    @apply relative w-full p-4;

    @screen md {
      @apply p-8;
      width: calc(100% - 14rem);
    }
  }

  &--no-sidebar {
    @screen md {
      .Dashboard__main {
        width: 100%;
      }

      .Dashboard__sidebar {
        @apply hidden;
      }
    }
  }
}

.Logo {
  @apply pl-8 mt-2 mb-4 font-bold;
}

.Navigation {
  @apply flex flex-col;
  top: theme('spacing.16');
  height: calc(100vh - theme('spacing.16'));

  &__item {
    @apply flex items-center p-3 pl-6 no-underline border-b cursor-pointer;
    border-left: 4px solid transparent;

    @screen md {
      @apply p-4 pl-8 border-b-0;
    }

    &.router-link-active {
      @apply font-medium;
      border-left-color: theme('colors.primary');
    }
  }
}
</style>

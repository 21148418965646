<template>
  <div>
    <Header />
    <Changelog />
    <Footer />
  </div>
</template>

<script>
import Header from 'components/Header'
import Footer from 'components/Footer'
import Changelog from 'components/Changelog'

export default {
  components: {
    Header,
    Footer,
    Changelog
  },
  metaInfo() {
    return {
      title: "What's new?",
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Discover features recently added to FormBeat cycling Analytics'
        }
      ]
    }
  }
}
</script>

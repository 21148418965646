import axios from 'axios'

import { garmin_authorize_success_path, garmin_authorize_url_path, garmin_deauthorize_path } from 'routes'

export default {
  connect() {
    return axios.post(garmin_authorize_url_path()).then((response) => (window.location = response.data.url))
  },

  authorize(code) {
    return axios.post(garmin_authorize_success_path(), { code }).then((response) => response.data.user)
  },

  deauthorize() {
    return axios.post(garmin_deauthorize_path()).then((response) => response.data)
  }
}

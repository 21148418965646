import trainingStatusColors from 'constants/trainingStatusColors'

export default {
  'form-fitness': {
    title: 'Form & Fitness',
    description: `Our Form & Fitness chart helps you to track and predict your level of Fitness, Fatigue, and your cycling Form.

Form & Fitness chart in Formbeat is personalized for every rider and helps you to understand the cumulative effect of your training and it is calculated using the Training Stress Score (TSS) of every ride.

**"Fitness"** represents your accumulated fitness level and builds up as you train. If you don't log and rides in a longer time, your Fitness level will eventually start to slowly decline.

**"Fatigue"** represents the tiredness of your body after training. It will increase with each ride and accumulate after a few hard efforts, but it will quickly drop after a few days of lighter training or recovery. A high level of fatigue has a negative impact on your performance, even if your fitness level is high.

**"Form"** represents your predicted, momentary cycling performance and it is a difference between fitness and fatigue. You are in your best possible form when your fitness level is high fatigue is low.
`,
    image: require('images/features/form-and-fitness.png')
  },
  'training-load': {
    title: 'Training Load',
    image: require('images/features/training-load-chart.png'),
    description: `**Training Load** represents the accumulated Training Stress Score from activities in a given week.
It is a great way to see how hard you have trained each week.

Besides your measured training load, FormBeat will calculate your **optimal training load range** for each week, which is based on your past training.
Training in the optimal range will help you maintain and improve your overall fitness.
    `
  },
  'training-status': {
    title: 'Training Status',
    image: require('images/features/training-status.png'),
    description: `See how your training pays off week by week. FormBeat evaluates your weekly progress and shows you how productive was your training.

Training Status helps you to understand the fitness impact of training in a selected period of time. It's a result of your actual training load in a 7-day period compared to the optimal training load predicted for the same period of time, which is based on your fitness and previous load.

Training Status will be presented in the following form:

### <span class="text-2xl" style="color: ${trainingStatusColors['Overreaching']}">&bull;</span> Overreaching
Your training load is too high and your body needs a recovery period. Make sure to add lighter, recovery activities.

### <span class="text-2xl" style="color: ${trainingStatusColors['Productive']}">&bull;</span> Productive
Your training had a positive impact and increased your overall fitness.

### <span class="text-2xl" style="color: ${trainingStatusColors['Maintaining']}">&bull;</span> Maintaining
Enough training load to maintain your fitness level.

### <span class="text-2xl" style="color: ${trainingStatusColors['Recovery']}">&bull;</span> Recovery
Lighter training load after a period of more intensive training.

### <span class="text-2xl" style="color: ${trainingStatusColors['Detraning']}">&bull;</span> Detraining
Training load is too low and your fitness is decreasing.
    `
  },
  'normalized-power': {
    title: 'Normalized Power® (NP)',
    description: `**Normalized Power** (NP or Weighted Average Power) is an estimate of the power that you could have maintained for the same physiological "cost" if your power had been perfectly constant.

This metric smooths out the variability in the power of your ride caused by different factors (e.g. terrain, wind, grade, etc.) to provide a better indicator of your overall effort and allows you to compare your effort between different rides.

Normalized power is an important metric which we use to calculate the Training Stress Score.
    `
  },
  'max-power': {
    title: 'Maximum Power',
    image: require('images/features/peak-power.png'),
    description: `Maximum power output is an important metric which represents the average maximum power in Watts an athlete can sustain for a given period of time.

Our Peak Power chart will help you understand improvements to your maximum power output for time periods of one second to one hour.
This chart is complementary to the Power Curve chart, but it allows you to see the variability and improvement of your maximum power over at a given breakpoint over time.

You can display and analyze your maximum power output for various periods (e.g. Past 30 days, This Year, All Time) and segment it by day, week, month or year.
    `
  },
  'functional-threshold-power': {
    title: 'Functional Threshold Power (FTP)',
    description: `Functional threshold power (or FTP) is one of the most important metrics used in cycling. It is defined as a maximum mean power that a cyclist can sustainably produce for the period of one hour and it is measured in watts.

FTP is a benchmark that can be used to measure fitness and predict performance. Functional Threshold Power is critical for analyzing your workouts and seeing long-term improvements.

There are a number of reasons to measure your Functional Threshold Power (FTP). Knowing your FTP will show you how hard are your workouts and just how much improvement you've made. If you're training for a race, accurate FTP measurements will help find the right balance of interval work and longer, sustained efforts.`
  },
  'tss': {
    title: 'Training Stress Score® (TSS)',
    description: `Training Stress Score is a way of measuring overall training load (stress put on the body) for a given training session and it is a measure of the intensity and duration of a ride.
TSS® is calculated using Normalized Power, Intensity Factor, and ride duration.
Training Stress Score of 100 points represents an hour-long workout at 100% intensity.

Training Stress Score can be also helpful to determine the required rest or recovery period after each activity:

- **0 – 150** - low stress, recovery for the ride will likely be finished by the next day
- **150 – 300** - medium stress, possible leftover tiredness the next day, but gone by 2nd day
- **300 – 450** - high stress, some tiredness even after two days
- **450+** - very high stress, likely tired for several days after the ride
    `
  },
  'intensity': {
    title: 'Intensity Factor® (IF)',
    description: `Use Intensity Factor to easily see how hard was a ride compared to your overall fitness.
It is calculated as a ratio of the workout's Normalized Power and your Functional Threshold Power.

For example, an intensity of 100% represents a ride with a Normalized Power equivalent to your FTP.
`
  },
  'variability-index': {
    title: 'Variability Index',
    description: `Variability Index measures how smooth (evenly paced) or variable (with frequent changes) was your power output during your ride.
We calculate it by dividing Normalized Power of activity by its Average Power.
    `
  },
  'efficiency-factor': {
    title: 'Efficiency Factor',
    description: `Efficiency Factor (EF) is calculated as Normalized Power divided by Average Heart Rate and represents the number of watts you can produce per heartbeat – higher EF number is better. If you can increase the watts for each beat, you’re improving efficiency.

Example: 236W Normalized power / 147 Average Heart Rate = 1.60 EF

**How to use the Efficiency Factor (EF)?**

The Efficiency Factor is often used in the base training for tracking your aerobic fitness – a productive aerobic base training should result in increased EF over a few weeks.
`
  },
  'aerobic-decoupling': {
    title: 'Aerobic Decoupling',
    description: `Aerobic decoupling is an important metric expressing the ability to maintain a steady effort below the anaerobic threshold for a long period of time and with minimal fatigue.

Decoupling happens when Heart Rate or Power start to drift away from each other (either by increased heart rate while maintaining the same power output, or by a drop in power output while keeping the same average heart rate).

In other words, the longer can ride without seeing a significant decoupling between your Heart Rate and Power, the more fit you are.

Aerobic decoupling is a great indicator of endurance and aerobic base training which is an important part of the training at the beginning of every season.
Aerobically fit endurance athletes are usually able to maintain a decoupling ratio of less than 5%.
`
  },
  'hrss': {
    title: 'Heart Rate Stress Score (hrTSS)',
    description: `Heart Rate Training Stress Score helps to represent the physiological cost of an individual activity. It is derived from the time spent in each heart rate training zone.

We use hrTSS to calculate the Training Load of an activity in case we can't use other, usually more accurate methods, such as data from a power meter.
    `
  },
  'trimp': {
    title: 'Training Impulse (TRIMP)',
    description: `TRaining IMpulse or (TRIMP) is an algorithm that estimates the training load and training stress to your body based on heart rate data and duration of the workout.
    `
  },
  'power-to-weight': {
    title: 'Power to Weight Ratio',
    description: `Power to Weight Ratio (or Watts per Kilo, W/kg) is your maximum power output divided by your body weight in kilograms.

For example, a rider with a weight of 70kg and sustainable power of 280 Watts will have power to weight ratio of 4W/kg (=280/70)`
  },
  'power-profile': {
    title: 'Power Profile',
    image: require('images/features/power-profile.png'),
    description: `Power Profile Chart shows how does your maximum power output compare to the riders of various fitness and experience level, ranging from novice to the world-class, pro road cyclists.
This chart uses a large dataset created by Dr. Andrew Coggan and compares riders through their power to weight ratio (Watts-per-kilo, W/Kg).
    `
  },
  'power-recency': {
    title: 'Power Curve Recency',
    image: require('images/features/power-recency.png'),
    description: `Our Power Curve Recency chart will help you understand how recent (versus how old) are your best maximum power efforts.

Records that you have made more recently are prominently displayed in the chart to help you easily identify your recent improvements.`
  }
}

<template>
  <div class="OnboardingStep">
    <div class="OnboardingStep__header">
      <h2>Notifications</h2>
    </div>

    <div class="label">Manage Email Notifications</div>

    <div class="FormGroup FormGroup--last">
      <label class="FormGroup__label cursor-pointer select-none">
        <Checkbox v-model="form.email_notifications" class="mr-2" @input="update($event)" />

        Receive Weekly Summary Email?
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      required: false
    }
  },

  methods: {
    update() {
      this.$emit('update', this.form)
    }
  }
}
</script>

export default function dateFormat(segmentBy = 'day') {
  switch (segmentBy) {
    case 'year':
      return '%Y'
    case 'month':
      return '%b %Y'
    case 'week':
      return '%b %d %Y / Week %W'
    default:
      return '%A, %b %d %Y'
  }
}

<template>
  <div class="Hero">
    <div class="relative w-full h-full select-none">
      <video
        :poster="require('images/homepage-video-poster.jpg') | imageUrl"
        loop="loop"
        autoplay="autoplay"
        playsinline=""
        muted="muted"
        disablepictureinpicture="disablepictureinpicture"
        class="Hero__video"
      >
        <source src="~videos/homepage-video-optimized.mp4" type="video/mp4" />
        <source src="~videos/homepage-video-optimized.webm" type="video/webm" />
      </video>

      <div class="Hero__overlay">
        <div class="lg:p-32 lg:-mt-12 p-8 -mt-8">
          <div class="Hero__heading">Faster,</div>
          <div class="Hero__heading">Further.</div>
          <h1 class="md:w-1/2 md:text-2xl my-6 text-xl font-normal">Intuitive, AI-powered cycling analytics that help you improve your performance.</h1>
          <div class="sm:flex-row flex flex-col items-center">
            <AppButton
              primary
              class="sm:w-auto w-full p-0 mt-2 text-center rounded"
              style="background: #fc4c01"
              aria-label="Connect with Strava"
              @click="connectStrava()"
            >
              <img src="~images/connect-with-strava.svg" alt="" class="inline-block cursor-pointer" width="256" />
            </AppButton>
            <!--<router-link :to="{name: 'join'}" class="w-full sm:w-auto mt-2">
              <AppButton primary class="w-full">
                Get Started
              </AppButton>
            </router-link>-->
            <div class="mx-6 my-2 text-center">or</div>

            <router-link :to="{ name: 'home', query: { section: 'features' } }" class="hover:underline text-xl text-white">Learn more</router-link>

            <img src="~images/strava-badge-white.svg" alt="" class="absolute top-0 right-0 w-24 mt-8 mr-8" />
          </div>
          <div class="md:absolute md:bottom-0 lg:px-32 md:mb-16 md:mt-0 md:px-8 left-0 right-0 mt-16 mb-4">
            <div class="md:flex-row md:space-y-0 flex flex-col items-center justify-between space-y-12">
              <div class="md:w-auto w-full">
                <div class="md:text-left text-xs font-bold uppercase">Works with</div>
                <div class="md:justify-start md:space-x-8 md:space-y-0 md:h-12 flex flex-wrap items-end justify-start space-x-4 space-y-4 opacity-75">
                  <img src="~images/logos/strava.svg" alt="Strava" class="w-24" />
                  <img src="~images/logos/garmin.svg" alt="Garmin" class="w-24" />
                  <img src="~images/logos/zwift.svg" alt="Zwift" class="w-24" />
                  <img src="~images/logos/suunto.svg" alt="Suunto" class="w-24" />
                  <img src="~images/logos/polar.svg" alt="Polar" class="w-24" />
                  <div class="text-sm">&amp; more...</div>
                </div>
              </div>

              <div class="md:hidden xl:block md:w-auto block w-full">
                <div class="md:text-left text-xs font-bold uppercase">Featured in</div>
                <div class="md:justify-start md:space-x-8 md:space-y-0 md:h-12 flex flex-wrap items-end justify-start space-x-4 space-y-4 opacity-75">
                  <img src="~images/logos/roadcc.svg" alt="Road.cc" class="w-40" />
                  <img src="~images/logos/cobbles.png" alt="Cobbles cycling" class="w-20" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StravaService from 'services/StravaService'
import AnalyticsService from 'services/AnalyticsService'

export default {
  methods: {
    async connectStrava() {
      StravaService.connect(this.privateScope)
      AnalyticsService.trackEvent('conversion', 'connect-with-strava')
      AnalyticsService.ahoyTrackEvent('connect-with-strava')
    }
  }
}
</script>

<style lang="postcss" scoped>
.Hero {
  @apply relative w-full;
  max-height: 1020px;
  height: 120%;

  @screen md {
    @apply h-full;
  }
}
.Hero__overlay {
  @apply flex items-center text-white;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 3;
  background: rgba(6, 14, 10, 0.4);
}
.Hero__video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  object-fit: cover;
}
.Hero__heading {
  @apply font-bold;
  font-size: 4rem;
  line-height: 1;

  @media only screen and (min-width: theme('screens.md')) {
    font-size: 8rem;
  }
}
</style>

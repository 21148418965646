<template>
  <div class="bg-gray-100">
    <Header />

    <div class="container">
      <div class="lg:flex-row flex flex-col items-center">
        <div class="lg:w-1/2 min-h-128 w-3/4 pt-16">
          <img src="~/images/features/mobile-app.png" alt="FormBeat cycling analytics app" />
        </div>
        <div class="lg:w-1/2 lg:-mt-16 w-3/4">
          <h1 class="text-5xl leading-tight">FormBeat Training App</h1>
          <p class="lg:w-96 text-xl leading-loose">Stay on top of your training on the go. Download FormBeat mobile app for your iOS or Android device.</p>
          <div class="flex mt-8 space-x-4">
            <a href="https://apps.apple.com/us/app/formbeat/id1539444595" target="_blank">
              <img src="~/images/icons/download-app-store.svg" alt="Cycling training app for iOS" class="h-16" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.formbeat.app" target="_blank">
              <img src="~/images/icons/download-google-play.svg" alt="Cycling training app for Android" class="h-16" />
            </a>
          </div>

          <div class="mt-8">
            <img src="~images/strava-badge-alt.svg" alt="" class="w-32" />
          </div>
        </div>
      </div>
    </div>

    <div class="md:py-16 md:pt-32 lg:py-24 py-6 bg-white">
      <FeaturesMetrics title="Mobile App Features" class="xl:w-256 w-full mx-auto" />
    </div>

    <Testimonials />

    <Footer />
  </div>
</template>

<script>
import Header from 'components/Header'
import Footer from 'components/Footer'
import FeaturesMetrics from 'components/FeaturesMetrics'

export default {
  components: {
    Header,
    Footer,
    FeaturesMetrics
  },
  metaInfo() {
    return {
      title: 'Training app for iOS and Android',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Stay on top of your training on the go. Download FormBeat mobile app for your iOS or Android device.'
        }
      ]
    }
  }
}
</script>

import ProfileService from 'services/ProfileService'
import StorageService from 'services/StorageService'

import config from 'config'
import mixpanel from 'mixpanel'
import sentry from 'sentry'

import dayjs from 'utils/dayjs'
import calculateRange from 'helpers/calculateRange'
import { parseSummary, calculateCurrentSummary } from 'utils/activity'

const PROFILE_STORAGE_KEY = 'profile'

const dateRangeOptions = calculateRange()
const defaultDateRange = dateRangeOptions[3]

const types = {
  APP_INITIALIZED: 'APP_INITIALIZED',
  APP_LOADING_SET: 'APP_LOADING_SET',
  USER_SET: 'USER_SET',
  SUMMARY_SET: 'SUMMARY_SET',
  ACTIVITIES_SET: 'ACTIVITIES_SET',
  ALL_ACTIVITIES_SET: 'ALL_ACTIVITIES_SET',
  FITNESS_SET: 'FITNESS_SET',
  DATE_RANGE_SET: 'DATE_RANGE_SET',
  CURRENT_SUMMARY_SET: 'CURRENT_SUMMARY_SET',
  DASHBOARD_DATE_RANGE_SET: 'DASHBOARD_DATE_RANGE_SET',
  DASHBOARD_CURRENT_SUMMARY_SET: 'DASHBOARD_CURRENT_SUMMARY_SET',
  SEGMENT_BY_SET: 'SEGMENT_BY_SET',
  ACHIEVEMENTS_SET: 'ACHIEVEMENTS_SET',
  FIRST_ACTIVITY_DATE_SET: 'FIRST_ACTIVITY_DATE_SET',
  REFRESHED_AT_SET: 'REFRESHED_AT_SET'
}

const defaultState = () => ({
  user: config.user,
  summary: undefined,
  activities: undefined,
  allActivities: undefined,
  fitness: undefined,
  achievements: undefined,
  currentSummary: undefined,
  dateRange: defaultDateRange,
  dashboardCurrentSummary: undefined,
  dashboardDateRange: defaultDateRange,
  segmentBy: 'week',
  dateRangeOptions: dateRangeOptions,
  segmentByOptions: ['day', 'week', 'month', 'year'],
  refreshedAt: undefined
})

const persistedState = () => {
  const data = StorageService.load(PROFILE_STORAGE_KEY, {})

  // Recalculate selected date range option
  if (data?.dateRange) {
    data.dateRange = dateRangeOptions.find(({ id }) => id === data.dateRange.id)
  }

  if (data?.dashboardDateRange) {
    data.dashboardDateRange = dateRangeOptions.find(({ id }) => id === data.dashboardDateRange.id)
  } else if (data?.dateRange) {
    data.dashboardDateRange = dateRangeOptions.find(({ id }) => id === data.dateRange.id)
  }

  if (data && data.refreshedAt) {
    try {
      const now = new Date()
      const lastRefresh = new Date(data.refreshedAt)
      if ((now - lastRefresh) / 1000 / 60 / 60 > 1) {
        delete data.summary
        delete data.activities
        delete data.allActivities
        delete data.fitness
        delete data.currentSummary
        delete data.dashboardCurrentSummary
      }
    } catch (e) {
      delete data.summary
      delete data.activities
      delete data.allActivities
      delete data.fitness
      delete data.currentSummary
      delete data.dashboardCurrentSummary
    }
  }

  return data
}

const getInitialState = () => ({
  ...defaultState(),
  ...persistedState(),
  isLoading: false,
  isInitialized: false,
  errors: {}
})

const state = getInitialState()

const actions = {
  async init({ commit }) {
    try {
      const user = await ProfileService.getUser()
      commit(types.USER_SET, { user })

      if (window.tidioChatApi) {
        window.tidioChatApi.setVisitorData({
          distinct_id: user.id,
          email: user.email,
          name: `${user.first_name} ${user.last_name}`,
          city: user.city,
          country: user.country
        })
      }

      mixpanel.people.set({
        '$email': user.email,
        'sex': user.sex,
        'ftp': user.ftp,
        'summit': user.summit,
        'premium': user.premium,
        'has_powermeter': user.has_powermeter,
        'follower_count': user.follower_count,
        'email_notifications': user.email_notifications,
        'authorized_with_garmin': user.authorized_with_garmin,
        'authorized_with_strava': user.authorized_with_strava
      })

      mixpanel.identify(user.id)
      sentry.setUser({ id: user.id })

      if (window.hj) {
        window.hj('identify', user.id)
      }
    } catch (e) {
      if (e?.response?.status != 401) {
        sentry.captureException(e)
      }
    }

    commit(types.APP_INITIALIZED)
  },

  refreshCurrentSummary({ commit, state, dispatch }) {
    const currentSummary = calculateCurrentSummary(state.summary, state.dateRange.from, state.dateRange.to)
    commit(types.CURRENT_SUMMARY_SET, { currentSummary })

    const dashboardCurrentSummary = calculateCurrentSummary(state.summary, state.dashboardDateRange.from, state.dashboardDateRange.to)
    commit(types.DASHBOARD_CURRENT_SUMMARY_SET, { dashboardCurrentSummary })

    dispatch('persist')
  },

  async loadProfile({ commit, dispatch, state }) {
    if (!state.activities) {
      commit(types.APP_LOADING_SET, true)
    }

    try {
      const activities = await ProfileService.loadActivities()
      const { summary, fitness, keys } = await ProfileService.loadSummary()

      // TODO: Remove later
      const parsedSummary = parseSummary(summary, keys)
      commit(types.SUMMARY_SET, { summary: parsedSummary })

      commit(types.ALL_ACTIVITIES_SET, { allActivities: activities })
      commit(types.ACTIVITIES_SET, { activities: [...activities].filter((a) => a.is_cycling) })
      commit(types.FITNESS_SET, { fitness })
      commit(types.REFRESHED_AT_SET, new Date())

      if (activities.length) {
        try {
          const firstActivityDate = dayjs(activities[activities.length - 1].start_date_local).startOf('day')
          commit(types.FIRST_ACTIVITY_DATE_SET, firstActivityDate)

          mixpanel.people.set({
            'fitness': fitness[fitness.length - 8]?.ctl,
            'activities_count': activities.length,
            'first_activity': activities[activities.length - 1].start_date_local,
            'last_activity': activities[0].start_date_local
          })

          const parsedSummaryKeys = Object.keys(parsedSummary.month)
          const parsedSummaryLength = parsedSummaryKeys.length

          if (parsedSummaryKeys && parsedSummaryLength > 1) {
            const parsedSummaryLastMonth = parsedSummary.month[parsedSummaryKeys[parsedSummaryLength - 2]]

            if (parsedSummaryLastMonth) {
              mixpanel.people.set({
                'monthly_distance': parsedSummaryLastMonth.distance,
                'monthly_average_distance': parsedSummaryLastMonth.average_distance,
                'monthly_moving_time': Number(((parsedSummaryLastMonth.moving_time || 0) / 3600).toFixed(1)),
                'monthly_average_moving_time': Number(((parsedSummaryLastMonth.average_moving_time || 0) / 3600).toFixed(1)),
                'monthly_tss': parsedSummaryLastMonth.tss,
                'monthly_average_watts': parsedSummaryLastMonth.average_watts
              })
            }
          }

          mixpanel.identify(String(state.user.id))
        } catch (e) {
          sentry.captureException(e)
        }
      }

      dispatch('refreshCurrentSummary')
    } catch (e) {
      sentry.captureException(e)
    }

    commit(types.APP_LOADING_SET, false)
  },

  async loadAchievements({ commit, dispatch }) {
    dispatch('setAppLoading', true)

    const result = await ProfileService.getAchievements()

    commit(
      types.ACHIEVEMENTS_SET,
      result.sort((a, b) => new Date(b.date) - new Date(a.date))
    )

    dispatch('setAppLoading', false)
  },

  setDateRange({ commit, dispatch, state }, { dateRange, type }) {
    if (type === 'dashboard') {
      commit(types.DASHBOARD_DATE_RANGE_SET, { dashboardDateRange: dateRange })
      dispatch('refreshCurrentSummary')
    } else {
      commit(types.DATE_RANGE_SET, { dateRange })

      if (['all'].includes(dateRange.id) && ['day', 'week'].includes(state.segmentBy)) {
        dispatch('setSegmentBy', 'month')
      } else if (['ytd', 'last_year', '365d'].includes(dateRange.id) && ['day'].includes(state.segmentBy)) {
        dispatch('setSegmentBy', 'week')
      } else if (['ytd', 'last_year', '365d'].includes(dateRange.id) && ['year'].includes(state.segmentBy)) {
        dispatch('setSegmentBy', 'month')
      } else if (['90d'].includes(dateRange.id) && ['year'].includes(state.segmentBy)) {
        dispatch('setSegmentBy', 'week')
      } else if (['30d'].includes(dateRange.id) && ['year', 'month'].includes(state.segmentBy)) {
        dispatch('setSegmentBy', 'week')
      } else if (['7d', '14d'].includes(dateRange.id) && ['week', 'month', 'year'].includes(state.segmentBy)) {
        dispatch('setSegmentBy', 'day')
      } else {
        dispatch('refreshCurrentSummary')
      }
    }
  },

  setSegmentBy({ commit, dispatch }, segmentBy) {
    commit(types.SEGMENT_BY_SET, segmentBy)
    dispatch('refreshCurrentSummary')
  },

  setAppLoading({ commit }, isLoading) {
    commit(types.APP_LOADING_SET, isLoading)
  },

  async signOut({ commit }) {
    try {
      const result = await ProfileService.signOut()
      commit(types.USER_SET, { user: undefined })
      StorageService.remove(PROFILE_STORAGE_KEY)

      return result
    } catch (e) {
      sentry.captureException(e)
    }
  },

  persist({ state }) {
    try {
      const persistedState = {
        segmentBy: state.segmentBy,
        dateRange: state.dateRange,
        summary: state.summary,
        activities: state.activities,
        allActivities: state.allActivities,
        fitness: state.fitness,
        currentSummary: state.currentSummary,
        dashboardDateRange: state.dashboardDateRange,
        dashboardCurrentSummary: state.dashboardCurrentSummary,
        refreshedAt: state.refreshedAt
      }

      StorageService.persist(PROFILE_STORAGE_KEY, persistedState)
    } catch (e) {
      const persistedState = {
        segmentBy: state.segmentBy,
        dateRange: state.dateRange,
        dashboardDateRange: state.dashboardDateRange,
        summary: state.summary
      }

      StorageService.persist(PROFILE_STORAGE_KEY, persistedState)
    }
  }
}

const mutations = {
  [types.APP_INITIALIZED](state) {
    state.isInitialized = true
  },

  [types.APP_LOADING_SET](state, isLoading) {
    state.isLoading = isLoading
  },

  [types.USER_SET](state, { user }) {
    state.user = user
  },

  [types.SUMMARY_SET](state, { summary }) {
    state.summary = summary
  },

  [types.CURRENT_SUMMARY_SET](state, { currentSummary }) {
    state.currentSummary = currentSummary
  },

  [types.ACTIVITIES_SET](state, { activities }) {
    state.activities = activities
  },

  [types.ALL_ACTIVITIES_SET](state, { allActivities }) {
    state.allActivities = allActivities
  },

  [types.FITNESS_SET](state, { fitness }) {
    state.fitness = fitness
  },

  [types.DATE_RANGE_SET](state, { dateRange }) {
    state.dateRange = dateRange
  },

  [types.DASHBOARD_DATE_RANGE_SET](state, { dashboardDateRange }) {
    state.dashboardDateRange = dashboardDateRange
  },

  [types.DASHBOARD_CURRENT_SUMMARY_SET](state, { dashboardCurrentSummary }) {
    state.dashboardCurrentSummary = dashboardCurrentSummary
  },

  [types.SEGMENT_BY_SET](state, segmentBy) {
    state.segmentBy = segmentBy
  },

  [types.ACHIEVEMENTS_SET](state, achievements) {
    state.achievements = achievements
  },

  [types.FIRST_ACTIVITY_DATE_SET](state, date) {
    state.dateRangeOptions[state.dateRangeOptions.length - 1].from = date
  },

  [types.REFRESHED_AT_SET](state, date) {
    state.refreshedAt = date
  }
}

const getters = {
  isInitialized(state) {
    return state.isInitialized
  },

  isLoading(state) {
    return state.isLoading
  },

  user(state) {
    return state.user
  },

  userWeight(state) {
    return state.user.weight
  },

  userWeightMetric(state) {
    return state.user.weight_metric
  },

  userFtp(state) {
    return state.user.ftp
  },

  userWKg(_, getters) {
    return getters['userFtp'] / getters['userWeightMetric']
  },

  activities(state) {
    return state.activities
  },

  allActivities(state) {
    return state.allActivities
  },

  summary(state) {
    return state.summary
  },

  fitness(state) {
    return state.fitness
  },

  achievements(state) {
    return state.achievements
  },

  currentSummary(state) {
    return state.currentSummary
  },

  dateRange(state) {
    return state.dateRange
  },

  dashboardCurrentSummary(state) {
    return state.dashboardCurrentSummary
  },

  dashboardDateRange(state) {
    return state.dashboardDateRange
  },

  segmentBy(state) {
    return state.segmentBy
  },

  dateRangeOptions(state) {
    return state.dateRangeOptions
  },

  segmentByOptions(state) {
    return state.segmentByOptions
  },

  errors(state) {
    return state.errors
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

<template>
  <StatsSection title="Ride Profile">
    <template #header>
      <div class="flex items-center space-x-3">
        <div class="text-muted">This ride vs.</div>
        <Dropdown right class="ml-8">
          <template #selected>
            <div class="w-36 flex items-center justify-between px-3 py-1 font-medium">
              <div>
                {{ selectedDateRange }}
              </div>
              <svg class="svg-icon">
                <use xlink:href="#icon-caret-down"></use>
              </svg>
            </div>
          </template>

          <template #options>
            <div class="w-auto">
              <a
                v-for="(option, index) in dateRangeOptions"
                :key="index"
                href
                class="Dropdown__item"
                :class="{ 'font-bold': option === selectedDateRange }"
                @click.prevent="setDateRange(option)"
              >
                {{ option }}
              </a>
            </div>
          </template>
        </Dropdown>
      </div>
    </template>

    <div class="text-muted px-6 mb-6 -mt-8 text-sm">
      This ride compared to the averages from
      <span v-if="selectedDateRange == dateRangeOptions[0]">
        <b>30 days before</b>
        the ride
      </span>
      <span v-if="selectedDateRange == dateRangeOptions[1]">
        <b>90 days before</b>
        the ride
      </span>
      <span v-if="selectedDateRange == dateRangeOptions[2]">
        all rides of
        <b>this year</b>
      </span>
      <span v-if="selectedDateRange == dateRangeOptions[3]"><b>all rides</b></span>
      .
    </div>

    <chart ref="chart" :options="chartData" :callback="setChart"></chart>
  </StatsSection>
</template>

<script>
import * as chartConfig from 'constants/chartConfig'

import chroma from 'chroma-js'

import dayjs from 'utils/dayjs'

import format from 'filters/format'
import formatUnits from 'filters/formatUnits'

import { dateIsBetween } from 'utils/date'

import StatsSection from 'components/StatsSection'

const dateRangeOptions = ['30-day trend', '90-day trend', 'This Year', 'All Time']

export default {
  components: {
    StatsSection
  },

  props: {
    activity: {
      type: Object,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    height: {
      type: Number,
      required: false,
      default: 460
    }
  },

  data() {
    return {
      chart: undefined,
      dateRangeOptions: dateRangeOptions,
      selectedDateRange: dateRangeOptions[1],
      attributes: [
        {
          name: 'Training Stress Score',
          key: 'tss',
          format: 'tss',
          icon: 'progress',
          color: chroma('#fe8364').hex()
        },
        {
          name: 'Normalized Power',
          key: 'computed_weighted_power',
          format: 'power',
          icon: 'power',
          color: chroma('#ac74ec').hex()
        },
        {
          name: 'Heart Rate',
          key: 'computed_average_heartrate',
          format: 'numeric',
          icon: 'heart',
          color: '#ec7474'
        },
        {
          name: 'Distance',
          key: 'distance',
          format: 'distance',
          icon: 'distance',
          color: '#a69085'
        },
        {
          name: 'Moving Time',
          key: 'moving_time',
          format: 'duration',
          icon: 'time',
          color: '#7b8f81'
        },
        {
          name: 'Average Speed',
          key: 'average_speed',
          format: 'speed',
          icon: 'distance',
          color: '#74d9ec'
        },
        {
          name: 'Elevation',
          key: 'total_elevation_gain',
          format: 'elevation',
          icon: 'elevation',
          color: '#d5ccc9'
        }
      ]
    }
  },

  computed: {
    chartUnits() {
      return formatUnits(this.format)
    },

    rideProfile() {
      let to = dayjs(this.activity.start_date_local)
      let from

      switch (this.selectedDateRange) {
        case dateRangeOptions[0]:
          from = to.subtract(30, 'days')
          break
        case dateRangeOptions[1]:
          from = to.subtract(90, 'days')
          break
        case dateRangeOptions[2]:
          to = dayjs()
          from = dayjs().startOf('year')
          break
        case dateRangeOptions[3]:
          to = dayjs()
          from = to.subtract(20, 'years')
          break
      }

      // const to = dayjs(this.activity.start_date_local)
      // const from = to.subtract(3, 'months')
      const activities = this.data.filter((a) => dateIsBetween(a.start_date_local, from, to))
      // const activities = this.data

      const profile = this.attributes.map((attribute) => {
        // get all values
        const values = activities
          .map((a) => Number(a[attribute.key]))
          .filter(Boolean)
          .filter((value) => Number(value) > 0)
        const value = Number(this.activity[attribute.key])
        let rank = undefined
        let avg = undefined

        if (value > 0) {
          const min = Math.min.apply(null, values)
          const max = Math.max.apply(null, values)
          const sum = values.reduce((a, b) => a + b, 0)
          avg = sum / values.length
          const scale = chroma.scale(['black', '#808080', 'white']).domain([min, avg, max])
          const result = scale(value)
          rank = result.num() / chroma('white').num()
        }

        return {
          ...attribute,
          values,
          value,
          avg,
          rank
        }
      })

      // return profile.filter(({ rank }) => Boolean(rank)).sort((a, b) => b.rank - a.rank)
      return profile.filter(({ rank }) => typeof rank !== 'undefined')
    },

    chartData() {
      const keys = this.rideProfile.map((a) => `<span class="font-bold">${a.name}</span><br>${format(a.value, a.format)}`)
      const data = this.rideProfile.map((a) => {
        return {
          y: a.rank,
          color: a.color,
          value: format(a.value, a.format),
          average: format(a.avg, a.format)
        }
      })

      return {
        ...chartConfig.defaultOptions,

        chart: {
          ...chartConfig.chart,
          height: this.$isMobile() ? this.height / 2 : this.height,
          animation: true,
          marginTop: 0,
          marginLeft: 0,
          marginRight: 0
        },
        exporting: {
          ...chartConfig.exporting,
          enabled: !this.$isMobile()
        },
        tooltip: {
          ...chartConfig.withTooltip,
          valueDecimals: 2,
          positioner: function (labelWidth, labelHeight, point) {
            var leftThird = point.plotX < this.chart.plotWidth / 2
            return {
              x: leftThird ? point.plotX + this.chart.plotLeft + 80 : this.chart.plotLeft + point.plotX - (labelWidth + 80),
              y: 20
            }
          },
          formatter: function () {
            return `<div class="w-48 mb-1 text-base font-bold">${this.x}</div>
              <div class="flex items-center text-sm font-medium">
                <svg class="mr-1" height="16" width="16"><circle cx="8" cy="8" r="4" fill="${this.points[0].color}" /></svg>
                <span class="font-bold">This Ride:</span>&nbsp;
                <span>${this.points[0].point.value}</span>
              </div>
              <div class="flex items-center text-sm font-medium">
                <svg class="mr-1" height="16" width="16"><circle cx="8" cy="8" r="4" fill="#eee" /></svg>
                <span class="font-bold">Average:</span>&nbsp;
                <span>${this.points[0].point.average}</span>
              </div>
            `
          }
        },
        xAxis: {
          type: 'category',
          categories: keys,
          margin: 0,
          padding: 0,
          labels: {
            // formatter: formatter,
            autoRotation: false,
            padding: 0,
            style: {
              fontSize: 14,
              lineHeight: 18
            }
          }
        },
        yAxis: [
          {
            // floor: 0,
            min: 0,
            max: 1,
            margin: 0,
            padding: 0,
            tickPositions: [0, 0.33, 0.66, 1],
            plotLines: [
              {
                color: 'rgba(80, 73, 73, 0.5)',
                dashStyle: 'LongDash',
                label: {
                  text: 'Average',
                  align: 'left',
                  x: 24
                },
                value: 0.5,
                width: 1,
                zIndex: 4
              }
            ],
            labels: {
              enabled: false
            },
            title: {
              enabled: false
            },
            plotBands: [
              {
                from: 0,
                to: 0.33,
                color: 'rgba(230, 230, 230, 0.1)'
              },
              {
                from: 0.33,
                to: 0.66,
                color: 'rgba(230, 230, 230, 0.2)'
              },
              {
                from: 0.66,
                to: 1,
                color: 'rgba(230, 230, 230, 0.25)'
              }
            ]

            // labels: {
            //   format: this.chartUnits ? `{value} ${this.chartUnits}` : '{value}'
            // }
          }
        ],
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true
            },
            marker: {
              enabled: false
            },
            enableMouseTracking: false
          },
          series: {
            shadow: false,
            pointPadding: 0.05,
            groupPadding: 0.05,
            borderWidth: 1,
            maxPointWidth: 64,
            crisp: false,
            marker: {
              enabled: false,
              symbol: 'circle'
            }
          }
        },
        series: [
          {
            type: 'column',
            colorByPoint: true,
            // name: this.title,
            data: data
          }
        ]
      }
    }
  },

  methods: {
    setChart(chart) {
      this.chart = chart
    },
    setDateRange(option) {
      this.selectedDateRange = option
    }
  }
}
</script>

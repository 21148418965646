var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tagName,_vm._g(_vm._b({tag:"component",staticClass:"AppButton button",class:{
    'isPrimary': _vm.primary,
    'isSecondary': _vm.secondary,
    'isLight': _vm.light,
    'isSmall': _vm.small,
    'isMedium': _vm.medium,
    'isExtraSmall': _vm.xsmall,
    'isExtraExtraSmall': _vm.xxsmall
  }},'component',_vm.$attrs,false),_vm.$listeners),[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="LoadingIcon" :class="{ 'LoadingIcon--inline': inline }">
    <div class="LoadingIcon__icon">&bull;</div>
    <div class="label">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    inline: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="postcss" scoped>
.LoadingIcon {
  @apply flex items-center justify-center;
}

.LoadingIcon--inline {
  @apply inline-flex;
}

.LoadingIcon__icon {
  @apply text-primary inline-flex mr-4 text-5xl;
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

<template>
  <chart ref="chart" :options="chartData" :callback="setChart"></chart>
</template>

<script>
import * as chartConfig from 'constants/chartConfig'

import dayjs from 'utils/dayjs'
import { dateIsBetween } from 'utils/date'

export default {
  props: {
    data: {
      type: Array,
      required: true
    },
    dateRange: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    height: {
      type: Number,
      required: false,
      default: 400
    }
  },

  data() {
    return {
      chart: undefined
    }
  },

  computed: {
    currentActivities() {
      return this.data.filter(({ start_date_local }) => dateIsBetween(start_date_local, this.dateRange.from, this.dateRange.to))
    },

    chartData() {
      const midnight = dayjs().startOf('day')
      const punchCard = []

      for (var y = 0; y < 7; y++) {
        for (var x = 0; x < 24; x++) {
          punchCard.push({
            y,
            x,
            count: 0
          })
        }
      }

      this.currentActivities.forEach((activity) => {
        const dayOfWeek = dayjs(activity.start_date_local).utc().format('d')
        const day = dayOfWeek == 0 ? 6 : dayOfWeek - 1
        const hour = dayjs(activity.start_date_local).utc().format('H')
        const index = Number(24 * day) + Number(hour)

        punchCard[index].count += 1
      })

      const maxValue = Math.max(...punchCard.map(({ count }) => count))

      const data = punchCard.map((record) => {
        const size = ((record.count / maxValue) * (this.$isMobile() ? 10 : 18)).toFixed(2)
        return {
          x: Number(midnight.add(record.x, 'hours').format('H')),
          y: record.y,
          count: record.count,
          marker: { radius: size }
        }
      })

      const series = [
        {
          data
        }
      ]

      return {
        ...chartConfig.defaultOptions,

        chart: {
          ...chartConfig.chart,
          height: this.$isMobile() ? this.height / 2 : this.height,
          marginLeft: 100,
          type: 'scatter'
        },
        exporting: {
          ...chartConfig.exporting,
          enabled: !this.$isMobile()
        },
        tooltip: {
          ...chartConfig.withTooltip,
          formatter: function () {
            return `<div class="font-bold">${this.point.options.count} activities</div>`
          }
        },

        colors: ['#ED561B'],
        xAxis: {
          margin: 0,
          padding: 0,
          tickInterval: 1,
          labels: {
            step: this.$isMobile() ? 4 : 1,
            formatter: function (point) {
              return midnight.add(point.value, 'hour').format('h a')
            },
            autoRotation: false,
            padding: 0
          }
        },

        yAxis: [
          {
            margin: 0,
            padding: 0,
            categories: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
            reversed: true,
            title: {
              enabled: false
            }
          }
        ],

        series: series
      }
    }
  },

  watch: {
    currentActivities: {
      immediate: false,
      handler() {
        setTimeout(() => {
          this.chart?.redraw()
        }, 100)
      }
    }
  },

  methods: {
    setChart(chart) {
      this.chart = chart
      this.chart.redraw()
    }
  }
}
</script>

<template>
  <div class="PowerProfile overflow-hidden">
    <table class="w-full table-auto">
      <thead>
        <tr>
          <th width="28%" class="py-2 pl-6 text-left">Category</th>
          <th width="18%" class="py-2 pl-6 text-left">5 sec Max Power</th>
          <th width="18%" class="py-2 pl-6 text-left">1 min Max Power</th>
          <th width="18%" class="py-2 pl-6 text-left">5 min Max Power</th>
          <th width="18%" class="py-2 pl-6 text-left">FTP</th>
        </tr>
      </thead>
    </table>

    <div class="relative">
      <div class="PowerProfileClass">
        <table class="w-full table-auto">
          <tbody>
            <tr v-for="(category, index) in eliteRangeCategories" :key="index">
              <td width="28%" class="px-6 py-2 border-t border-b">
                {{ category[0] }}
                <div class="text-muted -mt-1 text-sm">{{ category[1] }}</div>
              </td>
              <td width="18%" class="px-6 py-1 text-sm text-left text-gray-600 border">{{ eliteRange[index][0] }}</td>
              <td width="18%" class="px-6 py-1 text-sm text-left text-gray-600 border">{{ eliteRange[index][1] }}</td>
              <td width="18%" class="px-6 py-1 text-sm text-left text-gray-600 border">{{ eliteRange[index][2] }}</td>
              <td width="18%" class="px-6 py-1 text-sm text-left text-gray-600 border">{{ eliteRange[index][3] }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="PowerProfileValues">
        <div v-for="(effort, index) in efforts" :key="index" class="PowerProfileValues__item">
          <div
            v-for="(value, i) in effort"
            :key="i"
            class="PowerProfileValues__effort"
            :style="{ bottom: `${100 * value.value}%`, opacity: index == 3 ? '1' : '0.3' }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getElitePowerProfiles } from 'utils/powerCurve'

export default {
  props: {
    data: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    dateRange: {
      type: Object,
      required: false
    }
  },

  computed: {
    ...mapGetters('profile', ['user', 'userWKg', 'userWeightMetric', 'userFtp']),

    eliteProfiles() {
      return getElitePowerProfiles(this.user.sex)
    },

    eliteRangeCategories() {
      return [
        ['World Class', 'International Pro'],
        ['Exceptional', 'Domestic Pro'],
        ['Excellent', 'Cat 1'],
        ['Very Good', 'Cat 2'],
        ['Good', 'Cat 3'],
        ['Moderate', 'Cat 4'],
        ['Fair', 'Cat 5'],
        ['Novice', 'Non-racer']
      ]
    },

    eliteRange() {
      const ranges = []

      for (var i = 0; i < 9; i++) {
        ranges.push(
          this.eliteProfiles.map((range) => {
            const base = range[range.length - 1]
            const max = range[0]
            const step = (max - base) / 8

            return `> ${(max - step - i * step).toFixed(1)} W/kg`
          })
        )
      }

      return ranges
    },

    efforts() {
      let efforts = [[], [], [], []]
      const activities = this.data.filter((a) => a.power_curve)

      const eliteProfilesRanges = [
        this.eliteProfiles[0][0] - this.eliteProfiles[0][this.eliteProfiles[0].length - 1],
        this.eliteProfiles[1][0] - this.eliteProfiles[1][this.eliteProfiles[1].length - 1],
        this.eliteProfiles[2][0] - this.eliteProfiles[2][this.eliteProfiles[2].length - 1],
        this.eliteProfiles[3][0] - this.eliteProfiles[3][this.eliteProfiles[3].length - 1]
      ]

      activities.forEach((activity) => {
        activity.power_curve.forEach((datapoint) => {
          if ([5, 60, 300].includes(datapoint.t) && datapoint.v) {
            const wattsPerWeight = datapoint.v / this.userWeightMetric
            let dataIndex

            switch (datapoint.t) {
              case 5:
                dataIndex = 0
                break
              case 60:
                dataIndex = 1
                break
              case 300:
                dataIndex = 2
                break
            }

            const value = (wattsPerWeight - this.eliteProfiles[dataIndex][this.eliteProfiles[dataIndex].length - 1]) / eliteProfilesRanges[dataIndex]

            efforts[dataIndex].push({
              value: Math.max(0, value),
              wpkg: wattsPerWeight.toFixed(2)
            })
          }
        })
      })

      // Add FTP
      efforts[3] = [
        {
          value: (this.userWKg - this.eliteProfiles[3][this.eliteProfiles[3].length - 1]) / eliteProfilesRanges[3],
          wpkg: this.userWKg.toFixed(2)
        }
      ]

      // Take 10 best efforts
      efforts = efforts.map((e) => {
        return e.sort((a, b) => b.value - a.value).slice(0, 10)
      })

      return efforts
    }
  }
}
</script>

<style lang="postcss" scoped>
.PowerProfile {
  @apply relative;
}

.PowerProfileClass {
  @apply flex flex-col;

  &__item {
    @apply py-2 border-b;
  }
}

.PowerProfileValues {
  @apply absolute items-center justify-between;
  left: 28%;
  bottom: 0;
  right: 0;
  top: 0;

  display: flex;

  &__item {
    @apply relative flex-grow w-1/4 h-full text-center;
  }

  &__effort {
    @apply bg-primary absolute z-50 w-6 h-6 text-sm text-white border border-white rounded-full;
    left: 50%;
    transform: translate(-50%, 50%);
  }
}
</style>

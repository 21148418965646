<template>
  <div>
    <div class="md:block hidden">
      <a
        v-for="(option, index) in options"
        :key="index"
        href
        class="IntervalOption"
        :class="{ 'IntervalOption--selected': option == selectedOption }"
        @click.prevent="change(option)"
      >
        {{ label(option) }}
      </a>
    </div>
    <div class="md:hidden block">
      <Dropdown right>
        <template #selected>
          <div class="flex items-center justify-between px-3 py-1 font-medium">
            <div>
              {{ label(selectedOption) }}
            </div>
            <svg class="svg-icon">
              <use xlink:href="#icon-caret-down"></use>
            </svg>
          </div>
        </template>

        <template #options>
          <div class="w-32">
            <a
              v-for="(option, index) in options"
              :key="index"
              href
              class="Dropdown__item"
              :class="{ 'font-bold': option === selectedOption }"
              @click.prevent="change(option)"
            >
              {{ label(option) }}
            </a>
          </div>
        </template>
      </Dropdown>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true
    },
    selectedOption: {
      type: [String, Object, Array],
      required: true
    },
    labelAttribute: {
      type: String,
      required: false
    }
  },

  methods: {
    label(option) {
      return this.labelAttribute ? option[this.labelAttribute] : option
    },

    change(value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style></style>

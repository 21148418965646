<template>
  <div class="relative">
    <div class="md:flex text-muted absolute top-0 right-0 z-10 items-center hidden h-4 m-4 space-x-2 text-xs">
      <span>Smoothing</span>
      <input class="w-16" type="range" min="0" :value="selectedSmoothing" :max="smoothingOptions.length - 1" @change="setSmoothing" />
    </div>
    <chart ref="chart" :options="chartData" :callback="setChart" class="flex-grow"></chart>
  </div>
</template>

<script>
import * as chartConfig from 'constants/chartConfig'
import chroma from 'chroma-js'

import format from 'filters/format'
import formatUnits from 'filters/formatUnits'
import streamChartSeriesOptions from 'constants/streamChartSeriesOptions'

const debounce = require('lodash/debounce')
const allowedStreams = ['watts', 'heartrate', 'velocity_smooth', 'altitude', 'cadence']

export default {
  props: {
    activity: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    streams: {
      type: Object,
      required: false
    },
    height: {
      type: Number,
      required: false,
      default: 180
    },
    title: {
      type: String
    },
    type: {
      type: String,
      default: 'line'
    },
    units: {
      type: String,
      required: false
    },
    highlightedPoints: {
      type: Array,
      required: false
    },
    smoothingOptions: {
      type: Array,
      required: false
    },
    selectedSmoothing: {
      type: Number,
      required: false
    },
    showNavigator: {
      type: Boolean,
      required: false
    },
    zoomTo: {
      type: Array,
      required: false
    }
  },

  data() {
    return {
      chart: undefined,
      drag: false,
      position: undefined,
      referenceData: undefined,
      plotBands: [],
      streamChartSeriesOptions,
      allowedStreams,
      xAxisBase: 'distance',
      currentSelection: undefined,
      hr_colors: chroma.scale(['#ffeadd', '#e91500']).colors(5),
      power_colors: chroma.scale(['#e2e4ff', '#4d1bf5']).colors(7)
    }
  },

  computed: {
    chartUnits() {
      return formatUnits('distance')
    },

    activeStreamsCount() {
      return this.allowedStreams.filter((key) => this.streams[key] && this.streams[key].data.length).length
    },

    heartRateZones() {
      if (this.streams.heartrate?.data?.length && this.user.heartrate_zones && this.user.heartrate_zones.length) {
        return this.user.heartrate_zones
      }
      return
    },

    powerZones() {
      if (this.streams.watts?.data?.length && this.user.power_zones && this.user.power_zones.length) {
        return this.user.power_zones
      }
      return
    },

    hasGradeData() {
      return this.streams.grade_smooth?.data?.length && this.streams.grade_smooth.data.find((value) => Number(value) !== 0)
    },

    chartData() {
      const series = []
      const xAxis = []
      const yAxis = []

      const getHRZone = (value) => {
        for (var i = 4; i >= 0; i--) {
          if (Number(value) >= Number(this.heartRateZones[i])) {
            return i
          }
        }

        return null
      }

      const getPowerZone = (value) => {
        for (var i = 6; i >= 0; i--) {
          if (Number(value) >= Number(this.powerZones[i])) {
            return i
          }
        }

        return null
      }

      const hr_colors = this.hr_colors
      const power_colors = this.power_colors
      const chartUnits = this.chartUnits

      // Stream settings overrides
      const streamChartSeriesOptions = { ...this.streamChartSeriesOptions }

      // if (!this.streams.watts || !this.streams?.watts?.data?.length === 0) {
      streamChartSeriesOptions.heartrate.color = streamChartSeriesOptions.heartrate.colorWhenPrimary
      // }

      xAxis.push({
        plotBands: [],
        labels: {
          formatter: function () {
            return `${this.value / 1000} ${chartUnits}`
          }
        },
        tickLength: 0,
        gridLineWidth: 0,
        crosshair: {
          width: 1,
          opacity: 0.8,
          color: '#222',
          enabled: true,
          zIndex: 6,
          snap: true
        },
        events: {
          afterSetExtremes: this.afterSetExtremes
        }
      })

      this.allowedStreams
        .filter((key) => this.streams[key] && this.streams[key].data.length)
        .forEach((key, index) => {
          const options = streamChartSeriesOptions[key]
          const data = []

          this.streams[this.xAxisBase].data.forEach((item, i) => {
            let value = this.streams[key].data[i] || null

            let power_zone
            let hr_zone
            let timestamp
            let grade

            if (this.powerZones) {
              power_zone = getPowerZone(this.streams['watts'].data[i])
            }
            if (this.heartRateZones) {
              hr_zone = getHRZone(this.streams['heartrate'].data[i])
            }
            if (this.streams['time']) {
              timestamp = this.streams['time'].data[i]
            }
            if (this.hasGradeData) {
              grade = this.streams['grade_smooth'].data[i]
            }

            data.push({
              x: item,
              y: Number(value),
              power_zone,
              hr_zone,
              timestamp,
              grade
            })

            // if (this.xAxisBase === 'time') {
            //    const nextValue = this.streams[this.xAxisBase].data[i+1]
            //    if (nextValue && ((nextValue - item) > 60)) {
            //      data.push([
            //        item + 1,
            //        null
            //      ])
            //    }
            // }
          })

          if (index === 0) {
            this.referenceData = data
          }

          series.push({
            ...options,
            xAxis: 0,
            yAxis: index,
            data: data,
            selected: false,
            marker: {
              enabled: false,
              fillColor: 'transparent'
            }
          })

          let minValue = 0

          if (options.calculateMinValue) {
            minValue = Math.min(...data.filter(({ y }) => y !== 0).map(({ y }) => y))
          }

          let maxValue = (1 / options.scale) * Math.max(...data.filter(({ y }) => y > 0).map(({ y }) => y))

          yAxis.push({
            min: minValue,
            max: maxValue,
            startOnTick: false,
            endOnTick: true,
            gridLineWidth: 0,
            ticks: {
              enabled: false
            },
            title: {
              enabled: false
            },
            labels: {
              enabled: false
            }
          })
        })

      const showNavigator = this.showNavigator

      if (!series.find(({ showInNavigator }) => showInNavigator === true)) {
        series.forEach((serie) => (serie.showInNavigator = true))
      }

      return {
        ...chartConfig.defaultOptions,

        legend: {
          enabled: true,
          color: 'white',
          backgroundColor: 'transparent',
          align: 'left',
          verticalAlign: 'top',
          zIndex: 9,
          itemDistance: 12
        },

        scrollbar: {
          enabled: false,
          height: 8
        },

        navigator: {
          enabled: showNavigator,
          maskFill: 'rgba(180, 198, 220, 0.35)',
          series: {
            type: 'area',
            fillOpacity: 1,
            dataGrouping: {
              smoothed: true
            }
          },
          xAxis: {
            labels: {
              enabled: false
            }
          }
        },

        chart: {
          ...chartConfig.chart,
          height: this.$isMobile() ? this.height / 2 : this.height,
          type: `${this.type}`,
          backgroundColor: 'white',
          zoomType: 'x',
          border: 0,
          marginTop: 48,
          events: {
            render: function () {
              this.hideLoading()
            }
          }
        },

        loading: {
          labelStyle: {
            color: 'transparent'
          },
          style: {
            backgroundColor: 'white',
            opacity: 0.4
          }
        },

        exporting: {
          ...chartConfig.exporting,
          enabled: false
        },

        tooltip: {
          ...chartConfig.withTooltip,
          style: {
            opacity: 0.96
          },
          snap: true,
          lineWidth: 1,
          valueDecimals: 2,
          positioner: function (labelWidth, labelHeight, point) {
            var leftThird = point.plotX < 280
            return {
              x: leftThird ? point.plotX + this.chart.plotLeft + 48 : this.chart.plotLeft + point.plotX - (labelWidth + 48),
              y: 60
            }
          },
          formatter: function () {
            let str = ''
            let points = this.points
            let gradeData = ''

            const streamData = points
              .map(function (point) {
                return `<div class="flex items-end h-6 text-sm font-medium">
                <div class="flex items-center">
                  <!--<svg class="mr-1" height="16" width="16"><circle cx="8" cy="8" r="4" fill="${point.series.userOptions.lineColor}" /></svg>-->
                  <span class="text-xs font-bold">${point.series.name}</span>
                </div>
                <div class="w-full mx-2 mb-1 text-xs border-b border-gray-500 border-dotted" style="height: 1px"></div>
                <div class="ml-auto text-right">
                  ${format(point.y, point.series.userOptions.format)}
                </div>
              </div>`
              })
              .join('')

            if (points[0].point.grade !== undefined) {
              gradeData = `<div class="flex items-end h-6 text-sm font-medium">
                <div class="flex items-center">
                  <span class="text-xs font-bold">Grade</span>
                </div>
                <div class="w-full mx-2 mb-1 text-xs border-b border-gray-500 border-dotted" style="height: 1px"></div>
                <div class="ml-auto text-right">
                  ${format(points[0].point.grade, 'grade')}
                </div>
              </div>`
            }

            str += `<div class="flex">`
            str += `<div style="width: 12rem">`
            str += `<div class="flex justify-start mb-1 text-base font-bold">
                  <div style="width: 76px">${format(points[0].x / 1000, 'distance')}</div>
                  <div>${format(points[0].point.timestamp, 'digital_duration')}</div>
                </div>`
            str += streamData
            str += gradeData

            if (points[0].point.hr_zone !== undefined) {
              str += `<div class="flex items-end h-6 text-sm font-medium">`

              str += `<div class="flex items-center mr-4">`
              str += `<span class="text-xs font-bold">HR Zone</span>`
              str += `</div>`

              str += `<div class="ml-auto text-right">`
              str += `<div class="flex space-x-1">`
              for (var i = 0; i < 5; i++) {
                str += `<div class="flex flex-col justify-end w-2 h-2 mb-1 bg-gray-100">
                      <div class="timeInZoneAnimated w-full" style="background-color: ${
                        points[0].point.hr_zone === i ? hr_colors[i] : '#eee'
                      }; height: 100%"></div>
                    </div>`
              }
              str += `</div>`
              str += `</div>`
              str += `</div>`
            }

            if (points[0].point.power_zone !== undefined) {
              str += `<div class="flex items-end h-6 text-sm font-medium">`

              str += `<div class="flex items-center mr-4">`
              str += `<span class="text-xs font-bold">Power Zone</span>`
              str += `</div>`

              str += `<div class="ml-auto text-right">`
              str += `<div class="flex space-x-1">`
              for (var i = 0; i < 7; i++) {
                str += `<div class="flex flex-col justify-end w-2 h-2 mb-1 bg-gray-100">
                          <div class="timeInZoneAnimated w-full" style="background-color: ${
                            points[0].point.power_zone === i ? power_colors[i] : '#eee'
                          }; height: 100%"></div>
                        </div>`
              }
              str += `</div>`
              str += `</div>`
              str += `</div>`
            }

            str += `</div>`
            str += `</div>`

            return str
          }
        },
        title: {
          text: ''
        },
        colors: [this.color],
        xAxis: xAxis,
        yAxis: yAxis,
        plotOptions: {
          series: {
            turboThreshold: 0,
            lineWidth: 1,
            states: {
              inactive: {
                opacity: 1,
                enabled: false,
                animation: {
                  enabled: false
                },
                lineWidth: 1,
                halo: {
                  size: 0
                }
              },
              hover: {
                opacity: 1,
                enabled: false,
                animation: {
                  enabled: false
                },
                lineWidth: 1,
                halo: {
                  size: 0
                }
              }
            }
          }
        },
        series: series
      }
    }
  },

  watch: {
    zoomTo: {
      immediate: false,
      handler(newVal) {
        if (newVal && newVal.length) {
          const length = this.referenceData.length
          let startIndex = Math.floor(newVal[0][2] * length)
          let endIndex = Math.floor(newVal[0][3] * (length - 1))

          if (endIndex - startIndex < 10) {
            startIndex = startIndex - 10
            endIndex = endIndex + 10
          }

          const from = this.referenceData[startIndex]?.x
          const to = this.referenceData[endIndex]?.x

          this.chart.xAxis[0].setExtremes(from, to, true)
        }
      }
    },

    highlightedPoints: {
      immediate: false,
      handler(newVal, val) {
        if (newVal !== val) {
          this.chart.hideLoading()
          this.getPlotBands()
        }
      }
    }
  },

  mounted() {
    this.registerEventListeners()
  },

  methods: {
    setChart(chart) {
      this.chart = chart
      this.$emit('onchartloaded', chart)
    },
    setSmoothing(e) {
      this.chart.showLoading()
      setTimeout(() => {
        this.$emit('onsmoothingchanged', e.target.valueAsNumber)
      }, 5)
    },
    afterSetExtremes(e) {
      if (!e.trigger || !['zoom', 'navigator'].includes(e.trigger)) {
        return
      }

      if (e.target.userMin && e.target.userMax) {
        const stream = this.streams.distance.data
        const length = stream.length - 1

        const min = stream.findIndex((value) => value >= e.target.userMin)
        const max = length - [...stream].reverse().findIndex((value) => value <= e.target.userMax)

        const minRelative = min / length
        const maxRelative = max / length

        this.$emit('onselectionchange', [[min, max, minRelative, maxRelative, length, true]])
      }
    },
    resetZoom() {
      this.chart.xAxis[0].setExtremes(null, null, true)
      this.$emit('onselectionchange', [])
    },

    getPlotBands() {
      // TODO: ? if (!this.referenceData || !this.highlightedPoints || !this.highlightedPoints.length || this.highlightedPoints[0][5]) {
      if (!this.referenceData || !this.highlightedPoints || !this.highlightedPoints.length) {
        this.plotBands = []

        return
      }

      let plotBands = []

      this.highlightedPoints.forEach((item) => {
        const length = this.referenceData.length - 1
        const startIndex = Math.floor(item[2] * length)
        const endIndex = Math.floor(item[3] * length)
        const from = this.referenceData[startIndex]?.x
        const to = this.referenceData[endIndex]?.x

        if (from && to) {
          plotBands.push({
            from,
            to,
            color: 'rgba(149, 169, 233, 0.25)',
            zIndex: 3
          })
        }
      })

      this.plotBands = plotBands
    },

    registerEventListeners() {
      ;['mousemove', 'touchmove', 'touchstart'].forEach((type) => {
        this.$el.addEventListener(type, (e) => {
          const event = this.chart.pointer.normalize(e)
          const point = this.chart.series[0].searchPoint(event, true)

          if (!point) {
            return
          }

          const newPosition = [point.index, point.index / (this.streams.distance.data.length - 1), this.streams.distance.data.length]

          if (newPosition !== this.position) {
            this.position = newPosition
            this.onPositionChangeDebounced(newPosition)
          }
        })
      })

      this.$el.addEventListener('mousemove', () => (this.drag = true))
      this.$el.addEventListener('mousedown', () => (this.drag = false))
      this.$el.addEventListener('mouseup', (e) => {
        !this.drag && e.target.nodeName !== 'BUTTON' && e.target.nodeName !== 'tspan' && this.resetZoom(e)
      })

      this.onPositionChange = (position) => {
        this.$emit('onpositionchanged', position)
      }

      this.onPositionChangeDebounced = debounce(this.onPositionChange, 5)
    }
  }
}
</script>

<style lang="postcss" scoped>
input[type='range'] {
  background-color: transparent;
  -webkit-appearance: none;
}
input[type='range']:focus {
  outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
  background: #c8c8c8;
  border: 0px solid rgba(0, 0, 0, 0);
  border: 0;
  border-radius: 25px;
  width: 100%;
  height: 4px;
  cursor: pointer;
  transition: background 0.2s;
}
input[type='range']::-webkit-slider-thumb {
  margin-top: -6px;
  width: 16px;
  height: 16px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 16px;
  cursor: pointer;
  -webkit-appearance: none;
}
input[type='range']:hover::-webkit-slider-runnable-track {
  background: #a2a2a2;
}
input[type='range']::-moz-range-track {
  background: #c8c8c8;
  border: 0px solid rgba(0, 0, 0, 0);
  border: 0;
  border-radius: 25px;
  width: 100%;
  height: 4px;
  cursor: pointer;
}
input[type='range']::-moz-range-thumb {
  width: 16px;
  height: 16px;
  background: #ffffff;
  border: 1.1px solid rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  cursor: pointer;
}
input[type='range']::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 6px 0;
  color: transparent;
  width: 100%;
  height: 4px;
  cursor: pointer;
}
input[type='range']::-ms-fill-lower {
  background: #797979;
  border: 0px solid rgba(0, 0, 0, 0);
  border: 0;
  border-radius: 50px;
}
input[type='range']::-ms-fill-upper {
  background: #c8c8c8;
  border: 0px solid rgba(0, 0, 0, 0);
  border: 0;
  border-radius: 50px;
}
input[type='range']::-ms-thumb {
  width: 16px;
  height: 16px;
  background: #ffffff;
  border: 1.1px solid rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  cursor: pointer;
  margin-top: 0px;
  /*Needed to keep the Edge thumb centred*/
}

/*
  input[type=range]:focus::-ms-fill-lower {
    background: #c8c8c8;
  }
  input[type=range]:focus::-ms-fill-upper {
    background: #ffffff;
  }
  */

/*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
  how to remove the virtical space around the range input in IE*/
@supports (-ms-ime-align: auto) {
  /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
  input[type='range'] {
    margin: 0;
    /*Edge starts the margin from the thumb, not the track as other browsers do*/
  }
}
</style>

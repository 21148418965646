import axios from 'axios'
import config from 'config'

import {
  activities_path,
  activities_summary_path,
  user_profile_path,
  user_subscription_path,
  user_achievements_path,
  update_user_profile_path,
  update_user_email_path,
  user_sign_out_path,
  activity_detail_path,
  create_user_ftp_path,
  edit_user_ftp_path,
  destroy_user_ftp_path,
  create_user_weight_path,
  edit_user_weight_path,
  destroy_user_weight_path,
  create_sign_in_link_path,
  validate_sign_in_link_path,
  delete_account_path,
  create_activity_path,
  upload_activity_path,
  edit_activity_path,
  destroy_activity_path,
  update_activity_note_path,
  all_activities_path,
  similar_activities_path,
  unsubscribe_path
} from 'routes'

export default {
  getUser() {
    return axios.get(user_profile_path()).then((response) => response.data.user)
  },

  getSubscriptionPlans() {
    return config.subscription_plans
  },

  getActiveSubscriptionPlans() {
    return config.subscription_plans.filter((plan) => plan.active)
  },

  getUserSubscription() {
    return axios.get(user_subscription_path()).then((response) => response.data)
  },

  getActivity(id) {
    return axios.get(activity_detail_path({ id })).then((response) => response.data)
  },

  getActivities() {
    return axios.get(all_activities_path()).then((response) => response.data)
  },

  getAchievements() {
    return axios.get(user_achievements_path()).then((response) => response.data)
  },

  saveUserPreferences(preferences) {
    const payload = {
      preferences: {
        sex: preferences.sex,
        measurement_preference: preferences.measurement_preference,
        max_hr: preferences.max_hr,
        rest_hr: preferences.rest_hr,
        lactate_threshold: preferences.lactate_threshold,
        email: preferences.email,
        email_notifications: preferences.email_notifications,
        running_ftp: preferences.running_ftp,
        swimming_ftp: preferences.swimming_ftp,
        beginning_of_week: preferences.beginning_of_week
      },
      weight: { weight: preferences.weight },
      power: { ftp: preferences.ftp },
      zones: preferences.zones
    }

    return axios.post(update_user_profile_path(), payload).then((response) => response.data)
  },

  saveUserEmail(email) {
    const payload = { email }

    return axios.post(update_user_email_path(), payload).then((response) => response.data)
  },

  createUserFTP(data) {
    const payload = {
      ftp: data.ftp,
      effective_from: data.effective_from
    }

    return axios.post(create_user_ftp_path(), payload).then((response) => response.data)
  },

  editUserFTP(data) {
    const payload = {
      id: data.id,
      ftp: data.ftp,
      effective_from: data.effective_from
    }

    return axios.post(edit_user_ftp_path(), payload).then((response) => response.data)
  },

  destroyUserFTP(data) {
    const payload = {
      id: data.id
    }

    return axios.post(destroy_user_ftp_path(), payload).then((response) => response.data)
  },

  createUserWeight(data) {
    const payload = {
      weight: data.weight,
      effective_from: data.effective_from
    }

    return axios.post(create_user_weight_path(), payload).then((response) => response.data)
  },

  editUserWeight(data) {
    const payload = {
      id: data.id,
      weight: data.weight,
      effective_from: data.effective_from
    }

    return axios.post(edit_user_weight_path(), payload).then((response) => response.data)
  },

  destroyUserWeight(data) {
    const payload = {
      id: data.id
    }

    return axios.post(destroy_user_weight_path(), payload).then((response) => response.data)
  },

  loadActivities() {
    return axios.get(activities_path()).then((response) => response.data)
  },

  loadSummary() {
    return axios.get(activities_summary_path()).then((response) => response.data)
  },

  async createSignInLink(email) {
    const payload = {
      email: email
    }

    return (await axios.post(create_sign_in_link_path(), payload)).data
  },

  async validateSignInLink(token) {
    const payload = {
      token: token
    }

    return (await axios.post(validate_sign_in_link_path(), payload)).data
  },

  async createActivity(payload) {
    return (await axios.post(create_activity_path(), payload)).data
  },

  async destroyActivity(id) {
    const payload = {
      id
    }
    return (await axios.post(destroy_activity_path(), payload)).data
  },

  async editActivity(activity) {
    const payload = {
      ...activity
    }
    return (await axios.post(edit_activity_path(), payload)).data
  },

  async updateActivityNote(id, note) {
    const payload = {
      id,
      note
    }
    return (await axios.post(update_activity_note_path(), payload)).data
  },

  async getSimilarActivities(id) {
    const payload = {
      id
    }
    return (await axios.post(similar_activities_path(), payload)).data
  },

  async uploadActivity(file) {
    let formData = new FormData()

    formData.append('file', file)

    const headers = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    return axios.post(upload_activity_path(), formData, headers).then((response) => response.data)
  },

  deleteAccount() {
    return axios.post(delete_account_path()).then((response) => response.data)
  },

  unsubscribeFromEmails(email, id) {
    const payload = {
      id,
      email
    }

    return axios.post(unsubscribe_path(), payload).then((response) => response.data)
  },

  signOut() {
    return axios.post(user_sign_out_path()).then((response) => response.data)
  }
}

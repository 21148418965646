export function formatSeconds(time) {
  time = Number(time)

  const hours = Math.floor(time / 3600)
  const minutes = Math.floor((time % 3600) / 60)
  const seconds = time % 60

  const formattedHours = hours ? `${hours}h` : null
  const formattedMinutes = minutes ? `${minutes}m` : null
  const formattedSeconds = seconds ? `${seconds}s` : null

  return [formattedHours, formattedMinutes, formattedSeconds].filter(Boolean).join(' ')
}

<template>
  <chart ref="chart" :options="chartData" :callback="setChart"></chart>
</template>

<script>
import * as chartConfig from 'constants/chartConfig'

import formatUnits from 'filters/formatUnits'
import date from 'filters/date'
import { formatSeconds } from 'utils/time'
import { getPowerCurve, getTickPositions } from 'utils/powerCurve'

export default {
  props: {
    data: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    dateRange: {
      type: Object,
      required: false
    },
    height: {
      type: Number,
      required: false,
      default: 500
    },
    format: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      chart: undefined,
      drag: false
    }
  },

  computed: {
    chartUnits() {
      return formatUnits('power')
    },

    chartData() {
      // Build All-Time series
      const allActivities = this.data.filter((a) => a.power_curve)
      const allTimeData = getPowerCurve(allActivities)

      const series = [{ data: allTimeData, colorKey: 'colorValue', type: 'column', name: 'All time', color: '#ff5933' }]

      const maxDayDiff = Math.max(...allTimeData.map(({ colorValue }) => colorValue))

      const categories = allTimeData.map(({ name }) => name)
      const tickPositions = getTickPositions(allTimeData)

      return {
        ...chartConfig.defaultOptions,
        ...chartConfig.withLegend,

        chart: {
          ...chartConfig.chart,
          height: this.$isMobile() ? this.height / 2 : this.height,
          marginLeft: 75,
          type: 'column',
          zoomType: 'x'
        },
        exporting: {
          ...chartConfig.exporting,
          enabled: !this.$isMobile()
        },

        colorAxis: {
          min: 0,
          max: maxDayDiff,
          maxColor: '#ffffff',
          minColor: '#ff5933'
        },
        tooltip: {
          ...chartConfig.withTooltip,
          positioner: function (labelWidth, labelHeight, point) {
            var leftThird = point.plotX < this.chart.plotWidth / 3
            return {
              x: leftThird ? point.plotX + this.chart.plotLeft + 20 : this.chart.plotLeft + point.plotX - (labelWidth + 20),
              y: 32
            }
          },
          formatter: function () {
            let str = `<div class="w-56 mb-1 -mt-2 text-sm font-bold">${formatSeconds(this.x)} Power</div>`
            this.points.forEach((point) => {
              str += `<div class="flex items-center py-1 text-sm border-t">
                <div class="flex items-center">
                  <div class="ml-1 font-bold">${point.point.options.colorValue} days ago,</div>
                  <div class="text-muted ml-1 mr-6">${point.point.options.s}, ${date(point.point.options.d)}</div>
                </div>
                <div class="ml-auto">
                  ${point.y}W
                </div>
              </div>`
            })

            return str
          }
        },
        xAxis: {
          crosshair: {
            width: 1,
            color: '#000',
            enabled: true,
            zIndex: 99
          },
          plotLines: tickPositions.map((k) => {
            return {
              color: '#eee',
              width: 1,
              value: k
            }
          }),
          labels: {
            formatter: function () {
              return formatSeconds(this.value)
            }
          },
          categories: categories,
          tickPositions: tickPositions,
          margin: 0,
          padding: 0
        },
        yAxis: [
          {
            title: {
              enabled: false
            },
            labels: {
              format: this.chartUnits ? `{value} ${this.chartUnits}` : '{value}'
            }
          }
        ],
        plotOptions: {
          column: {
            shadow: false,
            minPointWidth: 4,
            borderWidth: 0,
            states: {
              hover: {
                enabled: false
              }
            }
          }
        },
        series: series
      }
    }
  },

  mounted() {
    this.$el.addEventListener('mousemove', () => (this.drag = true))
    this.$el.addEventListener('mousedown', () => (this.drag = false))
    this.$el.addEventListener('mouseup', () => !this.drag && this.resetZoom())
  },

  methods: {
    setChart(chart) {
      this.chart = chart
    },
    resetZoom() {
      this.chart.xAxis[0].setExtremes && this.chart.xAxis[0].setExtremes(null, null, false)
      this.chart.redraw()
    }
  }
}
</script>

<template>
  <modal-layout class="TooltipModal" width="800">
    <div class="flex">
      <div class="p-8">
        <h3 class="mb-6">{{ title }}</h3>
        <div class="TooltipMarkdown" v-html="formattedDescription"></div>
      </div>
    </div>
  </modal-layout>
</template>

<script>
import marked from 'marked'

export default {
  props: {
    title: {
      type: String,
      required: false
    },
    description: {
      type: String,
      required: false
    }
  },

  computed: {
    formattedDescription() {
      return `<div>${marked(this.description)}</div>`
    }
  }
}
</script>

<style lang="postcss">
.TooltipModal .ModalLayout__body {
  @apply p-0 overflow-hidden rounded;
}

.TooltipMarkdown {
  @apply text-sm;
  strong {
    @apply font-bold;
  }
  ul {
    @apply mt-3;
  }
  p + p {
    @apply mt-3;
  }
  h3 {
    @apply mt-2 mb-0 text-lg;
    font-size: theme('fontSize.base') !important;
  }
}
</style>

<template>
  <div id="map-container" class="relative">
    <div id="map" class="w-full h-full"></div>
    <div v-if="mapOptions.length" class="absolute top-0 left-0 flex mt-2 ml-2 space-x-2">
      <Dropdown>
        <template #selected>
          <div class="text-muted z-1 flex items-center justify-between px-3 py-1 text-sm font-medium bg-white rounded shadow">
            <div class="flex items-center">
              <svg class="svg-icon svg-icon--xs text-primary flex-shrink-0 mr-2">
                <use xlink:href="#icon-progress"></use>
              </svg>
              {{ selectedMapOption.name }}
            </div>
            <svg class="svg-icon flex-shrink-0 -mr-1">
              <use xlink:href="#icon-caret-down"></use>
            </svg>
          </div>
        </template>

        <template #options>
          <div class="w-32">
            <a
              v-for="(option, index) in mapOptions"
              :key="index"
              href
              class="Dropdown__item"
              :class="{ 'font-bold': option.name === selectedMapOption.name }"
              @click.prevent="changeMapSource(option)"
            >
              {{ option.name }}
            </a>
          </div>
        </template>
      </Dropdown>

      <Dropdown>
        <template #selected>
          <div class="text-muted z-1 flex items-center justify-between px-3 py-1 text-sm font-medium bg-white rounded shadow">
            <div class="flex items-center">
              {{ mapStyle.name }}
            </div>
            <svg class="svg-icon flex-shrink-0 -mr-1">
              <use xlink:href="#icon-caret-down"></use>
            </svg>
          </div>
        </template>

        <template #options>
          <div class="w-32">
            <a
              v-for="(option, index) in mapStyles"
              :key="index"
              href
              class="Dropdown__item"
              :class="{ 'font-bold': option.name === mapStyle.name }"
              @click.prevent="changeMapStyle(option)"
            >
              {{ option.name }}
            </a>
          </div>
        </template>
      </Dropdown>
    </div>
  </div>
</template>

<script>
import config from 'config'

import chroma from 'chroma-js'
import smooth from 'array-smooth'
import polyline from '@mapbox/polyline'

const mapStyles = [
  {
    name: 'Outdoors',
    style: 'mapbox://styles/mapbox/outdoors-v11'
  },
  {
    name: 'Light',
    style: 'mapbox://styles/mapbox/light-v10'
  },
  {
    name: 'Dark',
    style: 'mapbox://styles/mapbox/dark-v10'
  },
  {
    name: 'Streets',
    style: 'mapbox://styles/mapbox/streets-v11'
  },
  {
    name: 'Satellite',
    style: 'mapbox://styles/mapbox/satellite-v9'
  }
]

const colorCodingOptions = [
  {
    name: 'Route'
  },
  {
    name: 'Power',
    stream: 'watts',
    colors: ['#d8c1de', '#ddaae9', '#5200ff']
  },
  {
    name: 'Heart Rate',
    stream: 'heartrate',
    colors: ['#d7c1c1', '#eba69d', '#ff0000']
  },
  {
    name: 'Grade',
    stream: 'grade_smooth',
    average: 0,
    colors: ['#3db86f', '#d4d4d4', '#ff0000']
  },
  {
    name: 'Elevation',
    stream: 'altitude',
    colors: ['#e7e7e7', '#f2d5d5', '#d62828']
  },
  {
    name: 'Speed',
    stream: 'velocity_smooth',
    colors: ['#c2c7f0', '#b5bbe9', '#0030ff']
  }
]

export default {
  props: {
    polyline: {
      type: String,
      required: true
    },
    streams: {
      type: Object,
      required: false
    },
    markerPosition: {
      type: Number,
      required: false
    },
    units: {
      type: String,
      required: false
    },
    highlightedPoints: {
      type: Array,
      required: false
    },
    scrollZoom: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      marker: undefined,
      map: undefined,
      mapStyles: mapStyles,
      mapStyle: mapStyles[0],
      style: undefined,
      layer: undefined,
      selectionLayer: undefined,
      selectedMapOption: colorCodingOptions[0],
      mapOptions: []
    }
  },

  computed: {
    mapData() {
      if (this.streams?.latlng?.data?.length) {
        return {
          type: 'LineString',
          coordinates: this.streams.latlng.data.map(([a, b]) => [b, a])
        }
      } else {
        return polyline.toGeoJSON(this.polyline)
      }
    }
  },

  watch: {
    highlightedPoints: {
      immediate: false,
      handler(newVal, val) {
        if (!this.map) {
          return
        }

        if (newVal !== val || newVal === []) {
          if (!newVal || newVal === [] || newVal.length === 0) {
            this.selectionSource = undefined
            this.fitToBounds(this.mapData.coordinates)
          } else {
            let from = Math.floor(newVal[0][0])
            let to = Math.ceil(newVal[0][1])

            const coordinates = this.mapData.coordinates.slice(from, to + 1)

            this.selectionSource = {
              'type': 'geojson',
              'lineMetrics': true,
              'data': {
                'type': 'Feature',
                'properties': {},
                'geometry': {
                  ...this.mapData,
                  coordinates
                }
              }
            }

            this.selectionLayer = {
              'id': 'selection',
              'type': 'line',
              'source': 'selection',
              'layout': {
                'line-join': 'round',
                'line-cap': 'round'
              },
              'paint': {
                'line-color': 'blue',
                'line-width': 3
              }
            }

            if (newVal[0][5]) {
              this.fitToBounds(coordinates)
            }
          }

          this.addHighlightSource()
        }
      }
    },

    stream: {
      immediate: false,
      handler() {
        this.renderMap()
      }
    },

    scrollZoom: {
      immediate: false,
      handler(value) {
        if (value) {
          this.map?.scrollZoom?.enable()
        } else {
          this.map?.scrollZoom?.disable()
        }
      }
    },

    markerPosition: {
      immediate: false,
      handler(e) {
        if (!this.map) {
          return
        }

        const latLngIndex = Math.floor(e * this.mapData.coordinates.length)
        try {
          const point = this.mapData.coordinates[latLngIndex]

          if (!this.marker) {
            const el = document.createElement('div')
            el.className = 'activity-map-marker'

            this.marker = new mapboxgl.Marker(el).setLngLat(point).addTo(this.map)
          } else {
            this.marker.setLngLat(point)
          }
        } catch {
          // noop
        }
      }
    }
  },

  mounted() {
    // Import mapbox-gl webpack chunk
    import(/* webpackChunkName: 'mapbox', webpackPrefetch: true */ '!mapbox-gl').then(({ default: mapboxgl }) => {
      window.mapboxgl = mapboxgl
      mapboxgl.accessToken = config.mapbox_auth_token

      if (this.streams && this.streams.distance && this.streams.latlng) {
        this.mapOptions = colorCodingOptions.filter((option) => !option.stream || this.streams[option.stream]?.data?.length)
      }

      try {
        this.map = new mapboxgl.Map({
          container: 'map',
          center: this.mapData.coordinates[0],
          style: this.mapStyle.style,
          animate: false,
          zoom: 12
        })

        if (!this.scrollZoom) {
          this.map.scrollZoom.disable()
        }

        this.map.on('wheel', (event) => {
          if (this.scrollZoom) {
            return
          }

          if (event.originalEvent.metaKey || event.originalEvent.ctrlKey) {
            // Check if CTRL key is pressed
            event.originalEvent.preventDefault() // Prevent chrome/firefox default behavior
            if (!this.map.scrollZoom._enabled) this.map.scrollZoom.enable() // Enable zoom only if it's disabled
          } else {
            if (this.map.scrollZoom._enabled) this.map.scrollZoom.disable() // Disable zoom only if it's enabled
          }
        })

        this.map.addControl(new mapboxgl.ScaleControl({ maxWidth: 80, unit: this.units === 'feet' ? 'imperial' : 'metric' }), 'bottom-right')
        this.map.addControl(new mapboxgl.NavigationControl({ visualizePitch: true }), 'top-right')
        this.map.addControl(new mapboxgl.FullscreenControl({ container: document.querySelector('#map-container') }))

        this.fitToBounds(this.mapData.coordinates)

        this.selectionSource = undefined

        this.source = {
          'type': 'geojson',
          'lineMetrics': true,
          'data': {
            'type': 'Feature',
            'properties': {},
            'geometry': this.mapData
          }
        }

        this.map.on('load', () => {
          this.map.on('style.load', () => {
            this.addRouteSource()
            this.addHighlightSource()

            this.renderMap()
          })

          this.renderMap()
        })

        this.$emit('onmaploaded', this.map)
      } catch {
        throw new Error('Failed to load map data')
      }
    })
  },

  methods: {
    fitToBounds(points, padding = 80) {
      const latPoints = points.map(([lat]) => lat)
      const lngPoints = points.map(([, lng]) => lng)

      this.map?.fitBounds(
        [
          [Math.min(...latPoints), Math.min(...lngPoints)],
          [Math.max(...latPoints), Math.max(...lngPoints)]
        ],
        { padding, duration: 600, maxZoom: 15 }
      )
    },

    addRouteSource() {
      if (this.map.getLayer('route')) {
        this.map.removeLayer('route')
      }
      if (this.map.getSource('route')) {
        this.map.removeSource('route')
      }

      this.map.addSource('route', this.source)
      this.map.addLayer(this.layer)
    },

    addHighlightSource() {
      if (this.map.getLayer('selection')) {
        this.map.removeLayer('selection')
      }
      if (this.map.getSource('selection')) {
        this.map.removeSource('selection')
      }

      if (this.selectionSource && !(this.mapOptions && this.selectedMapOption.stream)) {
        this.map.addSource('selection', this.selectionSource)
        this.map.addLayer(this.selectionLayer)
      }
    },

    changeMapStyle(style) {
      this.mapStyle = style
      this.map.setStyle(this.mapStyle.style, { diff: false })
    },

    changeMapSource(e) {
      this.selectedMapOption = e
      if (this.mapStyle.name == this.mapStyles[0].name && e?.name !== 'Route') {
        this.mapStyle = this.mapStyles[1]
      }
      this.map.setStyle(this.mapStyle.style, { diff: false })
    },

    renderMap() {
      if (!this.map) {
        return
      }

      if (this.mapOptions && this.selectedMapOption.stream) {
        const moving = smooth([...this.streams.distance.data], 50)
        const source = smooth([...this.streams[this.selectedMapOption.stream].data], 50)

        let data = []

        moving.forEach((value, index) => {
          if (index > 0) {
            const diff = moving[index] - moving[index - 1]

            if (diff) {
              for (var i = 0; i < diff; i++) {
                data.push(source[index])
              }
            }
          }
        })

        if (data.length > 10000) {
          const ratio = Math.floor(data.length / 10000)

          data = data.filter(function (value, index) {
            return index % ratio == 0
          })
        }

        let stops = []

        const minValue = Math.min(...data)
        const maxValue = Math.max(...data)

        let avgValue = this.selectedMapOption.average

        if (this.selectedMapOption.average) {
          avgValue = this.selectedMapOption.average
        } else {
          avgValue = (data.reduce((a, b) => a + b, 0) / data.length).toFixed(2)
        }

        const colorScale = chroma.scale(this.selectedMapOption.colors).domain([minValue, avgValue, maxValue])

        data.forEach((value, index) => {
          const progress = index / data.length
          stops.push(progress)
          stops.push(colorScale(value).hex())
        })

        this.layer = {
          'id': 'route',
          'type': 'line',
          'source': 'route',
          'layout': {
            'line-join': 'round',
            'line-cap': 'round'
          },
          'paint': {
            'line-opacity': 1,
            'line-width': 4,
            'line-gradient': ['interpolate', ['linear'], ['line-progress'], ...stops]
          }
        }
      } else {
        this.layer = {
          'id': 'route',
          'type': 'line',
          'source': 'route',
          'layout': {
            'line-join': 'round',
            'line-cap': 'round'
          },
          'paint': {
            'line-color': '#ff5933',
            'line-width': 3
          }
        }
      }

      this.addRouteSource()
      this.addHighlightSource()
    }
  }
}
</script>

<style lang="postcss">
.activity-map-marker {
  @apply bg-primary w-5 h-5 border border-2 border-white rounded-full cursor-pointer;
}
.mapboxgl-canvas {
  outline: none !important;
}
</style>

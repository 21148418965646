import { render, staticRenderFns } from "./DashboardUploadActivityView.vue?vue&type=template&id=588c6ea5&scoped=true&"
import script from "./DashboardUploadActivityView.vue?vue&type=script&lang=js&"
export * from "./DashboardUploadActivityView.vue?vue&type=script&lang=js&"
import style0 from "./DashboardUploadActivityView.vue?vue&type=style&index=0&id=588c6ea5&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "588c6ea5",
  null
  
)

export default component.exports
<template>
  <div class="OnboardingStep">
    <div class="OnboardingStep__header">
      <h2>Connections</h2>
    </div>

    <div class="label">Manage Connected Services</div>

    <div class="FormGroup FormGroup--last">
      <div class="FormGroup__label font-bold">
        <img :src="stravaLogo" alt="Strava" width="112" />
      </div>
      <div class="FormGroup__content w-3/5">
        <AppButton v-if="user.authorized_with_strava" secondary small class="ml-auto" @click="deauthorizeStrava">Deauthorize</AppButton>
        <AppButton v-else secondary small class="ml-auto" @click="authorizeStrava">Authorize</AppButton>
      </div>
    </div>

    <div class="FormGroup FormGroup--last">
      <div class="FormGroup__label font-bold">
        <img :src="garminLogo" alt="Garmin" width="132" />
      </div>
      <div class="FormGroup__content w-3/5">
        <AppButton v-if="user.authorized_with_garmin" secondary small class="ml-auto" @click="deauthorizeGarmin">Deauthorize</AppButton>
        <AppButton v-else secondary small class="ml-auto" @click="authorizeGarmin">Authorize</AppButton>
      </div>
    </div>

    <div v-if="!user.authorized_with_garmin && !user.authorized_with_strava" class="FormGroup FormGroup--last">
      <div class="FormGroup__label font-bold">Remove my Account</div>
      <div class="FormGroup__content w-3/5">
        <AppButton secondary small class="ml-auto" @click="deleteAccount">Delete Acount</AppButton>
      </div>
    </div>

    <div class="FormGroup FormGroup--last mt-6 border-t">
      <div class="text-muted flex items-center justify-center text-sm text-center">
        <svg class="svg-icon svg-icon--sm mr-2">
          <use xlink:href="#icon-info"></use>
        </svg>
        You can connect both Strava and Garmin. We will recognize and won't import any duplicate activities.
      </div>
    </div>
  </div>
</template>

<script>
import StravaService from 'services/StravaService'
import GarminService from 'services/GarminService'
import ProfileService from 'services/ProfileService'

import ConfirmAccountDeletionModal from 'views/Modals/ConfirmAccountDeletionModal'
import ConfirmStravaDeauthorizeModal from 'views/Modals/ConfirmStravaDeauthorizeModal'
import ConfirmGarminDeauthorizeModal from 'views/Modals/ConfirmGarminDeauthorizeModal'

const stravaLogo = require('images/strava-logo.svg')
const garminLogo = require('images/garmin-logo.svg')

export default {
  props: {
    user: {
      type: Object
    }
  },

  data() {
    return {
      stravaLogo,
      garminLogo
    }
  },

  methods: {
    async authorizeStrava() {
      StravaService.connect(true)
    },

    async authorizeGarmin() {
      GarminService.connect()
    },

    async deauthorizeStrava() {
      const confirmed = await this.$modal(ConfirmStravaDeauthorizeModal)

      if (confirmed) {
        await StravaService.deauthorize()
        alert('You Strava account has been deauthorized')
        window.location.reload()
      }
    },

    async deauthorizeGarmin() {
      const confirmed = await this.$modal(ConfirmGarminDeauthorizeModal)

      if (confirmed) {
        await GarminService.deauthorize()
        alert('You Garmin Connect account has been deauthorized')
        window.location.reload()
      }
    },

    async deleteAccount() {
      const confirmed = await this.$modal(ConfirmAccountDeletionModal)

      if (confirmed) {
        await ProfileService.deleteAccount()
        alert('Your account has been deleted')
        window.location = window.location.origin
      }
    }
  }
}
</script>

<template>
  <div class="bg-gray-100">
    <Header />

    <h1 class="md:text-5xl mt-16 mb-4 text-4xl font-bold leading-tight text-center">Select a Plan</h1>
    <div class="md:w-128 mx-auto mb-8">
      <p class="text-lg text-center">
        Hi {{ user.first_name || 'there' }}, your Free Trial has ended and we hope you enjoyed using our platform. Please select a plan to continue using
        FormBeat. Need help?
        <a class="link" href="mailto:support@formbeat.com">Drop us an email.</a>
      </p>
    </div>

    <PricingPlans
      v-if="membership"
      :email="user.email"
      :passthrough="membership.passthrough"
      :discount="membership.discount"
      :coupon="membership.coupon"
      @subscribed="onSubscribed"
    />

    <!-- <p class="mb-8 text-center text-muted" v-if="user">
      <router-link :to="{ name: 'settings' }" class="">Open Account Settings</router-link>
    </p> -->

    <div class="py-16 bg-white">
      <Testimonials class="xl:w-256 w-full mx-auto" />
    </div>

    <Footer />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ProfileService from 'services/ProfileService'

import Header from 'components/Header'
import Footer from 'components/Footer'
import Testimonials from 'components/Testimonials'
import PricingPlans from 'components/PricingPlans'

export default {
  components: {
    Header,
    Footer,
    Testimonials,
    PricingPlans
  },

  computed: {
    ...mapGetters('profile', ['user'])
  },

  data() {
    return {
      membership: undefined
    }
  },

  async mounted() {
    /*
    User Subscription object
    {
      subscription: {},
      details: {},
      coupon: '10off',
      discount: 0.5,
      premium: true,
      on_trial: false,
      trial_ends_at: nil,
      cancelled: true,
      is_grace_period: true,
      is_early_adopter: false,
      has_previous_subscription: true,
      has_active_subscription: true,
      passthrough: "..."
    } */

    this.membership = await ProfileService.getUserSubscription()
  },

  methods: {
    onSubscribed() {
      const { href } = this.$router.resolve({ name: 'dashboard' })
      window.location = window.location.origin + href
    }
  },

  metaInfo() {
    return {
      title: 'Select a plan',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Please select a plan for your account'
        }
      ]
    }
  }
}
</script>

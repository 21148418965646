<template>
  <div>
    <StatsSection title="Total Distance">
      <Chart title="Distance" :data="currentSummary" :segment-by="segmentBy" attribute="distance" format="distance" />
    </StatsSection>

    <StatsSection title="Average Distance">
      <Chart title="Avg Distance" :data="currentSummary" :segment-by="segmentBy" attribute="average_distance" format="distance" />
    </StatsSection>
  </div>
</template>

<script>
import Chart from 'components/charts/Chart'
import StatsSection from 'components/StatsSection'

export default {
  components: {
    Chart,
    StatsSection
  },

  props: {
    summary: {
      type: Object,
      required: true
    },
    currentSummary: {
      type: Object,
      required: true
    },
    activities: {
      type: Array,
      required: true
    },
    segmentBy: {
      type: String,
      required: true
    },
    dateRange: {
      type: Object,
      required: true
    }
  },

  metaInfo() {
    return {
      title: 'Distance Analysis'
    }
  }
}
</script>

<template>
  <StatsSection v-if="selectedAttribute" title="Activity Laps">
    <template #header>
      <ChartNavigation :options="presentAttributes" :selected-option="selectedAttribute" label-attribute="name" @input="setAttribute" />
    </template>

    <chart v-if="chartData" ref="chart" :options="chartData" :callback="setChart"></chart>

    <vue-good-table
      class="-mb-6"
      :columns="columns"
      :rows="computedLaps"
      style-class="vgt-table striped"
      :search-options="{ enabled: false }"
      :pagination-options="{ enabled: false }"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'moving_time'">
          {{ props.row.moving_time | format('duration') }}
        </span>
        <span v-else-if="props.column.field == 'distance'">
          {{ props.row.distance | format('distance') }}
        </span>
        <span v-else-if="props.column.field == 'average_speed'">
          {{ props.row.average_speed | format('speed') }}
        </span>
        <span v-else-if="props.column.field == 'total_elevation_gain'">
          {{ props.row.total_elevation_gain | format('elevation') }}
        </span>
        <span v-else>
          {{ props.row[props.column.field] | format }}
        </span>
      </template>
    </vue-good-table>
  </StatsSection>
</template>

<script>
import * as chartConfig from 'constants/chartConfig'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

import { calculateSplits } from 'utils/activity'
import smooth from 'array-smooth'

import format from 'filters/format'
import formatUnits from 'filters/formatUnits'

import StatsSection from 'components/StatsSection'

const lapAnalysisAttributes = [
  {
    name: 'Power',
    attribute: 'average_watts',
    color: '#6e00bb',
    format: 'power'
  },
  {
    name: 'Heart Rate',
    attribute: 'average_heartrate',
    color: '#e91500',
    format: 'numeric'
  },
  {
    name: 'Speed',
    attribute: 'average_speed',
    color: '#3697dd',
    format: 'speed'
  },
  {
    name: 'Cadence',
    attribute: 'average_cadence',
    color: '#607165',
    format: 'numeric'
  }
]

export default {
  components: {
    StatsSection,
    VueGoodTable
  },

  props: {
    laps: {
      type: Array,
      required: true
    },
    elevation: {
      type: Array,
      required: true
    },
    height: {
      type: Number,
      required: false,
      default: 400
    },
    isFromSplits: {
      type: Boolean,
      required: false,
      default: false
    },
    smoothing: {
      type: Number,
      required: false,
      default: () => 10
    }
  },

  data() {
    return {
      chart: undefined,
      selectedAttribute: undefined,
      attributes: lapAnalysisAttributes,
      columns: [
        {
          label: 'Lap',
          field: 'lap_index',
          type: 'number',
          thClass: 'text-left',
          tdClass: 'text-left'
        },
        {
          label: 'Duration',
          field: 'moving_time',
          type: 'number'
        },
        {
          label: 'Speed',
          field: 'average_speed',
          type: 'decimal',
          firstSortType: 'desc'
        },
        {
          label: 'Average HR',
          field: 'average_heartrate',
          type: 'number',
          firstSortType: 'desc'
        },
        {
          label: 'Average Power',
          field: 'average_watts',
          type: 'number',
          firstSortType: 'desc'
        },
        {
          label: 'Elevation gain',
          field: 'total_elevation_gain',
          type: 'decimal',
          firstSortType: 'desc'
        },
        {
          label: 'Distance',
          field: 'distance',
          type: 'number'
        }
      ]
    }
  },

  computed: {
    dataAttribute() {
      return this.selectedAttribute?.attribute
    },

    presentAttributes() {
      return this.attributes.filter((attr) => this.computedLaps[0][attr.attribute])
    },

    chartUnits() {
      return formatUnits(this.selectedAttribute.format)
    },

    computedLaps() {
      if (this.isFromSplits) {
        return calculateSplits(this.laps)
      } else {
        return this.laps
      }
    },

    chartData() {
      const xAxis = []
      const yAxis = []
      const colors = []
      const series = []

      // Add elevation data
      const ratio = this.$isMobile() ? 50 : 5
      const altitudeData = smooth(this.elevation, Number(this.smoothing)).filter(function (value, index) {
        return index % ratio == 0
      })

      xAxis.push({
        type: 'category',
        title: { enabled: false },
        labels: { enabled: false }
      })

      yAxis.push({
        labels: { enabled: false },
        title: { enabled: false }
      })

      series.push({
        type: 'area',
        data: altitudeData.map((item, index) => [index, item]),
        xAxis: 0,
        yAxis: 0
      })

      // Add attribute data
      if (this.selectedAttribute) {
        const attr = this.dataAttribute
        const laps = []
        const sum = this.computedLaps.reduce((a, b) => a + Number(b[attr]), 0)
        const minValue = Math.min.apply(
          null,
          this.computedLaps.filter((l) => l[attr] > 0).map((l) => l[attr])
        )
        const maxValue = Math.max.apply(
          null,
          this.computedLaps.filter((l) => l[attr] > 0).map((l) => l[attr])
        )
        const averageValue = (sum / this.computedLaps.length).toFixed(2)

        this.computedLaps.forEach((lap) => {
          const pointOpacity = ((lap[attr] - averageValue) / (maxValue - minValue)).toFixed(2)
          const opacity = 0.45 + pointOpacity * 0.5

          const value = lap[attr]

          laps.push({
            x: lap.lap_index,
            y: value,
            z: lap.moving_time,
            opacity: opacity,
            averageWatts: lap.average_watts,
            averageSpeed: lap.average_speed,
            averageHr: lap.average_heartrate,
            distance: Math.round(lap.distance),
            moving_time: lap.moving_time,
            averageCadence: lap.average_cadence,
            elevationGain: lap.total_elevation_gain,
            index: lap.lap_index
          })
        })

        xAxis.push({
          type: 'category'
        })

        yAxis.push({
          floor: 0,
          margin: 0,
          padding: 0,
          plotLines: [
            {
              color: '#999',
              dashStyle: 'Dash',
              value: averageValue,
              width: 1,
              zIndex: 5
            }
          ],
          title: {
            enabled: false
          },
          labels: {
            format: this.chartUnits ? `{value} ${this.chartUnits}` : '{value}'
          }
        })

        series.push({
          type: 'variwide',
          colors: [this.selectedAttribute.color],
          selected: true,
          xAxis: 1,
          yAxis: 1,
          data: laps,
          colorByPoint: true
        })
      }

      return {
        ...chartConfig.defaultOptions,

        chart: {
          ...chartConfig.chart,
          height: this.$isMobile() ? this.height / 2 : this.height
        },
        exporting: {
          ...chartConfig.exporting,
          enabled: !this.$isMobile()
        },
        tooltip: {
          ...chartConfig.withTooltip,
          shared: false,
          positioner: function (labelWidth, labelHeight, point) {
            var leftThird = point.plotX < this.chart.plotWidth / 2
            return {
              x: leftThird ? point.plotX + this.chart.plotLeft + 80 : this.chart.plotLeft + point.plotX - (labelWidth + 80),
              y: 20
            }
          },
          formatter: function () {
            let str = `<div class="mb-1 text-base font-bold">Lap ${this.point.options.index}</div>`

            if (this.point.options.distance) {
              str += `<div class="flex items-center py-1 text-sm font-medium border-t"><div class="flex items-center text-sm font-medium"><div class="mr-8 font-bold">Distance</div></div><div class="ml-auto">${format(
                this.point.options.distance,
                'distance'
              )}</div></div>`
            }

            if (this.point.options.moving_time) {
              str += `<div class="flex items-center py-1 text-sm font-medium border-t"><div class="flex items-center text-sm font-medium"><div class="mr-8 font-bold">Moving Time</div></div><div class="ml-auto">${format(
                this.point.options.moving_time,
                'duration'
              )}</div></div>`
            }

            if (this.point.options.averageSpeed) {
              str += `<div class="flex items-center py-1 text-sm font-medium border-t"><div class="flex items-center text-sm font-medium"><div class="mr-8 font-bold">Avg. Speed</div></div><div class="ml-auto">${format(
                this.point.options.averageSpeed,
                'speed'
              )}</div></div>`
            }

            if (this.point.options.averageWatts) {
              str += `<div class="flex items-center py-1 text-sm font-medium border-t"><div class="flex items-center text-sm font-medium"><div class="mr-8 font-bold">Avg. Power</div></div><div class="ml-auto">${format(
                this.point.options.averageWatts,
                'power'
              )}</div></div>`
            }

            if (this.point.options.averageHr) {
              str += `<div class="flex items-center py-1 text-sm font-medium border-t"><div class="flex items-center text-sm font-medium"><div class="mr-8 font-bold">Avg. HR</div></div><div class="ml-auto">${format(
                this.point.options.averageHr,
                'numeric'
              )}</div></div>`
            }

            return str
          }
        },
        colors: colors,
        xAxis: xAxis,
        yAxis: yAxis,
        plotOptions: {
          variwide: {
            opacity: 1,
            shadow: false,
            states: {
              hover: {
                enabled: false
              },
              select: {
                opacity: 1
              },
              normal: {
                opacity: 1
              },
              inactive: {
                opacity: 1
              }
            }
          },
          area: {
            lineWidth: 0,
            shadow: false,
            color: '#e7e3e2',
            fillOpacity: 0.8,
            borderColor: 'transparent',
            borderWidth: 0,
            marker: {
              enabled: false
            },
            states: {
              inactive: {
                opacity: 1
              }
            },
            enableMouseTracking: false
          }
        },
        series: series
      }
    }
  },

  mounted() {
    this.setAttribute(this.presentAttributes[0])
  },

  methods: {
    setChart(chart) {
      this.chart = chart
    },

    setAttribute(attribute) {
      // Reset chart
      if (this.chart) {
        this.chart.update(
          {
            series: []
          },
          true,
          true
        )
      }

      this.selectedAttribute = attribute
    }
  }
}
</script>

<template>
  <StatsSection title="Summary">
    <template #header>
      <Dropdown right class="ml-8">
        <template #selected>
          <div class="flex items-center justify-between w-24 px-3 py-1 font-medium">
            <div>
              {{ selectedYear }}
            </div>
            <svg class="svg-icon">
              <use xlink:href="#icon-caret-down"></use>
            </svg>
          </div>
        </template>

        <template #options>
          <div class="w-32">
            <a
              v-for="(option, index) in years"
              :key="index"
              href
              class="Dropdown__item"
              :class="{ 'font-bold': option === selectedYear }"
              @click.prevent="setYear(option)"
            >
              {{ option }}
            </a>
          </div>
        </template>
      </Dropdown>
    </template>

    <div class="flex flex-wrap -mt-3 -mb-6 border-t">
      <div class="sm:w-1/2 lg:w-1/4 w-full">
        <AllTimeStatsCard
          title="Distance"
          :activities="activities"
          :data="data"
          :year="selectedYear"
          attribute="distance"
          type="distance"
          icon="distance"
          achievement="Longest Ride"
        />
      </div>

      <div class="sm:w-1/2 lg:w-1/4 w-full">
        <AllTimeStatsCard
          title="Elevation"
          :activities="activities"
          :data="data"
          :year="selectedYear"
          attribute="total_elevation_gain"
          type="elevation"
          icon="elevation"
          achievement="Most Elevation Gain"
        />
      </div>

      <div class="sm:w-1/2 lg:w-1/4 w-full">
        <AllTimeStatsCard
          title="Duration"
          :activities="activities"
          :data="data"
          :year="selectedYear"
          attribute="moving_time"
          type="duration"
          icon="time"
          achievement="Longest Duration"
        />
      </div>

      <div class="sm:w-1/2 lg:w-1/4 w-full">
        <AllTimeStatsCard
          title="Training Stress Score"
          :activities="activities"
          :data="data"
          :year="selectedYear"
          attribute="tss"
          type="tss"
          icon="progress"
          achievement="Toughest Ride"
        />
      </div>
    </div>
  </StatsSection>
</template>

<script>
import dayjs from 'utils/dayjs'

import AllTimeStatsCard from 'components/AllTimeStatsCard'
import StatsSection from 'components/StatsSection'

export default {
  components: {
    AllTimeStatsCard,
    StatsSection
  },

  props: {
    data: {
      type: Object,
      required: true
    },
    activities: {
      type: Array,
      required: true
    },
    height: {
      type: Number,
      required: false,
      default: 400
    }
  },

  data() {
    return {
      selectedYear: dayjs().format('YYYY')
    }
  },

  computed: {
    years() {
      return Object.keys(this.data['year'])
        .map((key) => dayjs(key).format('YYYY'))
        .reverse()
    }
  },

  methods: {
    setYear(year) {
      this.selectedYear = year
    }
  }
}
</script>

<template>
  <div class="OnboardingStep">
    <div class="OnboardingStep__header">
      <h2>Power</h2>
    </div>

    <h3>FTP History</h3>

    <div class="mt-8 mb-4">
      <div class="flex border-b">
        <div class="label w-64">FTP</div>
        <div class="label w-64">Effective since</div>
        <div class="label flex-grow text-right">Action</div>
      </div>

      <div v-for="(ftp, index) in userFTPValues" :key="ftp.id" class="flex py-2 border-b">
        <div class="w-64" :class="{ 'font-bold': index === 0 }">
          {{ ftp.ftp }}
          <span class="ml-2">{{ index == 0 ? '(Curent FTP)' : '' }}</span>
        </div>
        <div class="w-64">
          <div v-if="form.user_ftps.length > 1">
            <span v-if="ftp.effective_from == '1970-01-01T00:00:00.000Z'" key="more_ftp_readings">Initial value</span>
            <span v-else key="more_ftp_readings">{{ ftp.effective_from | date }}</span>
          </div>
          <div v-else>
            <span>Your most recent FTP</span>
          </div>
        </div>
        <div class="flex-grow text-right">
          <a class="link text-sm" href @click.prevent="editFTP(ftp)">Edit</a>
          <a v-if="index !== userFTPValues.length - 1" class="link ml-2 text-sm" href @click.prevent="deleteFTP(ftp)">Delete</a>
        </div>
      </div>
    </div>

    <AppButton xsmall class="my-4" @click="addNewFTP()">Add new FTP value</AppButton>
  </div>
</template>

<script>
import ProfileService from 'services/ProfileService'

import AddFtpModal from 'views/Modals/AddFtpModal'
import EditFtpModal from 'views/Modals/EditFtpModal'
import DeleteFtpModal from 'views/Modals/DeleteFtpModal'

export default {
  props: {
    form: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      required: false
    }
  },

  computed: {
    userFTPValues() {
      return [...this.form.user_ftps]
    }
  },

  methods: {
    async addNewFTP() {
      const ftp = await this.$modal(AddFtpModal)
      if (!ftp) {
        return
      }

      await ProfileService.createUserFTP(ftp)
      window.location.reload()
    },

    async editFTP(data) {
      const ftp = await this.$modal(EditFtpModal, { ftp: data })
      if (!ftp) {
        return
      }

      await ProfileService.editUserFTP(ftp)
      window.location.reload()
    },

    async deleteFTP(data) {
      const ftp = await this.$modal(DeleteFtpModal, { ftp: data })
      if (!ftp) {
        return
      }

      await ProfileService.destroyUserFTP(ftp)
      window.location.reload()
    }
  }
}
</script>

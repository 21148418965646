import { render, staticRenderFns } from "./ContentPlaceholder.vue?vue&type=template&id=03c733cd&scoped=true&"
var script = {}
import style0 from "./ContentPlaceholder.vue?vue&type=style&index=0&id=03c733cd&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03c733cd",
  null
  
)

export default component.exports
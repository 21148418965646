<template>
  <div>
    <div v-if="membership && subscriptionsPlans" class="OnboardingStep p-16 text-center">
      <div class="label">Your Membership</div>

      <!-- Early adopters -->
      <div v-if="membership.is_early_adopter" key="subscription">
        <h1 class="flex items-center justify-center mt-4">🙌🏼 Friend plan</h1>
        <p class="lead w-128 mx-auto mb-2">
          Hi {{ user.first_name || 'there' }}, thank you for being among the first athletes to use our platform. Your FormBeat membership is free forever.
        </p>
      </div>

      <!-- Premium -->
      <div v-else-if="membership.premium" key="subscription">
        <h1 class="mt-4 mb-1 text-center">FormBeat Premium</h1>

        <div v-if="currentPlan" class="md:space-x-4 flex flex-wrap justify-center my-8">
          <div class="md:w-96 w-full p-8 mb-4 text-center border rounded shadow-lg">
            <span class="bg-primary inline-block px-2 py-1 mb-2 text-xs font-bold text-white uppercase rounded-full">Premium</span>
            <div class="text-2xl font-bold text-center">{{ currentPlan.type === 'yearly' ? 'Annual' : 'Monthly' }} Plan</div>
            <div v-if="membership.discount">with {{ membership.discount * 100 }}% OFF</div>
            <hr class="my-4" />
            <div class="text-5xl font-bold text-center">
              <span v-if="membership.discount">{{ ((1 - membership.discount) * currentPlan.price) | currency }}</span>
              <span v-else>{{ currentPlan.price | currency }}</span>
            </div>
            <span class="label mb-6">USD / {{ currentPlan.type === 'yearly' ? 'year' : 'month' }}</span>
            <hr class="my-4 mt-8" />
            <p v-if="subscriptionDetails.next_payment">Your plan renews on {{ subscriptionDetails.next_payment.date | date }}</p>
            <p v-if="membership.cancelled">Your plan expires on {{ membership.subscription.ends_at | date }}</p>
          </div>
        </div>

        <div v-if="!membership.cancelled && (updateUrl || cancelUrl)" class="my-4 text-base text-center">
          <div v-if="updateUrl">
            <a class="inline-block mb-2" :href="updateUrl" target="_blank">Update Payment Method</a>
          </div>
          <div v-if="cancelUrl">
            <a class="inline-block mb-2" :href="cancelUrl" target="_blank">Cancel Subscription</a>
          </div>
        </div>
      </div>

      <!-- Non-subscribers -->
      <div v-else key="subscription">
        <div v-if="membership.on_trial" key="trial">
          <h1 class="mt-4 mb-2 text-center">Free Trial</h1>
          <p class="w-128 mx-auto mb-2">
            Your free trial expires on {{ membership.trial_ends_at | date }}.
            <br />
            Please select a plan to continue using FormBeat.
          </p>
        </div>

        <div v-else key="trial">
          <h1 class="mt-4 mb-2 text-center">Your Trial has Ended</h1>
          <p class="w-128 mx-auto mb-2">Please select a plan in order to continue using FormBeat.</p>
        </div>

        <PricingPlans
          :email="user.email"
          :passthrough="membership.passthrough"
          :discount="membership.discount"
          :coupon="membership.coupon"
          @subscribed="onSubscribed"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProfileService from 'services/ProfileService'
import PricingPlans from 'components/PricingPlans'

export default {
  components: {
    PricingPlans
  },

  props: {
    user: {
      type: Object
    }
  },

  data() {
    return {
      membership: undefined,
      subscriptionsPlans: undefined
    }
  },

  computed: {
    currentPlan() {
      return this.subscriptionsPlans.find(({ id }) => id == this.membership?.subscription?.processor_plan)
    },

    subscriptionDetails() {
      return this.membership?.details?.table || {}
    },

    updateUrl() {
      return this.membership?.subscription?.data?.paddle_update_url
    },

    cancelUrl() {
      return this.membership?.subscription?.data?.paddle_cancel_url
    }
  },

  async mounted() {
    /*
    User Subscription object
    {
      subscription: {},
      details: {},
      coupon: '10off',
      discount: 0.5,
      premium: true,
      on_trial: false,
      trial_ends_at: nil,
      cancelled: true,
      is_grace_period: true,
      is_early_adopter: false,
      has_previous_subscription: true,
      has_active_subscription: true,
      passthrough: "..."
    } */

    this.membership = await ProfileService.getUserSubscription()
    this.subscriptionsPlans = ProfileService.getSubscriptionPlans()
  },

  methods: {
    onSubscribed() {
      window.location.reload()
    }
  }
}
</script>

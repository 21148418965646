<template>
  <div>
    <Header />

    <div class="Legal">
      <h1>Privacy Policy</h1>
      <p class="text-lg leading-loose">
        By accessing the website at
        <a href="https://www.formbeat.com">https://www.formbeat.com</a>
        , you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with
        any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in
        this website are protected by applicable copyright and trademark law.
      </p>

      <div class="mb-8">
        <h4>Website visitors</h4>
        <p>
          Like most website operators, formbeat.com collects non-personally-identifying information of the sort that web browsers and servers typically make
          available, such as the browser type, language preference, referring site, and the date and time of each visitor request. formbeat.com may also collect
          statistics about the behavior of visitors to its websites. formbeat.com's purpose in collecting non-personally identifying information is to better
          understand how formbeat.com’s visitors use its website. From time to time, formbeat.com may release non-personally-identifying information in the
          aggregate, e.g., by publishing a report on trends in the usage of its website.
        </p>
      </div>

      <div class="mb-8">
        <h4>Gathering of personally identifying information</h4>
        <p>
          Certain visitors to formbeat.com’s websites choose to interact with formbeat.com in ways that require formbeat.com to gather personally-identifying
          information. The amount and type of information that formbeat.com gathers depends on the nature of the interaction. For example, we ask visitors who
          sign up for an account at formbeat.com to provide an email address. Those who engage in transactions with formbeat.com – by purchasing access to the
          premium services, for example – are asked to provide additional information, including as necessary the personal and financial information required to
          process those transactions. In each case, formbeat.com collects such information only insofar as is necessary or appropriate to fulfill the purpose of
          the visitor’s interaction with formbeat.com. formbeat.com does not disclose personally-identifying information other than as described below. And
          visitors can always refuse to supply personally-identifying information, with the caveat that it may prevent them from engaging in certain
          website-related activities.
        </p>
      </div>
      <div class="mb-8">
        <h4>Protection of certain personally identifying information</h4>
        <p>
          formbeat.com discloses potentially personally-identifying and personally-identifying information only to those of its employees, contractors and
          affiliated organizations that
        </p>
        <p>
          (i) need to know that information in order to process it on formbeat.com’s behalf or to provide services available at formbeat.com’s websites, and
        </p>
        <p>(ii) that have agreed not to disclose it to others.</p>
        <p>
          Some of those employees, contractors and affiliated organizations may be located outside of your home country; by using formbeat.com’s websites, you
          consent to the transfer of such information to them. formbeat.com will not rent or sell potentially personally-identifying and personally-identifying
          information to anyone. Other than to its employees, contractors and affiliated organizations, as described above, formbeat.com discloses potentially
          personally-identifying and personally-identifying information formbeat.com in response to a subpoena, court order or other governmental request, or
          when formbeat.com believes in good faith that disclosure is reasonably necessary to protect the property or rights of formbeat.com, third parties or
          the public at large. If you are a registered user of an formbeat.com website and have supplied your email address, formbeat.com may occasionally send
          you an email to tell you about new features, solicit your feedback, or just keep you up to date with what’s going on with formbeat.com and our
          products. We primarily use our various other channels to communicate this type of information, so we expect to keep this type of email to a minimum.
          If you send us a request (for example via a support email or via one of our feedback mechanisms), we reserve the right to publish it in order to help
          us clarify or respond to your request or to help us support other users. formbeat.com takes all measures reasonably necessary to protect against the
          unauthorized access, use, alteration or destruction of potentially personally-identifying and personally-identifying information.
        </p>
      </div>
      <div class="mb-8">
        <h4>Cookies</h4>
        <p>
          A cookie is a string of information that a website stores on a visitor’s computer, and that the visitor's browser provides to the website each time
          the visitor returns. formbeat.com uses cookies to help formbeat.com identify and track visitors, their usage of formbeat.com website, and their
          website access preferences. formbeat.com visitors who do not wish to have cookies placed on their computers should set their browsers to refuse
          cookies before using formbeat.com’s websites, with the drawback that certain features of formbeat.com’s websites may not function properly without the
          aid of cookies.
        </p>
        <p>formbeat.com uses Google Analytics features based on Display Advertising. Visitors can opt out from this at any time.</p>
      </div>
      <div class="mb-8">
        <h4>Business transfers</h4>
        <p>
          If formbeat.com, or substantially all of its assets, were acquired, or in the unlikely event that formbeat.com goes out of business or enters
          bankruptcy, user information would be one of the assets that is transferred or acquired by a third party. You acknowledge that such transfers may
          occur, and that any acquirer of formbeat.com may continue to use your personal information as set forth in this policy.
        </p>
      </div>
      <div class="mb-8">
        <h4>Privacy policy changes</h4>
        <p>
          Although most changes are likely to be minor, formbeat.com may change its Privacy Policy from time to time, and in formbeat.com’s sole discretion.
          formbeat.com encourages visitors to frequently check this page for any changes to its Privacy Policy. Your continued use of this site after any change
          in this Privacy Policy will constitute your acceptance of such change.
        </p>
        <p>
          Our privacy policy above is based on the one from the wonderful guys from Automattic. You’re more than welcome to steal it and repurpose it for your
          own use, just make sure to replace references to us with ones to you. Also check out the original and consider WordPress.com when looking for blog or
          general CMS.
        </p>
      </div>
      <div class="mb-8">
        <h4>Data Processing Agreement</h4>
        <p>
          <router-link :to="{ name: 'dpa' }">Please click here to review our DPA</router-link>
        </p>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from 'components/Header'
import Footer from 'components/Footer'

export default {
  components: {
    Header,
    Footer
  },
  metaInfo() {
    return {
      title: 'Privacy Policy',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Privacy Policy of FormBeat.com'
        }
      ]
    }
  }
}
</script>

<template>
  <div>
    <Header />

    <div class="Auth">
      <div class="Auth__image"></div>
      <div class="Auth__form">
        <div class="w-full">
          <!-- Form -->
          <h1 class="md:text-4xl mb-3 text-3xl font-extrabold leading-tight">Reconnect Strava</h1>
          <p class="mb-6 text-lg">
            Please reconnect your Strava account to continue using FormBeat. This is only an under-the-hood change so we can process everything faster.
          </p>

          <img src="~images/connect-with-strava.svg" alt="Connect with Strava" class="mb-3 -ml-1 cursor-pointer" width="256" @click="connectStrava()" />
          <label class="mb-4 cursor-pointer select-none">
            <Checkbox v-model="privateScope" class="mr-1" />
            Include private activities
          </label>

          <p v-if="user" class="pt-6 mt-8 border-t">
            <router-link :to="{ name: 'settings' }" class="text-link font-bold">Account Settings</router-link>
          </p>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import StravaService from 'services/StravaService'
import AnalyticsService from 'services/AnalyticsService'

import Header from 'components/Header'
import Footer from 'components/Footer'

export default {
  components: {
    Header,
    Footer
  },

  data() {
    return {
      privateScope: true
    }
  },

  computed: {
    ...mapGetters('profile', ['user'])
  },

  methods: {
    async connectStrava() {
      StravaService.connect(this.privateScope)
      AnalyticsService.trackEvent('conversion', 'connect-with-strava')
      AnalyticsService.ahoyTrackEvent('connect-with-strava')
    }
  },

  metaInfo() {
    return {
      title: 'Start Training Smarter',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Sign up and start improving your cycling performance'
        }
      ]
    }
  }
}
</script>

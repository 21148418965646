<template>
  <div class="observer" />
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      required: false,
      default: () => {}
    }
  },

  data() {
    return {
      observer: null
    }
  },

  mounted() {
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry && entry.isIntersecting) {
        this.$emit('intersect')
      }
    }, this.options)

    this.$nextTick(() => {
      this.observer.observe(this.$el)
    })
  },

  destroyed() {
    this.observer.disconnect()
  }
}
</script>

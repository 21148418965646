<template>
  <div class="w-full">
    <div v-if="user.has_powermeter">
      <PowerCurveChart title="Power Curve" :data="activities" :date-range="dateRange" :weight="user.weight_metric" />

      <StatsSection title="Power Profile">
        <PowerProfileChart title="Power Profile" :data="activities" :date-range="dateRange" />
        <template #header>
          <div class="text-muted items center flex space-x-2 text-xs font-bold uppercase">
            <svg class="svg-icon svg-icon--sm text-primary opacity-25">
              <use xlink:href="#icon-circle"></use>
            </svg>
            <div class="mr-4">Your best W/Kg efforts</div>
            <InfoIcon term="power-profile" />
          </div>
        </template>
      </StatsSection>

      <StatsSection title="Power Curve Recency">
        <PowerCurveRecencyChart title="Power Curve" :data="activities" :date-range="dateRange" />
        <template #header>
          <div class="text-muted items center flex space-x-4 text-xs font-bold uppercase" title="Color shows how recent is your power record">
            <div>Least recent</div>
            <img src="~images/icons/intensity.svg" alt="Power record recency" width="60" height="12" />
            <div>Most recent</div>
            <InfoIcon term="power-recency" />
          </div>
        </template>
      </StatsSection>

      <PowerRecordChart title="Power Record History" :data="summary" :date-range="dateRange" attribute="peak_power" format="power" />
    </div>
    <div v-else>
      <div class="text-muted p-12 text-center bg-white rounded shadow">
        Add activities with power data and
        <router-link class="text-link" :to="{ name: 'settings.power' }">set your FTP</router-link>
        to see you power profile and trends.
      </div>
    </div>
  </div>
</template>

<script>
import PowerCurveChart from 'components/charts/PowerCurveChart'
import PowerCurveRecencyChart from 'components/charts/PowerCurveRecencyChart'
import PowerProfileChart from 'components/charts/PowerProfileChart'
import PowerRecordChart from 'components/charts/PowerRecordChart'
import StatsSection from 'components/StatsSection'

export default {
  components: {
    PowerCurveChart,
    PowerCurveRecencyChart,
    PowerRecordChart,
    PowerProfileChart,
    StatsSection
  },

  props: {
    summary: {
      type: Object,
      required: true
    },
    currentSummary: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    activities: {
      type: Array,
      required: true
    },
    segmentBy: {
      type: String,
      required: true
    },
    dateRange: {
      type: Object,
      required: true
    }
  },

  metaInfo() {
    return {
      title: 'Power Profile Analysis'
    }
  }
}
</script>

<template>
  <div class="Header">
    <div class="Header__logo">
      <router-link :to="{ name: 'home' }" class="mr-4">
        <Logo />
      </router-link>
    </div>

    <div class="hover-text-primary font-extralight md:hidden px-4 -mr-4 text-4xl cursor-pointer" @click="toggleNavigation()">=</div>

    <div class="Header__content md:flex md:space-x-6 items-center" :class="{ 'hidden': !isNavigationOpen }">
      <router-link :to="{ name: 'home', query: { section: 'features' } }" class="Header__link" title="Features">Features</router-link>

      <router-link :to="{ name: 'mobile' }" class="Header__link" title="Get Mobile App">Mobile App</router-link>

      <router-link :to="{ name: 'tools' }" class="Header__link" title="Cycling Tools and Calculators">Tools</router-link>

      <router-link :to="{ name: 'pricing' }" class="Header__link" title="Pricing">Pricing</router-link>

      <router-link v-if="user" :to="{ name: 'dashboard' }" class="Header__link" title="Training Dashboard">Dashboard</router-link>

      <router-link v-if="!user" :to="{ name: 'signIn' }" class="Header__link" title="Sign in">Sign in</router-link>

      <router-link v-if="!user" :to="{ name: 'join' }" class="Header__link" title="Sign up">
        <AppButton primary block small class="md:w-auto w-full">Sign up</AppButton>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
      isNavigationOpen: false
    }
  },

  computed: {
    ...mapGetters('profile', ['user'])
  },

  methods: {
    ...mapActions('profile', ['signOut']),

    toggleNavigation(isOpen) {
      this.isNavigationOpen = isOpen === undefined ? !this.isNavigationOpen : isOpen
    },

    async logout() {
      await this.signOut()
      window.location.reload()
    }
  }
}
</script>

<style media="screen" lang="postcss" scoped>
.Header {
  @apply flex items-center justify-between h-16 px-8 bg-white border-b select-none;

  &__logo {
    @apply flex items-center;
  }
}

.Header__link {
  @apply no-underline;
}
@media only screen and (max-width: theme('screens.md')) {
  .Header__content {
    @apply absolute inset-x-0 top-0 bottom-0 mt-16 shadow-lg;
  }
  .Header__link {
    @apply relative z-20 block p-4 text-lg font-bold bg-white border-b;
  }
}
</style>

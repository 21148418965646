<template>
  <chart ref="chart" :options="chartData" :callback="setChart"></chart>
</template>

<script>
import * as chartConfig from 'constants/chartConfig'

import dayjs from 'utils/dayjs'
import dateFormat from 'utils/dateFormat'

import formatUnits from 'filters/formatUnits'
import formatLabels from 'filters/formatLabels'

export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    segmentBy: {
      type: String,
      required: true
    },
    attribute: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    height: {
      type: Number,
      required: false,
      default: 400
    },
    format: {
      type: String,
      required: false
    },
    decimalPlaces: {
      type: Number,
      required: false,
      default: 0
    }
  },

  data() {
    return {
      chart: undefined
    }
  },

  computed: {
    chartUnits() {
      return formatUnits(this.format)
    },

    chartData() {
      const source = this.data[this.segmentBy]
      const keys = Object.keys(source)
      const data = keys.map((key) => {
        return [dayjs(key).valueOf(), source[key] ? Number(source[key][this.attribute]) : 0]
      })

      const dataPoints = data.map((d) => d[1] || 0)
      const minPositiveValue = Math.min.apply(
        null,
        dataPoints.filter((v) => v > 0)
      )
      const minValue = 0.8 * minPositiveValue
      // const minValue = 0
      const maxValue = Math.max.apply(null, dataPoints)

      const sum = dataPoints.reduce((a, b) => a + b, 0)
      const averageValue = (sum / dataPoints.filter(Boolean).length).toFixed(2)

      const { formatter } = formatLabels(dataPoints.length, this.segmentBy, this.$isMobile())

      return {
        ...chartConfig.defaultOptions,

        chart: {
          ...chartConfig.chart,
          height: this.$isMobile() ? this.height / 2 : this.height,
          marginLeft: 75
        },
        exporting: {
          ...chartConfig.exporting,
          enabled: !this.$isMobile()
        },
        tooltip: {
          ...chartConfig.withTooltip,
          valueDecimals: this.decimalPlaces,
          valueSuffix: this.chartUnits ? ` ${this.chartUnits}` : '',
          xDateFormat: dateFormat(this.segmentBy)
        },
        colors: ['#ED561B', '#443be0', '#48856b', '#8b2477', '#f5cb5c'],
        xAxis: {
          type: 'datetime',
          startOfWeek: Number(dayjs().weekday(0).format('d')),
          margin: 0,
          padding: 0,
          labels: {
            formatter: formatter,
            autoRotation: false,
            padding: 0
          }
        },
        yAxis: [
          {
            floor: 0,
            min: minValue,
            max: maxValue,
            margin: 0,
            padding: 0,
            plotLines: [
              {
                color: '#ff8200',
                dashStyle: 'Dash',
                value: averageValue,
                width: 1,
                zIndex: 4
              }
            ],
            title: {
              enabled: false
            },
            labels: {
              format: this.chartUnits ? `{value} ${this.chartUnits}` : '{value}'
            }
          }
        ],
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true
            },
            marker: {
              enabled: false
            },
            enableMouseTracking: false
          },
          series: {
            shadow: false,
            pointPadding: 0.05,
            groupPadding: 0.05,
            borderWidth: 1,
            maxPointWidth: 148,
            crisp: false,
            marker: {
              enabled: false,
              symbol: 'circle'
            }
          }
        },
        series: [
          {
            type: 'column',
            name: this.title,
            data: data
          }
        ]
      }
    }
  },

  methods: {
    setChart(chart) {
      this.chart = chart
    }
  }
}
</script>

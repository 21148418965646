import Vue from 'vue'
import VueRouter from 'vue-router'
import mixpanel from 'mixpanel'
import bus from 'bus'
import AnalyticsService from 'services/AnalyticsService'

import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  routes,

  mode: 'history',

  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      if (to.path === from.path) {
        resolve()
        return
      }

      // Scroll the view to the saved position when returning to the list of activities
      if (to.name === 'dashboard.activities' && from.name === 'activity.overview') {
        bus.$once('dashboard.activitiesLoaded', () => resolve(savedPosition))
        return
      }

      // Scroll the view to the saved position when returning to the list of activities
      if (to.name === 'dashboard.calendar' && from.name === 'activity.overview') {
        bus.$once('dashboard.calendarLoaded', () => resolve(savedPosition))
        return
      }

      resolve({ x: 0, y: 0 })
    })
  }
})

// Datalayer reset
router.beforeEach((to, from, next) => {
  next()
  AnalyticsService.resetDatalayer()
})

// Virtual pageview tracking
router.afterEach((to) => {
  // Send custom pageview event to Google tag manager
  setTimeout(() => {
    AnalyticsService.trackPageView(to.fullPath)
    AnalyticsService.ahoyTrackPageView(to.name)
    mixpanel.track('Pageview', { name: to.name })
  }, 600)
})

export default router

<template>
  <div class="CreateActivity">
    <SectionTitle title="Add Manual Activity" />

    <div class="w-full px-8 py-4 mb-16 bg-white shadow-md">
      <div class="FormGroup">
        <div class="FormGroup__label lg:w-1/5 w-2/5 text-base">
          Date &amp; Time
          <span class="text-red-400">*</span>
          <InputErrorMessage v-if="errors.date || errors.time" :error="errors.date || errors.time" />
        </div>
        <div class="FormGroup__content lg:w-3/5 xl:w-2/5 flex w-full space-x-2">
          <div class="md:w-48 w-full">
            <datepicker v-model="form.date" name="date" input-class="input" :monday-first="true" :disabled-dates="disabledDates"></datepicker>
          </div>
          <SelectInput v-model="form.time" value-key="value" label-key="label" class="md:w-auto w-full" :options="timePickerOptions" @input="updateTime" />
        </div>
      </div>

      <div class="FormGroup">
        <div class="FormGroup__label lg:w-1/5 w-2/5 text-base">
          Name
          <span class="text-red-400">*</span>
          <InputErrorMessage v-if="errors.name" :error="errors.name" />
        </div>
        <div class="FormGroup__content lg:w-3/5 xl:w-2/5 w-full">
          <TextInput v-model="form.name" class="w-full" placeholder="e.g. Morning Ride" />
        </div>
      </div>

      <div class="FormGroup">
        <div class="FormGroup__label lg:w-1/5 w-2/5 text-base">
          Moving Time
          <span class="text-red-400">*</span>
          <InputErrorMessage v-if="errors.hours || errors.minutes" :error="errors.hours || errors.minutes" />
        </div>
        <div class="FormGroup__content lg:w-3/5 xl:w-2/5 flex items-center w-full space-x-2">
          <TextInput v-model="form.hours" class="md:w-32 w-full" placeholder="hours" type="number" />
          <div class="pr-4">hours</div>
          <TextInput v-model="form.minutes" class="md:w-32 w-full" placeholder="minutes" type="number" />
          <div class="">minutes</div>
        </div>
      </div>

      <div class="FormGroup">
        <div class="FormGroup__label lg:w-1/5 w-2/5 text-base">
          Distance
          <span class="text-red-400">*</span>
          <InputErrorMessage v-if="errors.distance" :error="errors.distance" />
        </div>
        <div class="FormGroup__content lg:w-3/5 xl:w-2/5 flex w-full space-x-2">
          <TextInput v-model="form.distance" class="md:w-32 w-full" placeholder="e.g. 54" type="number" />
          <SelectInput v-model="form.distanceUnits" class="md:w-24 w-full" :options="['km', 'mi']" />
        </div>
      </div>

      <div class="FormGroup">
        <div class="FormGroup__label lg:w-1/5 w-2/5 text-base">
          Elevation
          <InputErrorMessage v-if="errors.elevation" :error="errors.elevation" />
        </div>
        <div class="FormGroup__content lg:w-3/5 xl:w-2/5 flex w-full space-x-2">
          <TextInput v-model="form.elevation" class="md:w-32 w-full" placeholder="e.g. 1200" type="number" />
          <SelectInput v-model="form.elevationUnits" class="md:w-24 w-full" :options="['m', 'feet']" />
        </div>
      </div>

      <div class="FormGroup">
        <div class="FormGroup__label lg:w-1/5 w-2/5 text-base">
          TSS
          <InputErrorMessage v-if="errors.tss" :error="errors.tss" />
        </div>
        <div class="FormGroup__content lg:w-3/5 xl:w-2/5 flex-col w-full">
          <TextInput v-model="form.tss" class="md:w-32 w-full" placeholder="e.g. 100" type="number" />
          <div class="text-muted flex items-center mt-2 text-sm">
            <svg class="svg-icon svg-icon--sm mr-2">
              <use xlink:href="#icon-info"></use>
            </svg>
            Leave blank to have TSS estimated automatically
          </div>
        </div>
      </div>

      <div class="FormGroup">
        <div class="FormGroup__label lg:w-1/5 w-2/5 text-base">
          Note
          <InputErrorMessage v-if="errors.note" :error="errors.note" />
        </div>
        <div class="FormGroup__content lg:w-3/5 xl:w-2/5 flex-col w-full">
          <div v-if="addNote">
            <textarea v-model="form.note" rows="2" class="input w-full" placeholder="Add private activity note" />
          </div>
          <div v-else>
            <a href class="flex items-center" @click.prevent="addNote = true">Click to add a note</a>
          </div>
        </div>
      </div>

      <div class="FormGroup FormGroup--last">
        <div class="FormGroup__label lg:w-1/5 w-2/5 text-base"></div>
        <div class="FormGroup__content lg:w-3/5 xl:w-2/5 flex w-full space-x-2">
          <AppButton :disabled="isSubmitting" class="w-full" primary @click="submit()">Save</AppButton>
          <AppButton secondary @click="cancel()">Cancel</AppButton>
        </div>
      </div>

      <InputErrorMessage v-if="errors.form" :error="errors.form" />
    </div>
  </div>
</template>

<script>
import dayjs from 'utils/dayjs'
import { mapActions } from 'vuex'

import timePickerOptions from 'constants/timePickerOptions'
import Datepicker from 'vuejs-datepicker'
import SectionTitle from 'components/SectionTitle'

import ProfileService from 'services/ProfileService'
import ValidationService, { isRequired, isMin, isMax } from 'services/ValidationService'

export default {
  components: {
    Datepicker,
    SectionTitle
  },

  props: {
    user: {
      type: Object
    }
  },

  data() {
    return {
      timePickerOptions,
      errors: {},
      result: undefined,
      isSubmitting: false,
      addNote: false,
      form: {
        time: 8,
        minutes: 0,
        note: undefined,
        date: new Date(),
        elevationUnits: this.user.measurement_preference === 'meters' ? 'm' : 'feet',
        distanceUnits: this.user.measurement_preference === 'meters' ? 'km' : 'mi'
      },
      disabledDates: {
        from: new Date()
      }
    }
  },

  computed: {
    movingTime() {
      if ((!this.form.hours && this.form.hours !== 0) || (!this.form.minutes && this.form.minutes !== 0)) {
        return 0
      }

      return this.form.hours * 3600 + this.form.minutes * 60
    },

    startDate() {
      if (!this.form.date) {
        return ''
      }

      let date = dayjs(this.form.date).startOf('day')

      if (this.form.time) {
        date = date.add(this.form.time, 'hours').toDate()
      }

      return date
    },

    distance() {
      const mileToKm = 1.60934

      if (!this.form.distance) {
        return
      }

      if (this.form.distanceUnits === 'km') {
        return this.form.distance * 1000
      } else {
        return this.form.distance * 1000 * mileToKm
      }
    },

    elevationGain() {
      const feetToMeter = 0.3048

      if (!this.form.elevation) {
        return 0
      }

      if (this.form.elevationUnits === 'm') {
        return this.form.elevation
      } else {
        return this.form.elevation * feetToMeter
      }
    },

    averageSpeed() {
      if (!this.distance || !this.movingTime) {
        return
      }

      return (this.distance / 1000 / (this.movingTime / 3600) / 3.6).toFixed(3)
    }
  },

  methods: {
    ...mapActions('profile', ['loadProfile']),

    updateTime(hour) {
      if (this.form.name) {
        return
      }

      if (hour >= 18) {
        this.form.name = 'Evening Ride'
      } else if (hour >= 12) {
        this.form.name = 'Afternoon Ride'
      } else if (hour >= 4) {
        this.form.name = 'Morning Ride'
      } else {
        this.form.name = 'Night Ride'
      }
    },

    cancel() {
      this.$router.push({ name: 'dashboard.activities' })
    },

    async submit() {
      this.errors = {}

      const rules = {
        date: [isRequired()],
        time: [isRequired(), isMin(0), isMax(23.5)],
        name: [isRequired()],
        hours: [isRequired(), isMin(0), isMax(24)],
        minutes: [isRequired(), isMin(0), isMax(59)],
        distance: [isRequired(), isMin(0), isMax(9999)],
        elevation: [isMin(0), isMax(99999)],
        elevationUnits: [isRequired()],
        distanceUnits: [isRequired()]
      }

      const result = await ValidationService.validate(this.form, rules)

      if (!result.isValid) {
        this.errors = result.errors
      } else {
        const activity = {
          name: this.form.name,
          start_date: this.startDate,
          moving_time: this.movingTime,
          distance: this.distance,
          total_elevation_gain: this.elevationGain,
          average_speed: this.averageSpeed,
          tss: this.form.tss || undefined,
          note: this.form.note
        }

        this.isSubmitting = true

        try {
          const result = await ProfileService.createActivity(activity)
          await this.loadProfile()
          this.$router.push({ name: 'dashboard.activity', params: { id: result.id } })
        } catch (e) {
          this.errors = {
            form: 'Something wrong happened, please try again.'
          }
        }

        this.isSubmitting = false
      }
    }
  },

  metaInfo() {
    return {
      title: 'Add an Activity'
    }
  }
}
</script>

<style lang="postcss" scoped>
.CreateActivity .FormGroup .FormGroup__content {
  margin-left: 0;
}
</style>

const defaultStreamOptions = {
  lineWidth: 1,
  scale: 1,
  calculateMinValue: false,
  visible: true,
  zIndex: 0,
  type: 'area',
  showInLegend: true,
  showInNavigator: false
}

export default {
  heartrate: {
    ...defaultStreamOptions,
    lineColor: '#ff0054',
    color: '#ff0054',
    colorWhenPrimary: {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: [
        [0, 'rgba(236, 41, 41, 0.4)'],
        [0.15, 'rgba(236, 41, 41, 0.2)'],
        [1, 'rgba(240, 203, 203, 0.1)']
      ]
    },
    fillOpacity: 0.02,
    zIndex: 1,
    scale: 1,
    name: 'Heart Rate',
    format: 'heartrate',
    calculateMinValue: true
  },
  watts: {
    ...defaultStreamOptions,
    lineColor: 'rgba(132, 41, 236, 0.8)',
    altColor: 'rgba(220, 203, 240, 0.2)',
    color: {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: [
        [0, 'rgba(132, 41, 236, 0.5)'],
        [0.25, 'rgba(132, 41, 236, 0.4)'],
        [1, 'rgba(220, 203, 240, 0.2)']
      ]
    },
    zIndex: 2,
    name: 'Power',
    format: 'power',
    scale: 1,
    calculateMinValue: true
  },
  cadence: {
    ...defaultStreamOptions,
    color: 'rgba(84, 67, 42, 0.73)',
    fillOpacity: 0,
    scale: 0.6,
    type: 'line',
    zIndex: 9,
    enabled: true,
    visible: false,
    name: 'Cadence',
    format: 'numeric',
    calculateMinValue: true
  },
  velocity_smooth: {
    ...defaultStreamOptions,
    color: 'rgb(61, 143, 138)',
    altColor: 'rgb(0, 224, 255, 0.2)',
    fillOpacity: 0.15,
    zIndex: 3,
    scale: 0.65,
    name: 'Speed',
    format: 'speed',
    calculateMinValue: true
  },
  altitude: {
    ...defaultStreamOptions,
    lineWidth: 0,
    fillOpacity: 0.65,
    lineColor: '#e7e3e2',
    color: '#d4cac8',
    altColor: '#e7e3e2',
    zIndex: 0,
    showInNavigator: true,
    name: 'Altitude',
    format: 'elevation',
    calculateMinValue: true
  },
  grade_smooth: {
    ...defaultStreamOptions,
    color: '#ffe324',
    fillOpacity: 0,
    zIndex: 4,
    name: 'Grade',
    format: 'grade',
    showInLegend: true,
    enabled: true,
    calculateMinValue: true
  },
  distance: {
    ...defaultStreamOptions,
    lineColor: 'rgba(255, 52, 52, 0.9)',
    color: 'rgba(255, 52, 52, 0.15)',
    altColor: 'rgba(255, 52, 52, 0.03)',
    scale: 0.9,
    name: 'Distance',
    format: 'numeric',
    visible: false,
    showInLegend: false
  },
  time: {
    ...defaultStreamOptions,
    lineColor: 'rgba(255, 52, 52, 0.9)',
    color: 'rgba(255, 52, 52, 0.15)',
    altColor: 'rgba(255, 52, 52, 0.03)',
    scale: 0.9,
    name: 'Duration',
    format: 'numeric',
    visible: false,
    enabled: false,
    showInLegend: false
  }
}

<template>
  <div class="xl:w-192 w-full">
    <div class="p-8 mb-6 bg-white border rounded">
      <h1 class="mb-2 text-3xl">Power to Weight Ratio Calculator</h1>
      <p class="text-muted mb-4 text-sm">Enter power in Watts and your weight to calculate Watts per Kilo of your cycling workout.</p>

      <div class="FormGroup">
        <div class="FormGroup__label">
          Power in Watts
          <InputErrorMessage v-if="errors.power" :error="errors.power" />
        </div>
        <div class="FormGroup__content md:w-2/5">
          <TextInput v-model="form.power" class="w-full" placeholder="e.g. 220" type="number" aria-label="Enter power in watts" />
        </div>
      </div>

      <div class="FormGroup">
        <div class="FormGroup__label">
          Your Weight
          <InputErrorMessage v-if="errors.weight" :error="errors.weight" />
        </div>
        <div class="FormGroup__content md:w-2/5">
          <TextInput
            v-model="form.weight"
            class="w-full mr-2"
            :placeholder="form.weight_units == 'lb' ? 'e.g. 165' : 'e.g. 75'"
            type="number"
            aria-label="Enter your weight"
          />

          <SelectInput
            v-model="form.weight_units"
            value-key="value"
            label-key="label"
            class="md:w-2/3 w-3/5"
            :options="weightUnitOptions"
            aria-label="Select weight units"
          />
        </div>
      </div>

      <AppButton class="mt-4" primary small @click="calculate()">Calculate Power to Weight</AppButton>
    </div>

    <div v-if="result" class="p-6 mt-6 text-center bg-white border rounded">
      <div class="label">Watts per Kilo</div>
      <div class="-mt-4 text-5xl font-bold">{{ result }} W/Kg</div>
    </div>

    <div class="md:flex-row flex flex-col p-8 my-4 space-x-8 space-y-4 overflow-hidden bg-white border">
      <div class="max-w-md">
        <h2 class="mb-2 text-3xl font-bold leading-tight">Training with a power meter?</h2>
        Start tracking your cycling power and get actionable insights from your training. Our AI-powered tools are designed to help you become a stronger and
        faster cyclist in less than 8 weeks.
        <AppButton class="block mt-8" primary small>
          <router-link :to="{ name: 'join' }" title="Create a free account">Sign up for FREE</router-link>
        </AppButton>
      </div>
      <div class="max-w-md" style="margin-right: -6rem">
        <img :src="require('images/features/training-load-sm.jpg') | imageUrl('w_640')" alt="Cycling analytics dashboard" />
      </div>
    </div>
  </div>
</template>

<script>
import ValidationService, { isRequired, isMin, isMax } from 'services/ValidationService'

export default {
  data() {
    return {
      form: {
        weight_units: 'lb'
      },
      errors: {},
      result: undefined,
      weightUnitOptions: [
        {
          value: 'kg',
          label: 'Kg'
        },
        {
          value: 'lb',
          label: 'Lb'
        }
      ]
    }
  },

  methods: {
    async calculate() {
      this.errors = {}

      const rules = {
        weight: [isRequired(), isMin(1), isMax(400)],
        power: [isRequired(), isMin(1), isMax(999)]
      }

      const result = await ValidationService.validate(this.form, rules)

      if (!result.isValid) {
        this.result = undefined
        this.errors = result.errors
      } else {
        const weightInKilograms = this.form.weight_units == 'lb' ? (1 / 2.2046226218) * this.form.weight : this.form.weight
        this.result = (this.form.power / weightInKilograms).toFixed(2)
      }
    }
  },

  metaInfo() {
    return {
      title: `Power to Weight Calculator`,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Online Power to Weight (Watts per Kilo) calculator'
        }
      ]
    }
  }
}
</script>

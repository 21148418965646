<template>
  <div v-show="errorMessage && errorMessage.length" class="InputErrorMessage">
    {{ errorMessage }}
  </div>
</template>

<script>
export default {
  props: {
    error: {
      type: Array | String,
      required: false
    }
  },

  computed: {
    errorMessage() {
      if (this.error && this.error.length) {
        return Array.isArray(this.error) ? this.error[0] : this.error
      } else {
        return ''
      }
    }
  }
}
</script>

<style>
.InputErrorMessage {
  @apply text-sm text-red-600;
}
</style>

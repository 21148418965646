<template>
  <div>
    <Header />
    <div class="Legal">
      <h1>Terms of Use</h1>
      <p class="text-lg leading-loose">
        The following terms and conditions govern all use of the formbeat.com website and all content, services and products available at or through the website
        ("Website"). The Website is offered subject to your acceptance without modification of all of the terms and conditions contained herein and all other
        operating rules, policies (including, without limitation, formbeat.com's Privacy Policy) and procedures that may be published from time to time on this
        Site by formbeat.com (collectively, the "Agreement").
      </p>
      <p>
        Please read this Agreement carefully before accessing or using the Website. By accessing or using any part of the web site, you agree to become bound by
        the terms and conditions of this agreement. If you do not agree to all the terms and conditions of this agreement, then you may not access the Website
        or use any services. If these terms and conditions are considered an offer by formbeat.com, acceptance is expressly limited to these terms. The Website
        is available only to individuals who are at least 13 years old.
      </p>
      <div class="mb-8">
        <h4>Your formbeat.com Account and Site</h4>
        <p>
          If you create an account on the Website, you are responsible for maintaining the security of your account and experiments, and you are fully
          responsible for all activities that occur under the account.
        </p>
        <p>
          You must immediately notify formbeat.com of any unauthorized uses of your account or any other breaches of security. formbeat.com will not be liable
          for any acts or omissions by You, including any damages of any kind incurred as a result of such acts or omissions.
        </p>
      </div>
      <div class="mb-8">
        <h4>Responsibility of Contributors</h4>
        <p>
          If you post material to the Website, post links on the Website, or otherwise make (or allow any third party to make) material available by means of
          the Website (any such material, "Content"), You are entirely responsible for the content of, and any harm resulting from, that Content. That is the
          case regardless of whether the Content in question constitutes text, graphics, an audio file, or computer software. By making Content available, you
          represent and warrant that:
        </p>
        <p>
          the downloading, copying and use of the Content will not infringe the proprietary rights, including but not limited to the copyright, patent,
          trademark or trade secret rights, of any third party;
        </p>
        <p>
          if your employer has rights to intellectual property you create, you have either (i) received permission from your employer to post or make available
          the Content, including but not limited to any software, or (ii) secured from your employer a waiver as to all rights in or to the Content;
        </p>
        <p>
          you have fully complied with any third-party licenses relating to the Content, and have done all things necessary to successfully pass through to end
          users any required terms;
        </p>
        <p>the Content does not contain or install any viruses, worms, malware, Trojan horses or other harmful or destructive content;</p>
        <p>
          the Content is not spam, is not machine- or randomly-generated, and does not contain unethical or unwanted commercial content designed to drive
          traffic to third party sites, or to further unlawful acts (such as phishing) or mislead recipients as to the source of the material (such as
          spoofing);
        </p>
        <p>
          the Content is not pornographic, does not contain threats or incite violence, and does not violate the privacy or publicity rights of any third party;
        </p>
        <p>
          Without limiting any of those representations or warranties, formbeat.com has the right (though not the obligation) to, in formbeat.com’s sole
          discretion (i) refuse or remove any content that, in formbeat.com’s reasonable opinion, violates any formbeat.com policy or is in any way harmful or
          objectionable, or (ii) terminate or deny access to and use of the Website to any individual or entity for any reason, in formbeat.com’s sole
          discretion. formbeat.com will have no obligation to provide a refund of any amounts previously paid.
        </p>
      </div>
      <div class="mb-8">
        <h4>Responsibility of Website Visitors</h4>
        <p>
          formbeat.com has not reviewed, and cannot review, all of the material, including computer software, posted through the Website, and cannot therefore
          be responsible for that material’s content, use or effects. You are responsible for taking precautions as necessary to protect yourself and your
          computer systems from viruses, worms, Trojan horses, and other harmful or destructive content. The Website may contain content that is offensive,
          indecent, or otherwise objectionable, as well as content containing technical inaccuracies, typographical mistakes, and other errors. The Website may
          also contain material that violates the privacy or publicity rights, or infringes the intellectual property and other proprietary rights, of third
          parties, or the downloading, copying or use of which is subject to additional terms and conditions, stated or unstated. formbeat.com disclaims any
          responsibility for any harm resulting from the use by visitors of the Website, or from any downloading by those visitors of content there posted.
        </p>
      </div>
      <div class="mb-8">
        <h4>Content Posted on Other Websites</h4>
        <p>
          We have not reviewed, and cannot review, all of the material, including computer software, made available through the websites and webpages to which
          formbeat.com links, and that link to formbeat.com. formbeat.com does not have any control over those non-formbeat.com websites and webpages, and is
          not responsible for their contents or their use. By linking to a non-formbeat.com website or webpage, formbeat.com does not represent or imply that it
          endorses such website or webpage. You are responsible for taking precautions as necessary to protect yourself and your computer systems from viruses,
          worms, Trojan horses, and other harmful or destructive content. formbeat.com disclaims any responsibility for any harm resulting from your use of
          non-formbeat.com websites and webpages.
        </p>
      </div>
      <div class="mb-8">
        <h4>Intellectual Property</h4>
        <p>
          This Agreement does not transfer from formbeat.com to you any formbeat.com or third party intellectual property, and all right, title and interest in
          and to such property will remain (as between the parties) solely with formbeat.com. formbeat.com, its logo, and all other trademarks, service marks,
          graphics and logos used in connection with formbeat.com, or the Website are trademarks or registered trademarks of formbeat.com or formbeat.com's
          licensors. Other trademarks, service marks, graphics and logos used in connection with the Website may be the trademarks of other third parties. Your
          use of the Website grants you no right or license to reproduce or otherwise use any formbeat.com or third-party trademarks.
        </p>
      </div>
      <div class="mb-8">
        <h4>Changes</h4>
        <p>
          formbeat.com reserves the right, at its sole discretion, to modify or replace any part of this Agreement. It is your responsibility to check this
          Agreement periodically for changes. Your continued use of or access to the Website following the posting of any changes to this Agreement constitutes
          acceptance of those changes. formbeat.com may also, in the future, offer new services and/or features through the Website (including, the release of
          new tools and resources). Such new features and/or services shall be subject to the terms and conditions of this Agreement.
        </p>
      </div>
      <div class="mb-8">
        <h4>Termination</h4>
        <p>
          formbeat.com may terminate your access to all or any part of the Website at any time, with or without cause, with or without notice, effective
          immediately. If you wish to terminate this Agreement or your formbeat.com account (if you have one), you may simply discontinue using the Website. All
          provisions of this Agreement which by their nature should survive termination shall survive termination, including, without limitation, ownership
          provisions, warranty disclaimers, indemnity and limitations of liability.
        </p>
      </div>
      <div class="mb-8">
        <h4>Disclaimer of Warranties</h4>
        <p>
          The Website is provided "as is". formbeat.com and its suppliers and licensors hereby disclaim all warranties of any kind, express or implied,
          including, without limitation, the warranties of merchantability, fitness for a particular purpose and non-infringement. Neither formbeat.com nor its
          suppliers and licensors, makes any warranty that the Website will be error free or that access thereto will be continuous or uninterrupted. You
          understand that you download from, or otherwise obtain content or services through, the Website at your own discretion and risk.
        </p>
      </div>
      <div class="mb-8">
        <h4>Limitation of Liability</h4>
        <p>
          In no event will formbeat.com, or its suppliers or licensors, be liable with respect to any subject matter of this agreement under any contract,
          negligence, strict liability or other legal or equitable theory for: (i) any special, incidental or consequential damages; (ii) the cost of
          procurement for substitute products or services; (iii) for interruption of use or loss or corruption of data; or (iv) for any amounts that exceed the
          fees paid by you to formbeat.com under this agreement during the twelve (12) month period prior to the cause of action. formbeat.com shall have no
          liability for any failure or delay due to matters beyond their reasonable control. The foregoing shall not apply to the extent prohibited by
          applicable law.
        </p>
      </div>
      <div class="mb-8">
        <h4>General Representation and Warranty</h4>
        <p>
          You represent and warrant that (i) your use of the Website will be in strict accordance with the formbeat.com Privacy Policy, with this Agreement and
          with all applicable laws and regulations (including without limitation any local laws or regulations in your country, state, city, or other
          governmental area, regarding online conduct and acceptable content, and including all applicable laws regarding the transmission of technical data
          exported from the United States or the country in which you reside) and (ii) your use of the Website will not infringe or misappropriate the
          intellectual property rights of any third party.
        </p>
      </div>
      <div class="mb-8">
        <h4>Indemnification</h4>
        <p>
          You agree to indemnify and hold harmless formbeat.com, its contractors, and its licensors, and their respective directors, officers, employees and
          agents from and against any and all claims and expenses, including attorneys’ fees, arising out of your use of the Website, including but not limited
          to your violation of this Agreement.
        </p>
      </div>
      <div class="mb-8">
        <h4>Miscellaneous</h4>
        <p>
          This Agreement constitutes the entire agreement between formbeat.com and you concerning the subject matter hereof, and they may only be modified by a
          written amendment signed by an authorized executive of formbeat.com, or by the posting by formbeat.com of a revised version.
        </p>
        <p>
          If any part of this Agreement is held invalid or unenforceable, that part will be construed to reflect the parties’ original intent, and the remaining
          portions will remain in full force and effect. A waiver by either party of any term or condition of this Agreement or any breach thereof, in any one
          instance, will not waive such term or condition or any subsequent breach thereof. You may assign your rights under this Agreement to any party that
          consents to, and agrees to be bound by, its terms and conditions; formbeat.com may assign its rights under this Agreement without condition. This
          Agreement will be binding upon and will inure to the benefit of the parties, their successors and permitted assigns.
        </p>
        <p>
          Our terms of service above are based on the ones from the wonderful guys from Automattic. You’re more than welcome to steal it and repurpose it for
          your own use, just make sure to replace references to us with ones to you. Also check out the original and consider WordPress.com when looking for
          blog or general CMS.
        </p>
      </div>
      <div class="mb-8">
        <h4>Disclaimer</h4>
        <p>
          The data, graphics, images and information available on this website are for informational purposes only and not for the purposes of providing
          professional investing or trading advice. None of the content on the site is to be taken as a recommendation to enter in any transaction. You should
          use the information available for your own analysis or contact an investment or trading professional.
        </p>
      </div>
      <div class="mb-8">
        <h4>Outside links</h4>
        <p>
          formbeat.com has no responsibility for the contents of the linked websites and links should not be taken as endorsements or recommendations of any
          kind.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Header from 'components/Header'

export default {
  components: {
    Header
  },
  metaInfo() {
    return {
      title: 'Terms of Use',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content:
            'The following terms and conditions govern all use of the formbeat.com website and all content, services and products available at or through the website'
        }
      ]
    }
  }
}
</script>

<style lang="postcss">
.Legal {
  @apply p-16 mx-auto;
  max-width: 46rem;
  h1 {
    @apply mb-12 text-5xl text-center;
  }
  h4 {
    @apply mt-8 mb-4 text-2xl font-bold;
  }
  p,
  ol {
    @apply mt-4;
  }
  a {
    @apply text-link;
  }
}
</style>

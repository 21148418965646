<template>
  <div class="SectionTitle" :class="{ 'SectionTitle--sticky': sticky }">
    <h1 class="SectionHeading">{{ title }}</h1>
    <div class="StickyNav">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },

    sticky: {
      type: Boolean,
      required: false,
      default: true
    }
  }
}
</script>

<style lang="postcss">
.SectionTitle {
  @apply flex flex-wrap items-center justify-between mb-4;

  @screen md {
    @apply items-center h-16;
  }

  &--sticky {
    @screen md {
      @apply sticky top-0;
      z-index: 999;
    }
  }
}
.SectionHeading {
  @apply mb-1 mr-4 text-2xl font-bold;

  @screen md {
    @apply mb-0;
  }
}
.StickyNav {
  @apply my-2;

  @screen md {
    @apply my-0 ml-auto;
  }
}
</style>

<template>
  <div class="flex flex-col justify-between h-full text-sm">
    <div class="flex justify-between">
      <div class="w-1/3">
        <div class="text-xs">Distance</div>
        <div class="text-lg font-bold">
          {{ selectedAreaSummary.distance | format('distance') }}
        </div>
      </div>
      <div class="w-1/3 text-center">
        <div class="text-xs">Duration</div>
        <div class="text-lg font-bold">
          {{ selectedAreaSummary.moving_time | format('digital_duration') }}
        </div>
      </div>
      <div class="w-1/3 text-right">
        <div class="text-xs">Elevation</div>
        <div class="text-lg font-bold">
          {{ selectedAreaSummary.total_elevation_gain | format('elevation') }}
        </div>
      </div>
    </div>

    <div class="flex justify-between py-1 my-3 border-t border-b border-gray-500">
      <div class="text-muted mr-auto text-xs text-right uppercase"></div>
      <div class="text-muted w-10 ml-2 text-xs text-right">Avg</div>
      <div class="text-muted w-10 ml-2 text-xs text-right">Max</div>
      <div class="text-muted w-8 ml-3 text-xs text-right"></div>
    </div>

    <div class="flex flex-col justify-start flex-grow">
      <div v-if="hasWattsStream" class="flex items-center justify-between py-2">
        <div class="flex-nowrap whitespace-nowrap flex items-center mr-auto text-sm">
          <svg class="mr-1 -ml-1" height="16" width="16"><circle cx="8" cy="8" r="4" :fill="streamColors.power" /></svg>
          Power
        </div>
        <div class="w-10 ml-2 text-right">
          {{ selectedAreaSummary.computed_avg_watts | format('power', false, false, false) }}
        </div>
        <div class="w-10 ml-2 text-right">
          {{ selectedAreaSummary.max_watts | format('power', false, false, false) }}
        </div>
        <div class="w-8 ml-3 text-xs text-gray-500">
          {{ formatUnits('power') }}
        </div>
      </div>

      <div v-if="hasWattsStream" class="border-t border-dotted" />

      <div v-if="hasHeartRateStream" class="flex items-center justify-between py-2">
        <div class="flex-nowrap flex items-center mr-auto overflow-visible text-sm">
          <svg class="mr-1 -ml-1" height="14" width="14"><circle cx="8" cy="8" r="4" :fill="streamColors.heartrate" /></svg>
          Heart&nbsp;Rate
        </div>
        <div class="w-10 ml-2 text-right">
          {{ selectedAreaSummary.computed_average_heartrate | format('heartrate', false, false, false) }}
        </div>
        <div class="w-10 ml-2 text-right">
          {{ selectedAreaSummary.max_heartrate | format('heartrate', false, false, false) }}
        </div>
        <div class="w-8 ml-3 text-xs text-gray-500">
          {{ formatUnits('heartrate') }}
        </div>
      </div>

      <div v-if="hasHeartRateStream" class="border-t border-dotted" />

      <div v-if="hasVelocityStream" class="flex items-center justify-between py-2">
        <div class="flex-nowrap whitespace-nowrap flex items-center mr-auto text-sm">
          <svg class="mr-1 -ml-1" height="14" width="14"><circle cx="8" cy="8" r="4" :fill="streamColors.speed" /></svg>
          Speed
        </div>
        <div class="w-10 ml-2 text-right">
          {{ selectedAreaSummary.average_speed | format('speed', false, false, false) }}
        </div>
        <div class="w-10 ml-2 text-right">
          {{ selectedAreaSummary.max_speed | format('speed', false, false, false) }}
        </div>
        <div class="w-8 ml-3 text-xs text-gray-500">
          {{ formatUnits('speed') }}
        </div>
      </div>

      <div v-if="hasVelocityStream" class="border-t border-dotted" />

      <div v-if="hasGradeStream" class="flex items-center justify-between py-2">
        <div class="flex-nowrap whitespace-nowrap flex items-center mr-auto text-sm">
          <svg class="mr-1 -ml-1" height="14" width="14"><circle cx="8" cy="8" r="4" :fill="streamColors.altitude" /></svg>
          Grade/VAM
        </div>
        <div class="w-10 ml-2 text-right">
          {{ ((selectedAreaSummary.total_elevation_gain / (selectedAreaSummary.distance * 1000)) * 100) | format('short_decimal', false, false, false) }}%
        </div>
        <div class="w-10 ml-2 text-right">
          {{ selectedAreaSummary.vam | format('vam', false, false, false) }}
        </div>
        <div class="w-8 ml-3 text-xs text-gray-500">
          {{ formatUnits('vam') }}
        </div>
      </div>

      <div v-if="hasGradeStream" class="border-t border-dotted" />

      <div v-if="hasCadenceStream" class="flex items-center justify-between py-2">
        <div class="flex-nowrap whitespace-nowrap flex items-center mr-auto text-sm">
          <svg class="mr-1 -ml-1" height="14" width="14"><circle cx="8" cy="8" r="4" :fill="streamColors.cadence" /></svg>
          Cadence
        </div>
        <div class="w-10 ml-2 text-right">
          <span v-if="selectedAreaSummary.average_cadence">
            {{ selectedAreaSummary.average_cadence | format('cadence', false, false, false) }}
          </span>
          <span v-else>–</span>
        </div>
        <div class="w-10 ml-2 text-right">
          <span v-if="selectedAreaSummary.max_cadence">
            {{ selectedAreaSummary.max_cadence | format('cadence', false, false, false) }}
          </span>
          <span v-else>–</span>
        </div>
        <div class="w-8 ml-3 text-xs text-gray-500">
          {{ formatUnits('cadence') }}
        </div>
      </div>

      <div v-if="hasCadenceStream" class="border-t border-dotted" />

      <div v-if="hasWattsStream" class="flex items-center justify-between py-2">
        <div class="flex-nowrap whitespace-nowrap flex items-center mr-auto text-sm">
          <svg class="mr-1 -ml-1" height="14" width="14"><circle cx="8" cy="8" r="4" :fill="streamColors.power" /></svg>
          W/kg
        </div>
        <div class="w-10 ml-2 text-right">
          {{ (selectedAreaSummary.computed_avg_watts / user.weight_metric) | format('power_per_kg', false, false, false) }}
        </div>
        <div class="w-10 ml-2 text-right">
          {{ (selectedAreaSummary.max_watts / user.weight_metric) | format('power_per_kg', false, false, false) }}
        </div>
        <div class="w-8 ml-3 text-xs text-gray-500">
          {{ formatUnits('power_per_kg') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formatUnits from 'filters/formatUnits'
import streamColors from 'constants/streamColors'

export default {
  props: {
    selectedAreaSummary: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    hasWattsStream: {
      type: Boolean,
      required: true
    },
    hasHeartRateStream: {
      type: Boolean,
      required: true
    },
    hasCadenceStream: {
      type: Boolean,
      required: true
    },
    hasGradeStream: {
      type: Boolean,
      required: true
    },
    hasVelocityStream: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      formatUnits,
      streamColors
    }
  }
}
</script>

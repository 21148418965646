import config from 'config'

export default function formatUnits(type) {
  let units

  const imperial = config.user?.measurement_preference == 'feet'

  switch (type) {
    case 'distance':
      units = imperial ? 'mi' : 'km'
      break
    case 'speed':
      units = imperial ? 'mi/h' : 'km/h'
      break
    case 'elevation':
      units = imperial ? 'ft' : 'm'
      break
    case 'heartrate':
      units = 'bpm'
      break
    case 'cadence':
      units = 'rpm'
      break
    case 'hours':
      units = 'h'
      break
    case 'work':
      units = 'kJ'
      break
    case 'vam':
      units = 'm/h'
      break
    case 'percent':
      units = '%'
      break
    case 'power':
      units = 'W'
      break
    case 'power_per_kg':
      units = 'W/kg'
      break
  }

  return units
}

<template>
  <vue-good-table
    v-if="activities && activities.length > 1"
    :columns="columns"
    :rows="currentActivities"
    style-class="vgt-table"
    :search-options="{ enabled: false }"
    :row-style-class="rowStyleClassFn"
    @on-row-mouseenter="onRowMouseover"
    @on-row-mouseleave="onRowMouseleave"
    @on-row-click="onRowClick"
  >
    <template slot="table-column" slot-scope="props">
      <span v-if="props.column.label == 'Name'" class="pl-4">
        {{ props.column.label }}
      </span>
      <span v-else>
        {{ props.column.label }}
      </span>
    </template>
    <template slot="table-row" slot-scope="props">
      <div v-if="props.column.field == 'start_date_local' && props.column.label == 'Activity'">
        <div class="flex items-center w-auto">
          <router-link :to="{ name: 'dashboard.activity', params: { id: props.row.id } }" class="w-16 h-16 ml-1 mr-3 bg-gray-100 rounded-lg">
            <img
              v-if="props.row.map_summary_polyline"
              class="w-full h-full rounded-lg"
              :src="`https://www.formbeat.com/staticmap/?sensor=false&size=200x200&maptype=roadmap&path=weight:4|color:0xff5933FF|enc:${props.row.map_summary_polyline}`"
              alt=""
            />
          </router-link>
          <div class="w-64 ml-2 truncate">
            <router-link
              :to="{ name: 'dashboard.activity', params: { id: props.row.id } }"
              class="hover:text-primary hover:no-underline flex items-center font-bold"
            >
              <div v-if="props.row.id == activity.id" class="bg-primary w-2 h-2 mr-2 rounded-full"></div>
              {{ props.row.name }}
            </router-link>
            <div class="text-sm text-gray-600">
              {{ props.row.start_date_local | relativeTime }}
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="props.column.field == 'start_date_local'">
        {{ props.row.start_date_local | date('MMM DD, YYYY') }}
      </div>
      <div v-else-if="['computed_weighted_power', 'computed_avg_watts'].includes(props.column.field)">
        <span v-if="props.row[props.column.field] > 0">
          {{ props.row[props.column.field] | format(props.column.format) }}
        </span>
        <span v-else>–</span>
      </div>
      <div v-else-if="props.column.field == 'tss'">
        <span class="font-bold">
          {{ props.row[props.column.field] | format(props.column.format) }}
        </span>
      </div>
      <span v-else>
        <span class="font-bold">
          {{ props.row[props.column.field] | format(props.column.format) }}
        </span>
      </span>
    </template>
    <div
      v-if="activities.length > activitiesToShow"
      slot="table-actions-bottom"
      class="text-muted hover:bg-gray-100 hover:text-black px-4 py-3 text-center cursor-pointer"
      @click.prevent="showAll = !showAll"
    >
      Show {{ showAll ? 'Less &uarr;' : 'More &darr;' }}
    </div>
  </vue-good-table>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

export default {
  components: {
    VueGoodTable
  },

  props: {
    activity: {
      type: Object,
      required: true
    },
    activities: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      activitiesToShow: 5,
      showAll: false,
      highlightedRowIndex: undefined,
      columns: [
        {
          label: 'Activity',
          field: 'start_date_local',
          firstSortType: 'desc'
        },
        {
          label: 'Date',
          field: 'start_date_local',
          align: 'right',
          width: '9rem',
          firstSortType: 'desc'
        },
        {
          label: 'TSS',
          field: 'tss',
          type: 'number',
          format: 'numeric',
          width: '5rem',
          firstSortType: 'desc'
        },
        {
          label: 'Speed',
          field: 'average_speed',
          type: 'decimal',
          format: 'speed',
          width: '7rem',
          firstSortType: 'desc'
        },
        {
          label: 'Avg HR',
          field: 'computed_average_heartrate',
          type: 'number',
          format: 'numeric',
          width: '7rem',
          firstSortType: 'desc'
        },
        {
          label: 'Avg Power',
          field: 'computed_avg_watts',
          type: 'number',
          format: 'power',
          width: '7rem',
          firstSortType: 'desc'
        },
        {
          label: 'Normalized Power',
          field: 'computed_weighted_power',
          type: 'number',
          format: 'power',
          width: '10rem',
          firstSortType: 'desc'
        },
        {
          label: 'Intensity',
          field: 'tss_per_hour',
          type: 'decimal',
          format: 'is_percent',
          width: '7rem',
          firstSortType: 'desc'
        }
      ]
    }
  },

  computed: {
    currentActivities() {
      return this.showAll ? this.activities : this.activities.slice(0, this.activitiesToShow)
    }
  },

  methods: {
    onRowMouseover(params) {
      this.highlightedRowIndex = params.row.originalIndex
    },

    onRowMouseleave() {
      this.highlightedRowIndex = null
    },

    rowStyleClassFn(row) {
      return row.originalIndex === this.highlightedRowIndex ? 'cursor-pointer' : ''
    },

    onRowClick(params) {
      if (params.row.id === this.activity.id) {
        return
      }
      if (params.event.metaKey) {
        const { href } = this.$router.resolve({ name: 'dashboard.activity', params: { id: params.row.id } })
        window.open(window.location.origin + href)
      } else {
        this.$router.push({ name: 'dashboard.activity', params: { id: params.row.id } })
      }
    }
  }
}
</script>

<template>
  <div v-if="preferences" class="Settings">
    <h1 class="text-3xl font-bold">Settings</h1>

    <nav class="flex py-4 mb-6 border-t border-b">
      <router-link :to="{ name: 'settings.profile' }" class="mr-8">Profile</router-link>
      <router-link :to="{ name: 'settings.heartrate' }" class="mr-8">Heart Rate</router-link>
      <router-link :to="{ name: 'settings.heartrate-zones' }" class="mr-8">Zones</router-link>
      <router-link :to="{ name: 'settings.power' }" class="mr-8">Power & FTP</router-link>
      <router-link :to="{ name: 'settings.weight' }" class="mr-8">Weight</router-link>
      <router-link :to="{ name: 'settings.other-sports' }" class="mr-8">Other sports</router-link>
      <router-link :to="{ name: 'settings.connections' }" class="mr-8">Connections</router-link>
      <router-link :to="{ name: 'settings.notifications' }" class="mr-8">Notifications</router-link>
      <router-link :to="{ name: 'settings.membership' }" class="mr-8">Membership</router-link>
    </nav>

    <div>
      <router-view :form="form" :errors="errors" :user="user" @update="update" />

      <div v-if="showSaveButton" class="OnboardingFooter flex">
        <AppButton primary small class="ml-auto" @click="submit()">Save</AppButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    user: {
      type: Object
    }
  },

  data() {
    return {
      form: {}
    }
  },

  computed: {
    ...mapGetters('preferences', ['preferences', 'errors']),

    showSaveButton() {
      return !['settings.power', 'settings.weight', 'settings.connections', 'settings.membership'].includes(this.$route.name)
    }
  },

  watch: {
    preferences: {
      immediate: true,
      handler() {
        this.form = { ...this.preferences }
      }
    }
  },

  methods: {
    ...mapActions('preferences', ['updatePreferences', 'savePreferences']),

    update(preferences) {
      this.updatePreferences(preferences)
    },

    async submit() {
      try {
        const result = await this.savePreferences()
        result && window.location.reload()
      } catch (e) {
        // TODO
      }
    }
  },

  metaInfo() {
    return {
      title: 'Settings'
    }
  }
}
</script>

<style lang="postcss">
.Settings .OnboardingStep__header {
  display: none;
}
.Settings .Settings--isHidden {
  display: none;
}
.Settings .router-link-active {
  @apply text-primary font-bold;
}
</style>

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start()
// require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Vue from 'vue'
import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'

import 'sentry'

import router from 'router'
import store from 'store'
import config from 'config'

import 'filters'
import 'mixpanel'

import ActionCableVue from 'actioncable-vue'
Vue.use(ActionCableVue, {
  // debug: true,
  // debugLevel: 'error',
  connectionUrl: config.websocket_url,
  connectImmediately: true
})

import more from 'highcharts/highcharts-more'
more(Highcharts)

import accessibility from 'highcharts/modules/accessibility'
accessibility(Highcharts)

import exporting from 'highcharts/modules/exporting'
exporting(Highcharts)

import coloraxis from 'highcharts/modules/coloraxis'
coloraxis(Highcharts)

import variwide from 'highcharts/modules/variwide'
variwide(Highcharts)

import stockInit from 'highcharts/modules/stock'
stockInit(Highcharts)

Highcharts.setOptions({
  time: {
    useUTC: false
  }
})

Highcharts.dateFormats.W = function (timestamp) {
  var date = new Date(timestamp)
  date.setHours(0, 0, 0, 0)
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7))
  // January 4 is always in week 1.
  var week1 = new Date(date.getFullYear(), 0, 4)
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7)
}

Vue.use(HighchartsVue, { tagName: 'chart' })

// Activity indicator
import VueActivityIndicator from 'vue-activity-indicator'
Vue.use(VueActivityIndicator)

import LastModal from 'vue-last-modal'
Vue.use(LastModal)

import PortalVue from 'portal-vue'
Vue.use(PortalVue)

// Metatags
import VueMeta from 'vue-meta'
Vue.use(VueMeta)

import VueMobileDetection from 'vue-mobile-detection'
Vue.use(VueMobileDetection)

// Progress Bar
import VueProgressBar from 'vue-progressbar'
Vue.use(VueProgressBar, {
  color: '#ffab98',
  failedColor: '#cb4848',
  thickness: '1px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 600
  },
  autoRevert: true,
  inverse: false
})

// Global components
import globalComponents from 'components/global'

Object.entries(globalComponents).forEach(([name, component]) => {
  Vue.component(name, component)
})

import App from 'app'

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    created: () => store.dispatch('init'),
    render: (h) => h(App),
    router,
    store
  }).$mount()

  document.body.appendChild(app.$el)
})

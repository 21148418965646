<template>
  <div class="NotFoundView flex flex-col">
    <div class="m-auto text-center">
      <div class="my-12">
        <Logo class="mb-4" />
        Oops, the page you are looking for doesn't exist
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'prerender-status-code', content: '404' }
    ]
  }
}
</script>

<template>
  <div class="StatsCard h-full">
    <div class="StatsCard__title">
      <div>{{ title }}</div>
      <slot name="tooltip"></slot>
    </div>
    <h2 class="StatsCard__score">
      <div class="flex items-center py-2 space-x-2 text-3xl">
        <svg class="svg-icon" :style="{ color: trainingStatusColors[score.status] || '#fcfcfc' }">
          <use :xlink:href="`#icon-${trainingStatusIcons[score.status] || 'circle'}`"></use>
        </svg>
        <div>{{ score.status }}</div>
      </div>
    </h2>
  </div>
</template>

<script>
import trainingStatusColors from 'constants/trainingStatusColors'
import trainingStatusIcons from 'constants/trainingStatusIcons'

import dayjs from 'utils/dayjs'
// import { dateIsBetween } from 'utils/date'

export default {
  props: {
    data: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      trainingStatusColors,
      trainingStatusIcons
    }
  },

  computed: {
    score() {
      const dateToday = dayjs().format('YYYY-MM-DD')
      // const dateLastWeek = dayjs().subtract(7, 'days').format('YYYY-MM-DD')
      // const dateTwoWeeksAgo = dayjs().subtract(14, 'days').format('YYYY-MM-DD')
      //
      const today = this.data.find(({ date }) => date == dateToday)
      // const lastWeek = this.data.find(({ date }) => date == dateLastWeek)
      //
      // // calculate fitness trend
      // let fitnessTrend
      //
      // if (today.ctl - lastWeek.ctl > 2) {
      //   fitnessTrend = 1
      // } else if (lastWeek.ctl - today.ctl < 1) {
      //   fitnessTrend = 0
      // } else {
      //   fitnessTrend = -1
      // }
      //
      // // calculate training load trend
      // let loadTrend
      //
      // const dataPastWeek = this.data.filter(({ date }) => dateIsBetween(date, dateLastWeek, dateToday))
      // const dataWeekBefore = this.data.filter(({ date }) => dateIsBetween(date, dateTwoWeeksAgo, dateLastWeek))
      //
      // const loadPastWeek = dataPastWeek.reduce((a, b) => a + b.tss, 0)
      // const loadWeekBefore = dataWeekBefore.reduce((a, b) => a + b.tss, 0)
      //
      // if (loadPastWeek > 0 && loadWeekBefore > 0) {
      //   const ratio = loadPastWeek / loadWeekBefore
      //   if (ratio > 1.15) {
      //     loadTrend = 1
      //   } else if (ratio < 0.85) {
      //     loadTrend = -1
      //   } else {
      //     loadTrend = 0
      //   }
      // } else if (loadPastWeek > 0) {
      //   loadTrend = 1
      // } else {
      //   loadTrend = 0
      // }

      return {
        status: today.status
        // fitnessTrend: fitnessTrend,
        // loadTrend: loadTrend
      }
    }
  }
}
</script>

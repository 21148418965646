<template>
  <modal-layout title="Delete Weight">
    <div class="FormGroup border-t">
      <p class="my-6 text-base">
        Do you really want to
        <b>delete</b>
        this weight value of {{ weight.weight }} from {{ weight.effective_from | date }}?
      </p>
    </div>

    <template slot="footer">
      <div class="flex justify-end">
        <AppButton class="mr-2" primary small @click="cancel()">No</AppButton>
        <AppButton primary small @click="submit()">Yes</AppButton>
      </div>
    </template>
  </modal-layout>
</template>

<script>
export default {
  props: {
    weight: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      errors: {},
      form: {
        id: this.weight.id,
        weight: this.weight.weight,
        effective_from: this.weight.effective_from
      }
    }
  },

  methods: {
    cancel() {
      this.$emit('close', false)
    },
    submit() {
      if (this.form.id) {
        this.$emit('close', this.form)
      }
    }
  }
}
</script>

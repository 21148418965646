<template>
  <div class="OnboardingStep">
    <div class="FormGroup">
      <div class="FormGroup__label">
        Running Functional Threshold Pace
        <div class="text-muted mt-2 text-xs">Average pace from a recent race or a hard training run of 45-60 minutes. (e.g. 4:00/km).</div>
        <InputErrorMessage v-if="errors.running_ftp" :error="errors.running_ftp" />
      </div>
      <div class="FormGroup__content md:w-1/4 flex items-center">
        <div>
          <div class="label">Mins</div>
          <SelectInput v-model="running_ftp_minutes" class="w-24" value-key="value" label-key="label" :options="minutes" @input="updateRunningFtp()" />
        </div>

        <div class="whitespace-nowrap w-auto h-2 mx-4">:</div>

        <div>
          <div class="label">Secs</div>
          <SelectInput v-model="running_ftp_seconds" class="w-24" value-key="value" label-key="label" :options="seconds" @input="updateRunningFtp()" />
        </div>

        <div class="whitespace-nowrap w-auto h-2 ml-4">/&nbsp;km</div>
      </div>
    </div>

    <div class="FormGroup FormGroup--last">
      <div class="FormGroup__label">
        Swimming Functional Threshold Pace
        <div class="text-muted mt-2 text-xs">
          Average distance in meters you can swim in one minute
          <br />
          calculated from 30-minute or critical velocity test.
          <a class="underline cursor-pointer" @click.prevent="swimming_ftp_help">Learn more</a>
        </div>
        <InputErrorMessage v-if="errors.swimming_ftp" :error="errors.swimming_ftp" />
      </div>
      <div class="FormGroup__content md:w-1/4 flex items-center">
        <div>
          <div class="label">Meters</div>
          <TextInput v-model="form.swimming_ftp" class="w-auto" placeholder="e.g. 50 meters/minute" type="number" @change="update()" />
        </div>

        <div class="whitespace-nowrap w-auto h-2 ml-4">/&nbsp;min</div>
      </div>
    </div>

    <div class="FormGroup FormGroup--last mt-6 border-t">
      <div class="text-muted flex items-center justify-center text-sm text-center">
        <svg class="svg-icon svg-icon--sm mr-2">
          <use xlink:href="#icon-info"></use>
        </svg>
        Set your threshold values so we can estimate the TSS from your runs and swims.
      </div>
    </div>
  </div>
</template>

<script>
import padded from 'filters/padded'
import InfoModal from 'views/Modals/InfoModal'

const swimmingFtpDescription = `### 30-minute test

Swim for 30 or 60 minutes as fast as you can, then record how far you went. For example, if you swam 30 minutes and cover 1000 meters, then you can use the value of 1000 ÷ 30 = 33.3 m/min as your Functional Threshold Pace.

Since the actual FTP should be closer to the one-hour effort, it might be more advisable to perform a 60-minute test or to take the value obtained for 30 minutes, multiply by two and subtract 2.5 percent (as most trained swimmers swim roughly 2.5 percent slower in a 60-minute maximal effort than in a 30-minute maximal effort). So again, if you cover 1000 m in 30 minutes, your 60-minute FTP would be (1000 * 2 * 0.975) ÷ 60 = 32.5 m/min.
<br>
<br>

### Critical Velocity Test

You can also calculate your threshold pace from your 400m and 200m best efforts, with a full recovery between efforts.
Complete both tests and record your time. Then use this formula to calculate your swimming pace:

**(long swim distance – short swim distance) ÷ ((long swim time in seconds - short swim time in seconds) ÷ 60)**

For example, if you swim your 200m test swim in 4:00 min (240 seconds) and your 400m test swim in 9:00 (540 seconds). Your critical velocity is (400 – 200) ÷ ((540 - 240) ÷ 60) = 40.0 m/min
`

export default {
  props: {
    form: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      required: false
    }
  },

  data() {
    return {
      running_ftp_minutes: Math.floor(this.form.running_ftp / 60),
      running_ftp_seconds: Math.floor(this.form.running_ftp % 60)
    }
  },

  computed: {
    minutes() {
      const values = Array.from(Array(10).keys())
      return values.map((v) => {
        return {
          value: v,
          label: padded(v)
        }
      })
    },
    seconds() {
      const values = Array.from(Array(60).keys())
      return values.map((v) => {
        return {
          value: v,
          label: padded(v)
        }
      })
    }
  },

  methods: {
    update() {
      this.$emit('update', this.form)
    },

    updateRunningFtp() {
      const running_ftp = Number(this.running_ftp_minutes * 60 + Number(this.running_ftp_seconds))
      this.$emit('update', {
        ...this.form,
        running_ftp
      })
    },

    swimming_ftp_help() {
      this.$modal(InfoModal, { title: 'Determine your Swimming Functional Threshold Pace', description: swimmingFtpDescription })
    }
  }
}
</script>

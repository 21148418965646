<template>
  <div class="StatsSection py-6 mb-8 bg-white shadow" :class="`StatsSection--${size}`">
    <div class="flex items-center w-full px-6 mb-8">
      <h2 class="text-lg font-medium">{{ title }}</h2>
      <header class="ml-auto">
        <slot name="header"></slot>
      </header>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false
    },
    size: {
      type: String,
      required: false,
      default: 'base'
    }
  }
}
</script>

<style lang="postcss" scoped>
.StatsSection {
  @apply w-full;

  &--small {
    @apply pt-3 pb-1;

    > div {
      @apply mb-4;
    }
  }
}
</style>

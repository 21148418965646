<template>
  <modal-layout v-if="form" title="Edit Activity">
    <div class="FormGroup border-t">
      <div class="FormGroup__label">
        Name
        <InputErrorMessage v-if="errors.name" :error="errors.name" />
      </div>
      <div class="FormGroup__content md:w-3/5">
        <TextInput v-model="form.name" class="w-full" placeholder="Activity name" />
      </div>
    </div>

    <div class="FormGroup">
      <div class="FormGroup__label">
        Activity Type
        <InputErrorMessage v-if="errors.activity_type" :error="errors.activity_type" />
      </div>
      <div class="FormGroup__content md:w-3/5">
        <SelectInput v-model="form.activity_type" class="w-full" :options="activityTypes" value-key="value" label-key="label" />
      </div>
    </div>

    <div class="FormGroup">
      <div class="FormGroup__label">
        TSS
        <InputErrorMessage v-if="errors.tss" :error="errors.tss" />
      </div>
      <div class="FormGroup__content md:w-3/5">
        <TextInput v-model="form.tss" class="w-full" placeholder="Activity name" />
      </div>
    </div>

    <template slot="footer">
      <div class="flex justify-end">
        <AppButton class="mr-2" primary small @click="cancel()">Cancel</AppButton>
        <AppButton primary small @click="submit()">Save</AppButton>
      </div>
    </template>
  </modal-layout>
</template>

<script>
import ValidationService, { isRequired, isMin, isMax } from 'services/ValidationService'

export default {
  props: {
    resource: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      errors: {},
      form: undefined,
      activityTypes: [
        {
          label: 'Ride',
          value: 'Ride'
        },
        {
          label: 'Virtual Ride',
          value: 'VirtualRide'
        },
        {
          label: 'Run',
          value: 'Run'
        },
        {
          label: 'Virtual Run',
          value: 'VirtualRun'
        },
        {
          label: 'Swim',
          value: 'Swim'
        }
      ]
    }
  },

  mounted() {
    this.form = { ...this.resource }
  },

  methods: {
    cancel() {
      this.$emit('close', false)
    },
    async submit() {
      // Validate activity
      this.errors = {}

      const rules = {
        name: [isRequired()],
        activity_type: [isRequired()],
        tss: [isRequired(), isMin(0), isMax(1000)]
      }

      const result = await ValidationService.validate(this.form, rules)

      if (!result.isValid) {
        this.errors = result.errors
      } else {
        this.$emit('close', this.form)
      }
    }
  }
}
</script>

<template>
  <div>
    <SectionTitle title="Users">
      <input v-model="searchQuery" class="input" type="search" placeholder="Search" />
    </SectionTitle>

    <vue-good-table
      class="mb-16"
      :columns="columns"
      :rows="users"
      :row-style-class="rowStyleClassFn"
      style-class="vgt-table"
      :sort-options="{
        enabled: true,
        initialSortBy: { field: 'id', type: 'desc' }
      }"
      :search-options="{
        enabled: false,
        externalQuery: searchQuery
      }"
      :pagination-options="{
        enabled: true,
        mode: 'pages',
        perPage: 25,
        position: 'bottom',
        dropdownAllowAll: true,
        setCurrentPage: 1,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page', // for 'pages' mode
        allLabel: 'All'
      }"
    >
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field == 'utm_campaign'" class="text-sm">
          <a
            v-if="props.row.utm_campaign"
            target="_blank"
            :href="`https://www.facebook.com/adsmanager/manage/campaigns?act=50622971&columns=name%2Cdelivery%2Ccampaign_name%2Cbid%2Cbudget%2Clast_significant_edit%2Cresults%2Creach%2Cimpressions%2Ccost_per_result%2Cquality_score_organic%2Cquality_score_ectr%2Cquality_score_ecvr%2Cspend%2Cend_time%2Cschedule%2Cactions%3Alink_click%2Cactions%3Avideo_view%2Cvideo_avg_percent_watched_actions%3Avideo_view%2Cvideo_avg_time_watched_actions%3Avideo_view&attribution_windows=default&filter_set=SEARCH_BY_CAMPAIGN_ID-STRING%1EEQUAL%1E%22${props.row.utm_campaign}%22`"
          >
            {{ props.row.utm_campaign }}
          </a>
        </div>
        <div v-else-if="props.column.field == 'email'" class="text-sm">
          <h4 class="text-base font-bold">{{ props.row.full_name }}</h4>
          <span v-if="props.row.summit" class="text-primary text-lg font-bold">&bull;</span>
          <a :href="`https://www.strava.com/athletes/${props.row.strava_id}`" target="_blank">{{ props.row.email }}</a>
        </div>
        <div v-else-if="props.column.field == 'created_at' || props.column.field == 'last_visit' || props.column.field == 'last_activity'" class="w-24 text-sm">
          {{ props.row[props.column.field] | relativeTime }}
        </div>
        <div v-else-if="props.column.field == 'initialized'" class="w-24 text-sm">
          <AppButton secondary xxsmall @click.prevent="signInAs(props.row.id)">Sign in</AppButton>
        </div>
        <div v-else-if="['percent_analyzed', 'initialized_activities'].includes(props.column.field)" class="w-12 text-sm">
          {{ props.row[props.column.field] | format('percent') }}
        </div>
        <div v-else-if="props.column.type == 'boolean'" class="text-sm">
          <span v-if="props.row[props.column.field]" class="text-4xl text-green-500">&bull;</span>
          <span v-else>–</span>
        </div>
        <div v-else class="text-sm">
          {{ props.row[props.column.field] }}
        </div>
      </template>
    </vue-good-table>

    <div v-if="statistics">
      <StatsSection title="Trials and Subscriptions">
        <chart :options="trialsAndSubscriptions"></chart>
      </StatsSection>
      <StatsSection title="Web Visits">
        <chart :options="webVisits"></chart>
      </StatsSection>
      <StatsSection title="Mobile Visits">
        <chart :options="mobileVisits"></chart>
      </StatsSection>
      <StatsSection title="New users">
        <chart :options="signUps"></chart>
      </StatsSection>
      <StatsSection title="Activities">
        <chart :options="activitiesCreated"></chart>
      </StatsSection>
    </div>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

import dayjs from 'utils/dayjs'

import AdminService from 'services/AdminService'
import SectionTitle from 'components/SectionTitle'

import StatsSection from 'components/StatsSection'

const booleanFilterOptions = {
  enabled: true,
  placeholder: '–',
  filterDropdownItems: [
    { value: 'true', text: 'Yes' },
    { value: 'false', text: 'No' }
  ]
}

export default {
  components: {
    StatsSection,
    SectionTitle,
    VueGoodTable
  },

  data() {
    return {
      users: [],
      statistics: undefined,
      searchQuery: '',
      columns: [
        {
          label: 'ID',
          field: 'id',
          type: 'number'
        },
        {
          label: 'E-mail',
          field: 'email'
        },
        {
          label: 'Plan',
          field: 'plan',
          filterOptions: {
            enabled: true,
            placeholder: 'All',
            filterDropdownItems: [
              { value: 'Early Adopter', text: 'Early Adopter' },
              { value: 'Premium', text: 'Premium' },
              { value: 'Trial', text: 'Trial' },
              { value: '-', text: '-' }
            ]
          }
        },
        {
          label: 'Country',
          field: 'country'
        },
        {
          label: 'Activities',
          field: 'activities_count',
          type: 'number'
        },
        {
          label: 'Followers',
          field: 'follower_count',
          type: 'number'
        },
        {
          label: 'Active in 30d',
          field: 'most_active',
          type: 'boolean',
          filterOptions: booleanFilterOptions
        },
        {
          label: 'All Visits',
          field: 'visits',
          type: 'number'
        },
        {
          label: '30d visits',
          field: 'visits_30d',
          type: 'number'
        },
        {
          label: 'Signup',
          field: 'created_at'
        },
        {
          label: 'Last Visit',
          field: 'last_visit'
        },
        {
          label: 'Campaign',
          field: 'utm_campaign'
        },
        {
          label: 'Referrer',
          field: 'referrer'
        },
        {
          label: '',
          field: 'initialized'
        }
      ]
    }
  },

  computed: {
    webVisits() {
      if (!this.statistics) {
        return {}
      }

      return {
        title: '',
        xAxis: {
          type: 'datetime'
        },
        series: [
          {
            name: 'Web visits',
            data: Object.keys(this.statistics.web_visits).map((key) => [dayjs(key).valueOf(), this.statistics.web_visits[key]])
          }
        ]
      }
    },

    mobileVisits() {
      if (!this.statistics) {
        return {}
      }

      return {
        title: '',
        xAxis: {
          type: 'datetime'
        },
        series: [
          {
            name: 'Mobile visits',
            data: Object.keys(this.statistics.mobile_visits).map((key) => [dayjs(key).valueOf(), this.statistics.mobile_visits[key]])
          }
        ]
      }
    },

    signUps() {
      if (!this.statistics) {
        return {}
      }

      return {
        title: '',
        xAxis: {
          type: 'datetime'
        },
        series: [
          {
            name: 'Sign-ups',
            data: Object.keys(this.statistics.users).map((key) => [dayjs(key).valueOf(), this.statistics.users[key]])
          }
        ]
      }
    },

    activitiesCreated() {
      if (!this.statistics) {
        return {}
      }

      return {
        title: '',
        xAxis: {
          type: 'datetime'
        },
        series: [
          {
            name: 'Activities',
            data: Object.keys(this.statistics.activities).map((key) => [dayjs(key).valueOf(), this.statistics.activities[key]])
          }
        ]
      }
    },

    trialsAndSubscriptions() {
      if (!this.statistics) {
        return {}
      }

      return {
        title: '',
        xAxis: {
          type: 'datetime'
        },
        series: [
          {
            name: 'Trials started',
            data: Object.keys(this.statistics.trials).map((key) => [dayjs(key).valueOf(), this.statistics.trials[key]])
          },
          {
            name: 'Subscriptions started',
            data: Object.keys(this.statistics.subscriptions).map((key) => [dayjs(key).valueOf(), this.statistics.subscriptions[key]])
          }
        ]
      }
    }
  },

  async mounted() {
    this.statistics = await AdminService.getStatistics()
    this.users = await AdminService.getUsers()
  },

  methods: {
    rowStyleClassFn(row) {
      if (row.initialized === false) {
        return 'opacity-50 border-l-4 border-gray-200'
      } else if (row.analyzed_activities <= 15) {
        return 'border-l-8 border-red-400'
      }
    },

    async signInAs(id) {
      await AdminService.signInAs(id)
      window.location = window.location.origin
    }
  },

  metaInfo() {
    return {
      title: 'Admin / Users'
    }
  }
}
</script>

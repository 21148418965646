<template>
  <modal-layout title="Deauthorize Garmin Connect?">
    <p class="my-6 text-base">
      Do you really want to
      <b>deauthorize</b>
      Garmin Connect?
    </p>

    <template slot="footer">
      <div class="flex justify-end">
        <AppButton class="mr-2" primary small @click="cancel()">No</AppButton>
        <AppButton primary small @click="submit()">Yes</AppButton>
      </div>
    </template>
  </modal-layout>
</template>

<script>
export default {
  methods: {
    cancel() {
      this.$emit('close', false)
    },
    submit() {
      this.$emit('close', true)
    }
  }
}
</script>

export default function convertUnits(value, type, unitsPreferences = 'meters') {
  let result

  const imperial = unitsPreferences == 'feet'

  switch (type) {
    case 'distance':
      value = imperial ? value * 0.621371 : value
      result = value
      break
    case 'speed':
      value = imperial ? value * 0.621371 : value
      result = value
      break
    case 'elevation':
      value = imperial ? value * 3.28084 : value
      result = value
      break
    default:
      result = value
      break
  }

  return result
}

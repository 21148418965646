<template>
  <div>
    <Header />

    <div class="Auth">
      <div class="Auth__image"></div>
      <div class="Auth__form">
        <div class="w-full">
          <!-- Form -->
          <h1 class="md:text-4xl mb-3 text-3xl font-extrabold leading-tight">
            Take the First Step.
            <br class="sm:block hidden" />
            Join today!
          </h1>
          <p class="mb-6 text-lg">
            Connect your Strava account to get started.
            <br class="sm:block hidden" />
            You can revoke access at any time.
          </p>

          <img src="~images/connect-with-strava.svg" alt="Connect with Strava" class="mb-3 -ml-1 cursor-pointer" width="256" @click="connectStrava()" />
          <label class="mb-4 cursor-pointer select-none">
            <Checkbox v-model="privateScope" class="mr-1" />
            Include private activities
          </label>

          <p class="pt-6 mt-8 border-t">
            Already have an account?
            <router-link :to="{ name: 'signIn' }" class="text-link font-bold">Sign in &raquo;</router-link>
          </p>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import StravaService from 'services/StravaService'
import AnalyticsService from 'services/AnalyticsService'

import Header from 'components/Header'
import Footer from 'components/Footer'

export default {
  components: {
    Header,
    Footer
  },

  data() {
    return {
      privateScope: true
    }
  },

  methods: {
    async connectStrava() {
      StravaService.connect(this.privateScope)
      AnalyticsService.trackEvent('conversion', 'connect-with-strava')
      AnalyticsService.ahoyTrackEvent('connect-with-strava')
    }
  },

  metaInfo() {
    return {
      title: 'Start Training Smarter',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Sign up and start improving your cycling performance'
        }
      ]
    }
  }
}
</script>

<template>
  <div class="SelectInput">
    <select class="input mb-px" :class="{ 'isInvalid': error }" v-bind="$attrs" v-on="listeners">
      <option v-if="placeholder" :value="undefined" selected disabled>
        {{ placeholder }}
      </option>

      <option
        v-for="option in options"
        :key="valueKey === undefined ? option : option[valueKey].toString()"
        :value="valueKey === undefined ? option : option[valueKey]"
      >
        {{ labelKey === undefined ? option : option[labelKey] }}
      </option>
    </select>

    <InputErrorMessage :error="error" />
  </div>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    options: {
      type: Array
    },

    labelKey: {
      type: [String, Number]
    },

    valueKey: {
      type: [String, Number]
    },

    placeholder: {
      type: String
    },

    error: {
      type: String
    }
  },

  computed: {
    listeners() {
      return {
        ...this.$listeners,
        change: (e) => this.$emit('input', e.target.value),
        input: (e) => this.$emit('input', e.target.value)
      }
    }
  }
}
</script>
